import React, {useEffect, useState} from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, Font, Image } from '@react-pdf/renderer';
import axios from "../../../../../utils/axios";
import apiConfig from "../../../../../apiConfig";
import { formatPhoneNumber } from 'utils/formatNumber';

const useStyles = makeStyles(theme => ({
    
}));

const styles = StyleSheet.create({
    invoiceSection: {
        fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 20
    },
    sectionOne: {
        flexDirection: "row",
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center'
    },
    textBold: {
        fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px'
    },
    sectionDateAndInvoice: {
        width: '50%', height: 'auto', flexDirection: 'row'
    },
    sectionChildDateAndInvoice: {
        fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '50%', border: 1, textAlign: 'center', padding: 6
    },
    sectionTwo: {
        marginTop: 50, 
        flexDirection: "row",
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center'
    },
    sectionThree: {
        marginTop: 10, 
        flexDirection: "row",
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center'
    },
    dueDate: {
        fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '100%', border: 1, textAlign: 'center', padding: 6
    },
    sectionTable: {
        width: '100%', height: 'auto', flexDirection: 'row'
    },
    fieldSet:{
        padding: 8,
        borderRadius: 3,
        borderWidth: 1,
        borderColor: '#000',
    },
    legend:{
        position: 'absolute',
        top: -6,
        left: 10,
        fontWeight: 'bold',
        backgroundColor: '#FFFFFF',
        fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10,
        paddingRight: 5,
        paddingLeft: 5
    }
});

const RoutePdf = (props) => {
    const classes = useStyles();
    const [data, setData] = useState([]);

    useEffect(() => {
        if(props.routeInfoPdf) {
            setData(props.routeInfoPdf);
        }
    }, [props])

    Font.register({
        family: 'Roboto',
        fonts: [
            {
                src: `https://cdn.jsdelivr.net/npm/roboto-fontface@0.10.0/fonts/roboto/Roboto-Regular.woff`,
                fontWeight: 'medium'
            },
            {
                src: `https://cdn.jsdelivr.net/npm/roboto-fontface@0.10.0/fonts/roboto/Roboto-Bold.woff`,
                fontWeight: 'bold'
            }
        ]
    })

    return (
        <>
            <Document>
                <Page size="A4" style={{ padding: 20, paddingTop: 20 }}>
                    {/* header */}
                    <View fixed style={{ paddingLeft: 10, paddingRight: 10 }}>
                        <View style={{ width: '100%', flexDirection: 'row' }}>
                            <View style={{ width: '10%', height: 'auto', alignItems: 'center' }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                    {moment().format('MM/DD/YYYY')}
                                </Text>
                            </View>
                            <View style={{ width: '1%' }} />
                            <View style={{ width: '80%', height: 'auto', alignItems: 'center' }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13 }}>
                                    Squeegee Squad
                                </Text>
                            </View>
                            <View style={{ width: '1%' }} />
                            <View style={{ width: '10%', height: 'auto', alignItems: 'center' }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }} render={({ pageNumber, totalPages}) => "Page " + pageNumber +" of "+totalPages} fixed />
                            </View>
                        </View>
                        <View style={{ width: '100%', flexDirection: 'row' }}>
                            <View style={{ width: '10%', height: 'auto', alignItems: 'center' }} />
                            <View style={{ width: '1%' }} />
                            <View style={{ width: '80%', height: 'auto', alignItems: 'center' }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 14 }}>
                                    Route Report for {moment(props.routeInfoPdf.routeDate).format('MM/DD/YYYY')}
                                </Text>
                            </View>
                            <View style={{ width: '1%' }} />
                            <View style={{ width: '10%', height: 'auto', alignItems: 'center' }} />
                        </View>
                    </View>
                    
                    
                    <View fixed style={{ paddingLeft: 10, paddingRight: 10, marginTop: 40 }}>
                        <View style={{ width: '100%', flexDirection: 'row' }}>
                            <View style={{ width: '20%', height: 'auto' }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 11 }}>
                                    Date
                                </Text>
                            </View>
                            <View style={{ width: '1%' }} />
                            <View style={{ width: '79%', height: 'auto' }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 11 }}>
                                    Date Route Description and Summary
                                </Text>
                            </View>
                        </View>
                        <View style={{ width: '100%', flexDirection: 'row', marginTop: 10 }}>
                            <View style={{ width: '20%', height: 'auto' }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                    {moment(props.routeInfoPdf.routeDate).format('MM/DD/YYYY')}
                                </Text>
                            </View>
                            <View style={{ width: '1%' }} />
                            <View style={{ width: '79%', height: 'auto' }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                    {props.routeInfoPdf && props.routeInfoPdf.route && props.routeInfoPdf.route.cities.join(", ")}
                                    {" "}
                                    ${props.routeInfoPdf && props.routeInfoPdf.route && props.routeInfoPdf.route.total.toFixed(2)}
                                </Text>
                            </View>
                        </View>
                    </View>

                    {/* end header */}

                    <View style={{ borderBottomWidth: 4, borderBottomColor: 'black', marginTop: 5 }} />

                    <View style={{ paddingLeft: 10, paddingRight: 10, marginTop: 10, flexDirection: 'row' }}>
                        <View>
                            <View style={{ width: '100%', flexDirection: 'row' }}>
                                <View style={{ width: '100%', height: 'auto' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 11 }}>
                                        Route Notes:
                                    </Text>
                                </View>
                            </View>
                            <View style={{ width: '100%', flexDirection: 'row' }}>
                                <View style={{ width: '100%', height: 'auto' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                        {props.routeInfoPdf && props.routeInfoPdf.route && props.routeInfoPdf.route.routeNote}
                                    </Text>
                                </View>
                            </View>
                        </View>
                        <View>
                            <View style={{ width: '100%', flexDirection: 'row' }}>
                                <View style={{ width: '100%', height: 'auto' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 11 }}>
                                        Route Equipment:
                                    </Text>
                                </View>
                            </View>
                            <View style={{ width: '100%', flexDirection: 'row' }}>
                                <View style={{ width: '100%', height: 'auto' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                        {props.routeInfoPdf && props.routeInfoPdf.route && props.routeInfoPdf.route.routeEquipment.join(", ")}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>

                    <View style={{ paddingLeft: 5, paddingRight: 5, marginTop: 5 }}>
                        <View style={{ width: '50%', flexDirection: 'row' }}>
                            <View style={{ width: '100%', height: 'auto', borderWidth: 1, borderColor: 'black', padding: 10, borderBottomWidth: 0 }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 11, textAlign: 'center' }}>
                                    Cleaners
                                </Text>
                                {props.routeInfoPdf && props.routeInfoPdf.route && props.routeInfoPdf.route.employees.map((data, index) => {
                                    return (
                                        <View key={index} style={{ width: '100%', height: 'auto', marginTop: 3 }}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                                {data.firstName || ''}{" "}{data.lastName || ''}{" "}{formatPhoneNumber(data.phoneNumber) || ''}
                                            </Text>
                                        </View>
                                    )
                                })}
                            </View>
                        </View>
                    </View>

                    <View style={{ paddingLeft: 5, paddingRight: 5 }}>
                        <View style={{  }}>
                            <View style={{ width: '100%', padding: 10, paddingTop: 5, paddingBottom: 0, borderWidth: 1, borderColor: 'black', borderBottomWidth: 0 }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 11 }}>
                                    Jobs
                                </Text>
                            </View>
                            {props.routeInfoPdf && props.routeInfoPdf.route && props.routeInfoPdf.route.jobs.length > 0 && props.routeInfoPdf.route.jobs.map((data, index) => {
                                return (
                                    <View wrap={false} key={index} style={{ borderWidth: 1, borderColor: 'black', borderTopWidth: (index == 0 ? 0 : 1) }}>
                                        <View wrap={false} style={{ flexDirection: 'row' }}>
                                            <View style={{ width: '65%', alignItems: 'center' }}>
                                                <View style={{ width: '100%', height: 'auto', padding: 10, paddingTop: 5, paddingBottom: 5 }}>
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                                        {moment.utc(data.jobStart).local().format('MM/DD/YYYY')}{" "}{moment.utc(data.jobStart).local().format('hh:mm A')}{" - "}{moment.utc(data.jobEnd).local().format('hh:mm A')}
                                                    </Text>
                                                </View>
                                                <View style={{ width: '100%', height: 'auto', padding: 10, paddingTop: 5, paddingBottom: 5 }}>
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 11 }}>
                                                        Address
                                                    </Text>
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                                        {data.jobAddress ? data.jobAddress.name : ""}
                                                    </Text>
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                                        {data.jobAddress ? data.jobAddress.address1 : ""}
                                                    </Text>
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                                        {data.jobAddress ? data.jobAddress.address2 : ""}
                                                    </Text>
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                                        {data.jobAddress ? data.jobAddress.city+", " : ""}
                                                        {data.jobAddress ? data.jobAddress.state.name+" " : ""}
                                                        {data.jobAddress ? data.jobAddress.zipCode : ""}
                                                    </Text>
                                                </View>
                                            </View>
                                            <View style={{ width: '35%', alignItems: 'center' }}>
                                                <View style={{ width: '100%', height: 'auto', padding: 10, paddingTop: 5, paddingBottom: 5 }}>
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                                        {data.jobAddress && data.jobAddress.primaryContact ? data.jobAddress.primaryContact.name : ""}
                                                    </Text>
                                                    {/* <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                                        Home - (763) 757-9528
                                                    </Text> */}
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                                        {data.jobAddress && data.jobAddress.primaryContact && data.jobAddress.primaryContact.phone != "" ? "Cell - "+formatPhoneNumber(data.jobAddress.primaryContact.phone) : ""}
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>
                                        <View wrap={false} style={{ flexDirection: 'row' }}>
                                            <View style={{ width: '15%', height: 'auto', padding: 10, paddingTop: 5, paddingBottom: 5 }}>
                                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 11 }}>
                                                    Description:
                                                </Text>
                                            </View>
                                            <View style={{ width: '85%', height: 'auto', padding: 10, paddingTop: 5, paddingBottom: 5 }}>
                                                {data.description && data.splitDescription && data.description != data.splitDescription
                                                ? <View>
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                                        {data.description ? data.description : ""}
                                                    </Text>
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                                        {data.splitDescription ? data.splitDescription : ""}
                                                    </Text>
                                                </View>
                                                : <View>
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                                        {data.description ? data.description : ""}
                                                    </Text>
                                                </View>
                                                }
                                            </View>
                                        </View>

                                        {data.jobItems && data.jobItems.length > 0 && data.jobItems.map(function (value, index, array) {
                                            return <View wrap={false} style={{ flexDirection: 'row' }} key={index}>
                                                        <View style={{ width: '15%', height: 'auto', padding: 10, paddingTop: 5, paddingBottom: 5 }}>
                                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, textAlign: 'right' }}>
                                                                {value.quantity}
                                                            </Text>
                                                        </View>
                                                        <View style={{ width: '55%', height: 'auto', padding: 10, paddingTop: 5, paddingBottom: 5 }}>
                                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                                                {value.description}
                                                            </Text>
                                                        </View>
                                                        <View style={{ width: '15%', height: 'auto', padding: 10, paddingTop: 5, paddingBottom: 5 }}>
                                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                                                {(value.price && `$${value.price.toFixed(2)}`) || '$0.00'}
                                                            </Text>
                                                        </View>
                                                        <View style={{ width: '15%', height: 'auto', padding: 10, paddingTop: 5, paddingBottom: 5 }}>
                                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                                                {(value.total && `$${value.total.toFixed(2)}`) || '$0.00'}
                                                            </Text>
                                                        </View>
                                                    </View>
                                        })}

                                        <View wrap={false} style={{ padding: 10, paddingTop: 5, paddingBottom: 5 }}>
                                            <View style={{ borderBottomWidth: 1, borderBottomColor: 'black', width: '100%' }} />
                                        </View>

                                        <View wrap={false} style={{ flexDirection: 'row', paddingLeft: 10, paddingRight: 10 }}>
                                            <View style={{ height: 'auto', paddingTop: 5, paddingBottom: 5, flexDirection: 'row', width: '44%' }}>
                                                <View style={[styles.fieldSet, { width: '100%' }]}>
                                                    {data.discountType ? <Text style={styles.legend}>Discount Type</Text> : null}
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, }}>
                                                        {(data.discountType && data.discountType) || 'Discount Type'}
                                                    </Text>
                                                </View>
                                            </View>
                                            <View style={{ width: '1%' }}/>
                                            <View style={{ height: 'auto', paddingTop: 5, paddingBottom: 5, flexDirection: 'row', width: '23%' }}>
                                                <View style={[styles.fieldSet, { width: '100%' }]}>
                                                    {data.discountPercent ? <Text style={styles.legend}>Discount %</Text> : null}
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, }}>
                                                        {(data.discountPercent && data.discountPercent) || 'Discount %'}
                                                    </Text>
                                                </View>
                                            </View>
                                            <View style={{ width: '1%' }}/>
                                            <View style={{ height: 'auto', paddingTop: 5, paddingBottom: 5, flexDirection: 'row', width: '15%' }}>
                                                <View style={[styles.fieldSet, { width: '100%' }]}>
                                                    {data.discountDollars ? <Text style={styles.legend}>Discount $</Text> : null}
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, }}>
                                                        {(data.discountDollars && data.discountDollars && `$${data.discountDollars.toFixed(2)}`) || 'Discount $'}
                                                    </Text>
                                                </View>
                                            </View>
                                            <View style={{ width: '1%' }}/>
                                            <View style={{ height: 'auto', paddingTop: 5, paddingBottom: 5, flexDirection: 'row', width: '15%' }}>
                                                <View style={[styles.fieldSet, { width: '100%' }]}>
                                                    {data.splitAmount ? <Text style={styles.legend}>Split Total</Text> : null}
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, }}>
                                                        {(data.splitAmount && data.splitAmount && `$${data.splitAmount.toFixed(2)}`) || 'Split Total'}
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>
                                        <View wrap={false} style={{ flexDirection: 'row', paddingLeft: 10, paddingRight: 10, marginTop: 5 }}>
                                            <View style={{ height: 'auto', paddingTop: 5, paddingBottom: 5, flexDirection: 'row', width: '26%' }}>
                                                <View style={[styles.fieldSet, { width: '100%' }]}>
                                                    {data.total ? <Text style={styles.legend}>Total Price</Text> : null}
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, }}>
                                                        {(data.total && data.total && `$${data.total.toFixed(2)}`) || 'Total Price'}
                                                    </Text>
                                                </View>
                                            </View>
                                            <View style={{ width: '1%' }}/>
                                            <View style={{ height: 'auto', paddingTop: 5, paddingBottom: 5, flexDirection: 'row', width: '25%' }}>
                                                <View style={[styles.fieldSet, { width: '100%' }]}>
                                                    {data.subtotal ? <Text style={styles.legend}>Subtotal</Text> : null}
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, }}>
                                                        {(data.subtotal && data.subtotal && `$${data.subtotal.toFixed(2)}`) || 'Subtotal'}
                                                    </Text>
                                                </View>
                                            </View>
                                            <View style={{ width: '1%' }}/>
                                            <View style={{ height: 'auto', paddingTop: 5, paddingBottom: 5, flexDirection: 'row', width: '15%' }}>
                                                <View style={[styles.fieldSet, { width: '100%' }]}>
                                                    {data.taxPercent ? <Text style={styles.legend}>Tax</Text> : null}
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, }}>
                                                        {(data.taxPercent && data.taxPercent) || 'Tax'}
                                                    </Text>
                                                </View>
                                            </View>
                                            <View style={{ width: '1%' }}/>
                                            <View style={{ height: 'auto', paddingTop: 5, paddingBottom: 5, flexDirection: 'row', width: '15%' }}>
                                                <View style={[styles.fieldSet, { width: '100%' }]}>
                                                    {data.tip ? <Text style={styles.legend}>Tip</Text> : null}
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, }}>
                                                        {(data.tip && `$${data.tip.toFixed(2)}`) || 'Tip'}
                                                    </Text>
                                                </View>
                                            </View>
                                            <View style={{ width: '1%' }}/>
                                            <View style={{ height: 'auto', paddingTop: 5, paddingBottom: 5, flexDirection: 'row', width: '15%' }}>
                                                <View style={[styles.fieldSet, { width: '100%' }]}>
                                                    {data.totalDue ? <Text style={styles.legend}>Total Due</Text> : null}
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, }}>
                                                        {(data.totalDue && `$${data.totalDue.toFixed(2)}`) || 'Total Due'}
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>

                                        <View wrap={false} style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <View style={{ width: '100%', height: 'auto', padding: 10, paddingTop: 5, paddingBottom: 5 }}>
                                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 11 }}>
                                                    Job Notes:
                                                </Text>
                                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, marginTop: 5 }}>
                                                    {data.jobNote ? data.jobNote : ""}
                                                </Text>
                                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, marginTop: 5 }}>
                                                    {data.jobSiteNote ? data.jobSiteNote : ""}
                                                </Text>
                                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, marginTop: 5 }}>
                                                    {props.routeInfoPdf && props.routeInfoPdf.route && props.routeInfoPdf.route.jobEquipment.join(", ")}
                                                </Text>
                                            </View>
                                            <View style={{ width: '100%', height: 'auto', padding: 10, paddingTop: 5, paddingBottom: 5 }}>
                                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 11 }}>
                                                    Customer Notes:
                                                </Text>
                                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, marginTop: 5 }}>
                                                    {data.customerNote ? data.customerNote : ""}
                                                </Text>
                                            </View>
                                        </View>

                                        {/* <View wrap={false} style={{ padding: 5, paddingTop: 2.5, paddingBottom: 2.5 }}>
                                            <View style={{ borderBottomWidth: 2, borderBottomColor: 'black', width: '100%' }} />
                                        </View> */}
                                    </View>
                                    )
                                })}
                        </View>
                    </View>
                </Page>
            </Document>
        </>
    )
}

RoutePdf.propTypes = {
    
}

export default RoutePdf;
