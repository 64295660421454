import React, { useState, useEffect } from 'react'
import { Alert } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    colors,
    Typography,
    Button,
    Grid,
    CircularProgress,
    Snackbar, TableFooter
} from '@material-ui/core'
import {ArrowDownward, ArrowUpward} from '@material-ui/icons'
import { MissTaxGroup } from './components'
import { Pagination } from 'components'
import axios from 'utils/axios'
import apiConfig from 'apiConfig'
import {useDispatch} from "react-redux";

const useStyles = makeStyles(theme => ({
    root: {},
    resTable: {
        overflowX: 'auto'
    },
    tableCell: {
        borderBottom: '1px solid #969696'
    },
    circularProgress: {
        marginTop: '30px',
    },
    styleAction: {
        // flexDirection: 'column',
        justifyContent: 'center',
        paddingRight: theme.spacing(2)
    },
    styleActionEnd: {
        justifyContent: 'flex-end',
        paddingRight: theme.spacing(2)
    },
    btn: {
        width: '140px',
        whiteSpace: 'nowrap',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    paginate: {
        padding: '10px',
        '& ul': {
            justifyContent: 'center'
        }
    },
    iconSort: {
        marginBottom: '-5px',
        marginLeft: '10px'
    }
}));

let mounted;
let pageNumber = 1;
let forcePage = 0;

const MissingTaxGroup = props => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const [customers, setCustomers] = useState();
    const [oldCustomers, setOldCustomers] = useState();
    const [pageCount, setPageCount] = useState();
    const [taxGroups, setTaxGroups] = useState();
    const [changeAll, setChangeAll] = useState(false);
    const [savingAll, setSavingAll] = useState(false);

    const [loading, setLoading] = useState(false);
    const [openSnack, setOpenSnack] = useState(false);
    const [isSuccess, setIsSuccess] = useState({ failed: false, msg: '' })

    const [sortBy, setSortBy] = useState('Customer');
    const [sortType, setSortType] = useState('ASC');
    // const [showIcon, setShowIcon] = useState(false);
    
    const onChangeTax = (e, index) => {
        const listTaxs = [...customers];
        listTaxs[index] = { ...listTaxs[index], address: { ...listTaxs[index].address, taxGroupId: e.target.value } };
        setCustomers(listTaxs)
    }
    const onRemoveTax = index => {
        const listTaxs = [...customers];
        listTaxs.splice(index, 1);
        setCustomers(listTaxs)
    }

    const isChangeAll = (listTaxs) => {
        let bool = false;
        listTaxs.forEach((e, index) => {
            if (!!e.address.taxGroupId != !!oldCustomers[index].address.taxGroupId) {
                bool = true;
                return;
            }
        });
        mounted && setChangeAll(bool);
    }

    const onSave = (miss, index) => {
        const data = [{
            id: miss.id,
            addressId: miss.address.id,
            taxGroupId: miss.address.taxGroupId
        }]
        axios.post(apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMERS_MISSING_TAX_GROUP_UPDATE, data)
            .then(res => {
                onRemoveTax(index);
                setIsSuccess({ failed: false, msg: 'Update successfully.' });
                setOpenSnack(true);
                dispatch({type: 'RELOAD_CUSTOMERS_COUNTER', reloadCustomer: true});
                if (customers.length === 0) {
                    // reload to get next data
                    fetchMissingTaxGroup();
                }
            })
            .catch(err => {
                console.log(err);
                setIsSuccess({ failed: true, msg: 'Update failed.' });
                setOpenSnack(true);
            })
    }
    const onSaveAll = () => {
        let data = [...customers].map(item => {
            if (item.address.taxGroupId) {
                return {
                    id: item.id,
                    addressId: item.address.id,
                    taxGroupId: item.address.taxGroupId
                }
            }
        })
        data = data.filter(x => x);
        setSavingAll(true);
        axios.post(apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMERS_MISSING_TAX_GROUP_UPDATE, data)
            .then(res => {
                setIsSuccess({ failed: false, msg: 'Update successfully.' });
                setOpenSnack(true);
                const customersFilte = customers.filter(x => !x.address.taxGroupId);
                setCustomers(customersFilte)
                if (customers.length === 0) {
                    // reload to get next data
                    fetchMissingTaxGroup();
                }
                dispatch({type: 'RELOAD_CUSTOMERS_COUNTER', reloadCustomer: true});
                setChangeAll(false);
            })
            .catch(err => {
                console.log(err);
                setIsSuccess({ failed: true, msg: 'Update failed.' });
                setOpenSnack(true);
            }).finally(() => { setSavingAll(false); })
    }

    const fetchMissingTaxGroup = () => {
        // let url = apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMERS_MISSING_TAX_GROUP + pageNumber;
        let url = apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMERS_MISSING_TAX_GROUP;
        setLoading(true)
        setCustomers(null);
        setOldCustomers(null);
        axios.get(url, {
            params:
                {
                    sortBy: sortBy,
                    sortType: sortType,
                    pageSize: 24,
                    pageNumber: pageNumber,
                }
        }).then(res => {
            if (mounted) {
                setCustomers(res.data.customers);
                setOldCustomers(res.data.customers);
                setPageCount(res.data.pageCount);
                setTaxGroups(res.data.taxGroupOptions)
                setChangeAll(false);
            }
        })
            .finally(() => mounted && setLoading(false))
    }

    const loadDocument = (type) => {
        setSortBy(type)
        setSortType(type == sortBy ? (sortType === 'ASC' ? 'DESC' : 'ASC') : 'ASC');
        pageNumber = 1;
        forcePage = 0;
    }

    const getSortIcon = (type) => {
        return (
            sortBy == type &&
            (
                sortType == 'ASC' ?
                    <ArrowUpward className={classes.iconSort}/>
                    :
                    <ArrowDownward className={classes.iconSort}/>
            )
        )
    }
    const onPageChange = page => {
        pageNumber = page.selected + 1;
        forcePage = page.selected;
        fetchMissingTaxGroup();
    }

    useEffect(() => {
        mounted = true;
        if (customers && oldCustomers) {
            isChangeAll(customers);
        }
        return () => mounted = false;
    }, [customers, oldCustomers])

    useEffect(() => {
        mounted = true;

        fetchMissingTaxGroup();

        return () => mounted = false;
    }, [sortBy, sortType])

    if (!taxGroups) {
        return (
            <Grid
                container
                spacing={0}
                align="center"
                justifyContent="center"
                direction="column">
                <Grid item>
                    <CircularProgress className={classes.circularProgress} size={50} />
                </Grid>
            </Grid>

        )
    }

    return (
        <Card>
            <CardHeader title='Missing Tax Groups' />
            <Divider />
            <CardContent className={classes.resTable}>
                {loading ?
                    <Grid
                        container
                        spacing={0}
                        align="center"
                        justifyContent="center"
                        direction="column">
                        <Grid item>
                            <CircularProgress className={classes.circularProgress} size={30} />
                        </Grid>
                    </Grid>
                    :
                    <Table style={{ minWidth: '900px' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell onClick={() => loadDocument('Customer')} className={classes.tableCell}><span>Customer{ getSortIcon('Customer')}</span></TableCell>
                                <TableCell onClick={() => loadDocument('Address')} className={classes.tableCell}><span>Address{ getSortIcon('Address')}</span></TableCell>
                                <TableCell onClick={() => loadDocument('Note')} className={classes.tableCell}><span>Note{ getSortIcon('Note')}</span></TableCell>
                                <TableCell align='center' className={classes.tableCell}>Tax Group</TableCell>
                                <TableCell className={classes.tableCell}>
                                    <CardActions className={classes.styleAction}>
                                        <Button
                                            disabled={!changeAll}
                                            onClick={onSaveAll}
                                            className={classes.btn}
                                            variant="contained">
                                            Save All
                                        </Button>
                                        {savingAll && <CircularProgress size={24} />}
                                    </CardActions>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {customers && customers.length > 0 ? customers.map((miss, index) => (
                                <MissTaxGroup
                                    key={miss.id + index}
                                    missTax={miss}
                                    oldCustomer={oldCustomers[index]}
                                    taxGroups={taxGroups}
                                    onChangeTax={e => onChangeTax(e, index)}
                                    onSave={(mix) => onSave(mix, index)}
                                />
                            )) : <TableRow>
                                <TableCell colSpan={4}>
                                    No data found...
                                </TableCell>
                            </TableRow>
                            }
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell className={classes.tableCell}></TableCell>
                                <TableCell className={classes.tableCell}></TableCell>
                                <TableCell className={classes.tableCell}></TableCell>
                                <TableCell align='center' className={classes.tableCell}></TableCell>
                                <TableCell className={classes.tableCell}>
                                    <CardActions className={classes.styleActionEnd}>
                                        <Button
                                            disabled={!changeAll}
                                            onClick={onSaveAll}
                                            className={classes.btn}
                                            variant="contained">
                                            Save All
                                        </Button>
                                        {savingAll && <CircularProgress size={24} />}
                                    </CardActions>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                }
            </CardContent>
            <div className={classes.paginate}>
                <Pagination pageCount={pageCount} onPageChange={onPageChange} />
            </div>

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnack}
                onClose={() => setOpenSnack(false)}
                autoHideDuration={3000}
            >
                <Alert
                    elevation={6} variant="filled" severity={isSuccess.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isSuccess.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </Card >
    )
}

export default MissingTaxGroup;
