import React, {useState, useEffect} from 'react'
import {makeStyles} from '@material-ui/styles'
import {
    Typography,
    Grid,
    CircularProgress,
    TextField,
    FormControlLabel,
    Button,
    colors, IconButton, CardHeader, Divider, Collapse, Card, Snackbar
} from '@material-ui/core'
import SaleFollowUpsCard from './components'
import salesData from 'mockup/salesData'
import axios from "../../../../utils/axios";
import apiConfig from "../../../../apiConfig";
import {GreenRadio, Pagination, GreenRadioBidToDo} from 'components';
import NavigateBeforeRoundedIcon from '@material-ui/icons/NavigateBeforeRounded';
import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded';
import {KeyboardDatePicker} from 'components';
import {Redirect, useHistory, useParams} from 'react-router-dom'
import moment from 'moment-timezone'
import {KeyboardArrowDown, KeyboardArrowUp} from "@material-ui/icons";
import PdfModal from './components/PdfModal'
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Alert } from '@material-ui/lab'

const useStyles = makeStyles(theme => ({
    root: {},
    tableCell: {
        cursor: 'pointer',
        '& p': {
            whiteSpace: 'nowrap',
            fontWeight: 500
        },
        '& svg': {
            fontSize: '14px',
            transition: 'all 100ms ease-out'
        }
    },
    conTable: {
        overflowX: 'auto'
    },
    customSearch: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    noData: {
        textAlign: 'center',
        fontSize: theme.spacing(2),
        fontWeight: 500,
        padding: '20px'
    },
    filter: {
        padding: '20px 0',
        position: 'sticky',
        zIndex: 3,
        top: 0,
        backgroundColor: '#f4f6f8',
    },
    btnAction: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        "&:hover": {
            backgroundColor: colors.green[900]
        }
    },
    paginate: {
        marginTop: theme.spacing(3),
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '20px'
    },
    dateSelected: {
        padding: '12px 0',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto'
    },
    filterByBidder: {
        '& .MuiFormLabel-root': {
            background: '#f4f6f8',
            '&.MuiInputLabel-shrink': {
                background: 'inherit'
            }
        }
    }
}));

const lstFilter = [
    {
        id: 'companyName',
        name: 'Customer Name'
    },
    {
        id: 'jobsiteCity',
        name: 'Jobsite Address City'
    },
    {
        id: 'initialCallDate',
        name: 'Initial call'
    },
    {
        id: 'bidAppointmentDate',
        name: 'Bid Appointment'
    },
    {
        id: 'distance',
        name: 'Distance'
    }
]

const lstShort = [
    {
        id: 'asc',
        name: 'ASC'
    },
    {
        id: 'desc',
        name: 'DESC'
    }
]

let pageNumber = 1;
let pageNumberToday = 1;
let pageNumberBefore = 1;
let pageNumberAfter = 1;
let forcePage = 0;
let forcePageToday = 0;
let forcePageBefore = 0;
let forcePageAfter = 0;

const BidToDo = props => {
    const {select_date} = useParams();
    const date = select_date ? select_date.replaceAll('-', '/') : moment(new Date()).format('YYYY/MM/DD');

    const classes = useStyles();

    const [sort, setSort] = useState({name: 'companyName', type: 'asc'})
    const [load, setLoad] = useState(false);
    const [loadToday, setLoadToday] = useState(false);
    const [loadBefore, setLoadBefore] = useState(false);
    const [loadAfter, setLoadAfter] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [pageCountToday, setPageCountToday] = useState(0);
    const [pageCountBefore, setPageCountBefore] = useState(0);
    const [pageCountAfter, setPageCountAfter] = useState(0);
    const [loadByDistance, setLoadByDistance] = useState(false);
    const [jobIdByDistance, setJobIdByDistance] = useState(0);
    const [selectEmployeeId, setSelectEmployeeId] = useState();
    const [lstUserCanBill, setLstUserCanBill] = useState([]);

    const [listSalesFollowUps, setListSalesFollowUps] = useState([]);
    const [listSalesFollowUpsToday, setListSalesFollowUpsToday] = useState([]);
    const [listSalesFollowUpsBefore, setListSalesFollowUpsBefore] = useState([]);
    const [listSalesFollowUpsAfter, setListSalesFollowUpsAfter] = useState([]);

    const [totalToday, setTotalToday] = useState(0);
    const [totalBefore, setTotalBefore] = useState(0);
    const [totalAfter, setTotalAfter] = useState(0);

    const [expandedToday, setExpandedToday] = useState(true);
    const [expandedBefore, setExpandedBefore] = useState(true);
    const [expandedAfter, setExpandedAfter] = useState(true);

    const onChangeSort = name => {
        setSort({...sort, name: name, asc: true})
        setJobIdByDistance(0);
        setLoadByDistance(false);
        // debugger
        // if (!showSort[name]) {
        //     setShowSort({...showSort, [name]: true})
        // }
        // if (sort.name === name) {
        // } else {
        //     setSort({name, asc: true})
        // }
    }

    const onChangeDirection = type => {
        setSort({...sort, type})
        setJobIdByDistance(0);
        setLoadByDistance(false);
    }

    const onPageChange = (page) => {
        pageNumber = page.selected + 1;
        forcePage = page.selected;
        getBidsToDoDistances(jobIdByDistance);
    };
    const onPageChangeToday = (page) => {
        pageNumberToday = page.selected + 1;
        forcePageToday = page.selected;
        if (loadByDistance) {
            getBidsToDoDistances(jobIdByDistance);
        } else {
            fetchJobsToday();
        }
    };
    const onPageChangeBefore = (page) => {
        pageNumberBefore = page.selected + 1;
        forcePageBefore = page.selected;
        if (loadByDistance) {
            getBidsToDoDistances(jobIdByDistance);
        } else {
            fetchJobsBefore();
        }
    };
    const onPageChangeAfter = (page) => {
        pageNumberAfter = page.selected + 1;
        forcePageAfter = page.selected;
        if (loadByDistance) {
            getBidsToDoDistances(jobIdByDistance);
        } else {
            fetchJobsAfter();
        }
    };

    const fetchJobsToday = () => {
        setLoadToday(true);
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.DASHBOARD_BILL_TO_DO_TODAY, {
            params:
                {
                    limit: 0,
                    pageSize: 24,
                    pageNumber: pageNumberToday,
                    sortBy: sort.name,
                    sortType: sort.type,
                    date: moment(date).utc().toISOString(),
                    employeeId: selectEmployeeId
                }
        })
            .then(res => {
                setListSalesFollowUpsToday(res.data.data || []);
                setTotalToday(res.data.total);
                setExpandedToday(res.data.total > 0);
                setPageCountToday(res.data.pageCount);
            }).finally(() => {
            setLoadToday(false);
        })
    }
    const fetchJobsBefore = () => {
        setLoadBefore(true);
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.DASHBOARD_BILL_TO_DO_PART_DUE, {
            params:
                {
                    limit: 0,
                    pageSize: 24,
                    pageNumber: pageNumberBefore,
                    sortBy: sort.name,
                    sortType: sort.type,
                    date: moment(date).utc().toISOString(),
                    employeeId: selectEmployeeId
                }
        })
            .then(res => {
                setListSalesFollowUpsBefore(res.data.data || []);
                setTotalBefore(res.data.total)
                setExpandedBefore(res.data.total > 0);
                setPageCountBefore(res.data.pageCount);
            }).finally(() => {
            setLoadBefore(false);
        })
    }
    const fetchJobsAfter = () => {
        setLoadAfter(true);
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.DASHBOARD_BILL_TO_DO_UP_COMMING, {
            params:
                {
                    limit: 0,
                    pageSize: 24,
                    pageNumber: pageNumberAfter,
                    sortBy: sort.name,
                    sortType: sort.type,
                    date: moment(date).utc().toISOString(),
                    employeeId: selectEmployeeId
                }
        })
            .then(res => {
                setListSalesFollowUpsAfter(res.data.data || []);
                setTotalAfter(res.data.total);
                setExpandedAfter(res.data.total > 0);
                setPageCountAfter(res.data.pageCount);
            }).finally(() => {
            setLoadAfter(false);
        })
    };

    const fetchCanBill = () => {
        axios.get(apiConfig.url.USER_URL + apiConfig.url.LIST_CAN_BILL, {})
            .then(res => {
                setLstUserCanBill(res.data.users);
            }).finally(() => {
        })
    };


    useEffect(() => {
        if (sort.name === 'distance') return;
        fetchJobsToday();
        fetchJobsAfter();
        fetchJobsBefore();
    }, [sort, selectEmployeeId])

    useEffect(() => {
        fetchCanBill();
    }, [])

    useEffect(() => {
            // let listSalesFollowUpsToday = listSalesFollowUps.filter(x => moment(moment.utc(x.bidAppointmentDate).toDate()).isSame(moment(date).utc(), 'day'));
            // if(sort == 'bidAppointmentDate'){
            //     listSalesFollowUpsToday = listSalesFollowUpsToday.sort((a, b) => new Date(a.bidAppointmentDate || '01/01/1970') - new Date(b.bidAppointmentDate || '01/01/1970'));
            // }
            // setListSalesFollowUpsToday(listSalesFollowUpsToday);
            // setExpandedToday(listSalesFollowUpsToday && listSalesFollowUpsToday.length > 0)
            //
            // let listSalesFollowUpsBefore = listSalesFollowUps.filter(x => moment(moment.utc(x.bidAppointmentDate).toDate()) < moment.utc(date).startOf('day'));
            // if(sort == 'bidAppointmentDate'){
            //     listSalesFollowUpsBefore = listSalesFollowUpsBefore.sort((a, b) => new Date(a.bidAppointmentDate || '01/01/1970') - new Date(b.bidAppointmentDate || '01/01/1970'));
            // }
            // setListSalesFollowUpsBefore(listSalesFollowUpsBefore);
            // setExpandedBefore(listSalesFollowUpsBefore && listSalesFollowUpsBefore.length > 0)
            //
            // let listSalesFollowUpsAfter = listSalesFollowUps.filter(x => moment(moment.utc(x.bidAppointmentDate).toDate()) < moment.utc(date).add('days', 7) && moment(moment.utc(x.bidAppointmentDate).toDate()) > moment.utc(date).endOf('day'))
            //
            // if(sort == 'bidAppointmentDate') {
            //     listSalesFollowUpsAfter = listSalesFollowUpsAfter.sort((a, b) => new Date(a.bidAppointmentDate || '01/01/1970') - new Date(b.bidAppointmentDate || '01/01/1970'));
            // }
            // setListSalesFollowUpsAfter(listSalesFollowUpsAfter);
            // setExpandedAfter(listSalesFollowUpsAfter && listSalesFollowUpsAfter.length > 0)
    }, [])

    function getBidsToDoDistances(jobId) {
        setJobIdByDistance(jobId);
        setLoadByDistance(true);
        setSort({...sort, name: 'distance'})
        setLoad(true);
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.DASHBOARD_BILL_TO_DO_DISTANCES, {
            params:
                {
                    limit: 0,
                    pageSize: 24,
                    pageNumber: pageNumber,
                    date: moment(date).utc().toISOString(),
                    jobId: jobId,
                    employeeId: selectEmployeeId
                }
        })
            .then(res => {
                setListSalesFollowUps(res.data.data);
                setPageCount(res.data.pageCount);
            }).finally(() => {
            setLoad(false);
        })
    }

    const history = useHistory();
    const previousDate = () => {
        const currDate = select_date ? new Date(`${select_date} 00:00 AM`) : new Date();
        const prevDate = currDate.setDate(currDate.getDate() - 1);
        history.push(moment(prevDate).format("YYYY-MM-DD"));
    }
    const nextDate = () => {
        const currDate = select_date ? new Date(`${select_date} 00:00 AM`) : new Date();
        const nextDate = currDate.setDate(currDate.getDate() + 1);
        history.push(moment(nextDate).format("YYYY-MM-DD"));
    }

    const [openPdfModal, setOpenPdfModal] = useState(false);
    const [selectedEmployees, setSelectedEmployees] = useState(0);
    const [jobIds, setJobIds] = useState([]);
    const [disableAssignBidder, setDisableAssignBidder] = useState(true);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });
    const [saving, setSaving] = useState(false);

    function onChangeIncludesBidderToJob(jobId, event) {
        if(event.target.checked) {
            setDisableAssignBidder(false);
            setJobIds([...jobIds, jobId])
        } else {
            var index = jobIds.indexOf(jobId);
            if (index !== -1) {
                jobIds.splice(index, 1);
            }
            if(jobIds.length == 0) {
                setDisableAssignBidder(true);
            }
            setJobIds(jobIds)
        }
    }

    function onAssignBidder(employeeId) {
        setSaving(true);
        axios.post(apiConfig.url.BASE_URL + apiConfig.url.LIST_CAN_BILL_SAVE, {
            jobIds: jobIds,
            employeeId: employeeId == "" ? null : employeeId
        }).then(res => {
            setSelectedEmployees(employeeId);
            setIsStatus({ failed: false, msg: 'Save successfully!' });
            setOpenSnackBar(true);
            })
            .catch(() => {
                setIsStatus({ failed: true, msg: 'An error occurred, please try again later.' });
                setOpenSnackBar(true);
            })
            .finally(() => {
                setSaving(false);
        })
    }

    if (!select_date) {
        return <Redirect to={`/customers/bidtodo/${(moment(new Date()).format('YYYY-MM-DD'))}`}/>;
    }

    // if (listSalesFollowUps.length == 0 && !load) {
    //     return (
    //         <Grid container justifyContent='center'>
    //             <Typography className={classes.noData}>No data found.</Typography>
    //         </Grid>
    //     )
    // }

    return (
        <>
            <div className={classes.filter}>
                <Grid container spacing={3} alignItems='center'>
                    <Grid item>
                        <TextField
                            variant='outlined'
                            select
                            label={'Sort by'}
                            SelectProps={{native: true}}
                            value={sort.name || ''}
                            onClick={e => e.stopPropagation()}
                            onChange={(event) => {
                                onChangeSort(event.target.value)
                            }}
                            style={{ width: '200px' }}
                        >
                            {
                                lstFilter.map(st => (
                                    <option disabled={st.id == 'distance' && true} key={`${st.id}`} value={st.id}>
                                        {st.name}
                                    </option>
                                ))
                            }
                        </TextField>
                    </Grid>
                    <Grid item style={{ width: 'min-content', borderRight: isMobile ? '0px' : '1px solid #c7c7c7' }}>
                        <FormControlLabel
                            disabled={loadByDistance}
                            style={{ paddingTop: '4px', marginLeft: -6, marginRight: 0 }}
                            value="bottom"
                            checked={sort.type == 'asc'}
                            control={<GreenRadioBidToDo/>}
                            label="Ascending"
                            labelPlacement="right"
                            onChange={() => onChangeDirection('asc')}
                        />
                        <FormControlLabel
                            style={isMobile ? { marginLeft: '5px', marginTop: '5px' } : {}}
                            disabled={loadByDistance}
                            style={{ marginLeft: -6, marginRight: 0 }}
                            value="bottom"
                            checked={sort.type == 'desc'}
                            control={<GreenRadioBidToDo/>}
                            label="Descending"
                            labelPlacement="right"
                            onChange={() => onChangeDirection('desc')}
                        />
                    </Grid>
                    <Grid item style={{ borderRight: isMobile ? '0px' : '1px solid #c7c7c7' }}>
                        <TextField
                            variant='outlined'
                            select
                            className={classes.filterByBidder}
                            label={'Filter by Salesperson'}
                            SelectProps={{native: true}}
                            value={selectEmployeeId || ''}
                            onClick={e => e.stopPropagation()}
                            onChange={(event) => {
                                setSelectEmployeeId(event.target.value)
                            }}
                            style={{ width: '200px' }}
                        >
                            <option key='' value=''>All</option>
                            {
                                lstUserCanBill.map(st => (
                                    <option key={`${st.id}`} value={st.id}>
                                        {st.fullName}
                                    </option>
                                ))
                            }
                        </TextField>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={() => setOpenPdfModal(true)}
                            variant={"contained"}
                            className={classes.btnAction}
                            disabled={false}
                        >
                            Print
                        </Button>
                    </Grid>
                    <Grid item style={{ marginLeft: 'auto' }}>
                        <TextField
                            disabled={disableAssignBidder}
                            fullWidth
                            variant='outlined'
                            select
                            label={'Assign Salesperson'} 
                            InputLabelProps={{ shrink: selectedEmployees ? true : false }}
                            value={selectedEmployees || ''}
                            SelectProps={{native: true}}
                            onClick={e => e.stopPropagation()}
                            onChange={(event) => {
                                onAssignBidder(event.target.value)
                            }}

                            style={{ width: '200px' }}
                        >
                            <option key={''} value={''}></option>
                            {lstUserCanBill.map(st => (
                                <option key={`${st.id}`} value={st.id}>
                                    {st.fullName}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                    {saving
                    && <Grid item>
                        <CircularProgress size={30} />
                    </Grid>
                    }
                </Grid>
            </div>
            {
                sort.name === 'distance'
                    ? load
                        ? <Grid
                            container
                            spacing={0}
                            align="center"
                            justifyContent="center"
                            direction="column">
                            <Grid item>
                                <CircularProgress className={classes.circularProgress} size={50}/>
                            </Grid>
                        </Grid>
                        : <>
                            {listSalesFollowUps.map((sale, inx) =>
                                <SaleFollowUpsCard 
                                    key={inx}
                                    customer={sale}
                                    getDistance={getBidsToDoDistances}
                                    lstUserCanBill={lstUserCanBill}
                                    onChangeIncludesBidderToJob={onChangeIncludesBidderToJob}
                                    selectedEmployeesBidTodo={selectedEmployees}
                                />
                            )}
                            <div style={pageCount <= 1 ? {display: 'none'} : null} className={classes.paginate}>
                                <Pagination pageCount={pageCount} forcePage={forcePage} onPageChange={onPageChange}/>
                            </div>
                        </>
                    : <>
                        <Card style={{marginTop: '24px'}}>
                            <CardHeader title={`Today's Bids (${totalToday})`}
                                        className={classes.cardHeader}
                                        action={expandedToday ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                                        onClick={() => setExpandedToday(!expandedToday)}
                            />
                            <Divider/>
                            <Collapse in={expandedToday} timeout='auto' unmountOnExit>
                                {loadToday ?
                                <Grid
                                    container
                                    spacing={0}
                                    align="center"
                                    justifyContent="center"
                                    direction="column">
                                    <Grid item>
                                        <CircularProgress className={classes.circularProgress} size={50}/>
                                    </Grid>
                                </Grid>
                                :
                                    (listSalesFollowUpsToday.length > 0 ?
                                    (
                                        <>
                                        {listSalesFollowUpsToday.map((sale, inx) => <SaleFollowUpsCard key={inx}
                                                                                                  customer={sale}
                                                                                                  getDistance={getBidsToDoDistances}
                                                                                                  lstUserCanBill={lstUserCanBill}
                                                                                                  onChangeIncludesBidderToJob={onChangeIncludesBidderToJob}
                                                                                                  selectedEmployeesBidTodo={selectedEmployees}
                                                                                                  />)}
                                        <div style={pageCountToday <= 1 ? {display: 'none'} : null} className={classes.paginate}>
                                            <Pagination pageCount={pageCountToday} forcePage={forcePageToday} onPageChange={onPageChangeToday}/>
                                        </div>
                                    </>
                                    )
                                    :
                                    (
                                        !loadToday &&
                                        <Grid container justifyContent='center'>
                                            <Typography className={classes.noData}>No data found.</Typography>
                                        </Grid>
                                    )
                                    )}
                            </Collapse>
                        </Card>

                        <Card style={{marginTop: '24px'}}>
                            <CardHeader title={`Past Due Bids (${totalBefore})`}
                                        className={classes.cardHeader}
                                        action={expandedBefore ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                                        onClick={() => setExpandedBefore(!expandedBefore)}
                            />
                            <Divider/>
                            <Collapse in={expandedBefore} timeout='auto' unmountOnExit>
                                {loadBefore ?
                                <Grid
                                    container
                                    spacing={0}
                                    align="center"
                                    justifyContent="center"
                                    direction="column">
                                    <Grid item>
                                        <CircularProgress className={classes.circularProgress} size={50}/>
                                    </Grid>
                                </Grid>
                                :
                                    (listSalesFollowUpsBefore.length > 0 ?
                                    <>
                                    {listSalesFollowUpsBefore.map((sale, inx) => <SaleFollowUpsCard key={inx}
                                                                                                   customer={sale}
                                                                                                   getDistance={getBidsToDoDistances}
                                                                                                   lstUserCanBill={lstUserCanBill}
                                                                                                   onChangeIncludesBidderToJob={onChangeIncludesBidderToJob}
                                                                                                   selectedEmployeesBidTodo={selectedEmployees}
                                                                                                   />)}
                                        <div style={pageCountBefore <= 1 ? {display: 'none'} : null} className={classes.paginate}>
                                            <Pagination pageCount={pageCountBefore} forcePage={forcePageBefore} onPageChange={onPageChangeBefore}/>
                                        </div>
                                    </>
                                    :
                                    (
                                        !loadBefore &&
                                        <Grid container justifyContent='center'>
                                            <Typography className={classes.noData}>No data found.</Typography>
                                        </Grid>
                                    )
                                    )}
                            </Collapse>
                        </Card>

                        <Card style={{marginTop: '24px'}}>
                            <CardHeader title={`Upcoming Bids (${totalAfter})`}
                                        className={classes.cardHeader}
                                        action={expandedAfter ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                                        onClick={() => setExpandedAfter(!expandedAfter)}
                            />
                            <Divider/>
                            <Collapse in={expandedAfter} timeout='auto' unmountOnExit>
                                {loadAfter ?
                                <Grid
                                    container
                                    spacing={0}
                                    align="center"
                                    justifyContent="center"
                                    direction="column">
                                    <Grid item>
                                        <CircularProgress className={classes.circularProgress} size={50}/>
                                    </Grid>
                                </Grid>
                                :
                                    (listSalesFollowUpsAfter.length > 0 ?
                                    <>
                                    {listSalesFollowUpsAfter.map((sale, inx) => <SaleFollowUpsCard key={inx}
                                                                                                  customer={sale}
                                                                                                  getDistance={getBidsToDoDistances}
                                                                                                  lstUserCanBill={lstUserCanBill}
                                                                                                  onChangeIncludesBidderToJob={onChangeIncludesBidderToJob}
                                                                                                  selectedEmployeesBidTodo={selectedEmployees}
                                                                                                  />)}
                                        <div style={pageCountAfter <= 1 ? {display: 'none'} : null} className={classes.paginate}>
                                            <Pagination pageCount={pageCountAfter} forcePage={forcePageAfter} onPageChange={onPageChangeAfter}/>
                                        </div>
                                    </>
                                    :
                                    (
                                        !loadAfter &&
                                        <Grid container justifyContent='center'>
                                            <Typography className={classes.noData}>No data found.</Typography>
                                        </Grid>
                                    )
                                    )}
                            </Collapse>
                        </Card>
                    </>
            }

            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                      open={openSnackbar}
                      autoHideDuration={3000}
                      onClose={() => setOpenSnackBar(false)}>
                <Alert elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>

            <PdfModal
                setTitle={() => { }}
                onCloseModal={() => setOpenPdfModal(false)}
                open={openPdfModal}
                selectedEmployeeId={selectEmployeeId}
            />
        </>
    )
}

export default BidToDo;
