const initState = {
    loadingJob: false,
    expanded: true,
    isDragging: false,
    routeJobs: [],
    routeJobsSchedule: null,
    payments: [],
    jobStage: 1,
    pageNum: 1
}

const JobsReducer = (state = initState, action) => {
    let routeJobs = state.routeJobs && [...state.routeJobs] || [];
    const routeJobsSchedule = state.routeJobsSchedule && [...state.routeJobsSchedule] || null;
    const jobIndex = routeJobs && routeJobs.findIndex(job => job.jobSplitId === action.jobSplitId);
    switch (action.type) {
        case 'INIT_JOB':
            return {
                ...state,
                routeJobs: action.routeJobs
            }
        case 'INIT_JOB_SCHEDULE':
            return {
                ...state,
                routeJobsSchedule: action.routeJobsSchedule
            }
        case 'LOAD_MORE_JOB':
            return {
                ...state,
                routeJobs: [...routeJobs, ...action.routeJobs]
            }
        case 'LOADING_JOB':
            return {
                ...state,
                loadingJob: action.loadingJob
            }
        case 'ADD_JOB':
            routeJobs.push(action.routeRemove)
            return {
                ...state,
                routeJobs
            }
        case 'REMOVE_JOB':
            if (Array.isArray(action.jobSplitId)) {
                routeJobs = routeJobs.filter(x => !action.jobSplitId.includes(x.jobSplitId))
            } else {
                routeJobs = routeJobs.filter(x => x.jobSplitId !== action.jobSplitId)
            }
            return {
                ...state,
                routeJobs: routeJobs
            }

        case 'CHANGE_ESTIMATED_HOURS':
            routeJobs[jobIndex] = { ...routeJobs[jobIndex], estimatedHours: action.estimatedHours }
            return { ...state, routeJobs }

        case 'CHANGE_JOB_STAGE':
            routeJobs[jobIndex] = { ...routeJobs[jobIndex], jobStage: action.jobStage }

            return { ...state, routeJobs }

        case 'CHANGE_STATUS_JOB':
            return {
                ...state,
                isDragging: action.status
            }
        case 'CHANGE_EXPANDED':
            return {
                ...state,
                expanded: !state.expanded
            }
        case 'INIT_JOB_PAYMENT':
            return {
                ...state, payments: action.payments
            }
        case 'CHANGE_STAGE':
            return {
                ...state,
                jobStage: action.jobStage
            }
        case 'CHANGE_PAGE':
            return {
                ...state,
                pageNum: action.pageNum
            }    
        default:
            return state;
    }
}

export default JobsReducer;
