import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Typography, colors, IconButton, useMediaQuery, Collapse, Tooltip, createTheme, MuiThemeProvider } from '@material-ui/core'
import SourcesModal from './Modals/SourcesModal'
import ScheduledModal from './Modals/ScheduledModal'
import { makeStyles } from '@material-ui/styles'
import moment from 'moment'
import DropBox from './DropBox'
import { DraggableBox } from 'components'
import { useDrop } from 'react-dnd'
import { useSelector, useDispatch } from 'react-redux'
import apiConfig from 'apiConfig'
import axios from 'utils/axios'
import { formatTwoPDecimal } from "utils/formatNumber";
import { AddBoxRounded } from '@material-ui/icons'
import ResizableBox from './ResizableBox'
import * as _ from 'lodash';

const useStyles = makeStyles(theme => ({
    routeWaper: {
        overflowX: 'scroll',
        overflowY: 'hidden',
        borderBottom: '1px solid #cccccc',
        '&::-webkit-scrollbar': {
            height: '5px'
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#e4e4e4'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#9c9c9c',
            borderRadius: '60px'
        }
    },
    routeJob: {
        width: '100%',
        display: 'flex',
        position: 'relative'
    },
    routeDetail: {
        width: '100%',
        whiteSpace: 'nowrap',
        overflowX: 'auto',
        overflowY: 'hidden',
        paddingLeft: '10px',
        height: '40px',
        lineHeight: '40px',
        left: '0',
        '&:hover': {
            backgroundColor: '#c7c7c7 !important'
        },
        '&::-webkit-scrollbar': {
            height: '5px'
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#e4e4e4'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#9c9c9c',
            borderRadius: '60px'
        }
    },
    job: {
        display: 'inline',
        position: 'absolute'
    },
    empeqp: {
        alignItems: 'center',
        color: theme.palette.white,
        padding: '2px 8px',
        borderRadius: '4px',
        marginRight: '5px'
    },
    bgGreen: {
        backgroundColor: colors.green[500],
    },
    bgPurple: {
        backgroundColor: '#8a2be2',
    },
    jobEquipments: {
        display: 'inline-flex',
        backgroundColor: '#bcbcbc',
        padding: '2px 8px',
        borderRadius: '4px',
        lineHeight: '22px',
        marginRight: 5
    },
    addBox: {
        padding: 0,
        marginLeft: -14,
        marginBottom: 2,
        color: colors.green[600],
        "&:hover": {
            color: colors.green[900],
            backgroundColor: 'transparent'
        },
        "& span svg": {
            fontSize: "38px"
        }
    },
    times: {
        display: "flex",
        width: "100%",
        minWidth: 100,
        lineHeight: "24px",
        "& span": {
            height: "40px",
            lineHeight: "24px",
            padding: "8px 5px",
            width: "100px",
            minWidth: "100px",
            borderBottom: "1px solid #c7c7c7",
            borderRight: "0.5px solid #c7c7c7",
            transition: 'all .3s ease',
            "&:last-child": {
                borderRight: "none"
            }
        }
    },
    selectedRoute: {
        backgroundColor: '#e0e0e0',
        '& span' : {
            backgroundColor: '#e0e0e0',
        }
    },
}));

const customThemeTooltip = createTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: '14px',
                maxWidth: '90vw'
            }
        }
    }
})

const times = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

const RouteContent = props => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const { date } = useParams();

    const v_ipad = useMediaQuery("(max-width: 769px)");

    const { route, error, changeSelectedRoute, time, isSelected, recurJob, showJobNotes, expandContent, colorInput } = props;

    let collisionJobs = []

    const { routes } = useSelector(state => state.RoutesReducer)
    const { routeJobs } = useSelector(state => state.JobsReducer);
    const { routeEmployees } = useSelector(state => state.EmployeesReducer);
    const { routeEquipment } = useSelector(state => state.EquipmentsReducer);

    const [jobEquipments, setJobEquipments] = useState('');
    const [defaultType, setDefaultType] = useState('jobs');
    const [openSourceModal, setOpenSourceModal] = useState(false);
    const [starttime, setStarttime] = useState();
    const [editedRoute, setEditedRoute] = useState();
    const [openScheduledModal, setOpenScheduledModal] = useState(false);
    const [linesJobs, setLinesJobs] = useState([]);
    const [routeJobsOnTime, setRouteJobsOnTime] = useState([]);

    const [{ isOverEmployee }, dropEmployee] = useDrop({
        accept: 'Employee',
        drop: item => onDropEmployee(item, route.id),
        collect: (monitor) => ({
            isOverEmployee: monitor.isOver()
        })
    });
    const [{ isOverEquipment }, dropEquipment] = useDrop({
        accept: 'Equipment',
        drop: item => onDropEquipment(item, route.id),
        collect: (monitor) => ({
            isOverEquipment: monitor.isOver()
        })
    });

    const [topJobId, setTopJobId] = useState('');

    const handleSelectedSource = ({ type, id }) => {
        if (type !== defaultType) return;
        switch (type) {
            case "jobs":
                if (starttime) {
                    onDropItem({ id, oldRouteId: undefined }, route.id, starttime)
                }
                break;
            case "employees":
                onDropEmployee({ id, oldRouteId: undefined }, route.id)
                break;
            case "equipments":
                onDropEquipment({ id, oldRouteId: undefined }, route.id)
                break;
            default:
                break;
        }
    }
    const handleShowSourceModal = (time, type) => {
        if (route.routeFull) return;
        setStarttime(time);
        setDefaultType(type);
        setOpenSourceModal(true)
    }

    const handleDropBackJobItem = ({ oldRouteId, id }) => {
        if (oldRouteId !== undefined) {
            const route = routes.find(route => route.id === oldRouteId);
            const routeRemove = route.routeJobs.find(job => job.jobSplitId === id);

            routeRemove.estimatedHours = routeRemove.job.estimatedHours;
            routeRemove.customerName = routeRemove.job.customerName;
            routeRemove.equipment = routeRemove.job.equipment;
            routeRemove.city = routeRemove.job.city || '';
            routeRemove.address1 = routeRemove.job.address1 || '';
            routeRemove.state = routeRemove.job.state;
            routeRemove.zipCode = routeRemove.job.zipCode;
            routeRemove.price = routeRemove.job.price;
            routeRemove.totalSplitJobs = routeRemove.job.totalSplitJobs;

            const routeData = {
                ...route,
                routeEquipment: !route.routeEquipment ? [] : [...route.routeEquipment],
                routeEmployees: !route.routeEmployees ? [] : [...route.routeEmployees],
                routeJobs: route.routeJobs.filter(job => job.jobSplitId !== id)
            };
            const requestForRoute = axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + oldRouteId, routeData);

            dispatch({ type: 'IS_UPDATE', status: true });
            Promise.all([requestForRoute])
                .then(values => {
                    dispatch({ type: 'REMOVE_JOB_ROUTE', routeId: oldRouteId, jobSplitId: id });
                    dispatch({ type: 'CHANGE_JOB_STAGE', jobSplitId: id, jobStage: 3 })

                    dispatch({ type: 'ADD_JOB', jobSplitId: id, routeRemove: routeRemove })
                })
                .catch(() => {
                    error({ failed: true, msg: 'Delete failed, please try again later.' });
                })
                .finally(() => dispatch({ type: 'IS_UPDATE', status: false }))
        }
    }
    const handleDropBackEmployeeItem = ({ oldRouteId, id }) => {
        if (oldRouteId !== undefined) {
            const route = routes.find(route => route.id === oldRouteId);
            const rj = route.routeJobs;
            rj.map(job => {
                const localEndDate = new Date(job.jobStart);
                job.estimatedHours = formatTwoPDecimal(job.estimatedHours * ((route.routeEmployees.length === 0 ? 1 : route.routeEmployees.length) / (route.routeEmployees.length - 1 <= 0 ? 1 : route.routeEmployees.length - 1)));
                const hour = localEndDate.getHours() + Math.round((localEndDate.getMinutes() / 60) * 100) / 100 + (job.estimatedHours);
                localEndDate.setHours(Math.floor(hour));
                localEndDate.setMinutes(Math.round((hour - Math.floor(hour)) * 60));
                job.jobEnd = new Date(localEndDate).toISOString();
            })

            const routeData = {
                ...route,
                routeEquipment: !route.routeEquipment ? [] : [...route.routeEquipment],
                routeEmployees: route.routeEmployees.filter(emp => emp.employeeId !== id),
                routeJobs: !route.routeJobs ? [] : [...rj]
            };
            dispatch({ type: 'IS_UPDATE', status: true });
            axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + oldRouteId, routeData)
                .then(() => {
                    let routeEmployees = route.routeEmployees.find(emp => emp.employeeId === id);
                    dispatch({ type: 'REMOVE_EMP_ROUTE', routeId: oldRouteId, employeeId: id });
                    routeEmployees.id = routeEmployees.employeeId
                    dispatch({ type: 'ADD_EMPLOYEE', employee: routeEmployees })
                })
                .catch(() => {
                    error({ failed: true, msg: 'Delete failed, please try again later.' });
                })
                .finally(() => dispatch({ type: 'IS_UPDATE', status: false }))
        }
    }
    const handleDropBackEquipmentItem = ({ oldRouteId, id }) => {
        if (oldRouteId !== undefined) {
            const route = routes.find(route => route.id === oldRouteId);
            const addEquipment = route.routeEquipment.find(eqp => eqp.id === id)
            const routeData = {
                ...route,
                routeEquipment: route.routeEquipment.filter(eqp => eqp.id !== id),
                routeEmployees: !route.routeEmployees ? [] : [...route.routeEmployees],
                routeJobs: !route.routeJobs ? [] : [...route.routeJobs]
            };
            dispatch({ type: 'IS_UPDATE', status: true });
            axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + oldRouteId, routeData)
                .then(() => {
                    dispatch({ type: 'REMOVE_EQP_ROUTE', routeId: oldRouteId, id });
                    dispatch({ type: 'ADD_EQUIPMENT', addEquipment: addEquipment })
                })
                .catch(() => {
                    error({ failed: true, msg: 'Delete failed, please try again later.' });
                })
                .finally(() => dispatch({ type: 'IS_UPDATE', status: false }))
        }
    }
    const onDropItem = ({ id, oldRouteId }, routeId, startTime) => {
        dispatch({ type: 'CHANGE_STATUS_JOB', status: false });
        if (oldRouteId === undefined) {
            const route = routes.find(route => route.id === routeId);
            const rjs = routeJobs.filter(job => id.includes(job.jobSplitId))
            const jobs = rjs.map(rj => {
                const job = {
                    ...rj,
                    job: {
                        estimatedHours: rj.estimatedHours,
                        price: rj.price,
                        jobAddressId: rj.jobAddressId,
                        address1: rj.address1 || '',
                        city: rj.city || '',
                        state: rj.state || '',
                        zipCode: rj.zipCode || '',
                        latitude: rj.latitude,
                        longitude: rj.longitude,
                        jobId: rj.jobId,
                        jobSplitId: rj.jobSplitId,
                        customerId: rj.customerId,
                        customerName: rj.customerName,
                        equipment: rj.equipment,
                        description: rj.description,
                        totalSplitJobs: rj.totalSplitJobs
                    }
                };

                const localStartDate = new Date((new Date(date)).toISOString().replace("Z", ""));
                localStartDate.setHours(Math.floor(startTime));
                localStartDate.setMinutes(startTime - Math.floor(startTime) === 0.5 ? 30 : 0);
                const jobStart = new Date(localStartDate).toISOString();

                const localEndDate = new Date((new Date(date)).toISOString().replace("Z", ""));
                job.estimatedHours = formatTwoPDecimal(job.estimatedHours / (route.routeEmployees.length === 0 ? 1 : route.routeEmployees.length));
                const hour = startTime + (job.estimatedHours);
                localEndDate.setHours(Math.floor(hour));
                localEndDate.setMinutes(Math.round((hour - Math.floor(hour)) * 60));
                const jobEnd = new Date(localEndDate).toISOString();

                job.jobStart = jobStart;
                job.jobEnd = jobEnd;

                return job;
            });

            const newRouteData = {
                ...route,
                routeEquipment: !route.routeEquipment ? [] : [...route.routeEquipment],
                routeEmployees: !route.routeEmployees ? [] : [...route.routeEmployees],
                routeJobs: route.routeJobs ? [...route.routeJobs, ...jobs] : jobs,
            }
            const requestForRoute = axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + routeId, newRouteData);
            // const requestForJob = axios.patch(apiConfig.url.BASE_URL + 'job', { id, jobStage: 1 });
            dispatch({ type: 'IS_UPDATE', status: true });
            Promise.all([requestForRoute])
                .then(values => {
                    dispatch({ type: 'UPDATE_JOB_ROUTE', routeId: routeId, routeJobs: values[0].data.routeJobs });
                    dispatch({ type: 'REMOVE_JOB', jobSplitId: id });
                    dispatch({ type: 'CHANGE_JOB_STAGE', jobSplitId: id, jobStage: 3 });
                    const newJobs = values[0].data.routeJobs.filter(rj => id.includes(rj.jobSplitId));
                    const hasChanged = jobs.some(job => {
                        const newJob = newJobs.find(j => j.jobSplitId == job.jobSplitId);
                        return job.job.latitude != newJob?.job.latitude || job.job.longitude != newJob?.job.longitude;
                    });
                    if (hasChanged) {
                        setTimeout(() => {
                            const newRouteJobs = [...routeJobs.filter(rj => !id.includes(rj.jobSplitId))];
                            newRouteJobs.forEach(rj => {
                                const newJob = newJobs.find(j => j.job.jobAddressId === rj.jobAddressId);
                                if (newJob != null) {
                                    rj.latitude = newJob.job.latitude;
                                    rj.longitude = newJob.job.longitude;
                                }
                            });
                            dispatch({ type: 'INIT_JOB', routeJobs: newRouteJobs });
                        }, 100);
                    }
                    error({ failed: false, msg: 'Update route successfuly.' })
                })
                .catch((e) => {
                    error({ failed: true, msg: 'Update route failed, please try again later.' })
                })
                .finally(() => dispatch({ type: 'IS_UPDATE', status: false }))
        } else if (oldRouteId !== routeId) {
            const route = routes.find(route => route.id === oldRouteId);
            const newRoute = routes.find(route => route.id === routeId);
            const job = { ...route.routeJobs.find(job => id.includes(job.jobSplitId)) };

            const localStartDate = new Date((new Date(date)).toISOString().replace("Z", ""));
            localStartDate.setHours(Math.floor(startTime));
            localStartDate.setMinutes(startTime - Math.floor(startTime) === 0.5 ? 30 : 0);
            const jobStart = new Date(localStartDate).toISOString();

            const localEndDate = new Date((new Date(date)).toISOString().replace("Z", ""));
            job.estimatedHours = formatTwoPDecimal(job.job.estimatedHours / (newRoute.routeEmployees.length === 0 ? 1 : newRoute.routeEmployees.length));
            const hour = startTime + (job.estimatedHours);
            localEndDate.setHours(Math.floor(hour));
            localEndDate.setMinutes(Math.round((hour - Math.floor(hour)) * 60));
            const jobEnd = new Date(localEndDate).toISOString();

            job.jobStart = jobStart;
            job.jobEnd = jobEnd;
            delete job.id;

            const oldRoute = routes.find(route => route.id === oldRouteId);
            const oldRouteData = {
                ...oldRoute,
                routeEquipment: !route.routeEquipment ? [] : [...route.routeEquipment],
                routeEmployees: !route.routeEmployees ? [] : [...route.routeEmployees],
                routeJobs: oldRoute.routeJobs.filter(job => job.jobSplitId !== id)
            };
            const newRouteData = {
                ...newRoute,
                routeEquipment: !newRoute.routeEquipment ? [] : [...newRoute.routeEquipment],
                routeEmployees: !newRoute.routeEmployees ? [] : [...newRoute.routeEmployees],
                routeJobs: newRoute.routeJobs ? [...newRoute.routeJobs, job] : [job]
            };
            const requestForOldRoute = axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + oldRouteId, oldRouteData);
            const requestForNewRoute = axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + routeId, newRouteData);

            dispatch({ type: 'IS_UPDATE', status: true });
            Promise.all([requestForOldRoute, requestForNewRoute])
                .then(values => {
                    dispatch({ type: 'CHANGE_JOB_ROUTE', oldRouteId, routeId, job });
                    error({ failed: false, msg: 'Update route successfuly..' })
                })
                .catch(() => error({ failed: true, msg: 'Update route failed, please try again later.' }))
                .finally(() => dispatch({ type: 'IS_UPDATE', status: false }))
        } else if (oldRouteId === routeId) {
            const route = routes.find(route => route.id === routeId);

            const job = { ...route.routeJobs.find(job => id.includes(job.jobSplitId)) };

            const localStartDate = new Date((new Date(date)).toISOString().replace("Z", ""));
            localStartDate.setHours(Math.floor(startTime));
            localStartDate.setMinutes(startTime - Math.floor(startTime) === 0.5 ? 30 : 0);
            const jobStart = new Date(localStartDate).toISOString();

            const localEndDate = new Date((new Date(date)).toISOString().replace("Z", ""));
            job.estimatedHours = formatTwoPDecimal(job.job.estimatedHours / (route.routeEmployees.length === 0 ? 1 : route.routeEmployees.length));
            const hour = startTime + (job.estimatedHours);
            localEndDate.setHours(Math.floor(hour));
            localEndDate.setMinutes(Math.round((hour - Math.floor(hour)) * 60));
            const jobEnd = new Date(localEndDate).toISOString();

            job.jobStart = jobStart;
            job.jobEnd = jobEnd;

            const routeJob = route.routeJobs.filter(job => job.jobSplitId !== id)
            const newRouteData = {
                ...route,
                routeEquipment: !route.routeEquipment ? [] : [...route.routeEquipment],
                routeEmployees: !route.routeEmployees ? [] : [...route.routeEmployees],
                routeJobs: route.routeJobs ? [...routeJob, job] : [job]
            };
            const requestForRoute = axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + routeId, newRouteData);
            dispatch({ type: 'IS_UPDATE', status: true });
            Promise.all([requestForRoute])
                .then(values => {
                    dispatch({ type: 'CHANGE_JOB_HOURS', oldRouteId, routeId, job });
                    error({ failed: false, msg: 'Update route successfuly..' })
                })
                .catch(() => error({ failed: true, msg: 'Update route failed, please try again later.' }))
                .finally(() => dispatch({ type: 'IS_UPDATE', status: false }))
        }
    }
    const onDropEmployee = ({ id, oldRouteId }, routeId) => {
        const route = routes.find(route => route.id === routeId);
        if (oldRouteId === undefined) {
            const eps = routeEmployees.filter(emp => id.includes(emp.id));
            let employees = eps.map(ep => ({
                ...ep,
                employeeId: ep.id,
                phoneNumber: ep.phones && ep.phones[0] ? ep.phones[0].formattedPhoneNumber : ''
            }));

            const routeJob = route.routeJobs;
            routeJob.map(job => {
                const localEndDate = new Date(job.jobStart);
                job.estimatedHours = formatTwoPDecimal(job.job.estimatedHours / (route.routeEmployees.length === 0 ? 1 : route.routeEmployees.length + 1));

                // job.estimatedHours = formatTwoPDecimal(job.estimatedHours * ( route.routeEmployees.length / (route.routeEmployees.length + 1)));
                const hour = localEndDate.getHours() + Math.round((localEndDate.getMinutes() / 60) * 100) / 100 + (job.estimatedHours);
                localEndDate.setHours(Math.floor(hour));
                localEndDate.setMinutes(Math.round((hour - Math.floor(hour)) * 60));
                // localEndDate.setHours((hour) - Math.floor(hour) > 0.5 ? Math.floor(hour) + 1 : Math.floor(hour));
                // localEndDate.setMinutes((hour) - Math.floor(hour) > 0 && (hour) - Math.floor(hour) <= 0.5 ? 30 : 0);
                job.jobEnd = new Date(localEndDate).toISOString();
            })
            const newRouteData = {
                ...route,
                routeEquipment: !route.routeEquipment ? [] : [...route.routeEquipment],
                routeEmployees: route.routeEmployees ? [...route.routeEmployees, ...employees] : employees,
                // set routeJobs is null to not update in server side
                routeJobs: routeJob,
            };
            dispatch({ type: 'IS_UPDATE', status: true });
            axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + routeId, newRouteData)
                .then(res => {
                    dispatch({ type: 'ADD_EMP_ROUTE', routeId, employees });
                    dispatch({ type: 'REMOVE_EMPLOYEE', employeeIds: id })
                    error({ failed: false, msg: 'Update route successfuly.' })
                })
                .catch(() => error({ failed: true, msg: 'Update route failed, please try again later.' }))
                .finally(() => dispatch({ type: 'IS_UPDATE', status: false }))
        } else if (oldRouteId !== routeId) {
            const oldRoute = routes.find(route => route.id === oldRouteId);
            const route = routes.find(route => route.id === routeId);
            const employee = oldRoute.routeEmployees.find(emp => id.includes(emp.employeeId));

            // get old routes
            const routeJobOld = oldRoute.routeJobs;
            routeJobOld.map(job => {
                const localEndDate = new Date(job.jobStart);
                job.estimatedHours = formatTwoPDecimal(job.estimatedHours * (oldRoute.routeEmployees.length === 0 ? 1 : oldRoute.routeEmployees.length / (oldRoute.routeEmployees.length - 1 <= 0 ? 1 : oldRoute.routeEmployees.length - 1)));
                const hour = localEndDate.getHours() + Math.round((localEndDate.getMinutes() / 60) * 100) / 100 + (job.estimatedHours);
                localEndDate.setHours(Math.floor(hour));
                localEndDate.setMinutes(Math.round((hour - Math.floor(hour)) * 60));
                job.jobEnd = new Date(localEndDate).toISOString();
            })

            const oldRouteData = {
                ...oldRoute,
                routeEquipment: !oldRoute.routeEquipment ? [] : [...oldRoute.routeEquipment],
                routeEmployees: oldRoute.routeEmployees.filter(emp => !id.includes(emp.employeeId)),
                routeJobs: !route.routeJobs ? [] : [...routeJobOld]
            };

            // get new routes
            const routeJobNew = route.routeJobs;
            routeJobNew.map(job => {
                const localEndDate = new Date(job.jobStart);
                job.estimatedHours = formatTwoPDecimal(job.estimatedHours * (route.routeEmployees.length === 0 ? 1 : route.routeEmployees.length / (route.routeEmployees.length + 1)));
                const hour = localEndDate.getHours() + Math.round((localEndDate.getMinutes() / 60) * 100) / 100 + (job.estimatedHours);
                localEndDate.setHours(Math.floor(hour));
                localEndDate.setMinutes(Math.round((hour - Math.floor(hour)) * 60));
                job.jobEnd = new Date(localEndDate).toISOString();
            })

            const newRouteData = {
                ...route,
                routeEquipment: !route.routeEquipment ? [] : [...route.routeEquipment],
                routeEmployees: route.routeEmployees ? [...route.routeEmployees, employee] : [employee],
                routeJobs: !route.routeJobs ? [] : [...routeJobNew]
            };
            const requestForOldRoute = axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + oldRouteId, oldRouteData);
            const requestForNewRoute = axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + routeId, newRouteData);
            dispatch({ type: 'IS_UPDATE', status: true });
            Promise.all([requestForOldRoute, requestForNewRoute])
                .then(values => {
                    dispatch({ type: 'CHANGE_EMP_ROUTE', oldRouteId, routeId, employees: [employee] });
                    error({ failed: false, msg: 'Update route successfuly.' })
                })
                .catch(() => error({ failed: true, msg: 'Update route failed, please try again later.' }))
                .finally(() => dispatch({ type: 'IS_UPDATE', status: false }))
        }
    }
    const onDropEquipment = ({ id, oldRouteId }, routeId) => {
        const route = routes.find(route => route.id === routeId);
        if (oldRouteId === undefined) {
            const equipment = routeEquipment.filter(eqp => id.includes(eqp.id));
            const newRouteData = {
                ...route,
                routeEquipment: route.routeEquipment ? [...route.routeEquipment, ...equipment] : equipment,
                routeEmployees: !route.routeEmployees ? [] : [...route.routeEmployees],
                // set routeJobs is null to not update in server side
                routeJobs: null,
            };
            dispatch({ type: 'IS_UPDATE', status: true });
            axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + routeId, newRouteData)
                .then(res => {
                    dispatch({ type: 'ADD_EQP_ROUTE', routeId, equipment });
                    dispatch({ type: 'REMOVE_EQUIPMENT', routeId, equipment });
                    error({ failed: false, msg: 'Update route successfuly.' })
                })
                .catch(() => error({ failed: true, msg: 'Update route failed, please try again later.' }))
                .finally(() => dispatch({ type: 'IS_UPDATE', status: false }))
        } else if (oldRouteId !== routeId) {
            const oldRoute = routes.find(route => route.id === oldRouteId);
            const equipment = oldRoute.routeEquipment.find(eqp => id.includes(eqp.id));
            const oldRouteData = {
                ...oldRoute,
                // routeEmployees: !route.routeEmployees ? [] : [...route.routeEmployees],
                // routeJobs: !route.routeJobs ? [] : [...route.routeJobs],
                routeEquipment: oldRoute.routeEquipment.filter(eqp => !id.includes(eqp.id))
            };
            const newRouteData = {
                ...route,
                // routeEmployees: !route.routeEmployees ? [] : [...route.routeEmployees],
                // routeJobs: !route.routeJobs ? [] : [...route.routeJobs],
                routeEquipment: route.routeEquipment ? [...route.routeEquipment, equipment] : [equipment]
            };
            const requestForOldRoute = axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + oldRouteId, oldRouteData);
            const requestForNewRoute = axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + routeId, newRouteData);
            dispatch({ type: 'IS_UPDATE', status: true });
            Promise.all([requestForOldRoute, requestForNewRoute])
                .then(values => {
                    dispatch({ type: 'CHANGE_EQP_ROUTE', oldRouteId, routeId, equipment });
                    error({ failed: false, msg: 'Update route successfuly.' })
                })
                .catch(() => error({ failed: true, msg: 'Update route failed, please try again later.' }))
                .finally(() => dispatch({ type: 'IS_UPDATE', status: false }))
        }
    }

    const getFullName = employee => {
        if (employee) {
            let days = [];
            employee.dayOff.forEach(day => {
                days.push(`${moment(day.start).format('hh:mm A')} - ${moment(day.end).format('hh:mm A')}`)
            })
            const dayString = days.length > 0 ? ` | Unavailable: ${days.join(', ')}` : '';
            return `${employee.firstName || ''}${employee.lastName ? ` ${employee.lastName}` : ' '} ${dayString}`.replace('N/A', '');
        } else {
            return '';
        }

    }

    const getTooltip = (employee) => {
        let days = [];
        employee.dayOff.forEach(day => {
            days.push(`${moment(day.start).format('hh:mm A')} - ${moment(day.end).format('hh:mm A')}`)
        })
        return (
            <>
                {
                    days.length > 0 &&
                    <Typography>
                        <em>Unavailable: </em> {days.join(', ')}
                    </Typography>
                }
                <Typography>
                    <em>Phone number: </em> {employee.phoneNumber}
                </Typography>
            </>
        )
    }

    const handleEditRoute = (type, item) => {
        const checkDisableRouteOption = type === "employee" || type === "equipment";
        const routeOrders = routes.map(r => {
            return {
                value: r.routeOrder,
                name: `Route ${r.routeOrder}`,
                disabled: checkDisableRouteOption && r.routeOrder === route.routeOrder
            }
        })
        setEditedRoute({
            type,
            item,
            order: route.routeOrder,
            routeOrders: [{ value: 0, name: 'Unschedule' }].concat(routeOrders)
        });
        setOpenScheduledModal(true)
    }
    const handleUpdateRoute = (data) => {
        setOpenScheduledModal(false)
        if (!data.routeOrder) {
            data.type === 'job' && handleDropBackJobItem({ oldRouteId: route.id, id: data.item.jobSplitId });
            data.type === 'employee' && handleDropBackEmployeeItem({ oldRouteId: route.id, id: data.item.employeeId });
            data.type === 'equipment' && handleDropBackEquipmentItem({ oldRouteId: route.id, id: data.item.id });
            return;
        }
        const newRoute = routes.find(r => r.routeOrder === data.routeOrder);
        switch (data.type) {
            case "job":
                onDropItem({ id: data.item.jobSplitId, oldRouteId: route.id }, newRoute.id, data.starttime)
                break;
            case "employee":
                onDropEmployee({ id: data.item.employeeId, oldRouteId: route.id }, newRoute.id)
                break;
            case "equipment":
                onDropEquipment({ id: data.item.id, oldRouteId: route.id }, newRoute.id)
                break;
            default:
                break;
        }
    }

    const getInforJob = (job) => {
        const _job = job;
        let hourDiff = moment.duration(moment(job.jobEnd).diff(moment(job.jobStart))).asHours();
        const idenSplits = _job.job && _job.job.totalSplitJobs > 1 ? `  (1/${_job.job.totalSplitJobs})` : '';
        const city = _job.job && _job.job.city ? `${_job.job.city},` : ''
        const info = _job.job ? `${_job.job.customerName}, ${city} ${hourDiff} hours, $${(_job.job.price + _job.job.discountDollars).toFixed(2)}${idenSplits}` : '';
        return info
    }

    const handleCollisionJobs = jobs => {
        if (jobs.length === 0) return

        let line = -1;
        let lastJob = undefined;
        do {
            lastJob = collisionJobs[++line]?.slice(-1).pop();
        } while (lastJob && moment(lastJob.jobEnd) > moment(jobs[0].jobStart));

        collisionJobs[line] = collisionJobs[line] ?? [];
        collisionJobs[line].push(jobs.shift())

        handleCollisionJobs(jobs);
    }

    useEffect(() => {
        if (!route.routeJobs || route.routeJobs.length == 0) {
            setJobEquipments('');
            return;
        }
        const { routeJobs } = route;
        // get job equipment string
        let equipments = routeJobs.map(job => job.job && job.job.equipment);
        equipments = equipments.filter(Boolean).join(';').replaceAll(',', '').replaceAll(';', ', ');
        equipments = equipments.trim();
        setJobEquipments(equipments.replaceAll(/^, ?|, ?$/g, ''));

        setRouteJobsOnTime(time.map((_, i) => routeJobs.filter(rj =>
            (moment.utc(rj.jobStart).local().hours() === i && rj.jobStart === rj.jobEnd) ||
            (moment.duration(moment.utc(rj.jobStart).local().format('HH:00')).asHours() <= i &&
                (moment.duration(moment.utc(rj.jobEnd).local().format('HH:mm')).asHours() > i ||
                    moment.utc(rj.jobStart).local().date() < moment.utc(rj.jobEnd).local().date()))
        ).sort((rj1, rj2) => moment(rj1.jobStart) - moment(rj2.jobStart))));
    }, [route.routeJobs])

    useEffect(() => {
        // check jobs was duplicate time
        const jobs = _.clone(route.routeJobs.sort((rj1, rj2) => moment(rj1.jobStart) - moment(rj2.jobStart)));
        handleCollisionJobs(jobs)
        setLinesJobs(collisionJobs)
        route.lineJobCount = collisionJobs.length;
    }, [route.routeJobs])

    useEffect(() => {
        const hideDistance = () => {
            if (!route.distance) return
            setTimeout(() => {
                dispatch({ type: "CLEAR_DISTANCE" })
            }, 0)
        }
        document.addEventListener("click", hideDistance);
        return (() => {
            document.removeEventListener("click", hideDistance);
        })
    }, [route.distance])

    return (
        <>
            <div className={classes.routeWaper}>
                <Typography style={v_ipad ? { cursor: 'pointer', minWidth: '100px' } : { cursor: 'initial' }}
                    className={`${classes.times} ${isSelected && v_ipad ? classes.selectedRoute : null}`}
                    onClick={() => v_ipad ? changeSelectedRoute(route) : false}>
                    <MuiThemeProvider theme={customThemeTooltip}>
                        {time.map((t, index) => (
                            <Tooltip arrow title={
                                !expandContent && routeJobsOnTime[index]?.length
                                    ? routeJobsOnTime[index].map(rj => (
                                        <div key={rj.id}>
                                            &#9679;&nbsp;{rj.job?.customerName},
                                            ${(rj.job?.price + rj.job?.discountDollars).toFixed(2)},&nbsp;
                                            {moment(rj.jobStart).format('LT')} - {moment(rj.jobEnd).format('LT')}
                                        </div>
                                    ))
                                    : ''
                            }>
                                <span key={`times-${index}`} style={!expandContent && routeJobsOnTime[index]?.length ? { backgroundColor: '#4caf5040', cursor: 'pointer' } : {}}>
                                    {t}
                                </span>
                            </Tooltip>
                        ))}
                    </MuiThemeProvider>
                </Typography>
                <Collapse in={expandContent} unmountOnExit>
                    <div className={classes.routeJob}>
                        {times.map(time => (
                            <React.Fragment key={`time-${time}`}>
                                <DropBox
                                    onClick={() => handleShowSourceModal(time, 'jobs')}
                                    className={classes.jobBox}
                                    routeId={route.id}
                                    startTime={time}
                                    error={error}
                                    canDrag={!route.routeFull}
                                    recurJob={recurJob}
                                    showJobNotes={showJobNotes}
                                    lineCount={linesJobs.length}
                                />
                                <DropBox
                                    onClick={() => handleShowSourceModal(time + 0.5, 'jobs')}
                                    className={classes.jobBox}
                                    routeId={route.id}
                                    startTime={time + 0.5}
                                    error={error}
                                    canDrag={!route.routeFull}
                                    recurJob={recurJob}
                                    showJobNotes={showJobNotes}
                                    lineCount={linesJobs.length}
                                />
                            </React.Fragment>
                        ))}
                        {linesJobs.map((lineJob, index) => {
                            return lineJob.map(job => (
                                <ResizableBox
                                    startTime={moment.duration(moment(job.jobStart).format('HH:mm')).asHours()}
                                    key={job.jobSplitId}
                                    index={index}
                                    routeId={route.id}
                                    job={job}
                                    canDrag={!route.routeFull}
                                    topJobId={topJobId}
                                    changeTopJob={() => setTopJobId(job.jobSplitId)}
                                    editJob={() => handleEditRoute('job', job)}
                                >
                                    {getInforJob(job)}
                                </ResizableBox>
                            ))
                        })}
                    </div>
                </Collapse>
            </div>
            <Collapse in={expandContent} unmountOnExit>
                <Typography onClick={() => handleShowSourceModal(null, 'employees')}
                    ref={route.routeFull ? null : dropEmployee}
                    style={{ backgroundColor: isOverEmployee ? '#c5c5c5' : 'transparent' }}
                    className={classes.routeDetail}>
                    <IconButton className={classes.addBox} onClick={() => handleShowSourceModal(null, 'employees')}>
                        <AddBoxRounded fontSize='small' />
                    </IconButton>
                    {route.routeEmployees && route.routeEmployees.map((emp) => (
                        <DraggableBox
                            key={emp ? emp.employeeId : null}
                            onClick={() => handleEditRoute('employee', emp)}
                            className={`${classes.empeqp} ${route.isCommercial ? classes.bgPurple : classes.bgGreen}`}
                            data={getFullName(emp)}
                            employees={emp}
                            source={{ id: emp ? emp.employeeId : null, type: 'Employee', oldRouteId: route.id }}
                            canDrag={!route.routeFull}
                            dragType={'employee'}
                            tooltip={getTooltip(emp)}
                        />
                    ))}
                </Typography>
                <Typography />
                <Typography onClick={() => handleShowSourceModal(null, 'equipments')}
                    ref={route.routeFull ? null : dropEquipment}
                    style={{ backgroundColor: isOverEquipment ? '#c5c5c5' : '#transparent' }}
                    className={classes.routeDetail}>
                    <IconButton className={classes.addBox} onClick={() => handleShowSourceModal(null, 'equipments')}>
                        <AddBoxRounded fontSize='small' />
                    </IconButton>
                    {jobEquipments && (
                        <span className={classes.jobEquipments}>
                            {jobEquipments}
                        </span>
                    )}
                    {route.routeEquipment && route.routeEquipment.map((eqp) => (
                        <DraggableBox
                            key={eqp ? eqp.id : null}
                            onClick={() => handleEditRoute('equipment', eqp)}
                            className={`${classes.empeqp} ${route.isCommercial ? classes.bgPurple : classes.bgGreen}`}
                            data={eqp.assetTag}
                            source={{ id: eqp ? eqp.id : null, type: 'Equipment', oldRouteId: route.id }}
                            canDrag={!route.routeFull}
                            dragType={'equipment'}
                        />
                    ))} 
                </Typography>
                <Typography style={{ borderBottom: '1px solid #b8b8b8' }} />
                <Typography className={classes.routeDetail}>
                    {route.routeNote || ''}
                </Typography>
            </Collapse>
            <SourcesModal
                defaultType={defaultType}
                open={openSourceModal}
                onClose={() => setOpenSourceModal(false)}
                handleSelectedSource={handleSelectedSource}
            />

            <ScheduledModal
                editedRoute={editedRoute}
                open={openScheduledModal}
                onClose={() => setOpenScheduledModal(false)}
                scheduled={handleUpdateRoute}
            />
        </>
    )
}

RouteContent.propTypes = {
    route: PropTypes.object.isRequired,
    routeWidth: PropTypes.number,
    error: PropTypes.func
}

export default RouteContent;
