import React, { useEffect, useState, useRef } from 'react'
import {
    Card, CardHeader, CardContent, CardActions, Divider, Grid,
    Typography, TextField, Button, FormControlLabel, colors, Snackbar, CircularProgress
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { DatePicker } from '@material-ui/pickers'
import { makeStyles } from '@material-ui/styles'
import { GreenCheckbox, KeyboardDatePicker } from 'components'
import moment from 'moment'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import axios from "utils/axios";
import apiConfig from "apiConfig";

const useStyles = makeStyles(theme => ({
    btn: {
        display: 'block',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    btnDis: {
        display: 'block',
        color: 'rgba(0, 0, 0, 0.26)',
        backgroundColor: 'rgb(217 217 217)',
        pointerEvents: 'none',
    },
    label: {
        fontSize: '15px',
        fontWeight: 500
    },
    dates: {
        display: 'flex',
        justifyContent: 'center',
        padding: '0 32px',
        marginTop: '16px'
    },
    styleTo: {
        fontWeight: 'bold',
        textAlign: 'center',
        lineHeight: '50px',
    },
    checkboxGroup: {
        border: '1px solid #b3b3b3',
        borderRadius: '4px',
        marginLeft: '15px',
        paddingLeft: '16px',
    },
    checkbox: {
        display: 'block'
    },
    transferCity: {
        display: 'flex',
        padding: '0 16px',
        marginTop: '16px',
        [theme.breakpoints.down('xs')]: {
            display: 'block'
        }
    },
    cities: {
        width: '45%',
        border: '1px solid #b3b3b3',
        borderRadius: '4px',
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    overBox: {
        width: '100%',
        height: '200px',
        overflowY: 'auto',
        padding: '16px 8px',
        '&::-webkit-scrollbar': {
            width: '5px'
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#e4e4e4'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#9c9c9c',
            borderRadius: '60px'
        }
    },
    labelCity: {
        position: 'absolute',
        top: '-10px',
        left: '10px',
        backgroundColor: '#ffffff',
        padding: '0 3px',
        fontWeight: 500
    },
    city: {
        paddingLeft: '3px',
        cursor: 'pointer'
    },
    filterCity: {
        marginBottom: '5px',
        '& .MuiInput-input': {
            paddingLeft: '4px'
        }
    },
    transferActions: {
        width: '10%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            flexDirection: 'row',
            margin: '16px 0'
        },
        '& p': {
            width: '50%',
            textAlign: 'center',
            borderRadius: '4px',
            padding: '6px 0',
            backgroundColor: colors.green[600],
            color: '#ffffff',
            fontSize: '20px',
            [theme.breakpoints.down('xs')]: {
                width: '20%'
            },
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: colors.green[900]
            }
        }
    }
}));

var citiesData = []
var citiesDataIds = []

const Advertising = ({ adminId }) => {

    const classes = useStyles();
    const mounted = useRef(false);

    const ipPlus = useMediaQuery('(max-width: 415px)');
    const ipad = useMediaQuery('(max-width: 769px)');

    const [states, setStates] = useState();
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [isExport, setIsExport] = useState(false);
    const [openPicker, setOpenPicker] = useState(false);
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });
    const [date, setDate] = useState({});
    const [typeDate, setTypeDate] = useState('fromDate');
    const [marketingList, setMarketingList] = useState([0]);
    const [include, setInclude] = useState(['billing']);
    const [cities, setCities] = useState({ excluded: [...citiesData], included: [], idsExcluded: [...citiesDataIds], idsIncluded: [] });
    const [filterValue, setFilterValue] = useState({ excluded: '', included: '', idsExcluded: '', idsIncluded: '' });
    const [filterCities, setFilterCities] = useState({ excluded: cities.excluded, included: cities.included, idsExcluded: cities.idsExcluded, idsIncluded: cities.idsIncluded })
    const [selectedCity, setSelectedCity] = useState({ excluded: null, included: null, idsExcluded: null, idsIncluded: null });

    // date actions
    const onChangeDate = (name, value) => {
        if (name === 'fromDate' && !value) {
            setDate({ ...date, fromDate: null, toDate: null })
        } else {
            setDate({ ...date, [name]: value });
        }
    }
    const onShowDatePicker = type => {
        setTypeDate(type);
        setOpenPicker(true)
    }
    const onHandleChangeDate = val => {
        setDate({ ...date, [typeDate]: new Date(val).toISOString() })
    }

    const onChangeMarketing = val => {
        marketingList.length === 2 ?
            (val === 2 ?
                setMarketingList([3]) : setMarketingList([2])
            )
            :
            ((marketingList[0] === 0 || marketingList[0] === 1) ?
                setMarketingList([val]) : (marketingList[0] !== val && setMarketingList([...marketingList, val]))
            )
    }
    const onChangeInclude = val => {
        include.length === 2 ?
            val === 'billing' ? setInclude(['jobsite']) : setInclude(['billing'])
            :
            include[0] !== val && setInclude([...include, val])
    }
    
    // transfer actions
    const transferOneToIncluded = () => {
        const filterCts = [...filterCities.excluded];
        const filterCtsIds = [...filterCities.idsExcluded];
        const cts = [...cities.excluded];
        const ctsIds = [...cities.idsExcluded];
        const city = filterCts.splice(selectedCity.excluded, 1);
        const cityIds = filterCtsIds.splice(selectedCity.idsExcluded, 1);
        cts.splice(cts.findIndex(c => c === city[0]), 1);
        ctsIds.splice(ctsIds.findIndex(c => c === cityIds[0]), 1);
        setSelectedCity({ ...selectedCity, excluded: null, ...selectedCity, idsExcluded: null });
        setFilterCities({ excluded: filterCts, included: [...filterCities.included, city[0]], idsExcluded: filterCtsIds, idsIncluded: [...filterCities.idsIncluded, cityIds[0]] });
        setCities({ excluded: cts, included: [...cities.included, city[0]], idsExcluded: ctsIds, idsIncluded: [...cities.idsIncluded, cityIds[0]] })
    }
    const transferAllToIncluded = () => {
        const filterCts = [...filterCities.excluded];
        const filterCtsIds = [...filterCities.idsExcluded];
        const cts = [...cities.excluded];
        const ctsIds = [...cities.idsExcluded];
        setFilterCities({ excluded: [], included: filterCities.included.concat(filterCts), idsExcluded: [], idsIncluded: filterCities.idsIncluded.concat(filterCtsIds) });
        setCities({ excluded: [], included: cities.included.concat(cts), idsExcluded: [], idsIncluded: cities.idsIncluded.concat(ctsIds) })
    }
    const transferOneToExcluded = () => {
        const filterCts = [...filterCities.included];
        const filterCtsIds = [...filterCities.idsIncluded];
        const cts = [...cities.included];
        const ctsIds = [...cities.idsIncluded];
        const city = filterCts.splice(selectedCity.included, 1);
        const cityIds = filterCtsIds.splice(selectedCity.idsIncluded, 1);
        cts.splice(cts.findIndex(c => c === city[0]), 1);
        ctsIds.splice(ctsIds.findIndex(c => c === cityIds[0]), 1);
        setSelectedCity({ ...selectedCity, included: null, ...selectedCity, idsIncluded: null });
        setFilterCities({ excluded: [...filterCities.excluded, city[0]], included: filterCts, idsExcluded: [...filterCities.idsExcluded, cityIds[0]], idsIncluded: filterCtsIds });
        setCities({ excluded: [...cities.excluded, city[0]], included: cts, idsExcluded: [...cities.idsExcluded, cityIds[0]], idsIncluded: ctsIds })
    }
    const transferAllToExcluded = () => {
        const filterCts = [...filterCities.included];
        const filterCtsIds = [...filterCities.idsIncluded];
        const cts = [...cities.included];
        const ctsIds = [...cities.idsIncluded];
        setFilterCities({ excluded: filterCities.excluded.concat(filterCts), included: [], idsExcluded: filterCities.idsExcluded.concat(filterCtsIds), idsIncluded: [] });
        setCities({ excluded: cities.excluded.concat(cts), included: [], idsExcluded: cities.idsExcluded.concat(ctsIds), idsIncluded: [] })
    }
    // filter cities
    const onChangeFilter = event => {
        const { name, value } = event.target;
        const filterCts = cities[name].filter(city => city.toLowerCase().indexOf(value.toLowerCase()) > -1);
        setFilterValue({ ...filterValue, [name]: value });
        setFilterCities({ ...filterCities, [name]: filterCts })
    }

    const stringToFile = (csvFile) => {
        const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, 'test.csv');
        } else {
            const link = document.createElement("a");
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", `customerMarketingExport${moment().format('MMDDYYYYhhmmss')}.csv`);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    useEffect(() => {
        getCities();
    }, [include])

    useEffect(() => {
        mounted.current = true;

        axios.get(apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMERS_GET_ENROLLMENTS)
            .then(res => {
                mounted.current && setStates(res.data.stateOptions)
            });

        return () => mounted.current = false;
    }, [])

    const getCities = () => {
        const param = {
            IncludeBillingContact: include.includes('billing'),
            IncludeJobsiteContact: include.includes('jobsite')
        }
        axios.get(apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMER_CITIES, { params: param })
            .then(res => {
                if (res.data && res.data.customerCities && res.data.customerCities.length > 0) {

                    citiesData = [];
                    citiesDataIds = [];

                    res.data.customerCities.map(item => {
                        citiesData.push(`${item.city || ''}, ${item.state || ''}`);
                        citiesDataIds.push(`${item.id}`);
                    })

                    if (mounted.current) {
                        setCities({ excluded: [...citiesData], included: [], idsExcluded: [...citiesDataIds], idsIncluded: [] });
                        setFilterCities({ excluded: [...citiesData], included: [], idsExcluded: [...citiesDataIds], idsIncluded: [] });
                    }
                }
            })
    }

    // export CSV
    const exportCSV = () => {
        if (!date.fromDate || !date.toDate) {
            setIsStatus({ failed: true, msg: 'Please choose date range.' });
            setOpenSnackBar(true);
            return
        }
        if (cities.included.length === 0) {
            setIsStatus({ failed: true, msg: 'Must have at least one city in Included Cities field.' });
            setOpenSnackBar(true);
            return
        }
        const data = {
            companyId: adminId,
            FromDate: date.fromDate,
            ToDate: date.toDate,
            HasCreatedDate: marketingList.includes(0),
            HasCancelationDate: marketingList.includes(1),
            HasCompletedDate: marketingList.includes(2),
            HasCreatedDateNoJobsComplete: marketingList.includes(3),
            IncludeBillingContact: include.includes('billing'),
            IncludeJobsiteContact: include.includes('jobsite'),
            CityIds: cities.idsIncluded
        }
        setIsExport(true)
        axios.post(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_ADVERTISING_CSV.replace('{companyId}', adminId), data)
            .then(res => {
                stringToFile(res.data)
                setIsExport(false);
            })
    }

    if (!states) {
        return (
            <Grid
                container
                spacing={0}
                align="center"
                justifyContent="center"
                direction="column">
                <Grid item>
                    <CircularProgress className={classes.circularProgress} size={50} />
                </Grid>
            </Grid>
        )
    }

    return (
        <>
            <Card>
                <CardHeader title='Customer Marketing Export' />
                <Divider />
                <CardContent>
                    <Typography className={classes.label}>Select Date Range:</Typography>
                    <div className={classes.dates} style={ipPlus ? { flexDirection: 'column' } : null}>
                        <div style={{
                            position: 'relative',
                            width: ipPlus ? '100%' : '45%',
                            marginBottom: ipPlus ? '8px' : '0'
                        }}>
                            <KeyboardDatePicker
                                label='From Date'
                                name='fromDate'
                                showTodayButton={true}
                                value={date.fromDate || null}
                                onShowDatePicker={() => onShowDatePicker('fromDate')}
                                onChange={date => onChangeDate('fromDate', date)}
                            />
                        </div>
                        <Typography style={{ width: '10%', display: ipPlus ? 'none' : 'block' }}
                            className={classes.styleTo}>To</Typography>
                        <div style={{ position: 'relative', width: ipPlus ? '100%' : '45%' }}>
                            <KeyboardDatePicker
                                disabled={!date.fromDate}
                                label='To Date'
                                name='toDate'
                                minDate={date.fromDate}
                                value={date.toDate || null}
                                onShowDatePicker={() => onShowDatePicker('toDate')}
                                onChange={date => onChangeDate('toDate', date)}
                            />
                        </div>
                    </div>

                    <Typography className={classes.label} style={{ marginTop: '32px' }}>Create Marketing List
                        Including:</Typography>
                    <div style={{ padding: '0 32px' }}>
                        <FormControlLabel
                            className={classes.checkbox}
                            label='Customers added between date range'
                            name='customerAdded'
                            checked={marketingList[0] === 0}
                            onChange={() => setMarketingList([0])}
                            control={<GreenCheckbox />}
                        />
                        <FormControlLabel
                            className={classes.checkbox}
                            label='Customers with cancelled jobs between date range'
                            name='customersWithCancelledJobs'
                            checked={marketingList[0] === 1}
                            onChange={() => setMarketingList([1])}
                            control={<GreenCheckbox />}
                        />
                    </div>
                    <div className={classes.checkboxGroup} style={ipad ? { marginRight: '15px' } : { width: '44%' }}>
                        <FormControlLabel
                            className={classes.checkbox}
                            label='Customers with last completed job between date range'
                            name='customersWithLastCompletedJob'
                            checked={marketingList.findIndex(val => val === 2) !== -1}
                            onChange={() => onChangeMarketing(2)}
                            control={<GreenCheckbox />}
                        />
                        <FormControlLabel
                            className={classes.checkbox}
                            label='Customers added between date range who received a quote but no work was complete'
                            name='customersAddedWithQuote'
                            checked={marketingList.findIndex(val => val === 3) !== -1}
                            onChange={() => onChangeMarketing(3)}
                            control={<GreenCheckbox />}
                        />
                    </div>

                    <Typography className={classes.label} style={{ marginTop: '32px' }}>Include:</Typography>
                    <div className={classes.checkboxGroup} style={ipad ? { marginRight: '15px' } : { width: '44%' }}>
                        <FormControlLabel
                            className={classes.checkbox}
                            label='Customer Billing Contact Information'
                            name='customerBillingContactInformation'
                            checked={include.findIndex(val => val === 'billing') !== -1}
                            onChange={() => onChangeInclude('billing')}
                            control={<GreenCheckbox />}
                        />
                        <FormControlLabel
                            className={classes.checkbox}
                            label='Customer Jobsite Contact Information'
                            name='customerJobsiteContactInformation'
                            checked={include.findIndex(val => val === 'jobsite') !== -1}
                            onChange={() => onChangeInclude('jobsite')}
                            control={<GreenCheckbox />}
                        />
                    </div>

                    <Typography className={classes.label} style={{ marginTop: '32px' }}>Choose Geography:</Typography>
                    <div className={classes.transferCity}>
                        <div className={classes.cities}>
                            <Typography className={classes.labelCity}>Excluded Cities</Typography>
                            <div className={classes.overBox}>
                                <TextField
                                    fullWidth
                                    className={classes.filterCity}
                                    name='excluded'
                                    placeholder='Search cities...'
                                    variant='standard'
                                    value={filterValue.excluded}
                                    onChange={onChangeFilter}
                                />
                                {filterCities.excluded.map((city, index) => (
                                    <Typography className={classes.city} key={index}
                                        style={index === selectedCity.excluded ? {
                                            backgroundColor: '#43a047',
                                            color: '#ffffff'
                                        } : null}
                                        onClick={() => setSelectedCity({
                                            ...selectedCity,
                                            excluded: selectedCity.excluded !== index ? index : null
                                        })}>
                                        {city}
                                    </Typography>
                                ))}
                            </div>
                        </div>
                        <div className={classes.transferActions}>
                            <Typography
                                style={selectedCity.excluded === null ? {
                                    pointerEvents: 'none',
                                    opacity: 0.7,
                                    color: 'rgba(0, 0, 0, 0.26)',
                                    backgroundColor: 'rgb(217 217 217)',
                                } : null}
                                onClick={transferOneToIncluded}>
                                {'>'}
                            </Typography>
                            <Typography
                                style={selectedCity.included === null ? {
                                    pointerEvents: 'none',
                                    opacity: 0.7,
                                    color: 'rgba(0, 0, 0, 0.26)',
                                    backgroundColor: 'rgb(217 217 217)',
                                } : null}
                                onClick={transferOneToExcluded}>
                                {'<'}
                            </Typography>
                            <Typography style={filterCities.excluded.length === 0 ? {
                                pointerEvents: 'none',
                                opacity: 0.7,
                                color: 'rgba(0, 0, 0, 0.26)',
                                backgroundColor: 'rgb(217 217 217)',
                            } : null}
                                onClick={transferAllToIncluded}>
                                {'>>'}
                            </Typography>
                            <Typography style={filterCities.included.length === 0 ? {
                                pointerEvents: 'none',
                                opacity: 0.7,
                                color: 'rgba(0, 0, 0, 0.26)',
                                backgroundColor: 'rgb(217 217 217)',
                            } : null}
                                onClick={transferAllToExcluded}>
                                {'<<'}
                            </Typography>
                        </div>
                        <div className={classes.cities}>
                            <Typography className={classes.labelCity}>Included Cities</Typography>
                            <div className={classes.overBox}>
                                <TextField
                                    fullWidth
                                    className={classes.filterCity}
                                    name='included'
                                    placeholder='Search cities...'
                                    variant='standard'
                                    value={filterValue.included}
                                    onChange={onChangeFilter}
                                />
                                {filterCities.included.map((city, index) => (
                                    <Typography className={classes.city} key={index}
                                        style={index === selectedCity.included ? {
                                            backgroundColor: '#43a047',
                                            color: '#ffffff'
                                        } : null}
                                        onClick={() => setSelectedCity({
                                            ...selectedCity,
                                            included: selectedCity.included !== index ? index : null
                                        })}>
                                        {city}
                                    </Typography>
                                ))}
                            </div>
                        </div>
                    </div>
                </CardContent>
                <Divider />
                <CardActions style={{ paddingLeft: '16px' }}>
                    <Button className={isExport ? classes.btnDis : classes.btn}
                        onClick={exportCSV}
                    >
                        Export CSV
                    </Button>
                    {isExport && <CircularProgress size={24} />}
                </CardActions>
            </Card>

            <DatePicker
                style={{ display: 'none' }}
                open={openPicker}
                onClose={() => setOpenPicker(false)}
                format="MM/DD/YYYY"
                onChange={onHandleChangeDate}
            />

            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={() => setOpenSnackBar(false)}>
                <Alert elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>

            <div style={{ height: 1, marginTop: 20 }}></div>
        </>
    )
}

export default Advertising;
