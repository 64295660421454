import React, { useState, useEffect } from 'react'
import { useParams, Link, useLocation } from "react-router-dom";
import {
    Card, CardContent, Divider, IconButton, TextField,
    Button, Typography, colors, Tooltip, Snackbar, ClickAwayListener, Popper, Table, TableBody, TableRow, TableCell, FormControlLabel
} from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Alert } from "@material-ui/lab"
import RecurModal from './components/Modals/RecurModal';
import CloneModal from './components/Modals/CloneModal';
import RouteNoteModal from './components/Modals/RouteNoteModal';
import ConfirmRecurJobModal from './components/Modals/ConfirmRecurJobModal';
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { ConfirmModal, GreenCheckbox, Page } from 'components';
import { RouteKeyboardDate } from "./components";
import RouteSources from './RouteSources'
import RoutesContent from './RoutesContent';
import NavigateBeforeRoundedIcon from "@material-ui/icons/NavigateBeforeRounded";
import NavigateNextRoundedIcon from "@material-ui/icons/NavigateNextRounded";
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import AddBoxIcon from "@material-ui/icons/AddBox";
import { makeStyles } from '@material-ui/styles'
import { Resizable } from "re-resizable";

import { useDispatch, useSelector } from "react-redux";
import { route, routeStatus } from "mockup/Routes";
import moment from 'moment'
import axios from "utils/axios";
import apiConfig from "apiConfig";
import { checkFeature, FeatureFlags } from 'FeatureService';
import clsx from 'clsx';
import RouteSummary from './components/RouteSummary';
import localStorage from 'utils/localStorage';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        paddingTop: theme.spacing(1.5)
    },
    routes: {
        marginTop: theme.spacing(1.5)
    },
    btn: {
        width: 95,
        whiteSpace: 'nowrap',
        margin: 5,
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
        padding: '3px 8px',
        fontSize: '12px'
    },
    btnsPrint: {
        display: 'flex',
        flexDirection: 'column',
        '& button:first-child': {
            marginBottom: 10
        }
    },
    btnsPrintXs: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '& button:first-child': {
            marginBottom: 10
        }
    },
    btnExpand: {
        whiteSpace: 'nowrap',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
        padding: '3px 8px',
        fontSize: '12px'
    },
    routeDate: {
        display: "flex",
        alignItems: "flex-start"
    },
    routesHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    headerLeft: {
        maxWidth: 'calc(100% - 106px)',
        margin: '-.5rem -.25rem',
        '&> *': {
            display: 'inline-block',
            padding: '.5rem .25rem'
        }
    },
    headerRight: {
        display: 'flex',
        alignItems: 'center'
    },
    routeActions: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center'
    },
    smallBtnsGroup: {
        position: 'absolute',
        right: '100%',
        zIndex: 99,
        backgroundColor: '#fff',
        boxShadow: '0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -4px rgb(0 0 0 / 10%)',
        borderRadius: '.5rem',
        padding: 4,
        border: '1px solid rgba(82, 63, 105, 0.06)'
    },
    routesDetail: {
        display: 'flex',
        maxHeight: '100%',
        height: '100%'
    },
    addBox: {
        padding: 0,
        marginLeft: 5,
        color: colors.green[600],
        "&:hover": {
            color: colors.green[900],
            backgroundColor: 'transparent'
        },
        "& span svg": {
            fontSize: "48px"
        }
    },
    toggleMap: {
        padding: 6,
        color: colors.green[600],
        "&:hover": {
            color: colors.green[900],
            backgroundColor: 'transparent'
        },
        "& span svg": {
            fontSize: 32
        }
    },
    hideToggleMap: {
        display: 'none'
    },
    mapBtnWrap: {
        marginLeft: 5,
        position: 'relative',
        width: 48,
        height: 46
    },
    mapBtn: {
        /* position: 'absolute',
        transition: 'all .2s ease',
        transitionDelay: '.3s',
        height: 46,
        overflow: 'hidden',
        '&:hover': {
            transitionDelay: '0s',
            borderRadius: 10,
            background: 'white',
            boxShadow: 'rgb(17 17 26 / 10%) 0px 4px 16px, rgb(17 17 26 / 10%) 0px 8px 24px, rgb(17 17 26 / 10%) 0px 16px 56px',
            padding: 8,
            marginLeft: -8,
            marginTop: -8,
            zIndex: 9,
            height: 108
        } */
    },
    drawingManagerOptionsPopper: {
        padding: '0.5rem',
        border: '1px solid',
        backgroundColor: 'white',
        opacity: 1,
        margin: '0.25rem 0px',
        '& td': {
            padding: 4
        }
    },
    summaryView: {
        display: 'block !important',
        height: '100%'
    },
    scheduleView: {
        height: '100%'
    },
    hidden: {
        visibility: 'hidden'
    },
    colExpand:{
        position: 'absolute',
        height: "100%",
        top: "0px",
        right: "-3px",
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
    },
    iconColExpand:{
        border: "0px",
        borderLeft: "1px solid #9c9c9c",
        borderRight: "1px solid #9c9c9c",
        height: "35px",
        width: "6px",
    }
}));

const customThemeTooltip = createTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: "14px",
                maxWidth: "none"
            }
        }
    }
});

const Routes2 = ({ history }) => {
    const classes = useStyles();
    const { date } = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const small_menu = useMediaQuery("(max-width: 1360px)");
    const mb = useMediaQuery("(max-width: 769px)");
    const xs = useMediaQuery("(max-width: 414px)");

    const { routes, isUpdate, orderType } = useSelector(state => state.RoutesReducer);

    const [drag, setDrag] = useState(false);
    const [expand, setExpand] = useState(true);
    const [statusRoutes, setStatusRoutes] = useState("route-number");
    const [status, setStatus] = useState([...routeStatus]);
    const [selectedRoute, setSelectedRoute] = useState();
    const [loadingPdfRoute, setLoadingPdfRoute] = useState(false);
    const [isStatus, setIsStatus] = useState({ failed: false, msg: "" });
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openModalRecur, setOpenModalRecur] = useState(false);
    const [openModalRouteNote, setOpenModalRouteNote] = useState(false);
    const [openConfirmRecurJobModal, setOpenConfirmRecurJobModal] = useState({ open: false });
    const [openConfirmRemoveRouteModal, setOpenConfirmRemoveRouteModal] = useState({ open: false });
    const [openJobNotesModal, setOpenJobNotesModal] = useState({ open: false, content: '' });
    const [recurJobCallback, setRecurJobCallback] = useState();
    const [showMenu, setShowMenu] = useState(false);
    const [enableVisualMap, setEnableVisualMap] = useState(false);
    const [mapVisible, setMapVisible] = useState(localStorage.getItem('openMap') === 'true');
    const [anchorEl, setAnchorEl] = useState(null);
    const [drawingManagerOptions, setDrawingManagerOptions] = useState({
        showToolbar: true,
        drawingMode: 'idle',
        interactionType: 'freehand',
        freehandInterval: 3,
        shapeDraggingEnabled: false,
        shapeRotationEnabled: false
    });
    const [view, setView] = useState('schedule');
    const [colorInput, setColorInput] = useState({GreenMiles: '', YellowMiles: '', RedMiles: ''});

    // handle changing the date
    const previousDate = () => {
        const currDate = moment(date);
        const prevDate = currDate.subtract(1, 'days');
        history.push(prevDate.format("YYYY-MM-DD"));
    };
    const nextDate = () => {
        const currDate = moment(date);
        const nextDate = currDate.add(1, 'days');
        history.push(nextDate.format("YYYY-MM-DD"));
    };

    const resetDistance = () => {
        dispatch({ type: 'CHANGE_ORDER', orderType: '' });
        dispatch({ type: 'SHOW_DISTANCE', showDistance: false })
    }

    const changeSelectedRoute = route => {
        if (selectedRoute) {
            selectedRoute.id === route.id ? setSelectedRoute() : setSelectedRoute(route)
        } else {
            setSelectedRoute(route)
        }
    }

    const onAddRoute = () => {
        dispatch({ type: "IS_UPDATE", status: true });
        axios.post(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + date, route)
            .then(res => {
                dispatch({
                    type: "ADD_ROUTE",
                    route: { ...res.data, index: routes.length }
                });
            })
            .catch(() => {
                setIsStatus({
                    failed: true,
                    msg: "Add failed, please try again later."
                });
                setOpenSnackbar(true);
            })
            .finally(() => dispatch({ type: "IS_UPDATE", status: false }));
    };

    const onToggleMap = () => {
        localStorage.set('openMap', !mapVisible);
        setMapVisible(!mapVisible);
    }

    const openDMO = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
      };

    const handleAction = (type) => {
        switch (type) {
            case "full":
            case "open":
                updateFull(type);
                return;
            case "commercial":
                updateCommercial();
                return;
            case "recur":
                setOpenModalRecur(true);
                return;
            case "clone":
                setOpenModal(true);
                return;
            case "remove":
                onRemoveRoute();
                return;
            case "completion":
                handleCompletion();
                return;
            case "print":
                handleRedirectRoutePdf();
                return;
            case "note":
                setOpenModalRouteNote(true);
                break;
            default:
                return;
        }
    }

    const updateFull = (type) => {
        if (!selectedRoute) return;
        const newRoute = { ...selectedRoute, routeFull: type === "full" };
        dispatch({ type: "IS_UPDATE", status: true });
        axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + selectedRoute.id, newRoute)
            .then(res => {
                setSelectedRoute({ ...selectedRoute, routeFull: res.data.routeFull })
                dispatch({ type: "UPDATE_ROUTE", routeId: selectedRoute.id, route: newRoute });
                setIsStatus({ failed: false, msg: "Update route successfuly." });
                setOpenSnackbar(true)
            })
            .catch(() => {
                setIsStatus({ failed: true, msg: "Update route failed, please try again later." })
                setOpenSnackbar(true)
            })
            .finally(() => dispatch({ type: "IS_UPDATE", status: false }));
    }
    const updateCommercial = () => {
        dispatch({ type: 'IS_UPDATE', status: true });
        const newRoute = { ...selectedRoute, isCommercial: !selectedRoute.isCommercial };
        axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + selectedRoute.id, newRoute)
            .then(res => {
                setSelectedRoute({ ...selectedRoute, isCommercial: newRoute.isCommercial })
                dispatch({ type: "UPDATE_ROUTE", routeId: selectedRoute.id, route: newRoute });
                setIsStatus({ failed: false, msg: "Update route successfuly." });
                setOpenSnackbar(true)
            })
            .catch(() => {
                setIsStatus({ failed: true, msg: "Update route failed, please try again later." })
                setOpenSnackbar(true)
            })
            .finally(() => dispatch({ type: "IS_UPDATE", status: false }));
    }
    const onCloneRoute = route => {
        dispatch({ type: "ADD_ROUTE", route: { ...route, index: routes.length } });
    }
    const onRecurRoute = route => {
        dispatch({ type: "ADD_ROUTE", route: { ...route, index: routes.length } });
    }
    const onRemoveRoute = (forceRemove) => {
        if (!forceRemove && (
            selectedRoute.routeEmployees.length > 0 ||
            selectedRoute.routeJobs.length > 0 ||
            selectedRoute.routeEquipment.length > 0)) {
            setOpenConfirmRemoveRouteModal({ open: true });
            return;
        }

        setOpenConfirmRemoveRouteModal({ open: false });
        dispatch({ type: "IS_UPDATE", status: true });
        axios.delete(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + selectedRoute.id)
            .then(() => {
                setIsStatus({ failed: false, msg: "Delete successfully!" });
                setOpenSnackbar(true);
                setSelectedRoute();
                dispatch({ type: "REMOVE_ROUTE", routeId: selectedRoute.id });
                if (selectedRoute.routeJobs.length > 0) {
                    selectedRoute.routeJobs.forEach(rj => {
                        const routeRemove = { ...rj, ...(rj.job ?? {}) };
                        dispatch({ type: 'ADD_JOB', routeRemove });
                    });
                }
            })
            .catch(() => {
                setIsStatus({ failed: true, msg: "Delete failed, please try again later." });
                setOpenSnackbar(true);
            })
            .finally(() => dispatch({ type: "IS_UPDATE", status: false }));
    }
    const handleCompletion = () => {
        const d = date.replaceAll('-', '/');
        history.push({
            pathname: '/job-completion',
            state: {
                date: d,
                routeId: selectedRoute.id
            }
        });
    }
    const handleRedirectRoutePdf = () => {
        window.open("/route/pdf/" + selectedRoute.id, '_blank');
    }
    const printExpanded = () => {
        window.open("/expanded-routes/pdf/" + date, '_blank');
    }
    const printAllRoutes = () => {
        window.open("/all-routes/pdf/" + date, '_blank');
    }

    const handleRecurJob = (callback, recurrable = true, isDelete = false) => {
        setRecurJobCallback(() => callback);
        setOpenConfirmRecurJobModal({ open: true, recurrable, removing: isDelete });
    }

    const handleRecurComplete = result => {
        if (result === true) {
            const route = routes.find(r => r.id === selectedRoute.id);
            if (route) {
                if (route.recurringRouteParentId) {
                    route.recurringRouteParentId = null;
                } else {
                    route.recurringRouteParentId = route.id;
                }
            }
        }

        setOpenModalRecur(false);
    }

    useEffect(() => {
        if (orderType === "DISTANCE") {
            if (status[status.length - 1].id !== "distance") {
                let l = [
                    ...status,
                    {
                        id: "distance",
                        name: "Distance"
                    }
                ];
                setStatus(l);
            }
            setStatusRoutes("distance");
        }
        if(!orderType){
            setStatusRoutes("route-number");
        }
    }, [orderType]);

    useEffect(() => {
        if (!selectedRoute) return;
        const r = routes.find(r => r.id === selectedRoute.id);
        setSelectedRoute(r)
    }, [routes])

    useEffect(() => {
        (async () => {
            const isEnableVisualMap = await checkFeature(FeatureFlags.VISUAL_MAP);
            setEnableVisualMap(isEnableVisualMap);
        })();

        return () => dispatch({ type: 'LOCATE_ON_ROUTE_COORD', locateOnRouteCoord: null });
    }, []);

    useEffect(() => {
        const companyId = localStorage.getCompanyId();
        let url = apiConfig.url.BASE_URL + apiConfig.url.COMPANY_INFO + companyId;
        axios.get(url)
            .then((res) => {
                setColorInput({
                    GreenMiles: res.data.greenMiles,
                    YellowMiles: res.data.yellowMiles,
                    RedMiles: res.data.redMiles
                });
            })
            .finally()
    }, [])

    return (
        <Page className={classes.root} title="Management Schedules">
            <Link to={`/schedules/${date}`} onClick={resetDistance}>
                <Typography style={{ display: 'inline' }}>Back to Calendar</Typography>
            </Link>
            <div className={classes.routes}>
                <Card>
                    <CardContent className={classes.routesHeader}
                        style={mb ? { flexDirection: 'column', alignItems: 'flex-start' } : null}>
                        <div className={classes.headerLeft}>
                            <div style={xs ? {width: '100%'} : {}}>
                                <TextField
                                    style={{width: '100%'}}
                                    variant="outlined"
                                    select
                                    label={"View"}
                                    SelectProps={{ native: true }}
                                    value={view || ""}
                                    // onClick={e => e.stopPropagation()}
                                    onChange={event => {
                                        setView(event.target.value);
                                    }}>
                                    <option value="schedule">Schedule View</option>
                                    <option value="summary">Summary View</option>
                                </TextField>
                            </div>
                            {view === 'schedule' && <div style={xs ? {width: '100%'} : {}}>
                                <TextField
                                    style={{width: '100%'}}
                                    variant="outlined"
                                    select
                                    label={"Sort Order"}
                                    SelectProps={{ native: true }}
                                    value={statusRoutes || ""}
                                    // onClick={e => e.stopPropagation()}
                                    onChange={event => {
                                        setStatusRoutes(event.target.value);
                                    }}>
                                    {status.map(st => (
                                        <option key={`${st.id}`} value={st.id}>
                                            {st.name}
                                        </option>
                                    ))}
                                </TextField>
                            </div>}
                        </div>
                        <div className={classes.headerRight}
                            style={
                                xs ?
                                    { flexDirection: 'column', alignItems: 'flex-start', width: '100%' } :
                                    (mb ? { width: '100%', justifyContent: 'space-between' } : null)
                            }>
                            <div className={xs ? classes.btnsPrintXs : classes.btnsPrint} style={mb ? { margin: '10px 0' } : null}>
                                <Button style={xs ? {marginBottom: 'initial'} : {}} variant="contained" className={classes.btnExpand} disabled={!routes || routes.length <= 0}
                                    onClick={printExpanded}>
                                    Print Expanded
                                </Button>
                                <Button variant="contained" className={classes.btnExpand} disabled={!routes || routes.length <= 0}
                                    onClick={printAllRoutes}>
                                    Print All Routes
                                </Button>
                            </div>
                            <div className={classes.routeDate}>
                                <IconButton style={{ width: 48 }} onClick={previousDate}>
                                    <NavigateBeforeRoundedIcon />
                                </IconButton>
                                <RouteKeyboardDate
                                    value={date}
                                    onChange={day => day && history.push(day)}
                                />
                                <IconButton style={{ width: 48 }} onClick={nextDate}>
                                    <NavigateNextRoundedIcon />
                                </IconButton>
                            </div>
                            <div className={clsx(classes.routeActions, view === 'summary' ? classes.hidden : null)}
                                style={mb ? { position: 'absolute', right: 9, top: 19 } : null}>
                                {enableVisualMap && !mb && <MuiThemeProvider theme={customThemeTooltip}>
                                    <Tooltip arrow title={"Add Route"}>
                                        <IconButton
                                            className={classes.addBox}
                                            disabled={isUpdate}
                                            onClick={onAddRoute}>
                                            <AddBoxIcon />
                                        </IconButton>
                                    </Tooltip>
                                </MuiThemeProvider>}
                                <div className={small_menu ? classes.smallBtnsGroup : null}
                                    style={small_menu ? { display: showMenu ? 'flex' : 'none' } : null}>
                                    <div style={{ marginTop: small_menu ? 0 : -5 }}>
                                        <Button variant="contained" className={classes.btn} disabled={!selectedRoute}
                                            onClick={() => handleAction('commercial')}>
                                            Commercial
                                        </Button>
                                        <Button variant="contained" className={classes.btn} disabled={!selectedRoute}
                                            onClick={() => handleAction('recur')}>
                                            Recur
                                        </Button>
                                        <Button variant="contained" className={classes.btn} disabled={!selectedRoute}
                                            onClick={() => handleAction(selectedRoute.routeFull ? 'open' : 'full')}>
                                            {selectedRoute && selectedRoute.routeFull ? 'Open Route' : 'Mark Full'}
                                        </Button>
                                        <Button variant={"contained"} className={classes.btn}
                                            disabled={!selectedRoute || selectedRoute.recurringRouteParentId}
                                            onClick={() => handleAction('remove')}>
                                            Remove
                                        </Button>
                                    </div>
                                    <div style={{ marginTop: small_menu ? 0 : -5 }}>
                                        <Button variant="contained" className={classes.btn} disabled={!selectedRoute}
                                            onClick={() => handleAction('completion')}>
                                            Completion
                                        </Button>
                                        <Button variant="contained" className={classes.btn} disabled={!selectedRoute}
                                            onClick={() => handleAction('clone')}>
                                            Clone
                                        </Button>
                                        <Button variant="contained" className={classes.btn} disabled={loadingPdfRoute || !selectedRoute}
                                            onClick={() => handleAction('print')}>
                                            {loadingPdfRoute ? "Loading" : "Print"}
                                        </Button>
                                        <Button variant="contained" className={classes.btn} disabled={!selectedRoute}
                                            onClick={() => handleAction('note')}>
                                            Route Note
                                        </Button>
                                    </div>
                                </div>
                                {small_menu &&
                                    <IconButton className={classes.addBox} onClick={() => setShowMenu(!showMenu)}>
                                        <MenuRoundedIcon />
                                    </IconButton>
                                }
                                {enableVisualMap && !mb ? (
                                        <MuiThemeProvider theme={customThemeTooltip}>
                                            <div className={classes.mapBtnWrap}>
                                                <div className={classes.mapBtn}>
                                                    <Tooltip arrow title={"Toggle map"} placement="bottom" style={{ marginBottom: 4 }}>
                                                        <IconButton
                                                            className={classes.toggleMap}
                                                            onClick={onToggleMap}>
                                                            <span className={mapVisible ? null : classes.hideToggleMap}>
                                                                <i class="fas fa-map-marked-alt"></i>
                                                            </span>
                                                            <span className={mapVisible ? classes.hideToggleMap : null}>
                                                                <i class="fal fa-map-marked-alt"></i>
                                                            </span>
                                                        </IconButton>
                                                    </Tooltip>
                                                    {/* <Tooltip arrow title={"Drawing Manager Options"} placement="left">
                                                        <IconButton
                                                            className={classes.toggleMap}
                                                            onClick={openDMO}>
                                                            <span>
                                                                <i class="fal fa-cogs"></i>
                                                            </span>
                                                        </IconButton>
                                                    </Tooltip> */}
                                                </div>
                                            </div>
                                            {/* {anchorEl != null && <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                                                <Popper open={anchorEl != null} anchorEl={anchorEl} placement={'bottom-end'} style={{ zIndex: 999 }}>
                                                    <div className={classes.drawingManagerOptionsPopper}>
                                                        <Table>
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell>Show toolbar:</TableCell>
                                                                    <TableCell>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <GreenCheckbox
                                                                                    checked={drawingManagerOptions.showToolbar}
                                                                                    onChange={e => setDrawingManagerOptions({ ...drawingManagerOptions, showToolbar: e.target.checked })}
                                                                                />
                                                                            }
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell>Interaction type:</TableCell>
                                                                    <TableCell>
                                                                        <TextField
                                                                            fullWidth
                                                                            size="small"
                                                                            name="interaction-type"
                                                                            onChange={event => setDrawingManagerOptions({ ...drawingManagerOptions, interactionType: event.target.value })}
                                                                            select
                                                                            value={drawingManagerOptions.interactionType}
                                                                            SelectProps={{ native: true }}
                                                                            variant="outlined">
                                                                                <option value="click">click</option>
                                                                                <option value="freehand">freehand</option>
                                                                                <option value="hybrid">hybrid</option>
                                                                        </TextField>
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell>Freehand interval:</TableCell>
                                                                    <TableCell>
                                                                        <TextField
                                                                            fullWidth
                                                                            size="small"
                                                                            name="freehand-interval"
                                                                            onChange={event => setDrawingManagerOptions({ ...drawingManagerOptions, freehandInterval: event.target.value })}
                                                                            select
                                                                            value={drawingManagerOptions.freehandInterval}
                                                                            SelectProps={{ native: true }}
                                                                            variant="outlined">
                                                                                <option value={1}>1</option>
                                                                                <option value={2}>2</option>
                                                                                <option value={3}>3</option>
                                                                                <option value={4}>4</option>
                                                                                <option value={5}>5</option>
                                                                                <option value={10}>10</option>
                                                                                <option value={25}>25</option>
                                                                                <option value={50}>50</option>
                                                                        </TextField>
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell>Shape dragging enabled:</TableCell>
                                                                    <TableCell>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <GreenCheckbox
                                                                                    checked={drawingManagerOptions.shapeDraggingEnabled}
                                                                                    onChange={e => setDrawingManagerOptions({ ...drawingManagerOptions, shapeDraggingEnabled: e.target.checked })}
                                                                                />
                                                                            }
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell>Shape rotation enabled:</TableCell>
                                                                    <TableCell>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <GreenCheckbox
                                                                                    checked={drawingManagerOptions.shapeRotationEnabled}
                                                                                    onChange={e => setDrawingManagerOptions({ ...drawingManagerOptions, shapeRotationEnabled: e.target.checked })}
                                                                                />
                                                                            }
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </div>
                                                </Popper>
                                            </ClickAwayListener>} */}
                                        </MuiThemeProvider>
                                    ) : (
                                        <MuiThemeProvider theme={customThemeTooltip}>
                                            <Tooltip arrow title={"Add Route"}>
                                                <IconButton
                                                    className={classes.addBox}
                                                    disabled={isUpdate}
                                                    onClick={onAddRoute}>
                                                    <AddBoxIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </MuiThemeProvider>
                                    )}
                            </div>
                        </div>
                    </CardContent>
                    <Divider />
                    <CardContent style={{height: mb ? 'calc(100vh - 328px)' : 'calc(100vh - 214px)', padding: 0}}>
                        <div className={view === 'summary' ? classes.summaryView : null} style={{ display: 'none' }}>
                            <RouteSummary />
                        </div>
                        <div className={view === 'schedule' ? classes.scheduleView : classes.hidden}>
                            <div className={classes.routesDetail}>
                                <Resizable 
                                    defaultSize={mb ? {
                                        width: 0,
                                    } : {
                                        width: 350,
                                        }}
                                    maxWidth={expand ? (mb ? setExpand(false) : "50%") : (mb ? 0 : 25)}
                                    minWidth={expand ? (mb ? setExpand(false) : 126) : (mb ? 0 : 25)}
                                    minHeight="100%"
                                    enable={{
                                        top:false,
                                        right:expand ? true : false,
                                        bottom:false,
                                        left:false,
                                        topRight:false,
                                        bottomRight:false,
                                        bottomLeft:false,
                                        topLeft:false
                                    }}
                                    style={{ marginRight: 5 }}
                                    onResize={() => window.dispatchEvent(new Event('resize'))}
                                >
                                    <RouteSources setDrag={() => setDrag(true)} recurJob={handleRecurJob} setExpand={setExpand} />
                                    {(expand && !mb) && (
                                        <div class={classes.colExpand} >
                                            <span class={classes.iconColExpand}></span>   
                                        </div>
                                    )}
                                </Resizable>
                                <RoutesContent
                                    statusRoutes={statusRoutes}
                                    selectedRoute={selectedRoute}
                                    changeSelectedRoute={changeSelectedRoute}
                                    recurJob={handleRecurJob}
                                    showJobNotes={notesContent => setOpenJobNotesModal({ open: true, content: notesContent })}
                                    mapVisible={mapVisible}
                                    drawingManagerOptions={drawingManagerOptions}
                                    colorInput={colorInput}
                                />
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>

            <CloneModal
                router={selectedRoute}
                open={openModal}
                onClose={() => setOpenModal(false)}
                addCloneRoute={onCloneRoute}
            />

            <RecurModal
                router={selectedRoute}
                open={openModalRecur}
                onClose={handleRecurComplete}
                addCloneRoute={onRecurRoute}
            />

            <RouteNoteModal
                open={openModalRouteNote}
                onClose={() => setOpenModalRouteNote(false)}
                router={selectedRoute}
            />



            <ConfirmRecurJobModal
                open={openConfirmRecurJobModal.open}
                recurrable={openConfirmRecurJobModal.recurrable}
                removing={openConfirmRecurJobModal.removing}
                onConfirm={recur => {
                    if (recurJobCallback && typeof recurJobCallback === 'function') recurJobCallback(recur);
                    setOpenConfirmRecurJobModal({ open: false });
                }}
                closeConfirm={() => setOpenConfirmRecurJobModal({ open: false })}
            />

            <ConfirmModal
                title={openJobNotesModal.content}
                isOk={false}
                openConfirm={openJobNotesModal.open}
                closeConfirm={() => setOpenJobNotesModal({ open: false, content: '' })}
                cancelButtonText='Close'
            />

            <ConfirmModal
                title="There are jobs on the selected route. These jobs will be marked To Be Scheduled."
                openConfirm={openConfirmRemoveRouteModal.open}
                onConfirm={() => onRemoveRoute(true)}
                closeConfirm={() => setOpenConfirmRemoveRouteModal({ open: false })}
                isProgress={isUpdate}
            />

            <Snackbar
                open={openSnackbar}
                onClose={() => setOpenSnackbar(false)}
                autoHideDuration={2000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    severity={isStatus.failed ? "error" : "success"}
                >
                    <Typography color="inherit" variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </Page>
    )
}

export default Routes2;
