import React, {useEffect, useState} from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, Font, Image } from '@react-pdf/renderer';
import axios from "../../../../../utils/axios";
import apiConfig from "../../../../../apiConfig";
import { formatPhoneNumber } from 'utils/formatNumber';

const useStyles = makeStyles(theme => ({
    
}));

const styles = StyleSheet.create({
    invoiceSection: {
        fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 20
    },
    sectionOne: {
        flexDirection: "row",
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center'
    },
    textBold: {
        fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px'
    },
    sectionDateAndInvoice: {
        width: '50%', height: 'auto', flexDirection: 'row'
    },
    sectionChildDateAndInvoice: {
        fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '50%', border: 1, textAlign: 'center', padding: 6
    },
    sectionTwo: {
        marginTop: 50, 
        flexDirection: "row",
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center'
    },
    sectionThree: {
        marginTop: 10, 
        flexDirection: "row",
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center'
    },
    dueDate: {
        fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '100%', border: 1, textAlign: 'center', padding: 6
    },
    sectionTable: {
        width: '100%', height: 'auto', flexDirection: 'row'
    }
});

const JobMultilpleQuotePdf = (props) => {
    const classes = useStyles();
    const [data, setData] = useState([]);

    useEffect(() => {
        if(props.jobInfoPdf) {
            setData(props.jobInfoPdf);
        }
    }, [props])

    Font.register({
        family: 'Roboto',
        fonts: [
            {
                src: `https://cdn.jsdelivr.net/npm/roboto-fontface@0.10.0/fonts/roboto/Roboto-Regular.woff`,
                fontWeight: 'medium'
            },
            {
                src: `https://cdn.jsdelivr.net/npm/roboto-fontface@0.10.0/fonts/roboto/Roboto-Bold.woff`,
                fontWeight: 'bold'
            }
        ]
    })

    return (
        <>
            <Document>
                <Page size="A4" style={{ padding: 20, paddingTop: 20 }}>
                    <View wrap={false} style={{ width: '100%', flexDirection: 'row' }}>
                        <View style={{ width: '70%' }}>
                            <View>
                                {props.jobInfoPdf?.company?.logoUrl?.includes('companylogo')
                                    ? <Image
                                        src={props.jobInfoPdf.company.logoUrl}
                                        style={{ maxHeight: 75, objectFit: 'contain', objectPosition: 'top left' }}
                                    />
                                    : <View style={{ height: 75 }} />
                                }
                            </View>
                            <View style={{ flexDirection: 'row' }}>
                                <View style={{ width: '42.5%', alignItems: 'center' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: '#006DB1', marginTop: 5 }}>
                                        SQUEEGEESQUAD.COM
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: '#006DB1' }}>
                                        {props.jobInfoPdf && props.jobInfoPdf.company ? formatPhoneNumber(props.jobInfoPdf.company.primaryPhoneNumber) : ""}
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 8, color: 'black', marginTop: 5 }}>
                                        Licensee: {props.jobInfoPdf && props.jobInfoPdf.company ? props.jobInfoPdf.company.legalName : ""}
                                    </Text>
                                </View>
                                <View style={{ width: '1%' }} />
                                <View style={{ width: '56.5%', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 12, color: 'black', marginTop: 5 }}>
                                        Guaranteed Satisfaction!
                                    </Text>
                                </View>
                            </View>
                        </View>
                        <View style={{ width: '1%' }} />
                        <View style={{ width: '29%', height: 'auto', alignItems: 'center' }}>
                            <View style={{ width: '100%', height: 110, border: 2, borderColor: '#006DB1', padding: 3, backgroundColor: '#E9ECF8' }}>
                                <View style={{ width: '100%', height: '50%' }}>
                                    <View style={{ width: '100%', height: '100%', border: 1, borderColor: 'black', padding: 3 }}>
                                        <View style={{ width: '100%', height: '100%', border: 1, borderColor: 'black', padding: 3, alignItems: 'center' }}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 14, color: 'black' }}>
                                                FREE ESTIMATE
                                            </Text>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', marginTop: 7 }}>
                                                GOOD FOR 90 DAYS
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ width: '100%', height: '50%', marginTop: 5 }}>
                                    <View style={{ flexDirection: 'row' }}>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 8, color: 'black', marginTop: 5, width: '40%' }}>
                                            Estimate Date:
                                        </Text>
                                        <View style={{ borderBottomWidth: 1, borderBottomColor: 'black', width: '60%' }}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black', marginTop: 5, width: '40%' }}>
                                                {props.bidCompleted ? moment(props.bidCompleted).format('MM/DD/YYYY') : ""}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row' }}>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 8, color: 'black', marginTop: 5, width: '40%' }}>
                                            Prepared By:
                                        </Text>
                                        <View style={{ width: '60%' }}>
                                            {props.salesPerson && (props.salesPerson.firstName || props.salesPerson.lastName)
                                            ? <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black', marginTop: 5, width: '100%', borderBottomWidth: 1, borderBottomColor: 'black', }}>
                                                    {(props.salesPerson.firstName || '') +" "+(props.salesPerson.lastName || '')}
                                                </Text>
                                            : null
                                            }
                                            {props.estimator && (props.estimator.firstName || props.estimator.lastName)
                                                ?<Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black', marginTop: 5, width: '100%', borderBottomWidth: 1, borderBottomColor: 'black', }}>
                                                    {(props.estimator.firstName || '')+" "+(props.estimator.lastName || '')}
                                                </Text>                                           
                                                : null
                                            }
                                            {(props.salesPerson && !props.salesPerson.firstName && !props.salesPerson.lastName) && (props.estimator && !props.estimator.firstName && !props.estimator.lastName)
                                                ? <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black', width: '100%', borderBottomWidth: 1, borderBottomColor: 'black', marginTop: 15 }}>
                                                    
                                                </Text>
                                                : null
                                            }
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>


                    <View style={{ width: '100%' }}>
                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 9, color: 'black', marginTop: 5 }}>
                            The confidence you have placed in us is appreciated. We stand 
                            behind our workmanship. If you see a mistake in our cleaning, we 
                            will happily come back and fix it for free. We strive to maintain the 
                            highest quality service at the best possible price. Your satisfaction 
                            is our goal. Please contact us if you have comments or suggestions 
                            about our service. The highest compliment we are paid is the 
                            referral of your friends.{'\n'}Thank you.
                        </Text>
                    </View>

                    <View wrap={false} style={{ width: '100%', flexDirection: 'row', marginTop: 10, alignItems: 'center' }}>
                        <View style={{ width: '60%' }}>
                            <View style={{ flexDirection: 'row' }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '30%' }}>
                                    Customer/Contact
                                </Text>
                                <View style={{ borderBottomWidth: 1, borderBottomColor: 'black', width: '70%' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                        {props.jobInfoPdf && props.jobInfoPdf.customer && props.jobInfoPdf.customer.primaryContact ? props.jobInfoPdf.customer.primaryContact.firstName+" "+props.jobInfoPdf.customer.primaryContact.lastName : ""}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', marginTop: 5 }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '15%' }}>
                                    Address
                                </Text>
                                <View style={{ borderBottomWidth: 1, borderBottomColor: 'black', width: '85%' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                        {props.jobInfoPdf && props.jobInfoPdf.customer && props.jobInfoPdf.customer.address ? props.jobInfoPdf.customer.address.name : ""}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', marginTop: 7 }}>
                                <View style={{ width: '15%' }} />
                                <View style={{ borderBottomWidth: 1, borderBottomColor: 'black', width: '85%' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                        {props.jobInfoPdf && props.jobInfoPdf.customer && props.jobInfoPdf.customer.address && props.jobInfoPdf.customer.address.address1 ? props.jobInfoPdf.customer.address.address1 : ""}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', marginTop: 7 }}>
                                <View style={{ width: '15%' }} />
                                <View style={{ borderBottomWidth: 1, borderBottomColor: 'black', width: '85%' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                        {props.jobInfoPdf && props.jobInfoPdf.customer && props.jobInfoPdf.customer.address ? props.jobInfoPdf.customer.address.city+", " : ""}
                                        {props.jobInfoPdf && props.jobInfoPdf.customer && props.jobInfoPdf.customer.address ? props.jobInfoPdf.customer.address.state.name+" " : ""}
                                        {props.jobInfoPdf && props.jobInfoPdf.customer && props.jobInfoPdf.customer.address ? props.jobInfoPdf.customer.address.zipCode : ""}
                                    </Text>
                                </View>
                            </View>
                        </View>
                        <View style={{ width: '1%' }} />
                        <View style={{ width: '39%' }}>
                            <View style={{ flexDirection: 'row' }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '26%', textAlign: 'right' }}>
                                    Company
                                </Text>
                                <View style={{ width: '4%' }} />
                                <View style={{ borderBottomWidth: 1, borderBottomColor: 'black', width: '70%' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                        {props.jobInfoPdf 
                                            && props.jobInfoPdf.customer 
                                            && props.jobInfoPdf.customer.companyName 
                                            && props.jobInfoPdf.customer.customerType == 0 
                                                ? props.jobInfoPdf.customer.companyName 
                                                : ""
                                        }
                                    </Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', marginTop: 5 }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '26%', textAlign: 'right' }}>
                                    Contact
                                </Text>
                                <View style={{ width: '4%' }} />
                                <View style={{ borderBottomWidth: 1, borderBottomColor: 'black', width: '70%' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                        {props.jobInfoPdf && props.jobInfoPdf.customer && props.jobInfoPdf.customer.primaryContact ? props.jobInfoPdf.customer.primaryContact.firstName+" "+props.jobInfoPdf.customer.primaryContact.lastName : ""}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', marginTop: 7 }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '26%', textAlign: 'right' }}>
                                    Phone
                                </Text>
                                <View style={{ width: '4%' }} />
                                <View style={{ borderBottomWidth: 1, borderBottomColor: 'black', width: '70%' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                        {props.jobInfoPdf && props.jobInfoPdf.customer && props.jobInfoPdf.customer.primaryContact && props.jobInfoPdf.customer.primaryContact.phones.length > 0 ? formatPhoneNumber(props.jobInfoPdf.customer.primaryContact.phones[0].phoneNumber) : ""}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', marginTop: 7 }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '26%', textAlign: 'right' }}>
                                    Email
                                </Text>
                                <View style={{ width: '4%' }} />
                                <View style={{ borderBottomWidth: 1, borderBottomColor: 'black', width: '70%' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                        {props.jobInfoPdf && props.jobInfoPdf.customer && props.jobInfoPdf.customer.primaryContact ? props.jobInfoPdf.customer.primaryContact.email : ""}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    
                    {props.jobInfoPdf && props.jobInfoPdf.quotes && props.jobInfoPdf.quotes.map(function (element, index) {
                        return <View wrap={false} style={{ padding: 5, marginTop: 10 }} key={index}>
                            <View style={{ flexDirection: 'row' }}>
                                <View style={{ width: '50%', border: 1, borderColor: '#006DB1', padding: 5, borderRight: 0 }}>
                                    {/* <View style={{ width: '50%', borderRight: 1 }}> */}
                                        <View style={{ flexDirection: 'row' }}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '30%' }}>
                                                Job Description:
                                            </Text>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black', width: '70%' }}>
                                                {element.job ? element.job.description : ""}
                                            </Text>
                                        </View>
                                        <View style={{ flexDirection: 'row', marginTop: 5}}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '30%' }}>
                                                Job Address:
                                            </Text>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black', width: '70%' }}>
                                                {element.job && element.job.jobAddress && element.job.jobAddress.primaryContact ? element.job.jobAddress.primaryContact.name : ""}
                                            </Text>
                                        </View>
                                        <View style={{ flexDirection: 'row' }}>
                                            <View style={{ width: '30%' }} />
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black',  width: '70%' }}>
                                                {element.job && element.job.jobAddress && element.job.jobAddress.address1 ? element.job.jobAddress.address1 : ""}
                                            </Text>
                                        </View>
                                        <View style={{ flexDirection: 'row' }}>
                                            <View style={{ width: '30%' }} />
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black', width: '70%' }}>
                                                {element.job && element.job.jobAddress ? element.job.jobAddress.city+", " : ""}
                                                {element.job && element.job.jobAddress ? element.job.jobAddress.state.name+" " : ""}
                                                {element.job && element.job.jobAddress ? element.job.jobAddress.zipCode : ""}
                                            </Text>
                                        </View>
                                    {/* </View> */}
                                </View>
                                <View style={{ width: '50%', border: 1, borderColor: '#006DB1', padding: 5 }}>
                                    {/* <View style={{ width: '50%', borderRight: 1 }}> */}
                                        <View style={{ flexDirection: 'row' }}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '30%' }}>
                                                Name:
                                            </Text>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black', width: '70%' }}>
                                            {element.job && element.job.jobAddress && element.job.jobAddress.primaryContact ? element.job.jobAddress.primaryContact.name : ""}
                                            </Text>
                                        </View>
                                        <View style={{ flexDirection: 'row', marginTop: 5}}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '30%' }}>
                                                Phone:
                                            </Text>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black', width: '70%' }}>
                                                {element.job && element.job.jobAddress && element.job.jobAddress.primaryContact ? formatPhoneNumber(element.job.jobAddress.primaryContact.phone) : ""}
                                            </Text>
                                        </View>
                                        <View style={{ flexDirection: 'row', marginTop: 5}}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '30%' }}>
                                                Email:
                                            </Text>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black', width: '70%' }}>
                                                {element.job && element.job.jobAddress && element.job.jobAddress.primaryContact ? element.job.jobAddress.primaryContact.email : ""}
                                            </Text>
                                        </View>
                                    {/* </View> */}
                                </View>
                            </View>
                            <View style={{ width: '100%', border: 1, borderColor: '#006DB1', padding: 5, borderTop: 0, marginTop: -1 }}>
                                <View style={{ width: '100%', flexDirection: 'row' }}>
                                    <View style={{ flexDirection: 'row', width: '35%' }}>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '40%' }}>
                                            Scheduled For:
                                        </Text>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                            
                                        </Text>
                                    </View>
                                    <View style={{ flexDirection: 'row', width: '35%' }}>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '40%' }}>
                                            Quoted By:
                                        </Text>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                            
                                        </Text>
                                    </View>
                                    <View style={{ flexDirection: 'row', width: '30%' }}>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '40%' }}>
                                            Quote Date:
                                        </Text>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                            {element.job && element.job.bidAppointment != null ? moment(element.job.bidAppointment).format('MM/DD/YYYY') : ""}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                            <View style={{ width: '100%', border: 1, borderColor: '#006DB1', borderTop: 0, marginTop: -1 }}>
                                <View style={{ width: '100%', height: 'auto', flexDirection: 'row' }}>
                                    <Text style={{ fontSize: 10, width: '15%', border: 1, borderColor: '#006DB1', borderTop: 0, textAlign: 'center', padding: 6, borderRight: 0, borderLeft: 0, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>Quantity</Text>
                                    <Text style={{ fontSize: 10, width: '55%', border: 1, borderColor: '#006DB1', borderTop: 0, textAlign: 'left', padding: 6, borderRight: 0, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>Description</Text>
                                    <Text style={{ fontSize: 10, width: '15%', border: 1, borderColor: '#006DB1', borderTop: 0, textAlign: 'center', padding: 6, borderRight: 0, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>Unit Price</Text>
                                    <Text style={{ fontSize: 10, width: '15%', border: 1, borderColor: '#006DB1', borderTop: 0, textAlign: 'center', padding: 6, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px', borderRight: 0, }}>Qnty Total</Text>
                                </View>

                                {element.jobPriceItems && element.job.displayOnPrintOutsEmails && element.jobPriceItems.map(function (value, index, array) {
                                    return <View key={index} style={styles.sectionTable}>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '15%', border: 1, borderColor: '#006DB1', textAlign: 'center', padding: 6, borderTop: 0, borderRight: 0, borderLeft: 0, }}>
                                            {value.quantity}
                                        </Text>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '55%', border: 1, borderColor: '#006DB1', textAlign: 'left', padding: 6, borderRight: 0, borderTop: 0 }}>
                                            {value.description}
                                        </Text>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '15%', border: 1, borderColor: '#006DB1', textAlign: 'right', padding: 6, borderRight: 0, borderTop: 0 }}>
                                            {(value && value.price && `$${value.price.toFixed(2)}`) || '$0.00'}
                                        </Text>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '15%', border: 1, borderColor: '#006DB1', textAlign: 'right', padding: 6, borderTop: 0, borderRight: 0, }}>
                                            {(value && value.total && `$${value.total.toFixed(2)}`) || '$0.00'}
                                        </Text>
                                    </View>
                                })}

                                {element.job && element.job.discountPercent > 0 && element.job.discountDollars > 0
                                ? <View style={styles.sectionTable}>
                                    <Text style={{ width: '15%' }}></Text>
                                    <Text style={{ width: '55%'}}></Text>
                                    <Text style={{ width: '15%', border: 1, borderColor: '#006DB1', borderBottom: 0, borderLeft: 0, borderRight: 0, borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>
                                        Discount ({element.job.discountPercent}%)
                                    </Text>
                                    <Text style={{ width: '15%', border: 1, borderColor: '#006DB1', borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, fontFamily: 'Roboto', fontWeight: 'medium', borderRight: 0 }}>
                                        {(element.job && `$${element.job.discountDollars.toFixed(2)}`) || '$0.00'}
                                    </Text>
                                </View>
                                : null }

                                <View style={styles.sectionTable}>
                                    <Text style={{ width: '15%' }}></Text>
                                    <Text style={{ width: '55%'}}></Text>
                                    <Text style={{ width: '15%', border: 1, borderColor: '#006DB1', borderBottom: 0, borderLeft: 0, borderRight: 0, borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>
                                        Sub-Total
                                    </Text>
                                    <Text style={{ width: '15%', border: 1, borderColor: '#006DB1', borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, fontFamily: 'Roboto', fontWeight: 'medium', borderRight: 0 }}>
                                        {(element.subTotal && `$${element.subTotal.toFixed(2)}`) || '$0.00'}
                                    </Text>
                                </View>
                                
                                <View style={styles.sectionTable}>
                                    <Text style={{ width: '15%' }}></Text>
                                    <Text style={{ width: '55%'}}></Text>
                                    <Text style={{ width: '15%', border: 1, borderColor: '#006DB1', borderBottom: 0, borderLeft: 0, borderRight: 0, borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>
                                        Tax ({element.job ? element.job.taxPercent : "0"}%)
                                    </Text>
                                    <Text style={{ width: '15%', border: 1, borderColor: '#006DB1', borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, fontFamily: 'Roboto', fontWeight: 'medium', borderRight: 0 }}>
                                        {(element.taxDollar && `$${element.taxDollar.toFixed(2)}`) || '$0.00'}
                                    </Text>
                                </View>
                                <View style={styles.sectionTable}>
                                    <Text style={{ width: '15%' }}></Text>
                                    <Text style={{ width: '55%'}}></Text>
                                    <Text style={{ width: '15%', border: 1, borderColor: '#006DB1', borderBottom: 0, borderLeft: 0, borderRight: 0, borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>
                                        Total
                                    </Text>
                                    <Text style={{ width: '15%', border: 1, borderColor: '#006DB1', borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, fontFamily: 'Roboto', fontWeight: 'medium', borderRight: 0, borderBottom: 0 }}>
                                        {(element.totalAmount && `$${element.totalAmount.toFixed(2)}`) || '$0.00'}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    })}

                    <View wrap={false} style={{ padding: 5, marginTop: 5, paddingTop: 0 }}>
                        <View style={{ width: '100%', height: 'auto', border: 2, borderColor: '#006DB1' }}>
                            <View style={{ backgroundColor: '#FBE9D6', paddingTop: 5, paddingBottom: 5 }}>
                                {props.jobInfoPdf?.company?.quoteForm?.split('\n').map(function (value, index) {
                                    return <View key={index} style={{ flexDirection: 'row' }}>
                                                <View style={{ width: '5%', marginTop: '-3px' }}>
                                                    <Image src="/images/quote_invoice/dot-24-458488.png" width={5} height={5} />
                                                </View>
                                                <View style={{ width: '95%' }}>
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 8, color: 'black', marginTop: 5 }}>
                                                        {value ? value : ""}
                                                    </Text>
                                                </View>
                                            </View>
                                    })
                                    ?? null
                                }
                                <View style={{ flexDirection: 'row', marginTop: 3 }}>
                                    <View style={{ width: '60%' }} />
                                    <View style={{ width: '40%', alignItems: 'center' }}>
                                        <Image src="/images/quote_invoice/CreditCards.jpg" style={{ width: 105, height: 15 }} />
                                    </View>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', alignItems: 'center', backgroundColor: '#E9ECF8', borderTop: 2, borderTopColor: '#006DB1', paddingTop: 5, paddingBottom: 5 }}>
                                <View style={{ width: '30%', alignItems: 'center' }}>
                                    <Image src="/images/quote_invoice/Logos/Logo2.jpg" style={{ width: 80, height: 30 }} />
                                </View>
                                <View style={{  }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 17, color: '#006DB1' }}>
                                        Glass Safe™
                                    </Text>
                                </View>
                                <View style={{  }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 17, color: '#DA6E2C' }}>
                                         - Our Cleaning Process
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View wrap={false} style={{ padding: 5, paddingTop: 0, alignItems: 'center' }}>
                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black', marginTop: 0 }}>
                            Squeegee Squad window cleaning technicians are trained to clean windows according to glass industry recommendations.
                        </Text>
                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black', marginTop: 0 }}>
                            Read more about our Glass Safe™ window cleaning methods on backside.
                        </Text>
                    </View>
                    <View wrap={false} style={{ padding: 5, paddingTop: 0 }}>
                        <View style={{ width: '100%', height: 125, border: 2, borderColor: '#006DB1' }}>
                            <View style={{ width: '100%', height: '35%', backgroundColor: '#006DB1', padding: 5 }}>
                                <View style={{ borderTop: 2, borderTopColor: 'white', borderBottom: 2, borderBottomColor: 'white', width: '100%', height: '100%', alignItems: 'center', paddingTop: 3, paddingBottom: 3, justifyContent: 'center' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 15, color: 'white' }}>
                                        FRANCHISES AVAILABLE NATIONWIDE!
                                    </Text>
                                </View>
                            </View>
                            <View style={{ width: '100%', height: '65%', backgroundColor: '#E9ECF8', flexDirection: 'row' }}>
                                <View style={{ width: '19%' }}>
                                    <Image src="/images/quote_invoice/Logos/Logo3.jpg" width="100%" height="100%" />
                                </View>
                                <View style={{ width: '1%' }} />
                                <View style={{ width: '39%' }}>
                                    <View style={{ flexDirection: 'row' }}>
                                        <View style={{ width: '5%'}}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 8, color: '#008080', marginTop: 5 }}>
                                                1.
                                            </Text>
                                        </View>
                                        <View style={{ width: '95%' }}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 8, color: 'black', marginTop: 5 }}>
                                                Fully insured company, with Squeegee Squad employees 
                                                thoroughly trained by owners and management.
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row' }}>
                                        <View style={{ width: '5%'}}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 8, color: '#008080', marginTop: 5 }}>
                                               2.
                                            </Text>
                                        </View>
                                        <View style={{ width: '95%' }}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 8, color: 'black', marginTop: 5 }}>
                                                Experienced crew, always in uniform, cleaning windows 
                                                since 1999!
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row' }}>
                                        <View style={{ width: '5%'}}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 8, color: '#008080', marginTop: 5 }}>
                                                3.
                                            </Text>
                                        </View>
                                        <View style={{ width: '95%' }}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 8, color: 'black', marginTop: 5 }}>
                                                Fast,efficient workers maintaining focus on quality service 
                                                and protection inside and outside of your home.
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ width: '1%' }} />
                                <View style={{ width: '39%' }}>
                                    <View style={{ flexDirection: 'row' }}>
                                        <View style={{ width: '5%'}}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 8, color: '#008080', marginTop: 5 }}>
                                                4.
                                            </Text>
                                        </View>
                                        <View style={{ width: '95%' }}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 8, color: 'black', marginTop: 5, lineHeight: '2.2px' }}>
                                                Safety training implemented with our staff regularly.
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row' }}>
                                        <View style={{ width: '5%'}}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 8, color: '#008080', marginTop: 5 }}>
                                               5.
                                            </Text>
                                        </View>
                                        <View style={{ width: '95%' }}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 8, color: 'black', marginTop: 5 }}>
                                                 Employee background checks mandated during 
                                                employee hiring process.
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row' }}>
                                        <View style={{ width: '5%'}}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 8, color: '#008080', marginTop: 5 }}>
                                                6.
                                            </Text>
                                        </View>
                                        <View style={{ width: '95%' }}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 8, color: 'black', marginTop: 5 }}>
                                                Consistently given 5-star online reviews - we get the 
                                                BEST ratings in the industry!
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ width: '1%' }} />
                            </View>
                        </View>
                    </View>

                    <View wrap={false} style={{ padding: 5, paddingTop: 0 }}>
                        <View style={{ flexDirection: 'row', alignItems: 'center', backgroundColor: '#E9ECF8', border: 2, borderColor: '#006DB1', paddingTop: 5, paddingBottom: 5 }}>
                            <View style={{ width: '30%', alignItems: 'center' }}>
                                <Image src="/images/quote_invoice/Logos/Logo2.jpg" style={{ width: 80, height: 30 }} />
                            </View>
                            <View style={{  }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 17, color: '#006DB1' }}>
                                    Glass Safe™
                                </Text>
                            </View>
                            <View style={{  }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 17, color: '#DA6E2C' }}>
                                        - Our Cleaning Process
                                </Text>
                            </View>
                        </View>
                    </View>

                    <View wrap={false} style={{ padding: 10, paddingTop: 0, paddingLeft: 20, paddingRight: 20 }}>
                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 14, color: 'black' }}>
                            ROUTINE WINDOW CLEANING METHODS
                        </Text>
                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, marginTop: 3 }}>
                            Squeegee Squad window cleaners will use safe routine window glass cleaning methods as defined by and approved by the glass industry 
                            according to the GANA/IWCA Bulletin 01-0116 Proper Procedures for Cleaning Architectural Glass Products. Routine window glass 
                            cleaning includes the use of tools and mild detergents such as: soft cloths, strip washers, squeegees, non-abrasive pads, water-fed poles, 
                            and mild detergents.
                        </Text>
                        <View style={{ width: '100%', alignItems: 'center' }}>
                            <View style={{ flexDirection: 'row', alignItems: 'center', width: '80%' }}>
                                <View style={{ width: '20%' }}>
                                    <Image src="/images/quote_invoice/Group1Images/Group1Image1.jpg" />
                                </View>
                                <View style={{ width: '20%' }}>
                                    <Image src="/images/quote_invoice/Group1Images/Group1Image2.jpg" />
                                </View>
                                <View style={{ width: '20%' }}>
                                    <Image src="/images/quote_invoice/Group1Images/Group1Image3.jpg" />
                                </View>
                                <View style={{ width: '20%' }}>
                                    <Image src="/images/quote_invoice/Group1Images/Group1Image4.jpg" />
                                </View>
                                <View style={{ width: '20%' }}>
                                    <Image src="/images/quote_invoice/Group1Images/Group1Image5.jpg" />
                                </View>
                                <View style={{ width: '20%' }}>
                                    <Image src="/images/quote_invoice/Group1Images/Group1Image6.jpg" />
                                </View>
                            </View>
                        </View>
                        <View style={{ width: '100%', alignItems: 'center' }}>
                            <View style={{ alignItems: 'center', width: '80%' }}>
                                <View style={{ flexDirection: 'row' }}>
                                    <View style={{ width: '5%' }}>
                                        <Image src="/images/quote_invoice/dot-24-458488.png" style={{ width: 15, height: 15 }} />
                                    </View>
                                    <View style={{ width: '95%' }}>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black' }}>
                                            Routine cleaning methods will remove normal dirt and debris that can adhere between frequent window cleaning 
                                            services.
                                        </Text>
                                    </View>
                                </View>
                                <View style={{ flexDirection: 'row' }}>
                                    <View style={{ width: '5%' }}>
                                        <Image src="/images/quote_invoice/dot-24-458488.png" style={{ width: 15, height: 15 }} />
                                    </View>
                                    <View style={{ width: '95%' }}>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black' }}>
                                            Routine cleaning methods may not remove all stains and weathering that can occur due to infrequent cleaning or
                                            when windows are improperly protected during construction or remodelling such as: hard water, artillery fungus 
                                            spores, sap, tar, paint, adhesives, varnish, mortar, silicone, and more.
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                    
                    <View wrap={false} style={{ padding: 5, paddingTop: 0 }}>
                        <View style={{ width: '100%', borderBottom: 1, borderBottomColor: "#006DB1" }} />
                    </View>

                    <View wrap={false} style={{ padding: 10, paddingTop: 0, paddingLeft: 20, paddingRight: 20 }}>
                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 14, color: 'black' }}>
                            NON-ROUTINE GLASS RESTORATION
                        </Text>
                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, marginTop: 3 }}>
                            In the event there are stubborn stains or construction debris on your windows that can not be removed with routine methods, Squeegee 
                            Squad will educate and inform you of non-routine restoration options to remove things like hard water, artillery fungus spores, sap, tar, 
                            paint, adhesives, varnish, mortar, silicone, etc.
                        </Text>
                        <View style={{ width: '100%', alignItems: 'center', marginTop: 5, marginBottom: 5 }}>
                            <View style={{ flexDirection: 'row', alignItems: 'center', width: '80%' }}>
                                <View style={{ width: '20%' }}>
                                    <Image src="/images/quote_invoice/Group2Images/Group2Image1.jpg" />
                                </View>
                                <View style={{ width: '20%' }}>
                                    <Image src="/images/quote_invoice/Group2Images/Group2Image2.jpg" />
                                </View>
                                <View style={{ width: '20%' }}>
                                    <Image src="/images/quote_invoice/Group2Images/Group2Image3.jpg" />
                                </View>
                                <View style={{ width: '20%' }}>
                                    <Image src="/images/quote_invoice/Group2Images/Group2Image4.jpg" />
                                </View>
                                <View style={{ width: '20%' }}>
                                    <Image src="/images/quote_invoice/Group2Images/Group2Image5.jpg" />
                                </View>
                                <View style={{ width: '20%' }}>
                                    <Image src="/images/quote_invoice/Group2Images/Group2Image6.jpg" />
                                </View>
                            </View>
                        </View>
                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, marginTop: 5 }}>
                            Window Restoration using non-routine cleaning methods will only be performed after the homeowner or client has read an important 
                            disclosure and understands the options, limitations, and risks involved in using tools like: metal razor blades, acids, abrasives, polishing 
                            compounds, and more.
                        </Text>
                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, marginTop: 5 }}>
                            Squeegee Squad will not use non-routine glass cleaning methods without your consent. Additional charges may be proposed if it is 
                            determined that non-routine methods are necessary and the scope of the job is affected.
                        </Text>
                    </View>

                    <View wrap={false} style={{ padding: 5, paddingTop: 0 }}>
                        <View style={{ width: '100%', borderBottom: 1, borderBottomColor: "#006DB1" }} />
                    </View>

                    <View wrap={false} style={{ padding: 5, paddingTop: 0 }}>
                        <View style={{ width: '100%', border: 3, borderColor: "#006DB1", paddingTop: 3, paddingBottom: 3, paddingLeft: 2, paddingRight: 2, backgroundColor: '#E9ECF8' }}>
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 19, color: '#DA6E2C' }}>
                                HOW TO PREPARE FOR YOUR WINDOW CLEANING
                            </Text>
                        </View>
                    </View>

                    <View wrap={false} style={{ padding: 25, paddingTop: 0, paddingBottom: 0 }}>
                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 14, color: 'black' }}>
                            CHECKLIST/NOTICE OF RESPONSIBILITIES
                        </Text>
                    </View>

                    <View wrap={false} style={{ padding: 10, paddingTop: 0, paddingBottom: 0, marginTop: 5 }}>
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <View style={{ width: '5%', alignItems: 'center' }}>
                                <Image src="/images/quote_invoice/check.png" style={{ width: 13, height: 13 }} />
                            </View>
                            <View style={{ width: '95%' }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 8, color: 'black' }}>
                                    Feel free to pull all blinds into the open (up) position. We will not take responsibility for any aged or broken blinds.
                                </Text>
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 2 }}>
                            <View style={{ width: '5%', alignItems: 'center' }}>
                                <Image src="/images/quote_invoice/check.png" style={{ width: 13, height: 13 }} />
                            </View>
                            <View style={{ width: '95%' }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 8, color: 'black' }}>
                                    Please take note of and inform our crew if you have any windows with tint film applied, broken blinds, cracked glass, torn screens, brittle 
                                    removable grids, scratched glass, or any other areas that may require extra care.
                                </Text>
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 2 }}>
                            <View style={{ width: '5%', alignItems: 'center' }}>
                                <Image src="/images/quote_invoice/check.png" style={{ width: 13, height: 13 }} />
                            </View>
                            <View style={{ width: '95%' }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 8, color: 'black' }}>
                                    Our crew will move most furniture and put it back into the original position with the exception of pianos, or any other large objects that 
                                    would require expert moving experience to move. Please clear away any knick knacks in your window sills.
                                </Text>
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 2 }}>
                            <View style={{ width: '5%', alignItems: 'center' }}>
                                <Image src="/images/quote_invoice/check.png" style={{ width: 13, height: 13 }} />
                            </View>
                            <View style={{ width: '95%' }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 8, color: 'black' }}>
                                    Be ready to pay by check, cash or credit card at the completion of your service.
                                </Text>
                            </View>
                        </View>
                    </View>

                    <View wrap={false} style={{ padding: 5, paddingTop: 0, marginTop: 5 }}>
                        <View style={{ width: '100%', borderBottom: 1, borderBottomColor: "#006DB1" }} />
                    </View>

                    <View wrap={false} style={{ padding: 5, paddingTop: 0, marginTop: 5 }}>
                        <View style={{ width: '100%', alignItems: 'center' }}>
                            <View style={{ width: '70%', flexDirection: 'row' }}>
                                <View style={{ width: '49%', height: '100%' }}>
                                    <Image src="/images/quote_invoice/Logos/Logo1.jpg" />
                                </View>
                                <View style={{ width: '2%' }} />
                                <View style={{ width: '49%' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 9, color: 'black', marginTop: 5 }}>
                                        {props.jobInfoPdf && props.jobInfoPdf.company && props.jobInfoPdf.company.addresses && props.jobInfoPdf.company.addresses.length > 0 ? (props.jobInfoPdf.company.addresses[0].address1 || '') : ""}
                                    </Text>
                                    {props.jobInfoPdf && props.jobInfoPdf.company && props.jobInfoPdf.company.addresses && props.jobInfoPdf.company.addresses.length > 0 && props.jobInfoPdf.company.addresses[0].address2 && props.jobInfoPdf.company.addresses[0].address2 != ""
                                        ? <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 9, color: 'black', marginTop: 5 }}>
                                            {props.jobInfoPdf.company.addresses[0].address2}
                                        </Text>
                                        : null 
                                    }
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 9, color: 'black', marginTop: 5 }}>
                                        {props.jobInfoPdf && props.jobInfoPdf.company && props.jobInfoPdf.company.addresses && props.jobInfoPdf.company.addresses.length > 0 ? props.jobInfoPdf.company.addresses[0].city+", " : ""}
                                        {props.jobInfoPdf && props.jobInfoPdf.company && props.jobInfoPdf.company.addresses && props.jobInfoPdf.company.addresses.length > 0 ? props.jobInfoPdf.company.addresses[0].state.name+" " : ""}
                                        {props.jobInfoPdf && props.jobInfoPdf.company && props.jobInfoPdf.company.addresses && props.jobInfoPdf.company.addresses.length > 0 ? props.jobInfoPdf.company.addresses[0].zipCode : ""}
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 9, color: 'black', marginTop: 5 }}>
                                        Phone: {props.jobInfoPdf && props.jobInfoPdf.company ? formatPhoneNumber(props.jobInfoPdf.company.primaryPhoneNumber) : ""}
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 9, color: 'black', marginTop: 5 }}>
                                        Email: {props.jobInfoPdf && props.jobInfoPdf.company ? props.jobInfoPdf.company.senderEmail : ""}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>

                    <View wrap={false} style={{ padding: 5, paddingTop: 0 }}>
                        <View style={{ width: '100%', alignItems: 'center' }}>
                            <View style={{ width: '60%', height: 40 }}>
                                <Image src="/images/quote_invoice/Socials.jpg" style={{  }} />
                            </View>
                        </View>
                    </View>

                </Page>
            </Document>
        </>
    )
}

JobMultilpleQuotePdf.propTypes = {
    
}

export default JobMultilpleQuotePdf;
