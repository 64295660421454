import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import CustomerToolbar from '../CustomerToolbar/CustomerToolbar';
import CustomerCards from '../CustomerCards/CustomerCards';
import axios from 'utils/axios';
import apiConfig from 'apiConfig';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Grid, Typography} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux'
import qs from 'query-string';

const useStyles = makeStyles(theme => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
        backgroundColor: '#43a047',
        '&:hover': {
            backgroundColor: '#1b5e20'
        },
    },
    circularProgress: {
        marginTop: '30px',
    },
    customSearch: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(2),
    },
    noData: {
        fontSize: theme.spacing(2),
        fontWeight: 500,
        textAlign: 'center'
    }
}));

let mounted;
let pageNumber = 1;
let forcePage = 0;

const useSearchDelay = (val, delay) => {
    delay = delay || 500;
    const [debounced, setDebounced] = useState(val)

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebounced(val)
        }, delay)

        return () => clearTimeout(handler)
    }, [val, delay])

    return debounced;
}

const AllCustomer = ({ defaultSearch }) => {

    const { customers } = useSelector(state => state.ReloadReducer);
    const history = useHistory();

    const onPageChange = (page) => {
        pageNumber = page.selected + 1;
        forcePage = page.selected;
        fetchCustomers();
    };

    const classes = useStyles();
    const [customersData, setCustomersData] = useState(null);
    const [value, setValue] = useState(defaultSearch || '');
    const [loading, setLoading] = useState();
    const [isHideArchived, setHideArchived] = useState(false);
    let searchQuery = useSearchDelay(value);

    const onSearch = () => {
        if (searchQuery) {
            history.push({ pathname: "/search", search: qs.stringify({ query: searchQuery, type: 'customer' , archived :  isHideArchived}) });
        }
    }
    const onClear = () => {
        forcePage = 0;
        pageNumber = 1;
        setValue('')
    }

    const fetchCustomers = () => {
        let url = apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMERS + pageNumber;
        const params = {};
        if (searchQuery) {
            params.query = searchQuery;
        }
        params.isHideArchived = isHideArchived;
        setLoading(true);
        axios.get(url, {params})
            .then(res => mounted && setCustomersData(res.data))
            .finally(() => mounted && setLoading(false))
    };
    const onHandleArchivedChange = (event) => {
       setHideArchived(event.target.checked);
    }
    useEffect(() => {
        mounted = true;

        if (mounted) {
            onSearch()
        }

        return () => mounted = false;
    }, [searchQuery]);

    useEffect(() => {
        mounted = true;

        if (mounted) {
            fetchCustomers()
        }

        return () => mounted = false;
    }, [isHideArchived]);

    if (!customersData) {
        return (

            <Grid
                container
                spacing={0}
                align="center"
                justifyContent="center"
                direction="column">
                <Grid item>
                    <CircularProgress className={classes.circularProgress} size={50} />
                </Grid>
            </Grid>
        )
    }

    return (
        <>
            <CustomerToolbar
                autoFocus={!!defaultSearch}
                className={classes.customSearch}
                onSearch={onSearch} onClear={onClear}
                value={value}
                onChange={event => setValue(event.target.value)}
                hideArchived={true}
                archived={isHideArchived}
                handleArchivedChange={onHandleArchivedChange}
            />
            {loading ?
                <Grid
                    container
                    spacing={0}
                    align="center"
                    justifyContent="center"
                    direction="column">
                    <Grid item>
                        <CircularProgress className={classes.circularProgress} size={30} />
                    </Grid>
                </Grid>
                :
                (customersData.customers && customersData.customers.length !== 0 ?
                    <CustomerCards customersData={customersData} forcePage={forcePage} onPageChange={onPageChange} />
                    :
                    <div>
                        <Typography className={classes.noData}>No data found.</Typography>
                    </div>
                )}

            <Link to="/customers/add-customer">
                <Fab className={classes.fab}
                    color="secondary">
                    <AddIcon />
                </Fab>
            </Link>
        </>
    );
};

export default AllCustomer;
