import React from "react";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Paper, Input, Button , FormControlLabel } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { GreenCheckbox } from 'components';
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  searchIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  clearIcon: {
    marginRight: theme.spacing(1)
  },
  searchButton: {
    marginLeft: theme.spacing(2)
  },
  searchPaper: {
    flexGrow: 1,
    height: 42,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center'
  },
  input: {
    width: '100%'
  },
  hideArchivedCheckbox: {
    marginLeft: theme.spacing(1)
  }
}));

const SearchInput = props => {
  const { className, placeholder, onSearch, onClear, value, onChange, style, hideArchived, handleArchivedChange, archived, ...rest } = props;
  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}>
      <Paper
        className={classes.searchPaper}>
        <SearchIcon className={classes.searchIcon} />
        <Input
          {...rest}
          className={classes.input}
          disableUnderline
          placeholder={placeholder}
          value={value}
          onChange={event => onChange(event)}
        />
      </Paper>
      <Button
        disabled={value.length === 0}
        className={classes.searchButton}
        onClick={onClear}
        size="large"
        variant="contained">
        <ClearIcon className={classes.clearIcon} />
        Clear
      </Button>
      {hideArchived &&  <FormControlLabel
            className={classes.hideArchivedCheckbox}
            name='isDeleted'
            control={<GreenCheckbox />}
            label="Hide Archived"
            checked={archived || false}
            onChange={e => handleArchivedChange(e)}
        />}
    </div>
  );
};

SearchInput.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onSearch: PropTypes.func,
  onClear: PropTypes.func,
  value: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object
};

export default SearchInput;
