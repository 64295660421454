import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Card, CardContent, Grid, Typography, Button, colors, Tooltip, TextField, CardActions, FormControlLabel} from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles } from '@material-ui/styles'
import { Link } from 'react-router-dom'
import moment from "moment";
import {createTheme, MuiThemeProvider} from "@material-ui/core/styles";
import RoomIcon from '@material-ui/icons/Room';
import axios from "../../../../../utils/axios";
import apiConfig from "../../../../../apiConfig";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import {formatNumber} from "../../../../../utils/formatNumber";
import { AddressLink, GreenCheckbox } from 'components'

const useStyles = makeStyles(theme => ({
    button: {
        whiteSpace: 'nowrap',
        padding: '5px 10px',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    actionBtn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly'
    },
    addressLink: {
        textDecoration: 'underline',
    }
}));
const customThemeTooltip = createTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: '14px',
                maxWidth: 'none'
            }
        }
    }
});
const SaleFollowUpsCard = props => {

    const classes = useStyles();
    const sm_down = useMediaQuery('(max-width: 959px)');

    const { customer, getDistance, lstUserCanBill, onChangeIncludesBidderToJob, selectedEmployeesBidTodo } = props;
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });
    const [saving, setSaving] = useState(false);
    const [selectedEmployees, setSelectedEmployees] = useState();

    const updateCanBill = (jobId, employeeId) => {
        setSaving(true);
        axios.post(apiConfig.url.BASE_URL + apiConfig.url.LIST_CAN_BILL_SAVE, {
            jobIds: [jobId],
            employeeId: employeeId == "" ? null : employeeId
        }).then(res => {
            setSelectedEmployees(employeeId);
            setIsStatus({ failed: false, msg: 'Save successfully!' });
            setOpenSnackBar(true);
            })
            .catch(() => {
                setIsStatus({ failed: true, msg: 'An error occurred, please try again later.' });
                setOpenSnackBar(true);
            })
            .finally(() => {
                setSaving(false);
        })
    }

    const [jobIds, setJobIds] = useState([]);
    function handleChangeIncludesBidderToJob(jobId, event) {
        onChangeIncludesBidderToJob(jobId, event);
        if(event.target.checked) {
            setJobIds([...jobIds, jobId])
        } else {
            var index = jobIds.indexOf(jobId);
            if (index !== -1) {
                jobIds.splice(index, 1);
            }
            setJobIds(jobIds)
        }
    }

    useEffect(() => {
        setSelectedEmployees(customer.employeeId);
    }, [customer])

    useEffect(() => {
        if(selectedEmployeesBidTodo) {
            if(jobIds && jobIds.includes(customer.jobId)) {
                setSelectedEmployees(selectedEmployeesBidTodo);
            }
        } else {
            if(jobIds && jobIds.includes(customer.jobId)) {
                setSelectedEmployees();
            }
        }
    }, [selectedEmployeesBidTodo])

    return (
        <Card>
            <CardContent>
                <Grid container>
                    <Grid item xs={12} sm={5} md={5}>
                        <Typography gutterBottom variant='h4'>
                            {customer.companyName}
                            <MuiThemeProvider theme={customThemeTooltip}>
                                <Tooltip arrow title={'View Customer'}>
                                    <a
                                        href={`/customers/${encodeURIComponent(customer.companyName)}/${customer.companyId}/information`}
                                    >
                                        <i style={{
                                            marginLeft: '10px',
                                            fontSize: '18px',
                                            color: '#263238'
                                        }} className="fas fa-external-link" />
                                    </a>
                                </Tooltip>
                            </MuiThemeProvider>
                        </Typography>
                        <Typography gutterBottom>
                            <strong>Address: </strong>
                            <AddressLink
                                address={{
                                    address1: customer.jobsiteAddress,
                                    city: customer.jobsiteCity,
                                    state: { name: customer.jobsiteState },
                                    zipCode: customer.jobsiteZip
                                }}
                                inline
                            />
                        </Typography>
                        <Typography gutterBottom>
                            <strong>Phone: </strong>{customer.jobsiteContactPhone}
                        </Typography>
                        <Typography gutterBottom>
                            <strong>Job Description: </strong>{customer.jobDescription}
                        </Typography>
                        <Typography gutterBottom>
                            <strong>Note: </strong>{customer.jobSiteNote}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <Typography gutterBottom variant='h4' style={{ display: 'flex' }}>
                            Job
                            <MuiThemeProvider theme={customThemeTooltip}>
                                <Tooltip arrow title={'View Job'}>
                                    <a
                                        href={`/customers/${encodeURIComponent(customer.companyName)}/${customer.companyId}/jobs/${customer.jobId}/information`}
                                    >
                                        <i style={{
                                            marginLeft: '10px',
                                            fontSize: '18px',
                                            color: '#263238'
                                        }} className="fas fa-external-link" />
                                    </a>
                                </Tooltip>
                                <Tooltip arrow title={'Locate'}>
                                    <div style={{ marginTop: '1.5px', marginLeft: '8px' }}>
                                        <a
                                            // style={isSelected ? { color: '#ffffff', backgroundColor: '#4caf50' } : null}
                                            style={{ cursor: 'pointer' }}
                                            onClick={()=>getDistance(customer.jobId)}
                                            target='_blank'>
                                            {/* {gettingDistance ? <CircularProgress size={24} /> : <RoomIcon style={{ fontSize: 24 }} />} */}
                                            <RoomIcon style={{ fontSize: 18 }} />
                                        </a>
                                    </div>
                                </Tooltip>
                                {customer && (customer.distanceToSelected || customer.distanceToSelected == 0)
                                    ? <Typography gutterBottom style={{ marginLeft: '50px' }}>
                                        {formatNumber(customer.distanceToSelected, 2)} Miles
                                        </Typography>
                                    : null
                                }
                            </MuiThemeProvider>
                        </Typography>
                        {/*<Typography gutterBottom>*/}
                        {/*    <strong>Created By: </strong>{customer.jobsiteContactName}*/}
                        {/*</Typography>*/}
                        <Typography gutterBottom>
                            Initial Call: {moment(customer.initialCallDate).format('MM/DD/YYYY')}
                        </Typography>
                        <Typography gutterBottom>
                            Bid Appointment: {customer.bidAppointmentDate != '0001-01-01T00:00:00'
                        &&  moment(customer.bidAppointmentDate+'.000Z').format('MM/DD/YYYY h:mm A')}
                        </Typography>
                        <Typography gutterBottom>
                            Job description: {customer.jobDescription}
                        </Typography>
                    </Grid>
                    {/*<Grid className={classes.actionBtn} item xs={12} md={2}>*/}
                    {/*    <Link  target='_blank'*/}
                    {/*           to={`/customers/${customer.companyName}/${customer.companyId}/information`}*/}
                    {/*    >*/}
                    {/*    <Button style={sm_down ? { marginBottom: '10px', width: '140px', maxWidth: '150px' } : {width: '140px', maxWidth: '150px'}} className={classes.button}>*/}
                    {/*        View Customer*/}
                    {/*    </Button>*/}
                    {/*    </Link>*/}
                    {/*    <Link  target='_blank' to={`/customers/${customer.companyName}/${customer.companyId}/jobs/${customer.jobId}/information`}>*/}
                    {/*        <Button*/}
                    {/*            style={{ width: '140px', maxWidth: '150px'}}*/}
                    {/*            className={classes.button}*/}
                    {/*            variant="contained">*/}
                    {/*            View Job*/}
                    {/*        </Button>*/}
                    {/*    </Link>*/}
                    {/*</Grid>*/}

                    <Grid item xs={12} sm={3} md={3}>
                        <CardActions style={{ width: '100%', justifyContent: 'end' }}>
                            <FormControlLabel
                                name="bidder"
                                control={
                                    <GreenCheckbox />
                                }
                                value={customer.jobId}
                                onChange={(event) => {
                                    handleChangeIncludesBidderToJob(customer.jobId, event)
                                }}
                            />
                            <TextField
                                fullWidth
                                variant='outlined'
                                select
                                label={'Assigned Salesperson'} 
                                InputLabelProps={{ shrink: selectedEmployees ? true : false }}
                                value={selectedEmployees || ""}
                                SelectProps={{native: true}}
                                onClick={e => e.stopPropagation()}
                                onChange={(event) => {
                                    updateCanBill(customer.jobId , event.target.value)
                                }}
                                style={{ width: '200px' }}
                            >
                                <option key={''} value={''}></option>
                                {lstUserCanBill.map(st => (
                                    <option key={`${st.id}`} value={st.id}>
                                        {st.fullName}
                                    </option>
                                ))}
                            </TextField>
                            {saving && <CircularProgress style={{width: '30px'}} size={24} />}
                        </CardActions>
                    </Grid>

                </Grid>
            </CardContent>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                      open={openSnackbar}
                      autoHideDuration={3000}
                      onClose={() => setOpenSnackBar(false)}>
                <Alert elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </Card>
    )
}

SaleFollowUpsCard.propTypes = {
    customer: PropTypes.object.isRequired,
    getDistance: PropTypes.func
}

SaleFollowUpsCard.defaultProps = {
    customer: {},
    getDistance: () => {
    }
}

SaleFollowUpsCard.defaultProps = {
    customer: {},
    getDistance: () => {
    }
}

export default SaleFollowUpsCard;