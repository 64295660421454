import React from 'react';
import {Switch, Redirect, Route} from 'react-router-dom';

import {RouteWithLayout} from './components';
import {Main as MainLayout, Minimal as MinimalLayout, MinimalPdf as MinimalPdfLayout , MainPayment as MainPaymentLayout} from './layouts';

import {
    Dashboard as DashboardView,
    SignIn as SignInView,
    EmployeeList as EmployeeListView,
    EmployeeDetails as EmployeeDetailsView,
    EmployeesPayroll as EmployeesPayrollView,
    EmployeePayroll as EmployeePayrollView,
    EmployeeLogHours as EmployeeLogHoursView,
    EmployeeRoutes as EmployeeRoutesView,

    AddCustomer as AddCustomerView,
    ForgotPassword as  ForgotPasswordView,
    ResetPassword as ResetPasswordView,
    CustomerList as CustomerView,
    CustomerDetails as CustomerDetailsView,
    CustomerJobs as CustomerJobsView,

    Schedules as SchedulesView,
    Routes as RoutesView,
    Routes2 as Routes2View,
    RoutesMobile as RoutesMobileView,

    AddUser as AddUserView,
    NotFound as NotFoundView,
    JobCompletion as JobCompletionView,
    ExpiredSchedules as ExpiredSchedulesView,
    Report as ReportView,
    Admin as AdminView,
    CommunicationCenter as CommunicationCenterView,
    CustomForms as CustomFormsView,
    Companies as CompaniesView,
    MultiCompanyUsers as MultiCompanyUsersView,
    TransferCompanyData as TransferCompanyDataView,

    Help as HelpView,
    Quote as QuoteView,
    Invoice as InvoiceView,
    RoutesPdf as RoutesPdfView,
    RoutePdf as RoutePdfView,
    RoutesExpandedPdf as RoutesExpandedPdfView,
    MutilpleQuote as MutilpleQuoteView,
    MultipleInvoice as MultipleInvoiceView,
    BidsTodo as BidsTodoView,
    Search as SearchView,
    EmployeeTasks as EmployeeTasksView,
    Payment as PaymentView,
} from './views';
import AllCustomers from "./views/CustomerList/components/AllCustomers";
import {BidToDo, Duplicate, MissingTaxGroup, SaleFollowUps} from "./views/CustomerList/components";
import { useMediaQuery } from '@material-ui/core';

const Routes = () => {

    const isMobile = useMediaQuery('(max-width: 780px)', { noSsr: true });

    return (
        <Switch>
            <Redirect
                exact
                from="/"
                to="/dashboard"
            />
            <RouteWithLayout
                component={DashboardView}
                exact
                layout={MainLayout}
                path="/dashboard"
            />
            <Route
                component={SignInView}
                exact
                path="/sign-in"
            />
            <Route
                component={ForgotPasswordView}
                exact
                path="/sign-in/forgot-password"
            />
            <Route
                component={ResetPasswordView}
                exact
                path="/sign-in/reset-password/:userId/:code"
            />
            <RouteWithLayout
                component={EmployeeListView}
                exact
                layout={MainLayout}
                path="/employees"
            />
            <RouteWithLayout
                component={EmployeeDetailsView}
                exact
                layout={MainLayout}
                path='/employees/:name/:id/:tab'
            />
            <RouteWithLayout
                component={AddUserView}
                exact
                layout={MainLayout}
                path="/employees/add-user"
            />
            <RouteWithLayout
                component={EmployeesPayrollView}
                exact
                layout={MainLayout}
                path='/employees/payroll-list'
            />
            <RouteWithLayout
                component={EmployeesPayrollView}
                exact
                layout={MainLayout}
                path='/employees/payroll-list/:start/:end'
            />
            <RouteWithLayout
                component={EmployeePayrollView}
                exact
                layout={MainLayout}
                path='/employees/payroll-detail/:name/:id/:startdate/:enddate'
            />
            <RouteWithLayout
                component={EmployeeLogHoursView}
                exact
                layout={MainLayout}
                path='/employees/log-hours'
            />
            <RouteWithLayout
                component={EmployeeTasksView}
                exact
                layout={MainLayout}
                path='/employees/tasks'
            />
            <RouteWithLayout
                component={EmployeeLogHoursView}
                exact
                layout={MainLayout}
                path='/employees/log-hours/:select_date'
            />
            <RouteWithLayout
                component={EmployeeRoutesView}
                exact
                layout={MainLayout}
                path='/employees/today-route'
            />
            <RouteWithLayout
                component={AddCustomerView}
                exact
                layout={MainLayout}
                path="/customers/add-customer"
            />
            <RouteWithLayout
                component={AddCustomerView}
                exact
                layout={MainLayout}
                path="/customers/add-customer/:id"
            />
            <RouteWithLayout
                component={CustomerView}
                exact
                layout={MainLayout}
                path='/customers/:tab'
            />
            <RouteWithLayout
                component={CustomerView}
                exact
                layout={MainLayout}
                path='/customers/:tab/:select_date'
            />
            <RouteWithLayout
                component={CustomerDetailsView}
                exact
                layout={MainLayout}
                path='/customers/:name/:id/:tab'
            />
            <RouteWithLayout
                component={CustomerJobsView}
                exact
                layout={MainLayout}
                path='/customers/:name/:id/jobs/:jobId/:tab'
            />
            <RouteWithLayout
                component={SchedulesView}
                exact
                layout={MainLayout}
                path="/schedules/:selectDate?"
            />
            <RouteWithLayout
                component={isMobile ? RoutesMobileView : Routes2View}
                exact
                layout={MainLayout}
                path="/schedules/routes/:date"
            />
            <RouteWithLayout
                component={JobCompletionView}
                exact
                layout={MainLayout}
                path='/job-completion'
            />
            <RouteWithLayout
                component={ExpiredSchedulesView}
                exact
                layout={MainLayout}
                path='/expired-schedules'
            />
            <RouteWithLayout
                component={ReportView}
                exact
                layout={MainLayout}
                path='/report'
            />
            <RouteWithLayout
                component={AdminView}
                exact
                layout={MainLayout}
                path='/admin'
            />
            <RouteWithLayout
                component={CommunicationCenterView}
                exact
                layout={MainLayout}
                path='/admin/communication-center'
            />
            <RouteWithLayout
                component={CustomFormsView}
                exact
                layout={MainLayout}
                path='/admin/custom-forms'
            />
            <RouteWithLayout
                component={CommunicationCenterView}
                exact
                layout={MainLayout}
                path='/admin/communication-center/:name/:id/:tab'
            />
            <RouteWithLayout
                component={AdminView}
                exact
                layout={MainLayout}
                path='/admin/:name/:id/:tab'
            />
            <RouteWithLayout
                component={CompaniesView}
                exact
                layout={MainLayout}
                path='/super-admin/companies'
            />
            <RouteWithLayout
                component={MultiCompanyUsersView}
                exact
                layout={MainLayout}
                path='/super-admin/multi-company-users'
            />
            {/*<RouteWithLayout*/}
            {/*    component={TransferCompanyDataView}*/}
            {/*    exact*/}
            {/*    layout={MainLayout}*/}
            {/*    path='/super-admin/transfer-company-data'*/}
            {/*/>*/}
            <RouteWithLayout
                component={HelpView}
                exact
                layout={MainLayout}
                path='/help'
            />
            <RouteWithLayout
                component={SearchView}
                exact
                layout={MainLayout}
                path='/search'
            />
            <RouteWithLayout
                component={QuoteView}
                exact
                layout={MinimalPdfLayout}
                path='/quote/pdf/:id'
            />
            <RouteWithLayout
                component={InvoiceView}
                exact
                layout={MinimalPdfLayout}
                path='/invoice/pdf/:id'
            />
            <RouteWithLayout
                component={RoutesPdfView}
                exact
                layout={MinimalPdfLayout}
                path='/all-routes/pdf/:date'
            />
            <RouteWithLayout
                component={RoutePdfView}
                exact
                layout={MinimalPdfLayout}
                path='/route/pdf/:id'
            />
            <RouteWithLayout
                component={RoutesExpandedPdfView}
                exact
                layout={MinimalPdfLayout}
                path='/expanded-routes/pdf/:date'
            />
            <RouteWithLayout
                component={MutilpleQuoteView}
                exact
                layout={MinimalPdfLayout}
                path='/multiple-quote/pdf/:id'
            />
            <RouteWithLayout
                component={MultipleInvoiceView}
                exact
                layout={MinimalPdfLayout}
                path='/multiple-invoice/pdf/:id'
            />
            <RouteWithLayout
                component={BidsTodoView}
                exact
                layout={MinimalPdfLayout}
                path='/bidtodo/pdf'
            />
            <RouteWithLayout
                component={NotFoundView}
                exact
                layout={MinimalLayout}
                path="/not-found"
            />
            <RouteWithLayout
                component={PaymentView}
                exact
                layout={MainPaymentLayout}
                path="/payment/:encryptedPaymentInfo"
            />
            <Redirect to="/not-found"/>
        </Switch>
    );
};

export default Routes;
