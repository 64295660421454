import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import moment from 'moment';
import { Alert } from '@material-ui/lab'
import {
    Modal, Card, CardHeader, CardContent, CardActions,
    Grid, Typography, TextField, Button, Divider,
    colors, Snackbar, CircularProgress, FormControlLabel
} from '@material-ui/core'
import ForceAddDayOff from './ForceAddDayOff';
import { FormErrorCallback, GreenCheckbox, InputTime, KeyboardDatePicker, Page } from 'components'
import { useDispatch } from 'react-redux'
import apiConfig from 'apiConfig'
import axios from 'utils/axios'
import { Formik } from "formik";
import * as Yup from "yup";

const useStyles = makeStyles(theme => ({
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 500,
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: 500
        },
    },
    conButton: {
        padding: '16px',
        justifyContent: 'flex-end'
    },
    buttonSave: {
        flexShrink: '0',
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
    timeCheckbox: {
        marginLeft: '16px',
        minWidth: '100px',
    },
    time: {
        width: '100%',
        marginRight: '16px',
        height: '53px',
    },
    inputTime: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: '16px',
        width: '100%',
    }
}))

const customerSchema = Yup.object().shape({
    start: Yup.string()
        .nullable().required('This field is required.'),
    timeOffType: Yup.string()
        .nullable().required('This field is required.'),
    startTime: Yup.string()
        .nullable()
        .when(['isFullday', 'end'], {
            is: (isFullday, end) => !isFullday && !end,
            then: Yup.string().required('This field is required.')
        }),
    endTime: Yup.string()
        .nullable()
        .when(['isFullday', 'end'], {
            is: (isFullday, end) => !isFullday && !end,
            then: Yup.string().required('This field is required.')
        })
})
const initValue = { start: '', end: '', isFullday: false, timeOffType: '', note: '' }

const AddDayOff = props => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const { userId, open, onCloseModal, dayOff } = props;

    const [dayoff, setDayoff] = useState({ start: '', end: '', isFullday: false, timeOffType: '', note: '' });
    const [status, setStatus] = useState({ failed: false, msg: '' })
    const [openSnack, setOpenSnack] = useState(false);
    const [isProgress, setIsProgress] = useState(false);
    const [openForceModel, setOpenForceModal] = useState(false);
    const [scheduledDates, setScheduledDates] = useState([]);

    const resetModal = () => {
        setDayoff({ userId: userId });
        onCloseModal()
    }

    const setIsFullday = () => {
        setDayoff({ ...dayoff, isFullday: !dayoff.isFullday, startTime: null, endTime: null })
    }

    const onChangeTime = (time, typeTime) => {
        setDayoff({ ...dayoff, [typeTime]: time ? new Date(`01/01/2020 ${time}`).toISOString() : null })
    }
    const minDate = end => {
        const date = new Date(end);
        date.setDate(date.getDate() + 1);
        return date;
    }
    const maxDate = start => {
        const date = new Date(start);
        date.setDate(date.getDate() - 1);
        return date;
    }
    const onChangeDate = (date, typeDate) => {
        if (typeDate === 'start') {
            setDayoff({ ...dayoff, start: date })
        } else {
            setDayoff({ ...dayoff, end: date, isFullday: true })
        }
    }

    const dayoffData = () => {

        const { start, end, isFullday, startTime, endTime } = dayoff;
        const dayoffData = { ...dayoff };
        delete dayoffData.isFullday;
        delete dayoffData.startTime;
        delete dayoffData.endTime;
        if (isFullday) {
            dayoffData.start = new Date(`${moment(start).format('MM/DD/YYYY')} 00:00 AM`).toISOString();
            dayoffData.end = new Date(`${moment(start).format('MM/DD/YYYY')} 11:59 PM`).toISOString();
        }
        if (isFullday && end) {
            dayoffData.start = new Date(`${moment(start).format('MM/DD/YYYY')} 00:00 AM`).toISOString();
            dayoffData.end = new Date(`${moment(end).format('MM/DD/YYYY')} 11:59 PM`).toISOString();
        }
        if (!isFullday && !end) {
            dayoffData.start = new Date(`${moment(start).format('MM/DD/YYYY')} ${moment(startTime).format('HH:mm')}`).toISOString();
            dayoffData.end = new Date(`${moment(start).format('MM/DD/YYYY')} ${moment(endTime).format('HH:mm')}`).toISOString();
        }
        return dayoffData;
    }

    const forceAddDayOff = () => {
        setOpenForceModal(false);
        const data = dayoffData();
        data.forceSave = true;

        setIsProgress(true);
        axios.put(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_DAYOFF_PUT, data)
            .then(res => {
                data.id = res.data.id;
                dispatch({ type: 'ADD_DAYOFF', dayoff: data });
                setStatus({ failed: false, msg: 'Create successfully.' });
                setOpenSnack(true);
                resetModal()
            })
            .catch(() => {
                setStatus({ failed: true, msg: 'Create failed, please try again later.' })
                setOpenSnack(true);
            })
            .finally(() => setIsProgress(false))
    }

    useEffect(() => {
        if (dayOff) {
            const { start, end, timeOffType, note, id } = dayOff.dayoff;
            const startDate = moment(start).format('MM/DD/YYYY');
            const endDate = moment(end).format('MM/DD/YYYY');
            const startTime = moment(start).format('LT');
            const endTime = moment(end).format('LT');

            const _dayoff = {
                start: start,
                end: startDate === endDate ? null : end,
                isFullday: (startTime === '12:00 AM' && endTime === '11:59 PM') ? true : false,
                startTime: (startTime === '12:00 AM' && endTime === '11:59 PM') ? null : start,
                endTime: (startTime === '12:00 AM' && endTime === '11:59 PM') ? null : end,
                timeOffType,
                note,
                userId: userId,
                id
            }
            setDayoff(_dayoff)
        } else {
            setDayoff({ ...dayoff, userId: userId })
        }
    }, [dayOff, userId])

    return (
        <>
            <Modal open={open} onClose={resetModal}>
                <Formik
                    initialValues={dayoff}
                    validationSchema={customerSchema}
                    enableReinitialize
                    onSubmit={values => {
                        const _dayoff = dayoffData();
                        if (dayOff) {
                            setIsProgress(true);
                            axios.post(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_DAYOFF_UPDATE, _dayoff)
                                .then(res => {
                                    if (res.data.scheduledDates && res.data.scheduledDates.length > 0) {
                                        setScheduledDates(res.data.scheduledDates);
                                        setOpenForceModal(true);
                                    } else {
                                        dispatch({ type: 'UPDATE_DAYOFF', dayoff: _dayoff, index: dayOff.index })
                                        setStatus({ failed: false, msg: 'Update successfully.' });
                                        setOpenSnack(true);
                                        resetModal();
                                    }
                                })
                                .catch(err => {
                                    setStatus({ failed: true, msg: 'Update failed, please try again later.' })
                                    setOpenSnack(true);
                                })
                                .finally(() => setIsProgress(false))
                        } else {
                            setIsProgress(true);
                            _dayoff.forceSave = false
                            axios.put(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_DAYOFF_PUT, _dayoff)
                                .then(res => {
                                    if (res.data.scheduledDates && res.data.scheduledDates.length > 0) {
                                        setScheduledDates(res.data.scheduledDates);
                                        setOpenForceModal(true);
                                    } else {
                                        _dayoff.id = res.data.id;
                                        dispatch({ type: 'ADD_DAYOFF', dayoff: _dayoff });
                                        setStatus({ failed: false, msg: 'Create successfully.' });
                                        setOpenSnack(true);
                                        resetModal()
                                    }
                                })
                                .catch(() => {
                                    setStatus({ failed: true, msg: 'Create failed, please try again later.' })
                                    setOpenSnack(true);
                                })
                                .finally(() => setIsProgress(false))
                        }
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        submitCount,
                        isValid,
                        setFieldTouched,
                        submitForm
                    }) => (
                        <form>
                            <FormErrorCallback
                                submitCount={submitCount}
                                isValid={isValid}
                                onSubmissionError={(errors) => {
                                    console.log(errors);
                                    setStatus({ failed: true, msg: 'Please check the fields again.' });
                                    setOpenSnack(true);
                                }}
                            />
                            <Card className={classes.styleModal}>
                                <CardHeader title='Add Day Off' />
                                <Divider />
                                <CardContent>
                                    <Grid container spacing={4}>
                                        <Grid item xs={6} sm>
                                            <KeyboardDatePicker
                                                error={errors.start && touched.start}
                                                helperText={(errors.start && touched.start) && errors.start}
                                                onBlur={() => {
                                                    setFieldTouched('start', true);
                                                }}
                                                label='Start Date'
                                                name={'start'}
                                                value={values.start || ''}
                                                maxDate={values.end && maxDate(values.end)}
                                                onChange={date => onChangeDate(date, 'start')}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm>
                                            <KeyboardDatePicker
                                                label='End Date'
                                                name={'end'}
                                                value={values.end || ''}
                                                minDate={values.end && minDate(values.end)}
                                                onChange={date => {
                                                    onChangeDate(date, 'end')
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} style={{ display: 'flex' }}>
                                            <FormControlLabel
                                                className={classes.timeCheckbox}
                                                label='Full day'
                                                labelPlacement='end'
                                                name='isFullday'
                                                checked={values.isFullday ? true : false}
                                                onChange={setIsFullday}
                                                control={
                                                    <GreenCheckbox />
                                                }
                                            />
                                            <div className={classes.inputTime}>
                                                <InputTime
                                                    error={errors.startTime}
                                                    ampm='AM'
                                                    disabled={(values.isFullday) ? true : false}
                                                    className={classes.time}
                                                    name={'startTime'}
                                                    value={values.startTime || null}
                                                    onChange={time => onChangeTime(time, 'startTime')}
                                                />
                                                {
                                                    errors.startTime &&
                                                    <p style={{ fontSize: '11px', color: 'red' }} className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
                                                        {(errors.startTime) && errors.startTime}</p>
                                                }
                                            </div>
                                            <div className={classes.inputTime}>
                                                <InputTime
                                                    error={errors.endTime}
                                                    ampm='PM'
                                                    name={'endTime'}
                                                    disabled={(values.isFullday) ? true : false}
                                                    className={classes.time}
                                                    value={values.endTime || null}
                                                    onChange={time => onChangeTime(time, 'endTime')}
                                                />
                                                {
                                                    errors.endTime &&
                                                    <p style={{ fontSize: '11px', color: 'red' }} className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
                                                        {(errors.endTime) && errors.endTime}</p>
                                                }
                                            </div>

                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                error={errors.timeOffType && touched.timeOffType}
                                                helperText={(errors.timeOffType && touched.timeOffType) && errors.timeOffType}
                                                onBlur={() => {
                                                    setFieldTouched('timeOffType', true);
                                                }}
                                                fullWidth
                                                name='timeOffType'
                                                variant='outlined'
                                                label='Type'
                                                select
                                                SelectProps={{ native: true }}
                                                value={values.timeOffType || ''}
                                                onChange={e => setDayoff({ ...values, timeOffType: e.target.value })}>
                                                <option value=''></option>
                                                <option value='Sick_Day'>Sick day</option>
                                                <option value='Vacation'>Vacation</option>
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                multiline
                                                rows={3}
                                                variant='outlined'
                                                label='Note'
                                                name='note'
                                                value={values.note || ''}
                                                onChange={e => setDayoff({ ...values, note: e.target.value })}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <Divider />
                                <CardActions className={classes.conButton}>
                                    <Button
                                        variant="contained"
                                        size="large"
                                        onClick={resetModal}>
                                        Close
                                    </Button>
                                    <div>
                                        <CardActions>
                                            <Button
                                                disabled={isProgress}
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                onClick={submitForm}
                                                className={classes.buttonSave}>
                                                Save
                                            </Button>
                                            {isProgress && <CircularProgress size={24} />}
                                        </CardActions>
                                    </div>
                                </CardActions>
                            </Card>
                        </form>
                    )}
                </Formik>
            </Modal>

            <ForceAddDayOff
                open={openForceModel}
                onClose={() => setOpenForceModal(false)}
                date={scheduledDates}
                forceAddDayOff={forceAddDayOff}
            />

            <Snackbar
                open={openSnack}
                onClose={() => setOpenSnack(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={3000}>
                <Alert elevation={6} variant='filled' severity={status.failed ? 'error' : 'success'}>
                    <Typography variant='h6' color='inherit'>
                        {status.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    )
}

AddDayOff.propTypes = {
    userId: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    dayOff: PropTypes.object,
}

AddDayOff.defaultProps = {
    open: false,
    onCloseModal: () => { },
}

export default AddDayOff;
