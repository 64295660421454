import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/styles'
import {Alert} from '@material-ui/lab'
import {
    Modal, Button, colors, TextField,
    Card, CardContent, CardActions,
    Typography, Snackbar, CircularProgress,
} from '@material-ui/core'
import {Dropzone} from 'components'
import apiConfig from "../../../../../apiConfig";
import axios from "../../../../../utils/axios";

const useStyles = makeStyles(theme => ({
    styleModal: {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 700,
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: 500
        },
    },
    btnUpFile: {
        flexShrink: '0',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
    actionCard: {
        justifyContent: 'flex-end',
        borderTop: '1px solid #eeeeee'
    }
}));

const AddPromoFile = props => {
    let filename = '';
    let isCheck = false;
    const classes = useStyles();

    const {adminId, open, onClose, onUploadFile} = props;

    const [files, setFiles] = useState([]);
    const [changePromoName, setChangePromoName] = useState(false);
    const [promoName, setPromoName] = useState();
    const [promoNameArr, setPromoNameArr] = useState();
    const [att, setAtt] = useState();
    const [isProgress, setIsProgress] = useState(false);
    const [isStatus, setIsStatus] = useState({failed: false, msg: ''});
    const [openSnack, setOpenSnack] = useState(false)

    const onChangeFile = files => {
        files.forEach(file => {
            let name = file.name.replace(/\.[^/.]+$/, "");
            filename += filename ? ', ' + name :  name;
        })
        setPromoName(filename)
        setFiles(files);
    }

    const onUploadAttachments = () => {
        // onReset();
        const promises = [];
        files.forEach(file => {
            const formData = new FormData();
            isCheck = false;
            formData.append('Name', changePromoName ? promoName : file.name.replace(/\.[^/.]+$/, ""));
            formData.append('file', file);
            setIsProgress(true)
            promises.push(axios.post(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_FILE.replace('{id}', adminId), formData)
                .then(res => {
                    // setAtt(res.data);
                })
                .catch(err => {
                    isCheck = err && err.response && err.response.data && err.response.data.includes("System.Exception: ContentType");
                    if(isCheck)
                    {
                        setIsStatus({failed: true, msg: 'Add file failed, please add file pdf.'});
                        setOpenSnack(true);
                    }
                })
                .finally(() => {
                    setIsProgress(false);
                    onReset();
                })
            )
        });
        Promise.all(promises).then(() => {
            onUploadFile(files, promoNameArr);
            if(!isCheck){
                setIsStatus({failed: false, msg: 'Add files successfully.'});
                setOpenSnack(true);
            }
        }).catch(ex => {
            setIsStatus({failed: true, msg: 'Add file failed, please try again later.'});
            setOpenSnack(true);
        })
    }

    const onReset = () => {
        setFiles([]);
        setPromoName('');
        onClose();
    }

    return (
        <>
            <Modal open={open} onClose={onReset}>
                <Card className={classes.styleModal}>
                    <CardContent>
                        <Dropzone acceptedFiles={['application/pdf']} onChange={files => onChangeFile(files)}/>
                        <TextField
                            fullWidth
                            label='Promo Name'
                            name='promoName'
                            variant='outlined'
                            value={promoName || ''}
                            onChange={e => {
                                setChangePromoName(true);
                                setPromoName(e.target.value)
                            }}
                        />
                    </CardContent>
                    <CardActions className={classes.actionCard}>
                        <Button
                            onClick={onReset}
                            variant="contained">
                            Close
                        </Button>
                        <Button
                            disabled={(files.length === 0 || !promoName) ? true : (isProgress)}
                            onClick={onUploadAttachments}
                            variant="contained"
                            color="primary"
                            className={classes.btnUpFile}>
                            Add files
                        </Button>
                        {isProgress && <CircularProgress size={24}/>}
                    </CardActions>
                </Card>
            </Modal>

            <Snackbar
                open={openSnack}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                autoHideDuration={2000}
                onClose={() => setOpenSnack(false)}>
                <Alert
                    elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    )
}

AddPromoFile.propTypes = {
    adminId: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onUploadFile: PropTypes.func
}
AddPromoFile.defaultProps = {
    open: false,
    onClose: () => {
    }
};

export default AddPromoFile;
