import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/styles'
import {Alert} from '@material-ui/lab'
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    colors,
    Divider,
    FormControlLabel,
    Grid,
    Modal,
    Snackbar,
    TextField,
    Typography
} from '@material-ui/core'
import {FormErrorCallback, GreenCheckbox} from 'components'
import apiConfig from 'apiConfig'
import axios from 'utils/axios'
import * as Yup from "yup";
import {Formik} from "formik";
import {errorMessages} from 'common/constants'
import localStorage from "../../../utils/localStorage";
import {useDispatch} from "react-redux";
import {KeyboardDateTimePicker,} from '@material-ui/pickers';
import moment from "moment";

const useStyles = makeStyles(theme => ({
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 500,
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: 500
        },
    },
    conButton: {
        padding: '16px',
        justifyContent: 'space-between'
    },
    buttonSave: {
        flexShrink: '0',
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
    timeCheckbox: {
        marginLeft: '16px',
        width: '100%'
    },
    time: {
        width: '100%',
        marginRight: '16px'
    }
}))

const followUpSchemaManager = Yup.object().shape({
    taskUrgencyId: Yup.string().required(),
    assignedUserId: Yup.string().required(),
    dateDue: Yup.string().nullable().required('This field is required.'),
    description: Yup.string()
        .nullable().required('This field is required.'),
});
const followUpSchema = Yup.object().shape({
    taskUrgencyId: Yup.string().required(),
    assignedUserId: Yup.string().nullable(),
    dateDue: Yup.string().nullable().required('This field is required.'),
    description: Yup.string()
        .nullable().required('This field is required.'),
});

const EmployeesTaskModal = props => {
    const classes = useStyles();
    const {open, onCloseModal, onload, taskUrgencies, usersData, selected} = props;

    const initValue = {
        assignedUserId: '',
        emailAssignee: true,
        dateDue: '',
        description: '',
        taskUrgencyId: '',
    };

    const [isStatus, setIsStatus] = useState({failed: false, msg: ''});
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [isProgress, setIsProgress] = useState(false);
    const [followUp, setFollowUp] = useState(initValue);
    const [hasEdit, setHasEdit] = useState(false);
    const [isProgressComplete, setIsProgressComplete] = useState(false);
    const [dueDateValid, setDueDateValid] = useState(true);

    const managerRole = ["Company Administrator", "System Admin", "Area Director"];
    const userRole = localStorage.getUserRoles();
    const dispatch = useDispatch();
    const user = localStorage.getFullInfo();
    let hasManager = false;
    userRole.forEach((x, i) => {
        if(managerRole.find(y=>y === x)){
            hasManager = true;
        }
    })

    const onMarkComplete = (values) => {
        setIsProgressComplete(true)
        const data = {
            id: values.id
        }
        axios.patch(apiConfig.url.BASE_URL + apiConfig.url.EMPLOYEE_COMPLETE_TASK, data)
            .then(() => {
                setIsStatus({ failed: false, msg: 'Update successfully.' });
                setOpenSnackBar(true);
                setFollowUp({...values, dateCompleted: new Date()})
                onCloseModal();
                onload();
                dispatch({ type: 'RELOAD_EMPLOYEES_TASK_COUNTER', reloadTaskSideBar: true });
            })
            .catch(() => {
                setIsStatus({ failed: true, msg: 'Update failed, please try again later.' });
                setOpenSnackBar(true);
            }).finally(() => {
            setIsProgressComplete(false);
        })
    }

    useEffect(() => {
        if(selected && Object.keys(selected).length > 0) {
            setFollowUp({
                ...selected,
                emailAssignee: true
            });
            setHasEdit(hasManager || (!hasManager && selected.assignedUserId == user.nameid && !selected.assignerId));
        }else{
            initValue.assignedUserId = user.nameid;
            setFollowUp(initValue);
            setHasEdit(true);
        }
    }, [selected, open])

    return (
        <>
            <Modal open={open}>
                <div>
                    <Formik
                        initialValues={followUp}
                        validationSchema={hasManager ? followUpSchemaManager : followUpSchema}
                        enableReinitialize
                        onSubmit={values => {
                            setIsProgress(true);
                            // if (oldDate && moment(oldDate).format('MM/DD/YYYY') !== moment(values.followUpDate).format('MM/DD/YYYY')) {
                            //     values.dateCompleted = null
                            // }
                            const data = {...values, assignedUserId: !hasManager ? user.nameid : values.assignedUserId, dateDue: moment(values.dateDue).format('YYYY-MM-DDTHH:mm:ss')}
                            if (values.id) {
                                axios.put(apiConfig.url.BASE_URL + apiConfig.url.EMPLOYEE_UPDATE_TASK, data)
                                    .then(res => {
                                        setIsStatus({failed: false, msg: 'Save successfully!'});
                                        setOpenSnackBar(true);
                                        onCloseModal();
                                        onload()
                                        dispatch({ type: 'RELOAD_EMPLOYEES_TASK_COUNTER', reloadTaskSideBar: true });
                                    })
                                    .catch(err => {
                                        setIsStatus({failed: true, msg: 'An error occurred, please try again later.'});
                                        setOpenSnackBar(true);
                                    })
                                    .finally(e => setIsProgress(false));
                            } else {
                                axios.post(apiConfig.url.BASE_URL + apiConfig.url.EMPLOYEE_ADD_TASK, data)
                                    .then(res => {
                                        setIsStatus({failed: false, msg: 'Save successfully!'});
                                        setOpenSnackBar(true);
                                        onCloseModal();
                                        onload();
                                        dispatch({ type: 'RELOAD_EMPLOYEES_TASK_COUNTER', reloadTaskSideBar: true });

                                    })
                                    .catch(err => {

                                        setIsStatus({failed: true, msg: 'An error occurred, please try again later.'});
                                        setOpenSnackBar(true);
                                    })
                                    .finally(e => setIsProgress(false));
                            }

                        }}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              submitCount,
                              isValid,
                              setFieldValue,
                              setFieldTouched,
                              handleSubmit,
                              resetForm,
                              submitForm
                          }) => (
                            <form onSubmit={handleSubmit}>
                                <FormErrorCallback
                                    submitCount={submitCount}
                                    isValid={isValid}
                                    onSubmissionError={() => {
                                        setIsStatus({failed: true, msg: errorMessages.FIELD_CHECK});
                                        setOpenSnackBar(true);
                                    }}
                                />
                                <Card className={classes.styleModal}>
                                    <CardHeader title={values.id ? 'Edit task' : 'Create task'}/>
                                    <Divider/>
                                    <CardContent>
                                        <Grid container spacing={4}>
                                            {hasManager &&
                                            <Grid item xs={12}>
                                                {usersData && usersData.length > 0 &&
                                                <TextField
                                                    disabled={!hasEdit || values.dateCompleted}
                                                    error={errors.assignedUserId && touched.assignedUserId}
                                                    helperText={(errors.assignedUserId && touched.assignedUserId) && errors.assignedUserId}
                                                    style={{width: '100%'}}
                                                    className={classes.statusSelect}
                                                    // size={"small"}
                                                    name="assignedUserId"
                                                    SelectProps={{native: true}}
                                                    variant="outlined"
                                                    select
                                                    label="Assignee"
                                                    //disabled={!currentDate}
                                                    value={values.assignedUserId || ' '}
                                                    onChange={(event) => {
                                                        setFieldValue('assignedUserId', event.target.value.trim())
                                                    }}
                                                >
                                                    {
                                                        usersData.map((st) => (
                                                            <option
                                                                key={st.id}
                                                                value={st.id}
                                                            >
                                                                {`${st.fullName}`}
                                                            </option>
                                                        ))}
                                                </TextField>
                                                }
                                            </Grid>
                                            }

                                            {!selected?.id &&
                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        name="emailAssignee"
                                                        control={<GreenCheckbox />}
                                                        label="Email Assignee"
                                                        checked={values.emailAssignee ?? true}
                                                        onChange={e => setFieldValue('emailAssignee', e.target.checked)}
                                                    />
                                                </Grid>
                                            }

                                            <Grid item xs={12}>
                                                <TextField
                                                    disabled={!hasEdit || values.dateCompleted}
                                                    fullWidth
                                                    multiline
                                                    rows={3}
                                                    variant='outlined'
                                                    label='Task'
                                                    name='description'
                                                    value={values.description || ''}
                                                    error={errors.description && touched.description}
                                                    helperText={(errors.description && touched.description) && errors.description}
                                                    onBlur={() => setFieldTouched('description')}
                                                    onChange={event => {
                                                        setFieldValue('description', event.target.value)
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                {taskUrgencies && taskUrgencies.length > 0 &&
                                                <TextField
                                                    disabled={!hasEdit || values.dateCompleted}
                                                    error={errors.taskUrgencyId && touched.taskUrgencyId}
                                                    helperText={(errors.taskUrgencyId && touched.taskUrgencyId) && errors.taskUrgencyId}
                                                    style={{width: '100%'}}
                                                    className={classes.statusSelect}
                                                    // size={"small"}
                                                    name="status"
                                                    SelectProps={{native: true}}
                                                    variant="outlined"
                                                    select
                                                    label="Urgency"
                                                    //disabled={!currentDate}
                                                    value={values.taskUrgencyId || ' '}
                                                    onChange={(event) => {
                                                        // setSelectedAddress(event.target.value.trim());
                                                        setFieldValue('taskUrgencyId', event.target.value.trim())
                                                        // if(!event.target.value.trim()){
                                                        //     setFieldValue('jobId', '')
                                                        // }
                                                    }}
                                                >
                                                    <option value=' '></option>
                                                    {
                                                        taskUrgencies.map((st) => (
                                                            <option
                                                                key={st.id}
                                                                value={st.id}
                                                            >
                                                                {`${st.description}`}
                                                            </option>
                                                        ))}
                                                </TextField>
                                                }
                                            </Grid>
                                            <Grid item xs={12}>
                                                <KeyboardDateTimePicker
                                                    fullWidth
                                                    error={(errors.dateDue && touched.dateDue) || !dueDateValid}
                                                    helperText={((errors.dateDue && touched.dateDue) && errors.dateDue) || (!dueDateValid && 'Invalid format date')}
                                                    label='Due Date'
                                                    disabled={!hasEdit || values.dateCompleted}
                                                    inputVariant="outlined"
                                                    format={'MM/DD/YYYY hh:mm A'}
                                                    value={values.dateDue || null}
                                                    onChange={(date, value) => {
                                                        setDueDateValid(moment(value?.replace(/_/g, ' ').replace(/(A|P)$/, '$1M')).isValid())
                                                        setFieldValue('dateDue', date)
                                                    }}
                                                    onBlur={() => setFieldTouched('dateDue')}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <Divider/>
                                    <CardActions className={classes.conButton}>
                                        <div style={{float: 'left'}}>
                                            {
                                                values.id &&
                                                    <>
                                                        <Button
                                                            Alignment={'left'}
                                                            className={classes.buttonSave}
                                                            variant="contained"
                                                            color="primary"
                                                            disabled={!hasEdit || values.dateCompleted}
                                                            size="large"
                                                            onClick={() => onMarkComplete(values)}>
                                                            complete
                                                        </Button>
                                                        {isProgressComplete && <CircularProgress size={24} />}
                                                    </>
                                            }
                                        </div>
                                        <div style={{display: 'flex'}}>
                                            <Button
                                                variant="contained"
                                                size="large"
                                                onClick={() => {
                                                    onCloseModal()
                                                }}>
                                                Close
                                            </Button>
                                            <Button
                                                style={{marginLeft: '6px'}}
                                                disabled={isProgress || !hasEdit || values.dateCompleted}
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                onClick={() => {
                                                    submitForm().then(r => {
                                                    })
                                                }}
                                                className={classes.buttonSave}>
                                                Save
                                            </Button>
                                            {isProgress && <CircularProgress size={24}/>}
                                        </div>
                                    </CardActions>
                                </Card>
                            </form>
                        )}
                    </Formik>
                </div>
            </Modal>
            <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                      open={openSnackbar}
                      autoHideDuration={3000}
                      onClose={() => setOpenSnackBar(false)}>
                <Alert elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    )
}
EmployeesTaskModal.propTypes = {
    onCloseModal: PropTypes.func.isRequired,
    onLoad: PropTypes.func.isRequired,
}
EmployeesTaskModal.defaultProps = {
    onCloseModal: () => {
    },
    onLoad: () => {
    },
}
export default EmployeesTaskModal;
