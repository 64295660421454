import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
    Modal, Card, CardHeader, CardContent, Divider,
    Grid, Typography, FormControlLabel, Button,
    colors, CardActions, TextField, Chip, CircularProgress
} from '@material-ui/core'
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { GreenCheckbox, KeyboardDatePicker } from 'components'
import moment from 'moment'
import apiConfig from 'apiConfig'
import axios from 'utils/axios'
import { useDispatch, useSelector } from 'react-redux';
import RecurringRouteModal from './RecurringRouteModal';

const useStyles = makeStyles(theme => ({
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 700,
        maxHeight: '90%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: 500
        },
    },
    exclude: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'center',
        flexBasis: 450,
        margin: 'auto'
    },
    day: {
        flexBasis: 100,
        margin: `0 0 ${theme.spacing(1)}px 0`,
        '& span': {
            padding: 0
        }
    },
    month: {
        marginLeft: 0,
        width: '100px',
        margin: '0 20px',
        '& span': {
            padding: 0
        }
    },
    note: {
        textAlign: 'left',
        paddingRight: '20px',
        fontSize: '12px'
    },
    select: {
        padding: theme.spacing(1),
        boxShadow: '0 0px 12px 0px #cacaca',
        position: 'absolute',
        top: '50%',
        left: '5%',
        zIndex: 2,
        transform: 'translateY(-50%)',
        width: '90%',
        borderRadius: '4px',
        backgroundColor: '#ffffff',
        visibility: 'hidden',
        opacity: '0',
        transition: 'all .3s'
    },
    selectInput: {
        marginBottom: theme.spacing(1),
        position: 'sticky',
        top: 0,
        backgroundColor: '#ffffff'
    },
    clearIcon: {
        position: 'absolute',
        top: '3px',
        right: 0,
    },
    options: {
        maxHeight: '200px',
        overflowY: 'auto',
    },
    option: {
        padding: '8px',
        fontSize: theme.spacing(2),
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#e0e0e0'
        }
    },
    button: {
        minWidth: '170px',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
    chipItem: {
        borderRadius: '6px',
        margin: '3px',
        padding: '0px',
        height: '28px'
    },
    actionsBox: {
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    groupField: {
        display: 'flex',
        alignItems: 'center',
        '& div': {
            width: '130px',
            marginRight: '15px'
        }
    },
    typo: {
        marginRight: '10px'
    }
}));

const typeRecur = [
    { id: '1', name: 'Weekly' },
    { id: '2', name: 'Monthly' },
    { id: '3', name: 'Yearly' },
];

const months = [
    [
        { id: '1', name: 'January' },
        { id: '2', name: 'February' },
        { id: '3', name: 'March' },
    ], [
        { id: '4', name: 'April' },
        { id: '5', name: 'May' },
        { id: '6', name: 'June' }],
    [
        { id: '7', name: 'July' },
        { id: '8', name: 'August' },
        { id: '9', name: 'September' },
    ],
    [
        { id: '10', name: 'October' },
        { id: '11', name: 'November' },
        { id: '12', name: 'December' },
    ]
]

const dayMonth = [
    { value: 0, name: 'sunday' },
    { value: 1, name: 'monday' },
    { value: 2, name: 'tuesday' },
    { value: 3, name: 'wednesday' },
    { value: 4, name: 'thursday' },
    { value: 5, name: 'friday' },
    { value: 6, name: 'saturday' },

    { value: 1, name: 'january' },
    { value: 2, name: 'february' },
    { value: 3, name: 'march' },
    { value: 4, name: 'april' },
    { value: 5, name: 'may' },
    { value: 6, name: 'june' },
    { value: 7, name: 'july' },
    { value: 8, name: 'august' },
    { value: 9, name: 'september' },
    { value: 10, name: 'october' },
    { value: 11, name: 'november' },
    { value: 12, name: 'december' },
]

const RecurModal = ({ router, open, onClose }) => {

    const classes = useStyles();
    const cleanersRef = useRef();
    const equipmentsRef = useRef();

    const dispatch = useDispatch();
	const { reloadRoutes } = useSelector(state => state.RoutesReducer);

    const [loading, setLoading] = useState(false);
    const [excludeDays, setExcludeDays] = useState([]);
    const [excludeMonths, setExcludeMonths] = useState([]);
    const [openCleaners, setOpenCleaners] = useState(false);
    const [openEquipments, setOpenEquipments] = useState(false);
    const [errorItem, setErrorItem] = useState({});
    const [isRecuring, setIsRecuring] = useState(false);
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });
    const [typeId, setTypeId] = useState('1');
    const [every, setEvery] = useState('1');
    const [endRecur, setEndRecur] = useState();
    const [recurring, setRecurring] = useState(false);
    const [openRecurringRouteModal, setOpenRecurringRouteModal] = useState(false);

    const onChangeExcludeDays = val => {
        const dayIndex = excludeDays.findIndex(day => day === val);
        const days = [...excludeDays];
        dayIndex > -1 ? days.splice(dayIndex, 1) : days.push(val);
        setExcludeDays(days)
    }
    const onChangeExcludeMonths = val => {
        const monthIndex = excludeMonths.findIndex(day => day === val);
        const months = [...excludeMonths];
        monthIndex > -1 ? months.splice(monthIndex, 1) : months.push(val);
        setExcludeMonths(months)
    }

    const handletypeChange = (event) => {
        const { name, value } = event.target;
        setTypeId(value);
    }

    const handleRecurRoute = () => {
        if ((router && router.recurringRouteParentId) || recurring) {
            handleEndRecur()
        } else {
            cloneModal()
        }
    }
    const cloneModal = () => {
        if (typeId == 1) {
            if (!excludeDays || excludeDays.length == 0) {
                setIsStatus({ failed: true, msg: 'Please select recur days.' });
                setOpenSnackBar(true)
                return;
            }
            if (!every || every == 0) {
                setErrorItem({ ...errorItem, every: 'Required.' });
                return;
            } else {
                setErrorItem({ ...errorItem, every: '' });
            }
        }
        if (typeId == 2) {
            if (!excludeMonths || excludeMonths.length == 0) {
                setIsStatus({ failed: true, msg: 'Please select recur months.' });
                setOpenSnackBar(true)
                return;
            }
        }

        const data = {
            routeId: router.id,
            frequency: typeId,
            days: typeId == 1 ? [...excludeDays] : '',
            everyWeeks: every,
            months: typeId == 2 ? [...excludeMonths] : '',
        }
        setIsRecuring(true);
        axios.post(apiConfig.url.BASE_URL + apiConfig.url.RECUR_CLONE.replace('{routeId}', router.id), data)
            .then((res) => {
                if (res?.data) {
                    const newRoute = { ...router, recurringRouteParentId: res.data.lastRecurredRoute?.recurringRouteParentId };
                    dispatch({ type: "UPDATE_ROUTE", routeId: router.id, route: newRoute });
                    setIsStatus({ failed: false, msg: 'Recur route successfully.' });
                    setOpenSnackBar(true)
                    onCloseModal(true);
                } else {
                    setIsStatus({ failed: true, msg: 'Recur route failed, please try again later.' });
                    setOpenSnackBar(true)
                }
            }).catch(() => {
                setIsStatus({ failed: true, msg: 'Recur route failed, please try again later.' });
                setOpenSnackBar(true)
            }).finally(() => {
                setIsRecuring(false);
            })
    }
    const handleEndRecur = endDate => {
        if (endDate === undefined) endDate = endRecur;
        if (!endDate) {
            setIsStatus({ failed: true, msg: 'Please select end recur day.' });
            setOpenSnackBar(true)
            return;
        }
        if (moment(endDate).format('MM/DD/YYYY') < moment(new Date()).format('MM/DD/YYYY')) {
            setIsStatus({ failed: true, msg: 'Cannot end recur with date before today.' });
            setOpenSnackBar(true)
            return;
        }
        setIsRecuring(true)
        const reqBody = { routeId: router.recurringRouteParentId ?? router.id, endDate: moment(endDate).format('MM/DD/YYYY') }
        axios.post(apiConfig.url.BASE_URL + apiConfig.url.END_RECUR_ROUTE, reqBody)
            .then((res) => {
                dispatch({ type: 'RELOAD_ROUTES', reloadRoutes: !reloadRoutes })
                setIsStatus({ failed: false, msg: 'End recur route successfully.' });
                setOpenSnackBar(true)
                setOpenRecurringRouteModal(false)
                onCloseModal(moment(endDate).format('MM/DD/YYYY') === moment(new Date()).format('MM/DD/YYYY'))
            }).catch(() => {
                setIsStatus({ failed: true, msg: 'End recur route failed, please try again later.' });
                setOpenSnackBar(true)
            }).finally(() => {
                setIsRecuring(false);
            })
    }

    const onCloseModal = (result = false) => {
        setExcludeDays([]);
        setExcludeMonths([]);
        setErrorItem({});
        setRecurring(false);
        setEndRecur();
        onClose(result);
    }

    const onChangeEvery = (event) => {
        setEvery(event.target.value);
    }

    useEffect(() => {
        const clickOutsideSelect = (event) => {
            if (openCleaners && cleanersRef.current && !cleanersRef.current.contains(event.target)) {
                setOpenCleaners(false);
            }
            if (openEquipments && equipmentsRef.current && !equipmentsRef.current.contains(event.target)) {
                setOpenEquipments(false);
            }
        }
        document.addEventListener('click', clickOutsideSelect);
        return () => document.removeEventListener('click', clickOutsideSelect)
    }, [openCleaners, openEquipments])

    useEffect(() => {
        if (open) {
            setLoading(true)
            axios.get(apiConfig.url.BASE_URL + apiConfig.url.RECUR_ROUTE.replace('{routeId}', router.recurringRouteParentId ?? router.id))
                .then(res => {
                    const lastRecur = res.data[0];
                    if (lastRecur) {
                        setRecurring(true)
                        setTypeId(lastRecur.recurTypeId);
                        setEvery(lastRecur.frequencyEvery);
                        const exdays = [];
                        const exmonths = [];
                        for (let i = 0; i < dayMonth.length; i++) {
                            if (lastRecur[dayMonth[i].name]) {
                                i < 7 ? exdays.push(dayMonth[i].value) : exmonths.push(dayMonth[i].value)
                            }
                        }
                        exdays.length > 0 && setExcludeDays(excludeDays.concat(exdays));
                        exmonths.length > 0 && setExcludeMonths(excludeMonths.concat(exmonths))
                    }
                })
                .finally(() => setLoading(false))
        }
    }, [open])

    return (
        <div>
            <Modal open={open} onClose={onCloseModal}>
                <Card className={classes.styleModal}>
                    <CardHeader title={ (router && router.recurringRouteParentId) || recurring ? 'Edit Recurring Route' : 'Recur Route'} />
                    <Divider />
                    {loading ? (
                        <CardContent style={{ textAlign: 'center' }}>
                            <CircularProgress size={24} />
                        </CardContent>
                    ) : (
                        <>
                            <CardContent>
                                <Grid container spacing={2} style={{ alignItems: 'flex-start' }}>
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            fullWidth
                                            disabled={router && router.recurringRouteParentId || recurring}
                                            label="Frequency type"
                                            name="typeId"
                                            select
                                            SelectProps={{ native: true }}
                                            variant="outlined"
                                            value={typeId || ''}
                                            onChange={e => handletypeChange(e)}>
                                            {typeRecur.map(pt => (
                                                <option key={pt.id} value={pt.id}>
                                                    {pt.name}
                                                </option>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    {
                                        typeId == '1' &&
                                        <>
                                            <Grid item xs={12}>
                                                <Typography style={{ marginRight: '5px', whiteSpace: 'nowrap' }}>Recur Routes For
                                                    :</Typography>
                                            </Grid>
                                            <Grid item xs={12} className={classes.exclude}>
                                                <FormControlLabel
                                                    disabled={router && router.recurringRouteParentId || recurring}
                                                    className={classes.day}
                                                    checked={excludeDays.findIndex(day => day === 0) > -1}
                                                    onChange={() => onChangeExcludeDays(0)}
                                                    control={
                                                        <GreenCheckbox />
                                                    }
                                                    label="Sunday"
                                                />
                                                <FormControlLabel
                                                    disabled={router && router.recurringRouteParentId || recurring}
                                                    className={classes.day}
                                                    checked={excludeDays.findIndex(day => day === 1) > -1}
                                                    onChange={() => onChangeExcludeDays(1)}
                                                    control={
                                                        <GreenCheckbox />
                                                    }
                                                    label="Monday"
                                                />
                                                <FormControlLabel
                                                    disabled={router && router.recurringRouteParentId || recurring}
                                                    className={classes.day}
                                                    checked={excludeDays.findIndex(day => day === 2) > -1}
                                                    onChange={() => onChangeExcludeDays(2)}
                                                    control={
                                                        <GreenCheckbox />
                                                    }
                                                    label="Tuesday"
                                                />
                                                <FormControlLabel
                                                    disabled={router && router.recurringRouteParentId || recurring}
                                                    className={classes.day}
                                                    checked={excludeDays.findIndex(day => day === 3) > -1}
                                                    onChange={() => onChangeExcludeDays(3)}
                                                    control={
                                                        <GreenCheckbox />
                                                    }
                                                    label="Wednesday"
                                                />
                                                <FormControlLabel
                                                    disabled={router && router.recurringRouteParentId || recurring}
                                                    className={classes.day}
                                                    checked={excludeDays.findIndex(day => day === 4) > -1}
                                                    onChange={() => onChangeExcludeDays(4)}
                                                    control={
                                                        <GreenCheckbox />
                                                    }
                                                    label="Thursday"
                                                />
                                                <FormControlLabel
                                                    disabled={router && router.recurringRouteParentId || recurring}
                                                    className={classes.day}
                                                    checked={excludeDays.findIndex(day => day === 5) > -1}
                                                    onChange={() => onChangeExcludeDays(5)}
                                                    control={
                                                        <GreenCheckbox />
                                                    }
                                                    label="Friday"
                                                />
                                                <FormControlLabel
                                                    disabled={router && router.recurringRouteParentId || recurring}
                                                    className={classes.day}
                                                    checked={excludeDays.findIndex(day => day === 6) > -1}
                                                    onChange={() => onChangeExcludeDays(6)}
                                                    control={
                                                        <GreenCheckbox />
                                                    }
                                                    label="Saturday"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div className={classes.groupField}>
                                                    <Typography style={{ marginTop: errorItem && errorItem.every ? '-13px' : '' }} className={classes.typo}>Every: </Typography>
                                                    <TextField
                                                        disabled={router && router.recurringRouteParentId || recurring}
                                                        error={errorItem && !!errorItem.every}
                                                        helperText={errorItem && errorItem.every && errorItem.every}
                                                        size={'small'}
                                                        variant={'outlined'}
                                                        type="number"
                                                        value={every || ''}
                                                        onBlur={(e) => {
                                                            setErrorItem({ ...errorItem, every: e.target.value ? '' : 'Required.' });
                                                        }}
                                                        InputProps={{
                                                            inputProps: {
                                                                max: 100, min: 0,
                                                                step: '1'
                                                            }
                                                        }}
                                                        onChange={onChangeEvery}
                                                    />
                                                    <Typography style={{ marginTop: errorItem && errorItem.every ? '-13px' : '' }} className={classes.typo}>Weeks</Typography>
                                                </div>
                                            </Grid>
                                        </>
                                    }
                                    {
                                        typeId == '2' &&
                                        <>
                                            <Grid item xs={12}>
                                                <Typography style={{ marginRight: '5px', whiteSpace: 'nowrap' }}>Recur Routes For :</Typography>
                                            </Grid>
                                            {
                                                (() => {
                                                    let res = [];
                                                    months.forEach((item, index) => {
                                                        res.push(
                                                            <Grid key={index} item xs={12} className={classes.exclude}>
                                                                <div className={classes.days}>
                                                                    <FormControlLabel
                                                                        disabled={router && router.recurringRouteParentId || recurring}
                                                                        className={classes.month}
                                                                        checked={excludeMonths.findIndex(day => day === index * 3 + 1) > -1}
                                                                        onChange={() => onChangeExcludeMonths(index * 3 + 1)}
                                                                        control={
                                                                            <GreenCheckbox />
                                                                        }
                                                                        label={item[0].name}
                                                                    />
                                                                    <FormControlLabel
                                                                        disabled={router && router.recurringRouteParentId || recurring}
                                                                        className={classes.month}
                                                                        checked={excludeMonths.findIndex(day => day === index * 3 + 2) > -1}
                                                                        onChange={() => onChangeExcludeMonths(index * 3 + 2)}
                                                                        control={
                                                                            <GreenCheckbox />
                                                                        }
                                                                        label={item[1].name}
                                                                    />
                                                                    <FormControlLabel
                                                                        disabled={router && router.recurringRouteParentId || recurring}
                                                                        className={classes.month}
                                                                        checked={excludeMonths.findIndex(day => day === index * 3 + 3) > -1}
                                                                        onChange={() => onChangeExcludeMonths(index * 3 + 3)}
                                                                        control={
                                                                            <GreenCheckbox />
                                                                        }
                                                                        label={item[2].name}
                                                                    />
                                                                </div>
                                                            </Grid>
                                                        )
                                                    })

                                                    return res
                                                })()
                                            }
                                        </>
                                    }
                                    {(router && router.recurringRouteParentId || recurring) && (
                                        <>
                                            <Grid item xs={6}>
                                                <KeyboardDatePicker
                                                    style={{ width: 250 }}
                                                    label='End Recur Date'
                                                    name='scheduleDate'
                                                    minDate={new Date()}
                                                    value={endRecur || null}
                                                    onChange={date => setEndRecur(date)}
                                                />
                                            </Grid>
                                            <Grid item xs={6} style={{ alignSelf: 'end', textAlign: 'right' }}>
                                                <Button
                                                    className={classes.button}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => setOpenRecurringRouteModal(true)}>
                                                    Display Recurring Route
                                                </Button>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </CardContent>
                            <Divider />
                            <CardActions>
                                <Grid container spacing={2}>
                                    <Grid item xs={(router && router.recurringRouteParentId) || recurring ? 5 : 7}>
                                    </Grid>
                                    <Grid item xs={(router && router.recurringRouteParentId) || recurring ? 7 : 5} className={classes.actionsBox}>
                                        <CardActions>
                                            <div className={classes.actions}>
                                                <Button
                                                    onClick={onCloseModal}
                                                    variant="contained">
                                                    Close
                                                </Button>
                                                <Button disabled={isRecuring || (router && router.recurringRouteParentId && !endRecur)} style={{ marginLeft: 10 }}
                                                    className={classes.button}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleRecurRoute}>
                                                    {(router && router.recurringRouteParentId) || recurring ? 'End Recurring Route' : 'Recur Route'}
                                                </Button>
                                            </div>
                                            {isRecuring && <CircularProgress size={24} style={{ marginTop: 10 }} />}
                                        </CardActions>
                                    </Grid>
                                </Grid>
                            </CardActions>
                        </>
                    )}
                </Card>
            </Modal>

            {open &&
                <RecurringRouteModal
                    recurringRouteParentId={router.recurringRouteParentId}
                    open={openRecurringRouteModal}
                    onClose={() => setOpenRecurringRouteModal(false)}
                    onEndRecur={handleEndRecur}
                />
            }

            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnackbar}
                autoHideDuration={5000}
                onClose={() => setOpenSnackBar(false)}>
                <Alert elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </div>
    )
}

RecurModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
}

export default RecurModal;
