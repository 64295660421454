import React, {useRef, useState} from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useDrop } from 'react-dnd'
import { makeStyles } from '@material-ui/styles'
import { useSelector, useDispatch } from 'react-redux'
import apiConfig from 'apiConfig'
import axios from 'utils/axios'
import { formatTwoPDecimal } from "utils/formatNumber";
import moment from "moment";

const useStyles = makeStyles(theme => ({
    jobBox: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        minWidth: 50,
        minHeight: 55,
        position: 'relative',
        borderRight: '1px solid #c7c7c7',
        '&:hover': {
            backgroundColor: '#c7c7c7'
        }
    },
    dropover: {
        position: 'absolute',
        height: 35,
        borderRadius: 20
    }
}))

const DropBox = props => {
    const classes = useStyles()
    const { date } = useParams();

    const { routeId, startTime, error, canDrag, onClick, recurJob, showJobNotes, lineCount } = props;

    const dispatch = useDispatch();

    const { routes } = useSelector(state => state.RoutesReducer);
    const { routeJobs } = useSelector(state => state.JobsReducer);

    const [hourOver, setHourOver] = useState();
    const [dropOverOffsetTop, setDropOverOffsetTop] = useState(0);

    const containerRef = useRef();
    const canNotDrag = useRef(false);

    const [{ isOver }, drop] = useDrop({
        accept: 'Job',
        drop: item => handleDropItem(item, routeId, startTime),
        hover: (item, monitor) => {
            onCheckHover(item);
            const offset = monitor.getClientOffset();
            if (offset && containerRef.current) {
                const rect = containerRef.current.getBoundingClientRect();
                setDropOverOffsetTop(parseInt((offset.y - rect.top) / 55) * 55 + 10);
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver()
        })
    });

    const onCheckHover = (item) => {
        setHourOver(item.hours);
    }

    const handleDropItem = (item, routeId, startTime) => {
        const route = routes.find(route => route.id === routeId);
        if (route.recurringRouteParentId && item.oldRouteId !== routeId) {
            let job = {};
            if (item.oldRouteId) {
                const oldRouter = routes.find(route => route.id === item.oldRouteId);
                job = oldRouter.routeJobs.find(job => job.jobSplitId === item.id).job;
            } else {
                job = routeJobs.find(job => job.jobSplitId === item.id);
            }
            recurJob(recur => updateRoute(item, routeId, startTime, recur), job.isPrimaryJob);
        } else {
            updateRoute(item, routeId, startTime);
        }
    }

    const updateRoute = ({ id, oldRouteId }, routeId, startTime, recur = false) => {
        dispatch({ type: 'CHANGE_STATUS_JOB', status: false });
        const jobStartConverting = moment(date);
        jobStartConverting.set(
            {
                hour: Math.floor(startTime),
                minute: startTime - Math.floor(startTime) === 0.5 ? 30 : 0,
                second: 0,
                millisecond: 0
            }
        );
        const jobStart = moment.utc(jobStartConverting).local().toISOString();
        const jobEndConverting = moment(date);

        if (oldRouteId === undefined) {
            const route = routes.find(route => route.id === routeId);
            const rj = routeJobs.find(job => job.jobSplitId === id)
            const job = {
                ...rj,
                job: {
                    estimatedHours: rj.estimatedHours,
                    price: rj.price,
                    jobAddressId: rj.jobAddressId,
                    address1: rj.address1 || '',
                    city: rj.city || '',
                    state: rj.state || '',
                    zipCode: rj.zipCode || '',
                    latitude: rj.latitude,
                    longitude: rj.longitude,
                    jobId: rj.jobId,
                    jobSplitId: rj.jobSplitId,
                    customerId: rj.customerId,
                    customerName: rj.customerName,
                    equipment: rj.equipment,
                    description: rj.description,
                    totalSplitJobs: rj.totalSplitJobs,
                    showEquipmentOnSchedule: rj.showEquipmentOnSchedule,
                    showJobNoteOnSchedule: rj.showJobNoteOnSchedule,
                    showJobsiteNoteOnSchedule: rj.showJobsiteNoteOnSchedule
                }
            };

            job.estimatedHours = formatTwoPDecimal(job.estimatedHours / (route.routeEmployees.length === 0 ? 1 : route.routeEmployees.length));
            const hour = startTime + (job.estimatedHours);
            jobEndConverting.set(
                {
                    hour: Math.floor(hour),
                    minute: Math.round((hour - Math.floor(hour)) * 60),
                    second: 0,
                    millisecond: 0
                }
            );
            const jobEnd = moment.utc(jobEndConverting).local().toISOString();

            job.jobStart = jobStart;
            job.jobEnd = jobEnd;

            const newRouteData = {
                ...route,
                routeEquipment: !route.routeEquipment ? [] : [...route.routeEquipment],
                routeEmployees: !route.routeEmployees ? [] : [...route.routeEmployees],
                routeJobs: route.routeJobs ? [...route.routeJobs, job] : [job],
            }

            const {
                equipment, showEquipmentOnSchedule,
                jobNote, showJobNoteOnSchedule,
                jobSiteNote, showJobsiteNoteOnSchedule
            } = rj;
            let jobNotes = '';
            if (showEquipmentOnSchedule) jobNotes += `Equipment Needed: ${equipment.replace(/;/g, ', ')}`;
            if (showJobNoteOnSchedule) jobNotes += `<br><br>Permanent Note: ${jobNote}`;
            if (showJobsiteNoteOnSchedule) jobNotes += `<br><br>Today Only Note: ${jobSiteNote}`;
            if (jobNotes !== '') showJobNotes(jobNotes);

            dispatch({ type: 'IS_UPDATE', status: true });
            let requestForRoute;
            if (recur) {
                const recurJobData = { jobSplitId: id, routeId, jobStart, jobEnd };
                requestForRoute = axios.post(apiConfig.url.BASE_URL + apiConfig.url.RECUR_JOB, recurJobData);
            } else {
                requestForRoute = axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + routeId, newRouteData);
            }
            Promise.all([requestForRoute])
                .then(values => {
                    values[0].data.routeJobs.forEach(rj => {
                        rj.jobStart = moment.utc(rj.jobStart).toISOString();
                        rj.jobEnd = moment.utc(rj.jobEnd).toISOString();
                    });
                    dispatch({ type: 'UPDATE_JOB_ROUTE', routeId: routeId, routeJobs: values[0].data.routeJobs });
                    dispatch({ type: 'REMOVE_JOB', jobSplitId: id });
                    dispatch({ type: 'CHANGE_JOB_STAGE', jobSplitId: id, jobStage: 3 });
                    const newJob = values[0].data.routeJobs.find(rj => rj.jobSplitId == id);
                    if (job.job.latitude != newJob?.job.latitude || job.job.longitude != newJob?.job.longitude) {
                        setTimeout(() => {
                            const newRouteJobs = [...routeJobs.filter(rj => rj.jobSplitId !== id)];
                            newRouteJobs.forEach(rj => {
                                if (rj.jobAddressId === job.job.jobAddressId) {
                                    rj.latitude = newJob?.job.latitude;
                                    rj.longitude = newJob?.job.longitude;
                                }
                            });
                            dispatch({ type: 'INIT_JOB', routeJobs: newRouteJobs });
                        }, 100);
                    }
                    error({ failed: false, msg: 'Update route successfuly.' })
                })
                .catch((e) => {
                    error({ failed: true, msg: 'Update route failed, please try again later.' })
                })
                .finally(() => dispatch({ type: 'IS_UPDATE', status: false }))
        } else if (oldRouteId !== routeId) {
            const route = routes.find(route => route.id === oldRouteId);
            const newRoute = routes.find(route => route.id === routeId);
            const job = { ...route.routeJobs.find(job => job.jobSplitId === id) };

            job.estimatedHours = formatTwoPDecimal(job.job.estimatedHours / (newRoute.routeEmployees.length === 0 ? 1 : newRoute.routeEmployees.length));
            const hour = startTime + (job.estimatedHours);
            jobEndConverting.set(
                {
                    hour: Math.floor(hour),
                    minute: Math.round((hour - Math.floor(hour)) * 60),
                    second: 0,
                    millisecond: 0
                }
            );
            const jobEnd = moment.utc(jobEndConverting).local().toISOString();

            job.jobStart = jobStart;
            job.jobEnd = jobEnd;
            delete job.id;

            const oldRoute = routes.find(route => route.id === oldRouteId);
            const oldRouteData = {
                ...oldRoute,
                routeEquipment: !route.routeEquipment ? [] : [...route.routeEquipment],
                routeEmployees: !route.routeEmployees ? [] : [...route.routeEmployees],
                routeJobs: oldRoute.routeJobs.filter(job => job.jobSplitId !== id)
            };
            const newRouteData = {
                ...newRoute,
                routeEquipment: !newRoute.routeEquipment ? [] : [...newRoute.routeEquipment],
                routeEmployees: !newRoute.routeEmployees ? [] : [...newRoute.routeEmployees],
                routeJobs: newRoute.routeJobs ? [...newRoute.routeJobs, job] : [job]
            };

            const {
                equipment, showEquipmentOnSchedule,
                jobNote, showJobNoteOnSchedule,
                jobSiteNote, showJobsiteNoteOnSchedule
            } = job.job;
            let jobNotes = '';
            if (showEquipmentOnSchedule) jobNotes += `Equipment Needed: ${equipment.replace(/;/g, ', ')}`;
            if (showJobNoteOnSchedule) jobNotes += `<br><br>Permanent Note: ${jobNote}`;
            if (showJobsiteNoteOnSchedule) jobNotes += `<br><br>Today Only Note: ${jobSiteNote}`;
            if (jobNotes !== '') showJobNotes(jobNotes);

            dispatch({ type: 'IS_UPDATE', status: true });
            let requestForRoute;
            if (recur) {
                const recurJobData = { jobSplitId: id, routeId, jobStart, jobEnd };
                requestForRoute = axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + oldRouteId, oldRouteData)
                    .then(res => axios.post(apiConfig.url.BASE_URL + apiConfig.url.RECUR_JOB, recurJobData))
            } else {
                requestForRoute = axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + oldRouteId, oldRouteData)
                    .then(res => axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + routeId, newRouteData))
            }
            requestForRoute
                .then(res => {
                    dispatch({ type: 'CHANGE_JOB_ROUTE', oldRouteId, routeId, job })
                    error({ failed: false, msg: 'Update route successfuly..' })
                }).catch(() => error({ failed: true, msg: 'Update route failed, please try again later.' }))
                .finally(() => dispatch({ type: 'IS_UPDATE', status: false }))
        } else if (oldRouteId === routeId) {
            const route = routes.find(route => route.id === routeId);

            const job = { ...route.routeJobs.find(job => job.jobSplitId === id) };

            job.estimatedHours = formatTwoPDecimal(job.job.estimatedHours / (route.routeEmployees.length === 0 ? 1 : route.routeEmployees.length));
            const hour = startTime + (job.estimatedHours);
            jobEndConverting.set(
                {
                    hour: Math.floor(hour),
                    minute: Math.round((hour - Math.floor(hour)) * 60),
                    second: 0,
                    millisecond: 0
                }
            );
            const jobEnd = moment.utc(jobEndConverting).local().toISOString();

            job.jobStart = jobStart;
            job.jobEnd = jobEnd;

            const routeJob = route.routeJobs.filter(job => job.jobSplitId !== id)
            const newRouteData = {
                ...route,
                routeEquipment: !route.routeEquipment ? [] : [...route.routeEquipment],
                routeEmployees: !route.routeEmployees ? [] : [...route.routeEmployees],
                routeJobs: route.routeJobs ? [...routeJob, job] : [job]
            };
            const requestForRoute = axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + routeId, newRouteData);
            dispatch({ type: 'IS_UPDATE', status: true });
            Promise.all([requestForRoute])
                .then(values => {
                    dispatch({ type: 'CHANGE_JOB_HOURS', oldRouteId, routeId, job });
                    error({ failed: false, msg: 'Update route successfuly..' })
                })
                .catch(() => error({ failed: true, msg: 'Update route failed, please try again later.' }))
                .finally(() => dispatch({ type: 'IS_UPDATE', status: false }))
        }
    }

    return (
        <div ref={containerRef} style={{ postion:'relative' }}>
            <div
                ref={canDrag ? drop : canNotDrag}
                className={classes.jobBox}
                style={{ height: lineCount * 55 + 40 }}
                onClick={onClick}
            >
                <p className={classes.dropover} style={{
                    width: hourOver && `${hourOver * 100}px`,
                    backgroundColor: isOver ? '#a5a5a587' : '',
                    zIndex: isOver ? 99 : 'auto',
                    top: dropOverOffsetTop
                }} />
            </div>
        </div>
    )
}

DropBox.propTypes = {
    routeId: PropTypes.string,
    startTime: PropTypes.number,
    error: PropTypes.func,
}

DropBox.defaultProps = {
    className: '',
}

export default DropBox
