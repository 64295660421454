import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
    Card, CardHeader, CardContent, CardActions, Divider,
    TextField, Modal, Grid, Button, colors, Collapse,
    FormControlLabel, CircularProgress, Snackbar, Typography,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Phone from './Phone'
import { GreenCheckbox, FormErrorCallback, AddressInput } from 'components'
import { makeStyles } from '@material-ui/styles'
import NumberFormat from 'react-number-format'
import * as Yup from "yup";
import { Formik } from 'formik';
import axios from "utils/axios";
import apiConfig from "apiConfig";
import { Alert } from "@material-ui/lab";
import { errorMessages } from 'common/constants'
import {formatPhoneNumber} from "../../utils/formatNumber";

const useStyles = makeStyles(theme => ({
    saveBtn: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    btnForNew: {
        marginTop: 9
    },
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 700,
        maxHeight: '90%',
        maxWidth: '100%',
        overflowY: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: 500
        },
    },
    checkbox: {
        margin: 0,
        '& .MuiButtonBase-root': {
            padding: 0
        },
        '& .MuiTypography-root': {
            fontSize: 12,
            whiteSpace: 'nowrap'
        }
    },
    emailOption: {
        fontSize: 13,
        color: colors.grey[500]
    }
}));

const schema = Yup.object().shape({
    name: Yup.string().required('Required.'),
    address: Yup.object({
        address1: Yup.string().required('Required.'),
        city: Yup.string().required('Required.'),
        zipCode: Yup.string()
            .required('Required.')
            .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, 'Zip code is invalid.')
    }),
    contactId: Yup.string().required('Required.'),
    email: Yup.string().nullable().required('This field is required.'),
    phones: Yup.array()
        .of(Yup.object({
            phoneTypeId: Yup.string().nullable()
                .required('Required.'),
            phoneNumber: Yup.string()
                .required('Required.').nullable()
                .matches(/\W\d{3}\W\s\w{3}\W\w{4}$|\W\d{3}\W\s\w{3}\W\w{4}x\d{1,6}|\W\d{3}\W\s\w{3}\W\w{4}x/, 'Invalid phone number.')
        })),
});

const schemaAll = Yup.object().shape({
    name: Yup.string().required('Required.'),
    address: Yup.object({
        address1: Yup.string().required('Required.'),
        city: Yup.string().required('Required.'),
        zipCode: Yup.string()
            .required('Required.')
            .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, 'Zip code is invalid.')
    }),
    contact: Yup.object({
        firstName: Yup.string().required('Required.'),
        lastName: Yup.string().required('Required.'),
        email: Yup.string()
            .required('Required.')
            .email('Email is invalid.'),
        phoneNumber: Yup.string()
            .required('Required.').nullable()
            .matches(/\W\d{3}\W\s\w{3}\W\w{4}$|\W\d{3}\W\s\w{3}\W\w{4}x\d{1,6}|\W\d{3}\W\s\w{3}\W\w{4}x/, 'Invalid phone number.')
    }),
    password: Yup.string().required('Required.'),
});

const initialValue = {
    name: "",
    disabled: false,
    address: {
        address1: '',
        address2: '',
        city: '',
        stateId: '',
        zipCode: '',
    },
    email: '',
    phones: [],
    contactId: '',
    contact: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
    },
    password: ''

};

const CompanyModal = ({ open, onClose, employees, onUpdated, phoneTypes, company: editCompany, reloadEmployees }) => {

    const classes = useStyles();

    const addressInputRef = useRef();

    const [company, setCompany] = useState({ ...initialValue });
    const [isSaving, setIsSaving] = useState(false);
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [expandNew, setExpandNew] = useState(false);

    const onChangeCompany = (event, setFieldValue) => {
        const { name, value, type } = event.target;
        type === 'checkbox' ? setFieldValue(name, event.target.checked) : setFieldValue(name, value)
    }

    const resetModal = () => {
        setExpandNew(false);
        onClose()
    }

    const addUserPhone = (values, setFieldValue) => {
        const phones = [...values.phones];
        phones.push({
            id: "00000000-0000-0000-0000-000000000000",
            phoneNumber: "",
            phoneTypeId: ''
        });
        setFieldValue('phones', phones)
    }
    const onPhoneUpdate = (event, values, setFieldValue, index) => {
        const { name, value } = event.target;
        const phones = [...values.phones];
        phones[index] = { ...values.phones[index], [name]: value?.trim() };
        setFieldValue('phones', phones);
    }
    const onPhoneRemove = (values, setFieldValue, index) => {
        const phones = [...values.phones];
        phones.splice(index, 1);
        setFieldValue('phones', phones);
    }

    const setCreateNewUser = (setFieldValue, setErrors) => {
        if (expandNew) {
            setErrors({});
            setFieldValue('contact', { firstName: '', lastName: '', email: '', phoneNumber: '' }, false);
            setFieldValue('password', '', false);
            setExpandNew(!expandNew);
        } else {
            setErrors({});
            setFieldValue('contactId', '', false);
            setExpandNew(!expandNew);
        }
    }

    const handleUpdateCompany = (values) => {
        if (editCompany) {
            values.address.stateId = values.address.state?.id;
            const data = { ...values, addresses: [values.address] };
            if (values.contactId) delete data.contact;
            axios.put(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_UPDATE, data)
                .then(res => {
                    if (!values.contactId) {
                       /*  const dataPassword = {
                            userId: values.contact.id,
                            password: values.password
                        }
                        updatePassword(dataPassword, 'update') */
                        const contact = values.contact;
                        contact.address = {};
                        contact.phones = [];
                        contact.security = {
                            username: contact.email,
                            password: values.password,
                            roleIds: ['6c76405b-294c-4720-8396-2ff142a418b4'], // Company Administrator
                            companyId: values.id
                        };
                        return axios.put(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_ADD, contact)
                            .then(res => {
                                setIsStatus({ failed: false, msg: 'Update successfully.' });
                                setOpenSnackBar(true);
                                onUpdated();
                                onClose();
                                setExpandNew(false);
                                reloadEmployees();
                            })
                            .catch((err) => {
                                const message = err.response.data ? err.response.data.messages : null;
                                setIsStatus({ failed: true, msg: message ? message.username : 'An error occurred, please try again later.' });
                                setOpenSnackBar(true);
                            });
                    } else {
                        setIsStatus({ failed: false, msg: 'Update successfully.' });
                        setOpenSnackBar(true);
                        onUpdated();
                        onClose();
                    }
                })
                .catch(() => {
                    setIsStatus({ failed: true, msg: 'Update failed, please try again later' });
                    setOpenSnackBar(true);
                })
                .finally(() => setIsSaving(false))
        } else {
            // add new Job
            values.address.stateId = values.address.state?.id;
            const data = { ...values, addresses: [values.address] }
            const contact = values.contact;
            contact.address = {};
            contact.phones = [];
            contact.security = {
                username: contact.email,
                password: values.password,
                roleIds: ['6c76405b-294c-4720-8396-2ff142a418b4'] // Company Administrator
            };
            if (values.contactId) {
                delete data.contact;

                axios.post(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_ADD, data)
                    .then(res1 => {
                        setIsStatus({ failed: false, msg: 'Add successfully.' });
                        setOpenSnackBar(true);
                        onUpdated();
                        onClose();
                    }).catch(() => {
                        setIsStatus({ failed: true, msg: 'Add failed, please try again later' });
                        setOpenSnackBar(true);
                    }).finally(e => setIsSaving(false));
            } else {
                axios.put(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_ADD, contact)
                    .then(res => {
                        data.contact.id = res.data.id;
                        axios.post(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_ADD, data)
                            .then(res1 => {
                                setIsStatus({ failed: false, msg: 'Add successfully.' });
                                setOpenSnackBar(true);
                                onUpdated();
                                onClose();
                                setExpandNew(false);
                                reloadEmployees();
                            }).catch(() => {
                                setIsStatus({ failed: true, msg: 'Add failed, please try again later' });
                                setOpenSnackBar(true);
                            }).finally(e => setIsSaving(false));
                    })
                    .catch((err) => {
                        const message = err.response.data ? err.response.data.messages : null;
                        setIsStatus({ failed: true, msg: message ? message.username : 'An error occurred, please try again later.' });
                        setOpenSnackBar(true);
                        setIsSaving(false);
                    });
            }
        }
    }

    const updatePassword = (data, type) => {
        axios.post(apiConfig.url.AUTH_URL + apiConfig.url.AUTH_CHANGE_PASSWORD, data)
            .then(res => {
                setIsStatus({ failed: false, msg: type === 'add' ? 'Add successfully.' : 'Update successfully.' });
                setOpenSnackBar(true);
                onUpdated();
                onClose();
            })
            .catch(() => {
                setIsStatus({ failed: true, msg: 'Add failed, please try again later' });
                setOpenSnackBar(true);
            })
            .finally(() => setIsSaving(false))
    }

    useEffect(() => {
        if (editCompany) {
            const _company = {
                ...editCompany,
                phones: editCompany.phones && editCompany.phones.length ? editCompany.phones.map(phone => {
                        return {...phone, phoneNumber: formatPhoneNumber(phone.phoneNumber)}
                    }): [],
                address: editCompany.addresses?.[0] || { ...initialValue.address },
                contact: { ...initialValue.contact },
                password: '',
                states: company.states
            }
            _company.address.state = { id: _company.address.stateId };
            setCompany(_company);
        } else {
            setCompany(JSON.parse(JSON.stringify({ ...initialValue, states: company.states })));
        }
    }, [editCompany])

    useEffect(() => {
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.EMPLOYEE_STATES_LIST)
            .then(res => {
                res.data = res.data.sort((a, b) => a.name.localeCompare(b.name))
                setCompany(c => ({ ...c, states: res.data }));
            })
    }, [])

    return (
        <>
            <Modal open={open} onClose={resetModal}>
                <div>
                    <Formik
                        initialValues={company}
                        validationSchema={expandNew ? schemaAll : schema}
                        enableReinitialize
                        onSubmit={values => {
                            setIsSaving(true);
                            const { coordinate, isValid, forceSave, verifyFn } = addressInputRef.current;
                            if (isValid || forceSave) {
                                values = {
                                    ...values,
                                    address: {
                                        ...values.address,
                                        latitude: coordinate?.latitude,
                                        longitude: coordinate?.longitude
                                    }
                                };
                                handleUpdateCompany(values)
                            } else {
                                verifyFn(true).then(data => data ? handleUpdateCompany(data) : setIsSaving(false))
                            }
                        }}
                    >
                        {({
                            values,
                            errors,
                            setErrors,
                            setTouched,
                            touched,
                            submitCount,
                            isValid,
                            setFieldValue,
                            setFieldTouched,
                            handleSubmit
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <FormErrorCallback
                                    submitCount={submitCount}
                                    isValid={isValid}
                                    onSubmissionError={() => {
                                        setIsStatus({ failed: true, msg: errorMessages.FIELD_CHECK });
                                        setOpenSnackBar(true);
                                    }}
                                />
                                <Card className={classes.styleModal}>
                                    <CardHeader title={editCompany ? 'Edit Company' : 'Add Company'} />
                                    <Divider />
                                    <CardContent>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Grid container spacing={3}>
                                                    <Grid item style={{ flex: 1 }}>
                                                        <TextField
                                                            fullWidth
                                                            label='Company Name'
                                                            name='name'
                                                            variant='outlined'
                                                            value={values.name || ''}
                                                            onChange={(e) => {
                                                                onChangeCompany(e, setFieldValue)
                                                            }}
                                                            onBlur={() => setFieldTouched('name')}
                                                            error={errors.name && touched.name}
                                                            helperText={(errors.name && touched.name) && errors.name}
                                                        />
                                                    </Grid>
                                                    <Grid item style={!editCompany ? { display: 'none' } : null}>
                                                        <FormControlLabel
                                                            control={<GreenCheckbox />}
                                                            label='Disable Company'
                                                            name='disabled'
                                                            labelPlacement='top'
                                                            checked={values.disabled}
                                                            className={classes.checkbox}
                                                            onChange={(e) => {
                                                                onChangeCompany(e, setFieldValue)
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    label='Legal Name'
                                                    name='legalName'
                                                    variant='outlined'
                                                    value={values.legalName || ''}
                                                    onChange={(e) => {
                                                        onChangeCompany(e, setFieldValue)
                                                    }}
                                                    onBlur={() => setFieldTouched('legalName')}
                                                    error={errors.legalName && touched.legalName}
                                                    helperText={(errors.legalName && touched.legalName) && errors.legalName}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <AddressInput
                                                    ref={addressInputRef}
                                                    errors={errors}
                                                    values={values}
                                                    touched={touched}
                                                    setFieldValue={setFieldValue}
                                                    setFieldTouched={setFieldTouched}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    error={errors.email && touched.email}
                                                    helperText={(errors.email && touched.email) && errors.email}
                                                    label="Email"
                                                    name="email"
                                                    onBlur={() => setFieldTouched('email')}
                                                    onChange={event => setFieldValue('email', event.target.value)}
                                                    value={values.email || ''}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            {values.phones.map((phone, index) => (
                                                <Phone
                                                    key={phone.id}
                                                    index={index}
                                                    length={values.phones.length}
                                                    phone={phone}
                                                    errors={errors}
                                                    touched={touched}
                                                    setFieldTouched={setFieldTouched}
                                                    phoneTypes={phoneTypes}
                                                    onPhoneRemove={() => onPhoneRemove(values, setFieldValue, index)}
                                                    onPhoneUpdate={event => onPhoneUpdate(event, values, setFieldValue, index)}
                                                />
                                            ))}
                                            <Grid item md={12} xs={12}>
                                                <Button
                                                    onClick={() => addUserPhone(values, setFieldValue)}
                                                    color="primary"
                                                    variant="outlined">
                                                    Add Phone
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <CardHeader title='Admin User' />
                                    <Divider />
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <Autocomplete
                                                    disableClearable
                                                    options={employees}
                                                    autoHighlight
                                                    getOptionLabel={(option) => option.fullName || ''}
                                                    renderOption={(option) => (
                                                        <React.Fragment>
                                                            <div>
                                                                <p>{option.fullName}</p>
                                                                <p className={classes.emailOption}>{option.email}</p>
                                                            </div>
                                                        </React.Fragment>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            error={errors.contactId && touched.contactId ? true : false}
                                                            helperText={errors.contactId && touched.contactId ? errors.contactId : ''}
                                                            label="Employee"
                                                            variant="outlined"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                                            }}
                                                        />
                                                    )}
                                                    value={employees.find(e => e.id === values.contactId)}
                                                    onChange={(event, value) => {
                                                        setFieldValue('contactId', value.id)
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <div className={classes.btnForNew}>
                                                    <Button className={classes.saveBtn} variant="contained"
                                                        onClick={() => setCreateNewUser(setFieldValue, setErrors)}>
                                                        {expandNew ? 'Cancel Create New User' : 'Create New User'}
                                                    </Button>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <Collapse in={expandNew} timeout='auto' unmountOnExit>
                                        <CardContent>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        fullWidth
                                                        label='First Name'
                                                        name='firstName'
                                                        variant='outlined'
                                                        value={(values.contact && values.contact.firstName) || ''}
                                                        onChange={event => {
                                                            const contact = { ...values.contact };
                                                            contact.firstName = event.target.value;
                                                            setFieldValue('contact', contact);
                                                        }}
                                                        error={
                                                            errors.contact &&
                                                            errors.contact.firstName &&
                                                            touched.contact &&
                                                            touched.contact.firstName
                                                        }
                                                        helperText={(
                                                            errors.contact &&
                                                            errors.contact.firstName &&
                                                            touched.contact &&
                                                            touched.contact.firstName
                                                        ) && errors.contact.firstName}
                                                        onBlur={() => {
                                                            setFieldTouched('contact.firstName')
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        fullWidth
                                                        label='Last name'
                                                        name='lastName'
                                                        variant='outlined'
                                                        value={(values.contact && values.contact.lastName) || ''}
                                                        onChange={event => {
                                                            const contact = { ...values.contact };
                                                            contact.lastName = event.target.value;
                                                            setFieldValue('contact', contact);
                                                        }}
                                                        error={
                                                            errors.contact &&
                                                            errors.contact.lastName &&
                                                            touched.contact &&
                                                            touched.contact.lastName
                                                        }
                                                        helperText={(
                                                            errors.contact &&
                                                            errors.contact.lastName &&
                                                            touched.contact &&
                                                            touched.contact.lastName
                                                        ) && errors.contact.lastName}
                                                        onBlur={() => {
                                                            setFieldTouched('contact.lastName')
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={12}>
                                                    <NumberFormat
                                                        customInput={TextField}
                                                        format="(###) ###-####x######"
                                                        fullWidth
                                                        label="Phone Number"
                                                        name="phoneNumber"
                                                        variant="outlined"
                                                        value={values.contact.phoneNumber || ''}
                                                        onChange={event => {
                                                            const contact = { ...values.contact };
                                                            contact.phoneNumber = event.target.value?.trim();
                                                            setFieldValue('contact', contact);
                                                        }}
                                                        error={
                                                            errors.contact &&
                                                            errors.contact.phoneNumber &&
                                                            touched.contact &&
                                                            touched.contact.phoneNumber
                                                        }
                                                        helperText={(
                                                            errors.contact &&
                                                            errors.contact.phoneNumber &&
                                                            touched.contact &&
                                                            touched.contact.phoneNumber
                                                        ) && errors.contact.phoneNumber}
                                                        onBlur={() => {
                                                            setFieldTouched('contact.phoneNumber')
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        label='Email'
                                                        name='email'
                                                        variant='outlined'
                                                        value={(values.contact && values.contact.email) || ''}
                                                        onChange={event => {
                                                            const contact = { ...values.contact };
                                                            contact.email = event.target.value;
                                                            setFieldValue('contact', contact);
                                                        }}
                                                        error={
                                                            errors.contact &&
                                                            errors.contact.email &&
                                                            touched.contact &&
                                                            touched.contact.email
                                                        }
                                                        helperText={(
                                                            errors.contact &&
                                                            errors.contact.email &&
                                                            touched.contact &&
                                                            touched.contact.email
                                                        ) && errors.contact.email}
                                                        onBlur={() => {
                                                            setFieldTouched('contact.email')
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        label='Password'
                                                        name='password'
                                                        type='password'
                                                        variant='outlined'
                                                        value={values.password || ''}
                                                        onChange={e => setFieldValue('password', e.target.value)}
                                                        onBlur={() => setFieldTouched('password')}
                                                        error={errors.password && touched.password}
                                                        helperText={(errors.password && touched.password) && errors.password}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Collapse>
                                    <Divider />
                                    <CardActions style={{ justifyContent: 'flex-end', paddingRight: '16px' }}>
                                        <Button variant='contained' onClick={resetModal}>
                                            Close
                                        </Button>
                                        <Button
                                            type="submit"
                                            className={classes.saveBtn}
                                            disabled={isSaving}
                                            variant="contained">
                                            Save Changes
                                        </Button>
                                        {isSaving && <CircularProgress size={24} />}
                                    </CardActions>
                                </Card>
                            </form>
                        )}
                    </Formik>
                </div>
            </Modal>
            <Snackbar
                open={openSnackbar}
                onClose={() => setOpenSnackBar(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={3000}>
                <Alert elevation={6} variant='filled' severity={isStatus.failed ? 'error' : 'success'}>
                    <Typography variant='h6' color='inherit'>
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>

        </>
    )
}

CompanyModal.propTypes =
{
    open: PropTypes.bool,
    onClose: PropTypes.func,
    phoneTypes: PropTypes.arrayOf(Object),
    employees: PropTypes.arrayOf(Object),
    onUpdated: PropTypes.func,
    company: PropTypes.object
}

export default CompanyModal;
