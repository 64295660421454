import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { List, Typography, useMediaQuery } from '@material-ui/core';
import useRouter from 'utils/useRouter';
import { NavigationListItem } from 'components';
import { useLocation, useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(0)
  }
}));

const initExpand = {
  'Employee': true,
  'Employees': false,
  'Admin': false,
  'Super Admin': false
}

const NavigationList = props => {

  const { pages, title, depth, openDrawer, ...rest } = props;

  const { pathname } = useLocation();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  // const dispatch = useDispatch();
  const [expand, setExpand] = useState({ ...initExpand });

  let history = useHistory();

  const handleToggle = title => {
    if (title !== 'Employee' || !openDrawer) {
      setExpand({ ...initExpand, [title]: !expand[title] })
    }
  }

  const handleToggleAndRedirect = title => {
    if(title === "Customers") {
      history.push("/customers/allcustomers");
    }
    if(title === "Employees") {
      history.push("/employees");
    }
    if (title !== 'Employee' || !openDrawer) {
      setExpand({ ...initExpand, [title]: !expand[title] })
    }
  }

  const handleOpenSubmenu = title => {
    if (title !== 'Employee' || !openDrawer) {
      setExpand({ ...initExpand, [title]: true })
    }
  }

  const handleCloseSubmenu = title => {
    if (title !== 'Employee' || !openDrawer) {
      setExpand({ ...initExpand, [title]: false })
    }
  }

  const setExpandCurrent = () => {
    const path = pathname.split('/')[1];
    if (path === 'employees') {
      setExpand({...initExpand, 'Employees': true})
    } else if (path === 'customers') {
      setExpand({ ...initExpand, 'Customers': true })
    } else if (path === 'super-admin') {
      setExpand({ ...initExpand, 'Super Admin': true })
    } else if(path === 'admin'){
      setExpand({ ...initExpand, 'Admin': true })
    }else {
      setExpand({ ...initExpand })
    }
  }

  useEffect(() => {
    if (!openDrawer && isDesktop) setExpand({ ...initExpand, Employee: false });
    else setExpandCurrent();
  }, [openDrawer, pathname]);

  return (
    <>
      {!openDrawer && depth > 0 && <h4 style={{padding: 10}}>{title}</h4> || null}
      <List>
        {pages.reduce(
          (items, page) => mapChildRoutes({
            items,
            page,
            expand,
            handleToggle,
            handleToggleAndRedirect,
            handleOpenSubmenu,
            handleCloseSubmenu,
            openDrawer,
            depth,
            ...rest
          }), []
        )}
      </List>
    </>
  );
};

const mapChildRoutes = props => {
  const {
    router, items, page, depth, openDrawer,
    expand, handleToggle, handleOpenSubmenu, handleCloseSubmenu, onClose, handleToggleAndRedirect
  } = props;

  if (page.children) {
    // const isOpen = matchPath(router.location.pathname, {
    //   path: page.href,
    //   exact: false
    // });

    items.push(
      <NavigationListItem
        depth={depth}
        icon={page.icon}
        key={page.title}
        label={page.label}
        expandable={page.expandable}
        isOpen={expand[page.title]}
        openDrawer={openDrawer}
        handleToggle={() => handleToggle(page.title)}
        handleToggleAndRedirect={() => handleToggleAndRedirect(page.title)}
        handleOpenSubmenu={() => handleOpenSubmenu(page.title)}
        handleCloseSubmenu={() => handleCloseSubmenu(page.title)}
        title={page.title}>
        <NavigationList
          depth={depth + 1}
          title={page.title}
          pages={page.children}
          router={router}
          openDrawer={openDrawer}
          onClose={onClose}
        />
      </NavigationListItem>
    );
  } else {
    items.push(
      <NavigationListItem
        depth={depth}
        href={page.href}
        icon={page.icon}
        key={page.title}
        label={page.label}
        title={page.title}
        openDrawer={openDrawer}
        onClose={onClose}
      />
    );
  }

  return items;
};

const Navigation = props => {
  const {title, pages, className, component: Component, openDrawer, onClose, ...rest } = props;

  const classes = useStyles();
  const router = useRouter();

  return (
    <Component
      {...rest}
      className={clsx(classes.root, className)}
    >
      {title && <Typography variant="overline">{title}</Typography>}
      <NavigationList
        depth={0}
        pages={pages}
        router={router}
        openDrawer={openDrawer}
        onClose={onClose}
      />
    </Component>
  );
};

Navigation.propTypes = {
  // key: PropTypes.string
}
Navigation.defaultProps = {
  component: 'nav'
};

export default Navigation;
