const holidays = [ // keys are formatted as month,week,day
  ['0,1', "New Year's Day"],
  ['6,4', "Independence Day"],
  ['10,4,4', "Thanksgiving Day"],
  ['11,24', "Christmas Eve"],
  ['11,25', "Christmas Day"]
];

const getDayOfMonth = (year, month, week, dayOfWeek) =>
  (dayOfWeek + 6 - new Date(year, month, 7).getDay()) % 7 + week * 7 - 6;

const getMonthDays = (year, month) => new Date(year, month + 1, 0).getDate();

const calculateHolidays = (year, month) =>
  holidays
    .filter(holiday => holiday[0].startsWith(`${month},`))
    .map(val => {
      const [, week, dayOfWeek] = val[0].split(',');

      let calculatedDay;
      if (dayOfWeek === undefined) {
        calculatedDay = week;
      } else {
        calculatedDay = getDayOfMonth(year, month, week, +dayOfWeek);
        if (calculatedDay > getMonthDays(year, month)) calculatedDay -= 7;
      }
      const calculatedDate = (+month + 1) + '/' + calculatedDay + '/' + year;
      return [calculatedDate, val[1]];
    });

export function getHoliday(date) {
  const dayString = date.toLocaleDateString();
  const [month, , year] = dayString.split('/');
  const holidayArray = calculateHolidays(year, month - 1);
  const index = holidayArray.findIndex(x => x[0] === dayString);
  if (index === -1) return undefined;
  return holidayArray[index][1];
}
