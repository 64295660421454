import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import PersonIcon from '@material-ui/icons/Person';
import EventNoteIcon from '@material-ui/icons/EventNote';
import DescriptionIcon from '@material-ui/icons/Description';
import ReceiptIcon from '@material-ui/icons/Receipt';
import HelpIcon from '@material-ui/icons/Help';
import BuildIcon from '@material-ui/icons/Build';

const sidebarPages = [
  {
    pages: [
      {
        title: 'Dashboard',
        href: '/dashboard',
        icon: DashboardIcon
      },
      {
        title: 'Employees',
        icon: PeopleIcon,
        expandable: true,
        children: [
          {
            title: 'Manage',
            href: '/employees'
          },
          {
            title: 'Log Hours',
            href: '/employees/log-hours'
          },
          {
            title: 'Tasks',
            href: '/employees/tasks'
          },
          {
            title: 'Payroll',
            href: '/employees/payroll-list',
          },
          {
            title: "Today's Route",
            href: '/employees/today-route'
          },
        ]
      },
      {
        title: 'Customers',
        icon: PersonIcon,
        expandable: true,
        children: [
          {
            title: 'Manage',
            href: '/customers/allcustomers',
          },
          {
            title: 'Bids to do',
            href: '/customers/bidtodo'
          },
          {
            title: 'Sales Follow Ups',
            href: '/customers/salefollowups',
          },
          {
            title: "Expiring Documents",
            href: '/customers/expiringdocuments',
          },
          {
            title: "Duplicates",
            href: '/customers/duplicate',
          },
          {
            title: "Missing Tax Groups",
            href: '/customers/missingtaxgroup',
          },
        ]
      },
      {
        title: 'Schedules',
        href: '/schedules',
        icon: EventNoteIcon
      },
      {
        title: 'Job Completion',
        href: '/job-completion',
        icon: ReceiptIcon
      },
      {
        title: 'Reports',
        href: '/report',
        icon: DescriptionIcon
      },
      {
        title: 'Admin',
        href: '/admin',
        icon: SettingsIcon,
        expandable: true,
        children: [
          {
            title: 'Manage',
            href: '/admin'
          },{
            title: 'Communication Center',
            href: '/admin/communication-center'
          },
          {
            title: 'Custom Forms',
            href: '/admin/custom-forms'
          }
        ]
      },
      // {
      //   title: 'Help',
      //   href: '/help',
      //   icon: HelpIcon
      // }
    ]
  },
];

export const superAdminSidebarPages = [
  {
    pages: [
      {
        title: 'Dashboard',
        href: '/dashboard',
        icon: DashboardIcon
      },
      {
        title: 'Employees',
        icon: PeopleIcon,
        expandable: true,
        children: [
          {
            title: 'Manage',
            href: '/employees'
          },
          {
            title: 'Log Hours',
            href: '/employees/log-hours'
          },
          {
            title: 'Tasks',
            href: '/employees/tasks'
          },
          {
            title: 'Payroll',
            href: '/employees/payroll-list',
          },
          {
            title: "Today's Route",
            href: '/employees/today-route'
          },
        ]
      },
      {
        title: 'Customers',
        icon: PersonIcon,
        expandable: true,
        children: [
          {
            title: 'Manage',
            href: '/customers/allcustomers',
          },
          {
            title: 'Bids to do',
            href: '/customers/bidtodo'
          },
          {
            title: 'Sales Follow Ups',
            href: '/customers/salefollowups',
          },
          {
            title: "Expiring Documents",
            href: '/customers/expiringdocuments',
          },
          {
            title: "Duplicates",
            href: '/customers/duplicate',
          },
          {
            title: "Missing Tax Groups",
            href: '/customers/missingtaxgroup',
          },
        ]
      },
      {
        title: 'Schedules',
        href: '/schedules',
        icon: EventNoteIcon
      },
      {
        title: 'Job Completion',
        href: '/job-completion',
        icon: ReceiptIcon
      },
      {
        title: 'Reports',
        href: '/report',
        icon: DescriptionIcon
      },
      {
        title: 'Admin',
        href: '/admin',
        icon: SettingsIcon,
        expandable: true,
        children: [
          {
            title: 'Manage',
            href: '/admin'
          },
          {
            title: 'Communication Center',
            href: '/admin/communication-center'
          },
          {
            title: 'Custom Forms',
            href: '/admin/custom-forms'
          }
        ]
      },
      {
        title: 'Super Admin',
        icon: BuildIcon,
        expandable: true,
        children: [
          {
            title: 'Companies',
            href: '/super-admin/companies'
          },
          {
            title: 'Multi-Company Users',
            href: '/super-admin/multi-company-users'
          },
          // {
          //   title: 'Transfer Company Data',
          //   href: '/super-admin/transfer-company-data',
          // }
        ]
      },
      // {
      //   title: 'Help',
      //   href: '/help',
      //   icon: HelpIcon
      // }
    ]
  },
];
// CHANGING ANY OF THESE REQUIRES UPDATING LINES ~429 OF SIDEBARPAGES.JS
export const fieldTechnicianSidebarPages = [
  {
    pages: [
      {
        title: 'Employee',
        icon: PeopleIcon,
        expandable: false,
        children: [
          {
            title: 'Log Hours',
            href: '/employees/log-hours'
          },
          {
            title: 'Tasks',
            href: '/employees/tasks'
          },
          {
            title: 'Payroll',
            href: '/employees/payroll-detail',
          },
          {
            title: "Today's Route",
            href: '/employees/today-route'
          },
        ]
      },
    ]
  }
];

export default sidebarPages;
