import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import { makeStyles } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {
    Card, CardHeader, CardContent,
    TableContainer, Table, TableHead,
    TableBody, TableRow, TableCell, Divider,
    Grid, TextField, FormControlLabel, IconButton,
    colors, Tooltip, Button, Snackbar, Typography, CircularProgress, CardActions, Select, TableFooter, Hidden, Collapse
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxIcon from '@material-ui/icons/AddBox'
import { GreenCheckbox, ConfirmModal } from 'components'
import ConfirmTemplate from './ConfirmTemplate'
import PriceLine from './PriceLine'
import axios from 'utils/axios'
import apiConfig from 'apiConfig'
import localStorage from "utils/localStorage";
import { useParams } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import uuid from "uuid";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import {ArrowDownward, ArrowUpward, KeyboardArrowDown, KeyboardArrowUp} from "@material-ui/icons";
import PriceLineMobile from './PriceLineMobile'

const useStyles = makeStyles(theme => ({
    addStyle: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        "&:hover": {
            backgroundColor: colors.green[900]
        }
    },
    table: {
        minWidth: '800px'
    },
    tableHead: {
        borderBottom: '1px solid #888888',
        cursor: 'pointer',
        '& span': {
            display: 'flex'
        }

    },
    tableBody: {
        borderBottom: '1px solid #888888',
    },
    templateBox: {
        position: 'relative'
    },
    template: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
            alignSelf: "stretch",
            '& > div:first-child': {
                alignSelf: "stretch"
            }
        }
    },
    templateActions: {
        [theme.breakpoints.down("xs")]: {
            alignSelf: 'flex-end'
        }
    },
    checkbox: {
        flex: 1,
        justifyContent: 'end'
    },
    btn: {
        whiteSpace: 'nowrap',
        padding: '8px 32px',
        margin: '0 8px',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    btnDis: {
        whiteSpace: 'nowrap',
        color: 'rgba(0, 0, 0, 0.26)',
        backgroundColor: 'rgb(217 217 217)',
        pointerEvents: 'none'
    },
    iconBtn: {
        padding: '0',
        '& span svg': {
            fontSize: '40px',
            color: colors.green[600],
            '&:hover': {
                color: colors.green[900]
            }
        }
    },
    formControlItem: {
        '&:hover': {
            background: "rgba(27,130,231,0.2)",
        }
    },
    selected: {
        background: "rgba(27,130,231,0.5) !important",
    },
    iconSort: {
        marginBottom: '-5px',
        marginLeft: '10px'
    },
    customTableCell: {
        borderBottom: '1px solid #969696',
        cursor: 'pointer'
    }
}))

const PriceDetails = props => {
    const { name, id } = useParams();

    const classes = useStyles();
    const fullInfo = localStorage.getFullInfo();
    const ipad = useMediaQuery('(max-width: 1025px)');
    const sm = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const {
        jobId,
        jobDescription,
        prices,
        calcuator,
        onChange,
        onChangeDiscount,
        onChangeDiscountType,
        onAddItem,
        onChangeItem,
        onChangeDesc,
        onCopyItem,
        onRemoveItem,
        onAddItemTemplate,
        onChangeTemplate,
        setTotalSub,
        disabled,
        errors,
        touched,
        onDirty,
        postedToQuickbooks,
        loadDocument,
        sortBy,
        sortType
    } = props;
    const { jobItems, displayOnPrintOutsEmails, discountPercent, discountDollars, discountTypeId, taxPercent, tip } = prices;
    const [expand, setExpand] = useState(true);
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });
    const [isSaving, setIsSaving] = useState(false);
    const [isSavingTemplate, setIsSavingTemplate] = useState(false);
    const [tem, setTem] = useState(' ');
    const [open, setOpen] = useState(false);
    const [isRemove, setIsRemove] = useState(false);
    const [lineItem, setLineItem] = useState([]);
    const [listTemplate, setListTemplate] = useState([]);
    const [template, setTemplate] = useState({});
    const [discountTypes, setDiscountTypes] = useState();
    const [changeType, setChangeType] = useState('');
    const [hoverId, setHoverId] = useState();
    const [selected, setSelected] = useState();
    const [minHeight, setMinHeight] = useState();

    let taxTotal = 0;
    const [total, setAllTotal] = useState(0);
    const [subtotal, setSubtotal] = useState(0);
    const [_total, setTotal] = useState('0');
    const [closing, setClosing] = useState(false);
    const timer = useRef(null);

    const [timers, setTimer] = useState(null);
    const [scrollTop, setScrollTop] = useState(0);

    function changeDelay(fn) {
        if (timers) {
            clearTimeout(timers);
            setTimer(null);
        }
        setTimer(
            setTimeout(() => {
                fn();
            }, 100)
        );
    }

    // const loadDocument = (type) => {
        // setSortBy(type)
        // setSortType(type == sortBy ? (sortType === 'ASC' ? 'DESC' : 'ASC') : 'ASC');
    // }
    const getSortIcon = (type) => {
        return (
            sortBy == type &&
            (
                sortType == 'ASC' ?
                    <ArrowUpward className={classes.iconSort}/>
                    :
                    <ArrowDownward className={classes.iconSort}/>
            )
        )
    }

    const saveTemplate = () => {

        if (!tem.trim()) {
            setOpen(true)
        } else {
            const template = listTemplate.find(x => x.id === tem).templateName
            onSaveTemplate(template)
        }
    }
    const getTemplate = () => {
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMERS_JOB_TEMPLATE)
            .then(res => {
                setListTemplate(res.data)
            })
    }
    const onSaveTemplate = (name) => {
        if (!jobItems || (jobItems && jobItems.length === 0)) {
            setIsStatus({ failed: true, msg: 'Price detail can not empty.' });
            setOpenSnackBar(true);
            return;
        }
        let tp = {
            id: tem.trim() ? tem : '00000000-0000-0000-0000-000000000000',
            templateName: name,
            jobDescription: jobDescription,
            jobTemplateDetails: []
        }
        jobItems.map(item => {
            const temp = {
                id: item.id,
                companyPriceItemId: item.companyPriceItemId ? item.companyPriceItemId : uuid(),
                description: item.description,
                companyPriceItem: {
                    id: item.id,
                    companyId: id,
                    description: item.description,
                    price: item.price,
                    taxable: item.taxable,
                    active: true,
                    companyPriceItemId: item.companyPriceItemId,
                }
            }
            if (tem.trim()) {
                temp['jobTemplateId'] = tem
            }
            tp.jobTemplateDetails.push(temp);
        })
        setIsSavingTemplate(true);
        if (tem.trim()) {
            axios.put(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMERS_JOB_TEMPLATE_ADD, tp)
                .then(res => {
                    setIsStatus({ failed: false, msg: 'Update successfully' });
                    setOpenSnackBar(true);
                })
                .catch(err => {
                    setIsStatus({ failed: true, msg: 'An error occurred, please try again later.' });
                    setOpenSnackBar(true);
                })
                .finally(e => setIsSavingTemplate(false));
        } else {
            axios.post(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMERS_JOB_TEMPLATE_ADD, tp)
                .then(res => {
                    listTemplate.push(res.data)
                    setTem(res.data.id);
                    setIsStatus({ failed: false, msg: 'Add successfully' });
                    setOpenSnackBar(true);
                })
                .catch(err => {
                    setIsStatus({ failed: true, msg: 'An error occurred, please try again later.' });
                    setOpenSnackBar(true);
                })
                .finally(e => setIsSavingTemplate(false));
        }
    }
    const loadTemplate = (id) => {
        const data = listTemplate.find(x => x.id == id)
        const jonItem = []
        if (data) {
            data && data.jobTemplateDetails.map(item => {
                jonItem.push({
                    addedOnLocation: false,
                    companyPriceItemId: item.companyPriceItem.id,
                    description: item.companyPriceItem.description,
                    jobId: jobId,
                    price: item.companyPriceItem.price,
                    quantity: 1,
                    // quantity: item.companyPriceItem.quantity,
                    taxable: item.companyPriceItem.taxable,
                    total: 0,
                    upsale: false
                })
            })
            localStorage.set('list_origin_template', JSON.stringify(jonItem));
            localStorage.set('job_description_template', data.jobDescription);
            onAddItemTemplate(jonItem);
        }
    }
    const deleteTemplate = (id) => {
        setIsSaving(true)
        axios.delete(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMERS_JOB_TEMPLATE_DELETE.replace('{jobTemplateId}', id))
            .then(res => {
                setListTemplate(listTemplate.filter(x => x.id !== id));
                setTem(' ');
                setIsStatus({ failed: false, msg: 'Delete successfully' });
                setOpenSnackBar(true);
            })
            .catch(err => {
                setIsStatus({ failed: true, msg: 'An error occurred, please try again later.' });
                setOpenSnackBar(true);
            })
            .finally(e => setIsSaving(false));
    }

    const getDiscountTypes = () => {
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMERS_JOB_DISCOUNT_TYPE)
            .then(res => {
                setDiscountTypes(res.data || [])
            })
    }

    const loadTempTemplate = (id) => {
        setHoverId(id)
        const data = listTemplate.find(x => x.id == id)
        const jonItem = []
        data && data.jobTemplateDetails.map(item => {
            jonItem.push({
                addedOnLocation: false,
                companyPriceItemId: item.companyPriceItem.qbItemId,
                description: item.companyPriceItem.description,
                // id: item.companyPriceItem.id,
                jobId: jobId,
                price: item.companyPriceItem.price,
                quantity: 1,
                // quantity: item.companyPriceItem.quantity,
                taxable: item.companyPriceItem.taxable,
                total: 0,
                upsale: false
            })
        })
        onAddItemTemplate(jonItem);
        onChangeTemplate(data);
        let content = document.getElementById('content')
        let CardContent = document.getElementById('CardContent')
        if (CardContent) {
            let h = CardContent.clientHeight;
            if (h > (minHeight || 0)) {
                setMinHeight(h);
            }
        }
        content.scrollTo(0,scrollTop);
    }
    const setSelectedTemplate = () => {
        setClosing(true);
        timer.current = setTimeout(() => {
            if (hoverId === selected) return
            const lstItem = JSON.parse(localStorage.getItem('list_origin_template'));
            const jobDescription = localStorage.getItem('job_description_template');
            onAddItemTemplate(lstItem);
            onChangeTemplate({ jobDescription });
            setClosing(false);
        }, 200);
    }

    const updateTip = (e) => {
        const value = e.target.value;
        onChange(e);
    }
    // useEffect(() => {
    //     fetchJobItems();
    // }, [sortBy, sortType])

    useEffect(() => {
        let tt = 0;
        taxTotal = 0;
        Array.isArray(jobItems) && jobItems.map(item => {
            const { quantity, price } = item;
            const totalPrice = quantity * price;
            tt += totalPrice;
        });
        setAllTotal(tt);
        let pd = 0;
        let present = 0;
        if (changeType === 'discountDollars') {
            pd = discountDollars;
            present = parseFloat(pd / tt * 100).toFixed(2)
        } else {
            pd = discountPercent ? parseFloat(tt * discountPercent / 100).toFixed(2) : '0.00';
            present = discountPercent;
        }
        const sub = parseFloat(tt - pd).toFixed(2);
        setSubtotal(sub);
        setTotalSub(sub);

        Array.isArray(jobItems) && jobItems.map(item => {
            const { quantity, price, taxable } = item;
            const totalPrice = quantity * price;
            let _discountDollar = present ? totalPrice * present / 100 : 0;
            taxTotal += (totalPrice - _discountDollar) * taxable * (taxPercent || 0) / 100;
        });

        // setPriceDiscount(pd);
        setTotal((Math.round((parseFloat(sub) + taxTotal + parseFloat(tip || 0)) * 100) / 100).toFixed(2));
        if (changeType) {
            onChangeDiscount(changeType == 'discountDollars' ? 'discountPercent' : 'discountDollars', changeType == 'discountDollars' ? present : pd);
        }
    }, [jobItems, taxPercent, calcuator, tip])

    useEffect(() => {
        if (template) {
            const listPrice = [];
        }
    }, [template])

    // useEffect(() => {
    //     onAddItem();
    // },[])

    useEffect(() => {
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_PRICES_ITEM.replace('{companyId}', fullInfo.cid), {
            params: { pageSize: 9999 }
        })
            .then(value => {
                let data = [];
                value.data.data.map(item => {
                    if (item.active) {
                        data.push({
                            price: item.price,
                            title: item.description,
                            taxable: item.taxable,
                            id: item.id,
                            companyPriceItemId: item.companyPriceItemId,
                        })
                    }
                })
                setLineItem(data);
            })
        getTemplate()
        getDiscountTypes()
        let content = document.getElementById('content')
        content.addEventListener('scroll', (e) => {
            let CardContent = document.getElementById('CardContent')
            if (CardContent) {
                let h = CardContent.clientHeight
                if (h > (minHeight || 0)) {
                    setMinHeight(h);
                }
                e.preventDefault();
            }
        })
        return () => {
            clearInterval(timer.current);
        };
    }, [])

    return (
        <>
            <Card>
                <CardHeader title='Price Details'
                    action={sm && (expand ? <KeyboardArrowUp /> : <KeyboardArrowDown />)}
                    onClick={() => sm && setExpand(!expand)} />
                <Divider />
                <Collapse in={!sm || expand} timeout='auto' unmountOnExit>
                    <CardContent id={'CardContent'} style={{ minHeight: minHeight }} >
                        <Grid container spacing={2}>
                            <Grid style={ipad ? { flexDirection: 'column' } : null}
                                item container justifyContent='center' alignItems='center' className={classes.templateBox}>
                                <div style={{ flex: 1 }}></div>
                                <div className={classes.template}>
                                    <FormControl variant="outlined" style={{ minWidth: '200px' }} className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-filled-label">Template</InputLabel>
                                        <Select
                                            disabled={disabled}
                                            onClose={() => {
                                                setSelectedTemplate()
                                            }}
                                            onOpen={() => {
                                                setClosing(false);
                                                setScrollTop(document.getElementById('content').scrollTop);
                                            }}
                                            label="Template"
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={tem || ' '}
                                            onChange={e => {
                                                setTem(e.target.value);
                                                clearInterval(timer.current);
                                                if (e.target.value !== ' ') {
                                                    loadTemplate(e.target.value);
                                                } else {
                                                    const data = JSON.parse(sessionStorage.getItem('sw-information'));
                                                    if (data) {
                                                        window.localStorage.setItem('list_origin_template', JSON.stringify(data.jobItems))
                                                        onAddItemTemplate(data.jobItems)
                                                        onChangeTemplate(null)
                                                    }
                                                }
                                            }}>

                                            <MenuItem value=" " className={tem.trim() === "" ? classes.selected : classes.formControlItem}>
                                                <em>None</em>
                                            </MenuItem>
                                            {listTemplate && listTemplate.map(item => (
                                                <MenuItem disabled={disabled}
                                                    onMouseOver={(e) => {
                                                        if (closing) return;
                                                        let value = e.target.attributes['data-value'].value;
                                                        changeDelay(() => loadTempTemplate(value));

                                                    }}
                                                    onClick={(e) => {
                                                        clearInterval(timer.current);
                                                        setSelected(e.target.attributes['data-value'].value)
                                                    }}
                                                    key={item.id}
                                                    value={item.id}
                                                    className={item.id === tem ? classes.selected : classes.formControlItem}
                                                >
                                                    {item.templateName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <CardActions className={classes.templateActions}>
                                        <Button className={disabled ? classes.btnDis : classes.btn}
                                            onClick={saveTemplate}>
                                            Save template
                                        </Button>
                                        {isSavingTemplate && <CircularProgress size={30} />}


                                        <IconButton className={(disabled || !tem.trim()) ? classes.btnDis : classes.iconBtn}
                                            style={disabled ? { backgroundColor: '#fff' } : null}
                                            onClick={() => setIsRemove(true)}>
                                            <DeleteIcon />
                                        </IconButton>
                                        {isSaving && <CircularProgress size={30} />}
                                    </CardActions>
                                </div>
                                <FormControlLabel
                                    style={ipad ? { position: 'unset' } : null}
                                    disabled={disabled}
                                    className={classes.checkbox}
                                    name='displayOnPrintOutsEmails'
                                    checked={displayOnPrintOutsEmails || false}
                                    onChange={onChange}
                                    control={
                                        <GreenCheckbox />
                                    }
                                    label='Display Line Items on Print-Outs & Email'
                                />
                            </Grid>
                            {/* prices table */}
                            <Hidden xsDown>
                                <Grid item xs={12}>
                                    <TableContainer>
                                        <Table className={classes.table}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell onClick={() => loadDocument('Quantity')} style={{ width: '10%' }} align='center'
                                                        className={classes.tableHead}><span>Quantity{ getSortIcon('Quantity')}</span></TableCell>
                                                    <TableCell onClick={() => loadDocument('Description')} align='center'
                                                        className={classes.tableHead}><span>Description{ getSortIcon('Description')}</span></TableCell>
                                                    <TableCell onClick={() => loadDocument('Price')} style={{ width: '15%' }} align='center'
                                                        className={classes.tableHead}><span>Price{ getSortIcon('Price')}</span></TableCell>
                                                    <TableCell onClick={() => loadDocument('Total')} style={{ width: '15%' }} align='center'
                                                        className={classes.tableHead}><span>Total{ getSortIcon('Total')}</span></TableCell>
                                                    <TableCell onClick={() => loadDocument('Taxable')} style={{ width: '6%' }} align='center'
                                                        className={classes.tableHead}><span>Taxable{ getSortIcon('Taxable')}</span></TableCell>
                                                    <TableCell onClick={() => loadDocument('Upsale')} style={{ width: '6%' }} align='center'
                                                        className={classes.tableHead}><span>Upsale{ getSortIcon('Upsale')}</span></TableCell>
                                                    <TableCell  style={{ width: '9%' }} align='right'
                                                        className={classes.tableHead}></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className={classes.tableBody}>
                                                {Array.isArray(jobItems) && jobItems.map((item, index) => (
                                                    <PriceLine
                                                        postedToQuickbooks={postedToQuickbooks}
                                                        errors={errors}
                                                        touched={touched}
                                                        indexItem={index}
                                                        index={index}
                                                        disabled={disabled}
                                                        lineItem={lineItem}
                                                        key={index}
                                                        item={item}
                                                        length={jobItems.length - 1}
                                                        onChangeItem={e => {
                                                            onChangeItem(e, index)
                                                            setChangeType('discountPercent')
                                                        }}
                                                        onCopyItem={() => onCopyItem(item, index)}
                                                        onRemoveItem={() => onRemoveItem(index)}
                                                        onChangeDesc={(value, price, taxable, companyPriceItemId) => onChangeDesc(value, index, price, taxable, companyPriceItemId)}
                                                        onDirty={dirty => onDirty(dirty, index)}
                                                    />
                                                ))}
                                            </TableBody>
                                            <TableFooter>
                                                <TableCell colSpan={7} align='right'>
                                                    <Tooltip title='Add New' arrow>
                                                        <IconButton className={disabled || postedToQuickbooks ? classes.btnDis : classes.addStyle} onClick={() => onAddItem()}
                                                            style={disabled ? { backgroundColor: '#fff' } : null}>
                                                            <AddBoxIcon fontSize='large' />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableFooter>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Hidden>
                            <Hidden smUp>
                                <Grid item xs={12}>
                                    {Array.isArray(jobItems) && jobItems.map((item, index) => (
                                        <PriceLineMobile
                                            postedToQuickbooks={postedToQuickbooks}
                                            errors={errors}
                                            touched={touched}
                                            indexItem={index}
                                            index={index}
                                            disabled={disabled}
                                            lineItem={lineItem}
                                            key={index}
                                            item={item}
                                            length={jobItems.length - 1}
                                            onChangeItem={e => {
                                                onChangeItem(e, index)
                                                setChangeType('discountPercent')
                                            }}
                                            onCopyItem={() => onCopyItem(item, index)}
                                            onRemoveItem={() => onRemoveItem(index)}
                                            onChangeDesc={(value, price, taxable, companyPriceItemId) => onChangeDesc(value, index, price, taxable, companyPriceItemId)}
                                            onDirty={dirty => onDirty(dirty, index)}
                                        />
                                    ))}
                                </Grid>
                                <Grid item xs={12} style={{ textAlign: 'right' }}>
                                    <Divider style={{ marginBottom: 16 }} />
                                    <Button
                                        variant="contained"
                                        className={disabled || postedToQuickbooks ? classes.btnDis : classes.addStyle}
                                        onClick={() => onAddItem()}
                                        style={disabled ? { backgroundColor: '#fff' } : null}
                                        startIcon={<AddBoxIcon />}>
                                        Add New Item
                                    </Button>
                                </Grid>
                            </Hidden>
                            <Grid style={{ margin: 'auto' }} item xs={12} md={6} container spacing={2}>
                                <Grid item xs={12} sm={3}>
                                    <NumberFormat
                                        customInput={TextField}
                                        allowNegative={false}
                                        prefix='$'
                                        thousandSeparator
                                        fullWidth
                                        disabled
                                        label='Total Price'
                                        name='totalPrice'
                                        variant='outlined'
                                        value={parseFloat(total).toFixed(2) || ''}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        fullWidth
                                        disabled={disabled}
                                        label='Discount Type'
                                        name='discountTypeId'
                                        select
                                        SelectProps={{ native: true }}
                                        variant='outlined'
                                        value={(discountTypes && discountTypeId) || ''}
                                        onChange={e => onChangeDiscountType(e.target.value)}>
                                        <option value=''></option>
                                        {discountTypes && discountTypes.map(item => (
                                            <option
                                                key={item.id}
                                                value={item.id}
                                            >
                                                {item.description}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <NumberFormat
                                        disabled={disabled} // || !discountTypeId
                                        customInput={TextField}
                                        allowNegative={false}
                                        suffix='%'
                                        isAllowed={values => {
                                            return !values.floatValue || values.floatValue <= 100
                                        }}
                                        fullWidth
                                        label='Discount %'
                                        name='discountPercent'
                                        variant='outlined'
                                        value={discountPercent || ''}
                                        onChange={(e) => {
                                            onChange(e);
                                            setChangeType('discountPercent')
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <NumberFormat
                                        disabled={disabled} // || !discountTypeId
                                        customInput={TextField}
                                        allowNegative={false}
                                        prefix='$'
                                        thousandSeparator
                                        fullWidth
                                        onChange={(e) => {
                                            setChangeType('discountDollars')
                                            onChange(e);
                                        }}
                                        label='Discount $'
                                        name='discountDollars'
                                        variant='outlined'
                                        value={parseFloat(discountDollars).toFixed(2) || ''}
                                    />
                                </Grid>
                            
                                <Grid item xs={12} sm={3}>
                                    <NumberFormat
                                        customInput={TextField}
                                        allowNegative={false}
                                        prefix='$'
                                        thousandSeparator
                                        fullWidth
                                        disabled
                                        label='Subtotal'
                                        name='subtotal'
                                        variant='outlined'
                                        value={subtotal || ''}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <NumberFormat
                                        customInput={TextField}
                                        allowNegative={false}
                                        suffix='%'
                                        isAllowed={values => {
                                            return !values.floatValue || values.floatValue <= 100
                                        }}
                                        fullWidth
                                        disabled
                                        label='Tax'
                                        name='taxPercent'
                                        variant='outlined'
                                        value={taxPercent || '0'}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <NumberFormat
                                        customInput={TextField}
                                        allowNegative={false}
                                        prefix='$'
                                        fullWidth
                                        disabled={disabled}
                                        thousandSeparator
                                        decimalScale={2}
                                        label='Tip'
                                        name='tip'
                                        variant='outlined'
                                        value={tip || ''}
                                        onChange={(e) => updateTip(e)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <NumberFormat
                                        customInput={TextField}
                                        allowNegative={false}
                                        prefix='$'
                                        thousandSeparator
                                        fullWidth
                                        disabled
                                        label='Total Due'
                                        name='total'
                                        variant='outlined'
                                        value={_total || ''}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Collapse>
                <ConfirmTemplate
                    template={tem}
                    openConfirm={open}
                    closeConfirm={() => setOpen(false)}
                    onConfirm={(tem) => {
                        setOpen(false);
                        onSaveTemplate(tem)
                    }}
                />
                <ConfirmModal
                    openConfirm={isRemove}
                    closeConfirm={() => setIsRemove(false)}
                    onConfirm={() => {
                        setIsRemove(false)
                        deleteTemplate(tem)
                    }}
                />
            </Card>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={() => setOpenSnackBar(false)}>
                <Alert elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    )
}

PriceDetails.propTypes = {
    jobId: PropTypes.string,
    jobItems: PropTypes.arrayOf(Object),
    discountPercent: PropTypes.number,
    discountType: PropTypes.string,
    taxPercent: PropTypes.number,
    onChange: PropTypes.func,
    onChangeDiscountPrice: PropTypes.func,
    onChangeDiscountType: PropTypes.func,
    onAddItem: PropTypes.func,
    onChangeItem: PropTypes.func,
    onCopyItem: PropTypes.func,
    onRemoveItem: PropTypes.func,
    onChangeDesc: PropTypes.func
}

export default PriceDetails;
