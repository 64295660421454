import jwt_decode from "jwt-decode";

const getToken = () => {
  const usrData = JSON.parse(localStorage.getItem("sw-token"));
  if (
    !usrData ||
    !usrData.token ||
    usrData.expiresOn <= Math.floor(new Date().getTime() / 1000)
  ) {
    return null;
  }
  return "Bearer " + usrData.token;
};

const getUserRole = () => {
  const usrData = JSON.parse(localStorage.getItem("sw-role"));
  const parsedUserData = JSON.parse(usrData);
  if (parsedUserData) {
    return parsedUserData[0];
  } else {
    return null;
  }
};

const getUserRoles = () => {
  const usrData = JSON.parse(localStorage.getItem("sw-role"));
  const parsedUserData = JSON.parse(usrData);
  if (parsedUserData) {
    return parsedUserData;
  } else {
    return [];
  }
};

const getUserInfo = () => {
  const usrData = JSON.parse(localStorage.getItem("sw-usr"));
  if (usrData) {
    return usrData;
  } else {
    return null;
  }
};

const getFullInfo = () => {
  const usrData = JSON.parse(localStorage.getItem("sw-token"));
  const info = usrData ? jwt_decode(usrData.token) : null;
  return info;
};

const getCompanyId = () => {
  return localStorage.getItem("companyId");
};

const getListCompany = () => {
  const listCompany = JSON.parse(localStorage.getItem('listCompany'));
  if (listCompany) {
    return listCompany;
  } else {
    return null;
  }
};

const set = (key, value) => {
  return localStorage.setItem(key, value);
};
const getItem = (key) => {
  return localStorage.getItem(key);
};
const removeItem = (key) => {
  return localStorage.removeItem(key);
}

const clearAll = () => {
  return localStorage.clear();
};

export default {
  getToken,
  getUserInfo,
  getUserRole,
  getUserRoles,
  getFullInfo,
  getCompanyId,
  getListCompany,
  set,
  getItem,
  removeItem,
  clearAll
};
