import React from 'react';
import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {ReactPlugin, withAITracking} from '@microsoft/applicationinsights-react-js';
import {createBrowserHistory} from "history";

const browserHistory = createBrowserHistory({basename: ''});
var reactPlugin = new ReactPlugin();
export const getConnectionString = () => {
    switch (process.env.REACT_APP_ENV) {
        case "dev": {
            return 'InstrumentationKey=dc29bc9c-bbcd-4a51-8e7a-454b4af1c43a;IngestionEndpoint=https://centralus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://centralus.livediagnostics.monitor.azure.com';
        }
        case "demo": {
            return 'InstrumentationKey=aa94041b-1cbb-4661-b056-d2923f8888f2;IngestionEndpoint=https://centralus-2.in.applicationinsights.azure.com/;LiveEndpoint=https://centralus.livediagnostics.monitor.azure.com';
        }
        case "prod": {
            return 'InstrumentationKey=666d10de-e862-4757-8e59-0a6a3b822f33;IngestionEndpoint=https://centralus-2.in.applicationinsights.azure.com/;LiveEndpoint=https://centralus.livediagnostics.monitor.azure.com';
        }
        case "local": {
            return 'InstrumentationKey=dc29bc9c-bbcd-4a51-8e7a-454b4af1c43a;IngestionEndpoint=https://centralus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://centralus.livediagnostics.monitor.azure.com';
        }
        default:
            break;
    }
};
var appInsights = new ApplicationInsights({
    config: {
        connectionString: getConnectionString(),
        disableAjaxTracking: false,
        disableFetchTracking: false,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: {history: browserHistory}
        }
    }
});
appInsights.loadAppInsights();
appInsights.trackPageView();
export {reactPlugin, appInsights}
