import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { EmployeeToolbar, EmployeeCards } from './components';
import axios from 'utils/axios';
import apiConfig from 'apiConfig';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid, Typography } from '@material-ui/core';
import { Page } from 'components'
import { useSelector } from 'react-redux'
import localStorage from 'utils/localStorage';
import CompanySettingModel from './components/CompanySettingModel';
import qs from 'query-string';

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(3)
	},
	content: {
		marginTop: theme.spacing(2)
	},
	fab: {
		position: 'fixed',
		bottom: theme.spacing(4),
		right: theme.spacing(4),
		backgroundColor: '#43a047',
		'&:hover': {
			backgroundColor: '#1b5e20'
		},
	},
	circularProgress: {
		marginTop: '30px',
	},
	noData: {
		textAlign: 'center',
		fontSize: theme.spacing(2),
		fontWeight: 500
	},
}));

let forcePage = 0;
let pageNumber = 1;

const useSearchDelay = (val, delay) => {
	delay = delay || 500;
	const [debounced, setDebounced] = useState(val);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebounced(val)
		}, delay)

		return () => clearTimeout(handler)
	}, [val, delay])

	return debounced;
}

const EmployeeList = ({ history }) => {

	const classes = useStyles();
	const isMounted = useRef(false);
	const companyId = localStorage.getCompanyId();

	const { employees } = useSelector(state => state.ReloadReducer);

	const [companyInfo, setCompanyInfo] = useState();
	const [openCompanySettingModel, setOpenCompanySettingModel] = useState(false);

	const [usersData, setUsersData] = useState();
	const [loading, setLoading] = useState();
	const [value, setValue] = useState('');

	let searchQuery = useSearchDelay(value);

	const fetchUsers = () => {
		let url = apiConfig.url.USER_URL + apiConfig.url.EMPLOYEES + pageNumber;
		if (searchQuery) {
			url += '&query=' + searchQuery;
		}
		setLoading(true);
		axios.get(url)
			.then(res => {
				const activeUser = res.data.users.filter(x => x.termDate).sort((a, b) => a.fullName.localeCompare(b.fullName))
				const termUser = res.data.users.filter(x => !x.termDate).sort((a, b) => a.fullName.localeCompare(b.fullName))
				setUsersData({ ...res.data, users: [...termUser, ...activeUser] })
			})
			.finally(() => setLoading(false))
	};

	const onPageChange = (page) => {
		forcePage = page.selected;
		pageNumber = page.selected + 1;
		fetchUsers();
	};

	const onSearch = () => {
		if (searchQuery) {
			history.push({ pathname: "/search", search: qs.stringify({ query: searchQuery, type: 'employee' }) });
		}
	};
	const onClear = () => {
		forcePage = 0;
		pageNumber = 1;
		setValue('');
	}
	const onChangeText = event => {
		setValue(event.target.value)
	}

	const checkCompanySetting = () => {
		const { periodStartDate, periodDuration, periodOvertimeAfter } = companyInfo;
		if (!periodStartDate || !periodDuration || !periodOvertimeAfter) {
			setOpenCompanySettingModel(true);
		} else {
			history.push("/employees/add-user");
		}
	}

	useEffect(() => {
		onClear()
	}, [employees])

	useEffect(() => {
		isMounted.current = true;

		if (isMounted.current) {
			onSearch();
		}

		return () => {
			isMounted.current = false;
		};
	}, [searchQuery]);

	useEffect(() => {
		isMounted.current = true;
		if (isMounted.current) {
			axios.get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_INFO + companyId)
				.then(res => setCompanyInfo(res.data))

			fetchUsers();
		}

		return () => isMounted.current = false
	}, [])

	if (!usersData || !companyInfo) {
		return (
			<Page title='Employees'>
				<Grid
					container
					spacing={0}
					align="center"
					justifyContent="center"
					direction="column">
					<Grid item>
						<CircularProgress className={classes.circularProgress} size={50} />
					</Grid>
				</Grid>
			</Page>
		)
	}

	return (
		<Page title="Employees">
			<div className={classes.root}>
				<EmployeeToolbar
					onSearch={onSearch}
					onClear={onClear}
					value={value}
					onChange={onChangeText}
				/>

				<CompanySettingModel
					open={openCompanySettingModel}
					onClose={() => setOpenCompanySettingModel(false)}
					company={companyInfo}
				/>

				{loading ?
					<Grid
						container
						spacing={0}
						align="center"
						justifyContent="center"
						direction="column">
						<Grid item>
							<CircularProgress className={classes.circularProgress} size={30} />
						</Grid>
					</Grid>
					:
					(
						<div className={classes.content}>
							{usersData.users && usersData.users.length > 0 ? (
								<EmployeeCards usersData={usersData} forcePage={forcePage} onPageChange={onPageChange} />
							) : (
								<Grid>
									<Typography className={classes.noData}>No data found.</Typography>
								</Grid>
							)}

							<a onClick={checkCompanySetting}>
								<Fab className={classes.fab}
									 color="secondary">
									<AddIcon />
								</Fab>
							</a>
						</div>
					)
				}
			</div>
		</Page>
	);

};

export default EmployeeList;
