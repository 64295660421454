import React, {useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {makeStyles} from '@material-ui/styles';
import {
    Card,
    CardHeader,
    Divider,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow, colors,
    TableContainer,
    Typography, IconButton
} from '@material-ui/core';
import {ConfirmModal, Label} from 'components';
import DeleteIcon from "@material-ui/icons/Delete";
import axios from "../../../../../utils/axios";
import apiConfig from "../../../../../apiConfig";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import EditIcon from "@material-ui/icons/Edit";
import { AddBox } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    root: {},
    option: {
        border: `1px solid ${theme.palette.divider}`,
        display: 'flex',
        alignItems: 'flex-start',
        padding: theme.spacing(2),
        maxWidth: 560,
        '& + &': {
            marginTop: theme.spacing(2)
        }
    },
    optionRadio: {
        margin: -10
    },
    optionDetails: {
        marginLeft: theme.spacing(2)
    },
    cusFontsize: {
        '& th': {
            fontSize: '15px'
        }
    },
    responsiveTable: {
        width: '100%',
        overflowY: 'auto'
    },
    addStyle: {
        '& span svg': {
            color: colors.green[600],
            '&:hover': {
                color: colors.green[900]
            }
        }
    }
}));

const CompensationHistory = props => {

    const {userId, salaryHistory, onEdit, reload, className,handleEditOpen, ...rest} = props;
    const classes = useStyles();
    const [locateFile, setLocateFile] = useState();
    const [openConfirm, setOpenConfirm] = useState(false);
    const [isProgress, setIsProgress] = useState(false);
    const [isStatus, setIsStatus] = useState({failed: false, msg: ''});
    const [openSnackbar, setOpenSnackBar] = useState(false);

    const editHistory = (item) => {
        onEdit(item)
    }

    const removeHistory = () => {
        setIsProgress(true);
        axios.delete(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_COMPENSATION_HISTORY_DELETE + locateFile.id)
            .then(res => {
                setIsStatus({failed: false, msg: 'Remove files success.'});
                setOpenSnackBar(true);
                reload();
            })
            .catch(err => {
                setIsStatus({failed: true, msg: 'Remove failed, please try again later.'})
                setOpenSnackBar(true);
            })
            .finally(() => {
                setIsProgress(false);
                setOpenConfirm(false);
            })
    }

    if (!salaryHistory) {
        return (
            <Card>
                <CardHeader title='Compensation'/>
                <Divider/>
                <CardContent>
                    <Typography style={{fontSize: '16px'}}>No data found.</Typography>
                </CardContent>
            </Card>
        );
    }
    return (

        <Card
            {...rest}
            className={clsx(classes.root, className)}>
            <CardHeader title="Compensation"/>
            <Divider/>
            <CardContent className={classes.responsiveTable}>
                {/* <PerfectScrollbar> */}
                    {/* <TableContainer> */}
                        <Table>
                            <TableHead>
                                <TableRow className={classes.cusFontsize}>

                                    <TableCell
                                        style={{borderBottom: '1px solid #969696', width: '12.8%'}}>Weekly Salary</TableCell>
                                    <TableCell
                                        style={{borderBottom: '1px solid #969696', width: '12.8%'}}>Hourly</TableCell>
                                    <TableCell style={{borderBottom: '1px solid #969696', width: '12.8%'}}>Labor
                                        Commission</TableCell>
                                    <TableCell style={{borderBottom: '1px solid #969696', width: '12.8%'}}>
                                        Sales Commission <br/> New Customer</TableCell>
                                    <TableCell style={{borderBottom: '1px solid #969696', width: '12.8%'}}>Sales
                                        Commission <br/> Returning Customer</TableCell>
                                    <TableCell style={{borderBottom: '1px solid #969696', width: '12.8%'}}>Upsales
                                        Commission</TableCell>
                                    {/*<TableCell style={{ borderBottom: '1px solid #969696' }}>Type</TableCell>*/}
                                    {/*<TableCell style={{ borderBottom: '1px solid #969696', width: '12.8%' }}>Active</TableCell>*/}
                                    <TableCell style={{borderBottom: '1px solid #969696', width: '12.8%'}}>Start
                                        Date</TableCell>
                                    <TableCell style={{borderBottom: '1px solid #969696', width: '12.8%'}}>End
                                        Date</TableCell>
                                    <TableCell style={{borderBottom: '1px solid #969696', textAlign: 'center' }}>
                                        <IconButton onClick={handleEditOpen} className={classes.addStyle}>
                                            <AddBox fontSize='large' />
                                        </IconButton>
                                    </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {salaryHistory.map((item, index) => (
                                    <TableRow
                                        className={classes.tableRow}
                                        hover
                                        key={item.id}>

                                        <TableCell>{item.formattedSalary.replace('N/A', '')}</TableCell>
                                        <TableCell>{item.formattedHourly.replace('N/A', '')}</TableCell>
                                        <TableCell>{item.formattedLaborPercent.replace('N/A', '')}</TableCell>
                                        <TableCell>{item.formattedSalePercentNewSale.replace('N/A', '')}</TableCell>
                                        <TableCell>{item.formattedSalePercentRetSale.replace('N/A', '')}</TableCell>
                                        <TableCell>{item.formattedUpsalePercent.replace('N/A', '')}</TableCell>
                                        {/*<TableCell>{item.type}</TableCell>*/}

                                        <TableCell>{item.startDate}</TableCell>
                                        <TableCell className={classes.statusCell}>
                                            {
                                                item.endDate
                                                    ?
                                                    <Label color={
                                                        new Date(item.endDate) < new Date().setHours(0, 0, 0, 0) ?
                                                            colors.grey[600] :
                                                            (new Date(item.startDate) > new Date().setHours(0, 0, 0, 0) ? colors.blue[600] : colors.green[600])
                                                    }>
                                                        {item.endDate}
                                                    </Label>
                                                    :
                                                    (
                                                        new Date(item.startDate) > new Date().setHours(0, 0, 0, 0) ?
                                                            <Label color={colors.blue[600]}>
                                                                Pending
                                                            </Label>
                                                            :
                                                            <Label color={colors.green[600]}>
                                                                Current
                                                            </Label>
                                                    )

                                            }
                                        </TableCell>
                                        <TableCell style={{whiteSpace: 'nowrap'}}>
                                            <IconButton
                                                // disabled={item.endDate && new Date(item.endDate) < new Date().setHours(0, 0, 0, 0)}
                                                onClick={() => {
                                                    editHistory(item);
                                                }}>
                                                <EditIcon/>
                                            </IconButton>
                                            <IconButton
                                                disabled={item.startDate && new Date(item.startDate) <= new Date().setHours(0, 0, 0, 0)}
                                                aria-label="delete" onClick={() => {
                                                setLocateFile({index: index, id: item.id});
                                                setOpenConfirm(true)
                                            }}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    {/* </TableContainer> */}
                {/* </PerfectScrollbar> */}
            </CardContent>
            <ConfirmModal
                openConfirm={openConfirm}
                closeConfirm={() => setOpenConfirm(false)}
                onConfirm={removeHistory}
                isProgress={isProgress}
            />
            <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                      open={openSnackbar}
                      autoHideDuration={5000}
                      onClose={() => setOpenSnackBar(false)}>
                <Alert elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </Card>
    );
};

CompensationHistory.propTypes = {
    className: PropTypes.string,
    userId: PropTypes.string.isRequired
};

export default CompensationHistory;
