import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { ListItem, Button, Collapse, colors, Tooltip, MuiThemeProvider, createTheme, useMediaQuery, Popper, Fade, Box } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const NavigationRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

const customThemeTooltip = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "14px",
        maxWidth: "none"
      }
    }
  }
});

const useStyles = makeStyles(theme => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: colors.blueGrey[800],
    padding: '0px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%'
  },
  buttonLeaf: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },

  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },

  expandIcon: {
    marginLeft: 'auto',
    height: 16,
    width: 16
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto'
  },
  active: {
    color: '#134ADB',
    '& span svg': {
      color: '#134ADB'
    }
  },
  popper: {
    [theme.breakpoints.up("lg")]: {
      width: 200,
      backgroundColor: 'white',
      borderRadius: 4,
      boxShadow: '0 6px 12px rgba(0, 0, 0, .132)',
      zIndex: 1100,
      left: '17px !important'
    }
  },
  wrapIcon: {
    paddingTop: 10, paddingBottom: 10
  },
  wrapTitle: {
    paddingTop: 10, width: '80%', paddingBottom: 10, textAlign: 'left'
  },
  wrapExpanIcon: {
    paddingTop: 10, textAlign: 'right', marginRight: 5
  }
}));

const NavigationListItem = props => {
  const {
    title,
    href,
    depth,
    children,
    icon: Icon,
    className,
    expandable,
    isOpen,
    openDrawer,
    handleToggle,
    handleOpenSubmenu,
    handleCloseSubmenu,
    label: Label,
    onClose,
    handleToggleAndRedirect,
    ...rest
  } = props;

  const classes = useStyles();
  const { pathname } = useLocation();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  const listItem = useRef();

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = {
    minWidth: 'unset'
  };

  if (openDrawer) style.paddingLeft = paddingLeft;
  else style.padding = 10;

  const [active, setActive] = useState('');
  const [isManageEmployees, setIsManageEmployees] = useState(false);
  const [isPayrollDetail, setIsPayrollDetail] = useState(false);

  const hasActiveChild = () => {
    if (!children) return false;
    return children.props.pages?.map(x => x.href).includes(active);
  }

  useEffect(() => {
    const hrefArr = href ? href.split('/') : '';
    setIsPayrollDetail(hrefArr && hrefArr[2] === 'payroll-detail');
    const [emp, type, tab] = pathname.split('/');
    if (type === 'employees') {
      if (pathname.split('/').length === 5 || tab === 'add-user' || tab === undefined) {
        setActive('/employees');
        setIsPayrollDetail(false);
      } else if (tab === 'payroll-detail') {
        setActive('/employees/payroll-list');
      } else {
        setIsPayrollDetail(false);
        setActive(`/employees/${tab}`)
      }
    } else if (type === 'customers') {
      if (pathname.split('/').length > 4 || tab === 'add-customer') {
        setActive('/customers/allcustomers')
      } else {
        setActive(`/customers/${tab}`)
      }
    } else if (type === 'schedules') {
      setActive('/schedules')
    } else if (type === 'admin') {
        setActive(`/admin`) 
        if (tab === 'communication-center') {
        setActive(`/admin/communication-center`) 
      }
      if (tab === 'custom-forms') {
        setActive(`/admin/custom-forms`) 
      }
    } else {
      setActive(pathname)
    }
    setIsManageEmployees(hrefArr.length === pathname.split('/').length && hrefArr.length === 5);
  }, [pathname])

  if (children) {
    return (
      <ListItem
        {...rest}
        ref={listItem}
        className={clsx(classes.item, className)}
        disableGutters
        onMouseEnter={!openDrawer ? handleOpenSubmenu : null}
        onMouseLeave={!openDrawer ? handleCloseSubmenu : null}
      >
        <Button
          className={clsx(classes.button, !openDrawer && hasActiveChild() ? classes.active : '')}
          onClick={openDrawer && title !== "Customers" && title !== "Employees" ? handleToggle : null}
          style={style}
        >
          {
            openDrawer || !isDesktop
              ? <>
                <div className={classes.wrapIcon} onClick={openDrawer && (title === "Customers" || title === "Employees") ? handleToggleAndRedirect : null}>
                  {Icon && <Icon className={classes.icon} style={!openDrawer ? { marginRight: 0 } : null} />}
                </div>
                <div className={classes.wrapTitle} onClick={openDrawer && (title === "Customers" || title === "Employees") ? handleToggleAndRedirect : null}>{title}</div>
                <div className={classes.wrapExpanIcon} onClick={(title === "Customers" || title === "Employees") ? handleToggle : null}>
                  {expandable ? (isOpen ? (
                    <ExpandLessIcon
                      className={classes.expandIcon}
                      color="inherit"
                    />
                  ) : (
                    <ExpandMoreIcon
                      className={classes.expandIcon}
                      color="inherit"
                    />
                  )) : null}
                </div>
              </>
              : Icon && <Icon className={classes.icon} style={!openDrawer ? { marginRight: 0 } : null} />
          }
        </Button>
        {
          openDrawer || !isDesktop
            ? <Collapse in={isOpen}>{children}</Collapse>
            : <Popper
                open={isOpen}
                anchorEl={listItem.current}
                placement='right-start'
                className={classes.popper}
                transition
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={100}>
                    <Box>{children}</Box>
                  </Fade>
                )}
              </Popper>
        }
      </ListItem>
    );
  } else {
    return (
      <ListItem
        {...rest}
        className={clsx(classes.itemLeaf, className)}
        disableGutters
      >
        <Button
          className={`${classes.buttonLeaf} ${(href === active || isManageEmployees || isPayrollDetail) ? classes.active : ''}`}
          component={NavigationRouterLink}
          exact
          style={style}
          to={href}
          onClick={!isDesktop ? onClose : null}
        >
          {
            openDrawer || depth > 0 || !isDesktop
              ? <>
                {Icon && <Icon className={classes.icon} style={!openDrawer ? { marginRight: 0 } : null} />}
                {title}
              </>
              : Icon && <MuiThemeProvider theme={customThemeTooltip}>
                  <Tooltip arrow placement="right" title={title}>
                    <Icon className={classes.icon} style={!openDrawer ? { marginRight: 0 } : null} />
                  </Tooltip>
                </MuiThemeProvider>
          }
          {Label && (
            <span className={classes.label}>
              <Label />
            </span>
          )}
        </Button>
      </ListItem>
    );
  }
};

NavigationListItem.defaultProps = {
  depth: 0,
  isOpen: false,
  handleToggle: () => { },
  handleToggleAndRedirect: () => { },
  // closeAllItem: () => { },
};

export default NavigationListItem;
