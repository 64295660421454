import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Typography, Tooltip, colors } from "@material-ui/core";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { useDrag } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import RoomIcon from "@material-ui/icons/Room";
import { values } from "underscore";
import { AddressLink } from "components";

const useStyles = makeStyles((theme) => ({
  dragJob: {
    padding: 8,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    position: "relative",
    borderBottom: "1px solid #cbcbcb",
    "& p": {
      whiteSpace: "nowrap",
      width: "100%",
      overflowX: "scroll",
      "-ms-overflow-style": "none", // IE and Edge
      scrollbarWidth: "none", // Firefox
      "&::-webkit-scrollbar": {
        display: "none", // Chrome, Safari and Opera
      },
    },
    "&:hover": {
      "& p": {
        color: theme.palette.white,
      },
      "& .employee": {
        backgroundColor: theme.palette.white,
        "& p": {
          color: "#263238",
        },
      },
      "& .job": {
        backgroundColor: colors.green[500],
        color: theme.palette.white,
        "& p": {
          color: "#263238",
        },
      },
      backgroundColor: colors.green[500],
    },
  },
  buttons: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    backgroundColor: colors.green[500],
    "& p": {
      color: theme.palette.white,
    },
  },
  jobLink: {
    display: "inline-block",
    height: 20,
    padding: 0,
    color: "#263238",
    background: "transparent",
    "& i": {
      color: colors.green[500],
    },
  },
  jobMap: {
    display: "inline-block",
    height: 23,
    marginLeft: "auto",
    color: "#263238",
    background: "transparent",
    "& i": {
      color: colors.green[500],
    },
  },
  data: {
    display: "block",
    lineHeight: "20px",
  },
  addressLink: {
    color: 'white',
    fontSize: 16
  }
}));

const customThemeTooltip = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "14px",
        maxWidth: "none",
      },
    },
  },
});

const DraggableSource = (props) => {
  const classes = useStyles();
  const selectedRef = useRef(null);
  const {
    isSelected,
    type,
    source,
    changeSelected,
    locateInRoute,
    setDrag,
    date,
  } = props;

  const dispatch = useDispatch();

  const { routes } = useSelector((state) => state.RoutesReducer);

  useEffect(() => {
    setTimeout(function(){
      selectedRef && 
      selectedRef.current && 
      selectedRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
    },0);
  }, [selectedRef]);

  const [{ isDragging }, drag] = useDrag({
    item: {
      id: source.jobSplitId || source.employeeId || source.id,
      hours: type === "Job" ? source.estimatedHours : null,
      type: type,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging() ? true : false,
    }),
  });

  const fullName = `${source.firstName || ""}${
    source.lastName ? ` ${source.lastName}` : " "
  }`.replace("N/A", "");

  const getValue = () => {
    const values = [];
    if (type === "Employee") {
      let days = [];
      source.dayOff.forEach((day) => {
        days.push(
          `${moment(day.start).format("hh:mm A")} - ${moment(day.end).format(
            "hh:mm A"
          )}`
        );
      });
      const dayString =
        days.length > 0 ? `|Unavailable: ${days.join(", ")}` : "";
      const phone =
        source.phones && source.phones[0]
          ? ", " + source.phones[0].formattedPhoneNumber
          : source.phoneNumber
          ? ", " + source.phoneNumber
          : "";
      const day = moment(date)
        .format("dddd")
        .toLowerCase();
      const availabilities =
        source.availabilities && source.availabilities[day];
      let availabilitiesString = "";
      if (
        availabilities &&
        availabilities.isChecked &&
        availabilities.comment
      ) {
        availabilitiesString = `|${availabilities.comment}`;
      }
      values.push(`${fullName}${phone}${dayString}${availabilitiesString}`);
    }
    if (type === "Job") {
      const idenSplits =
        source.totalSplitJobs > 1 ? `  (1/${source.totalSplitJobs})` : "";
      const infor = `${source.customerName}, ${
        source.job.estimatedHours
      } hours, $${
        source.price ? source.price.toFixed(2) : "0.00"
      }${idenSplits}`;
      const city = `${source.address1 || ""}`;
      const address = `${source.city || ""}, ${source.state ||
        ""}, ${source.zipCode || ""}`;

      infor && values.push(infor);
      city && values.push(city);
      address && values.push(address);
    }
    if (type === "Equipment") {
      values.push(source.assetTag);
    }

    return values;
  };

  const handleOnDrag = (event) => {
    if (window.innerHeight - event.clientY <= 50) {
      const routeContainer = document.getElementById("content");
      routeContainer.scrollTop += 15;
    }
  };

  const getTooltip = () => {
        if (isDragging) return '';
    return (
      <>
        <Typography style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>
            {source.address1}
            </span>
          <span style={{ marginLeft: '.5rem' }}>
            {source.totalSplitJobs > 1 ? `(1/${source.totalSplitJobs})` : ''}
          </span>
        </Typography>
        <Typography>
          {source.address1}
        </Typography>
        <Typography>
          {source.city || ""}, $
          {source.price ? source.price.toFixed(2) : "0.00"}
        </Typography>
        <Typography>
          <em>Hours: </em> {source.estimatedHours}
        </Typography>
        <Typography>
          <em>Customer: </em> {source.customerName}
        </Typography>
        <Typography>
          <em>Address: </em> {source
            ? <AddressLink
                address={{
                  address1: source.address1,
                  city: source.city,
                  state: { code: source.state },
                  zipCode: source.zipCode
                }}
                className={classes.addressLink}
                inline
            />
            : ''}
        </Typography>
        <Typography>
          <em>Equipment: </em> {source.equipment || ""}
        </Typography>
        <Typography>
          <em>Description: </em> {(source && source.description && source.description.substr(0,Math.min(source.description.length,200))) || ""}
          {/*<em>Description: </em> {(source && source.jobDescription) || ''}*/}
        </Typography>
      </>
    );
  };

  useEffect(() => {
    if (type === "Job") {
      dispatch({ type: "CHANGE_STATUS_JOB", status: isDragging });
    } else if (type === "Employee") {
      dispatch({
        type: "DRAGGING_EMPLOYEES",
        status: isDragging,
        dayOff: isDragging ? source.dayOff : [],
      });
    }
  }, [isDragging]);

  return useMemo(
    () => (
      <div onDrag={handleOnDrag} ref={drag} onClick={changeSelected}>
        <div
          ref={isSelected ? selectedRef : null}
          style={isSelected ? { backgroundColor: "#4caf50" } : null}
          className={classes.dragJob}
        >
          {type === "Job" ? (
            <MuiThemeProvider theme={customThemeTooltip}>
              <Tooltip arrow interactive title={getTooltip()} enterDelay={1000}>
                <Typography style={{ color: isSelected ? "#ffffff" : "" }}>
                  {getValue().map((t) => (
                    <span key={t} className={classes.data}>
                      {t}
                    </span>
                  ))}
                </Typography>
              </Tooltip>
            </MuiThemeProvider>
          ) : (
            <Typography style={{ color: isSelected ? "#ffffff" : "" }}>
              {getValue().map((t) => (
                <span key={t} className={classes.data}>
                  {t.split("|").map((text) => {
                    return (
                      <>
                        <p>{text}</p>
                      </>
                    );
                  })}
                </span>
              ))}
            </Typography>
          )}

          {type === "Employee" && (
            <MuiThemeProvider theme={customThemeTooltip}>
              <Tooltip arrow title={"View Availability"}>
                <a
                  style={
                    isSelected
                      ? {
                          color: "#ffffff",
                          backgroundColor: "#4caf50",
                          marginLeft: "auto",
                        }
                      : { marginLeft: "auto" }
                  }
                  className={`${classes.button} ${classes.jobLink} job`}
                  href={`/employees/${encodeURIComponent(fullName)}/${source.id}/schedule`}
                  target="_blank"
                >
                  <OpenInNewIcon style={{ fontSize: 20 }} />
                </a>
              </Tooltip>
            </MuiThemeProvider>
          )}
          {type === "Job" && (
            <div className={classes.buttons}>
              <MuiThemeProvider theme={customThemeTooltip}>
                <Tooltip arrow title={"Locate On Route"}>
                  <a
                    style={
                      isSelected
                        ? { color: "#ffffff", backgroundColor: "#4caf50" }
                        : null
                    }
                    className={`${classes.button} ${classes.jobMap} job`}
                    onClick={locateInRoute}
                    target="_blank"
                  >
                    <RoomIcon style={{ fontSize: 24 }} />
                  </a>
                </Tooltip>
              </MuiThemeProvider>
              <MuiThemeProvider theme={customThemeTooltip}>
                <Tooltip arrow title={"View"}>
                  <a
                    style={
                      isSelected
                        ? { color: "#ffffff", backgroundColor: "#4caf50" }
                        : null
                    }
                    className={`${classes.button} ${classes.jobLink} job`}
                    href={`/customers/${encodeURIComponent(source.customerName)}/${source.customerId}/jobs/${source.jobId}/information`}>
                  
                    <OpenInNewIcon style={{ fontSize: 20 }} />
                  </a>
                </Tooltip>
              </MuiThemeProvider>
            </div>
          )}
        </div>
      </div>
    ),
    [source, routes, isSelected, isDragging]
  );
};

DraggableSource.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  source: PropTypes.object.isRequired,
  changeSelected: PropTypes.func,
};

DraggableSource.defaultProps = {
  isSelected: false,
  type: "Job",
  source: {},
  changeSelected: () => {},
};

export default DraggableSource;
