import React, { useEffect, useState, useRef } from 'react'
import {
    TableContainer, Table, TableHead, TableBody, TableRow, TableCell,
    Button, IconButton, colors, Typography,
} from '@material-ui/core'
import { Delete, DragHandle } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import AddPromoFile from './AddPromoFile'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import axios from "utils/axios";
import apiConfig from "apiConfig";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { ConfirmModal } from "components";
import GetAppIcon from "@material-ui/icons/GetApp";
import axiosNoAuth from "utils/axiosNoAuth";

const useStyles = makeStyles(theme => ({
    btn: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    headRow: {
        '& th': {
            padding: '8px 16px',
            borderBottom: '1px solid #888888',
            fontSize: '16px',
        }
    },
    bodyRow: {
        '& td': {
            padding: '8px 16px'
        }
    },
    openImage: {
        color: '#263238',
        '&:hover': {
            opacity: 0.7
        }
    },
}));

const PromoTable = props => {
    const { adminId } = props
    const mounted = useRef(false);

    const classes = useStyles();
    const [reload, setReload] = useState(false);
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [promos, setPromos] = useState([]);
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });
    const [locateFile, setLocateFile] = useState();
    const [isProgress, setIsProgress] = useState(false);
    const DragIcon = SortableHandle(() => <DragHandle />)

    const closeSnackbar = () => {
        setOpenSnackBar(false);
    };
    const onDownloadFile = (url, fileName) => {
        axiosNoAuth.get(url, {
            headers: {},
            responseType: 'blob',
        })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            })
            .catch(() => {
                setIsStatus({ failed: true, msg: 'Download file failed.' });
                setOpenSnackBar(true);
            })
    }
    const SortablePromoItem = SortableElement(({ position, promo, moveToTop, remove }) => (
        <TableRow className={classes.bodyRow}>
            <TableCell>
                <Typography
                    style={{ cursor: 'pointer', display: position === 0 ? 'none' : 'block' }}
                    onClick={moveToTop}>
                    Top
                </Typography>
                <DragIcon />
            </TableCell>
            <TableCell>
                <span>
                    <a className={classes.openImage} href={promo.path} target='_blank'>
                        {promo.name || ''}
                    </a>
                    <img width='20px' height='auto' src='/images/thumbnail/file.png' alt='thumb' />
                </span>

            </TableCell>
            <TableCell>{promo.fileName || ''}</TableCell>
            <TableCell align='center'>
                <IconButton onClick={() => onDownloadFile(promo.path, promo.fileName)}>
                    <GetAppIcon />
                </IconButton>
                <IconButton onClick={remove}>
                    <Delete />
                </IconButton>
            </TableCell>
        </TableRow>
    ))

    const SortablePromoList = SortableContainer(({ promos, moveToTop, remove }) => (
        <TableBody>
            {promos.map((promo, index) => (
                <SortablePromoItem
                    key={`promo-${index}`}
                    index={index}
                    position={index}
                    promo={promo}
                    moveToTop={() => moveToTop(index)}
                    remove={() => remove(index, promo.id)}
                />
            ))}
        </TableBody>
    ))
    const onUploadFile = (files, promoName) => {
        setReload(!reload);
        // const promoFiles = [];
        // files.forEach(file => {
        //     promoFiles.push({name: file.name.replace(/\.[^/.]+$/, ""), blobName: file.name})
        // });
        // setPromos(promos.concat(promoFiles));
        setOpenModal(false)
    }
    const moveToTop = index => {
        updatePosition(promos[index].id, 0).then(r => {
            const _promos = [...promos];
            const promo = _promos[index];
            _promos.splice(index, 1);
            _promos.unshift(promo);
            setPromos(_promos)
        }).catch(err => {

        })
    }

    const updateIndexAfterRemove = index => {
        const _promos = [...promos];
        _promos.splice(index, 1);
        setPromos(_promos)
    }

    const updatePosition = (id, newPosition) => {
        const data = {
            id: id,
            toPosition: newPosition
        }
        return new Promise((resolve, reject) => {
            axios.put(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_PROMO_ORDER.replace('{companyId}', adminId), data)
                .then(res => {
                    setIsStatus({ failed: false, msg: 'Change position success.' });
                    setOpenSnackBar(true);
                    resolve(true);
                })
                .catch(err => {
                    setIsStatus({ failed: true, msg: 'Change position failed, please try again later.' })
                    setOpenSnackBar(true);
                    reject(false)
                })
                .finally(() => {
                    setIsProgress(false);
                    setOpenConfirm(false);
                })
        });
    }

    const sortPromos = ({ oldIndex, newIndex }) => {
        updatePosition(promos[oldIndex].id, newIndex).then(res => {
            const promosSorted = arrayMove([...promos], oldIndex, newIndex);
            setPromos(promosSorted)
        }).catch(err => {

        })
    };

    const openModalDelete = (index, id) => {
        setLocateFile({ index: index, id: id });
        setOpenConfirm(true)
    }

    const removePromo = () => {
        if (!locateFile || !locateFile.id) {
            updateIndexAfterRemove(locateFile.index)
            setIsStatus({ failed: false, msg: 'Remove files success.' });
            setOpenSnackBar(true);
            setOpenConfirm(false);
            return;
        }
        setIsProgress(true);
        axios.delete(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_DELETE_FILE.replace('{companyId}', adminId).replace('{attachmentId}', locateFile.id))
            .then(res => {
                setIsStatus({ failed: false, msg: 'Remove files success.' });
                setOpenSnackBar(true);
                // setReload(!reload)
                updateIndexAfterRemove(locateFile.index)
            })
            .catch(err => {
                setIsStatus({ failed: true, msg: 'Remove failed, please try again later.' })
                setOpenSnackBar(true);
            })
            .finally(() => {
                setIsProgress(false);
                setOpenConfirm(false);
            })
    }

    useEffect(() => {
        mounted.current = true;
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_FILE.replace('{id}', adminId))
            .then(res => {
                mounted.current && setPromos(res.data);
            })
        return () => mounted.current = false;
    }, [reload])

    return (
        <>
            <TableContainer style={{ marginTop: '16px' }}>
                <Table>
                    <TableHead>
                        <TableRow className={classes.headRow}>
                            <TableCell style={{ width: '1%' }}></TableCell>
                            <TableCell style={{ width: '30%' }}>Name</TableCell>
                            <TableCell>Filename</TableCell>
                            <TableCell style={{ width: '5%' }}>
                                <Button style={{ whiteSpace: 'nowrap' }}
                                    className={classes.btn}
                                    onClick={() => setOpenModal(true)}>
                                    Add promo PDF
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <SortablePromoList
                        useDragHandle
                        promos={promos}
                        onSortEnd={sortPromos}
                        moveToTop={moveToTop}
                        remove={openModalDelete}
                    />
                </Table>
            </TableContainer>

            <AddPromoFile
                adminId={adminId}
                open={openModal}
                onClose={() => setOpenModal(false)}
                onUploadFile={onUploadFile}
            />

            <ConfirmModal
                openConfirm={openConfirm}
                closeConfirm={() => setOpenConfirm(false)}
                onConfirm={removePromo}
                isProgress={isProgress}
            />

            <Snackbar anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }} open={openSnackbar}
                autoHideDuration={3000}
                onClose={closeSnackbar}
            >
                {isStatus.failed
                    ?
                    <Alert
                        elevation={6} variant="filled" severity="error">
                        <Typography
                            color="inherit"
                            variant="h6">
                            {isStatus.msg}
                        </Typography>
                    </Alert>
                    :
                    <Alert
                        elevation={6} variant="filled" severity="success">
                        <Typography
                            color="inherit"
                            variant="h6">
                            Remove successfully!
                        </Typography>
                    </Alert>
                }
            </Snackbar>

        </>
    )
}

export default PromoTable;
