import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/styles'
import {Alert, Autocomplete} from '@material-ui/lab'
import {
    Modal, Card, CardHeader, CardContent, CardActions,
    Grid, Typography, Button, Divider,
    colors, Snackbar, CircularProgress, FormControlLabel,
    TextField, TableContainer, Table, TableBody, TableRow, TableCell, Link, Tabs, Tab, Chip
} from '@material-ui/core'
import {FormErrorCallback, GreenCheckbox} from 'components'
import apiConfig from 'apiConfig'
import axios from 'utils/axios'
import { DragHandle } from '@material-ui/icons'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import OpenInNew from "@material-ui/icons/OpenInNew";
import { Document, pdf, Page } from '@react-pdf/renderer';
import JobMultilpleQuotePdf from './JobMultilpleQuotePdf'
import JobInvoicePdf from './JobInvoicePdf'
import localStorage from 'utils/localStorage'
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import JobMultipleInvoicePdf from './JobMultipleInvoicePdf'
var qs = require('qs');

const useStyles = makeStyles(theme => ({
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 500,
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: 500
        },
    },
    conButton: {
        padding: '16px'
    },
    buttonSave: {
        flexShrink: '0',
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
    buttonCancel: {
        marginLeft: 'auto !important'
    },
    rootAttachments: {
        marginTop: 10,
        paddingTop: 10,
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            display: 'block'
        }
    },
    rootChildAttachments: {
        width: '100%',
        padding: '16px 8px',
        border: '1px solid #b3b3b3',
        borderRadius: '4px',
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    labelAttachments: {
        position: 'absolute',
        top: '-10px',
        left: '10px',
        backgroundColor: '#ffffff',
        padding: '0 3px',
        color: '#263238',
        fontSize: 14
    },
    bodyRow: {
        '& td': {
            padding: '0px'
        }
    },
    checkbox: {
        margin: 0
    },
    customersEmailExplain: {
        color: '#263238',
        fontSize: 14,
        textAlign: 'right'
    }
}))

const initValue = {
    hasPurchaseOrder: true,
    hasJobSiteNote: true,
    hasDiscount: true,
    hasSalesperson: true,
};

const EmailModalMultipleQuote = props => {
    const classes = useStyles();
    const {open, onCloseModal, jobId, jobIdArray} = props;

    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [isProgress, setIsProgress] = useState(false);
    const [customerEmail, setCustomerEmail] = useState("");
    const [ccEmail, setccEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [body, setBody] = useState("");
    const [attachments, setAttachments] = useState([]);
    const [type, setType] = useState("Quote");
    const DragIcon = SortableHandle(() => <DragHandle />)
    const [jobInfoPdf, setJobInfoPdf] = useState([]);
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });
    const [openSnackbar, setOpenSnackBar] = useState(false);

    const onChangeEmail = (value) => {
        var hasValid = value.find(x => {
            return !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(x));
        })
        if (!hasValid) {
            setCustomerEmail(value.join(';'))
        }
    }

    const onChangeBcc = (value) => {
        var hasValid = value.find(x => {
            return !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(x));
        })
        if (!hasValid) {
            setccEmail(value.join(';'))
            //setSelectedOptions(value);
        }
    }

    useEffect(() => {
        if (!open) return;
        if(type == "Quote" && jobIdArray && jobIdArray.length > 0) {
            var newArray = [];
            jobIdArray.map(function(element){
                newArray.push(element.id);
            });
            axios.get(apiConfig.url.BASE_URL + apiConfig.url.MULTILPLE_QUOTE_PDF, 
                {
                    params: {
                        jobIds: newArray
                    },
                    paramsSerializer: params => {
                        return qs.stringify(params)
                    }
                }
            )
                .then(res => {
                    if(res.data){
                        setJobInfoPdf(res.data);
                    }
                })
                .catch(err => {
                    
                });
            setLoadingData(true);
            axios.get(apiConfig.url.BASE_URL + apiConfig.url.JOB_GET_ESTIMATE_EMAIL.replace('{jobId}', jobIdArray[0].id))
                .then(res => {
                    if (res.data) {
                        setCustomerEmail(res.data.toEmail ?? '');
                        setSubject(res.data.quoteSubject);
                        setBody(res.data.quoteBody);
                        var first_item = { 
                            fileName: "Quote.pdf",
                            path: window.location.origin+"/multiple-quote/pdf/"+newArray.toString(),
                            isPrimary: true,
                            checked: true
                        }
                        let returnAttachments = [ ...res.data.attachments ];
                        returnAttachments.unshift(first_item);
                        setAttachments(returnAttachments);
                    }
                })
                .catch(err => {
                    
                })
                .finally(() => setLoadingData(false));
        }
        if(type == "Invoice" && jobIdArray && jobIdArray.length > 0) {
            const jobInfo = [];
            jobIdArray.reduce(
                (p, x) =>
                    p.then(res => {
                        if (res?.data) {
                            jobInfo.push(res.data);
                        }
                        return axios.get(apiConfig.url.BASE_URL + apiConfig.url.JOB_INVOICE_PDF, {params: {jobId: x.id, isQuote: false, isInvoice: true, sortBy : 'Quantity', sortType : 'ASC'} });
                    }),
                Promise.resolve()
            )
                .then(res => {
                    if (res?.data) {
                        jobInfo.push(res.data);
                        setJobInfoPdf(() => jobInfo);
                    }
                })
                .catch(err => {
                    
                });
            setLoadingData(true);
            axios.get(apiConfig.url.BASE_URL + apiConfig.url.JOB_GET_ESTIMATE_EMAIL.replace('{jobId}', props.jobId))
                .then(res => {
                    if (res.data) {
                        setCustomerEmail(res.data.toEmail ?? '');
                        setSubject(res.data.invoiceSubject);
                        setBody(res.data.invoiceBody);
                        var first_item = { 
                            fileName: "Invoice.pdf",
                            path: window.location.origin+"/invoice/pdf/"+props.jobId,
                            isPrimary: true,
                            checked: true
                        }
                        let returnAttachments = [ ...res.data.attachments ];
                        returnAttachments.unshift(first_item);
                        setAttachments(returnAttachments)
                    }
                })
                .catch(err => {
                    
                })
                .finally(() => setLoadingData(false));
        }
    }, [open, type]);

    const onChangeItem = (e, indexRecord, checked) => {
        let returnAttachments = [ ...attachments ];
        returnAttachments[indexRecord].checked = checked;
        setAttachments(returnAttachments)
    }

    const submit = () => {
        setIsProgress(true);
        const blob1 = pdf(type == "Quote" ? <JobMultilpleQuotePdf jobInfoPdf={jobInfoPdf} /> : <JobMultipleInvoicePdf jobInfoPdf={jobInfoPdf} />).toBlob()
            .then(res => {
                let metadata = {
                    type: 'application/pdf'
                };
                let file = new File([res], type+".pdf", metadata);

                var attachments_new = attachments.filter(value => value.checked);
                var bodyFormData = new FormData();
                    bodyFormData.append('ToEmail', customerEmail);
                    bodyFormData.append('CcEmail', ccEmail);
                    bodyFormData.append('Subject', subject);
                    bodyFormData.append('Body', body);
                    bodyFormData.append('Attachments', JSON.stringify(attachments_new));
                    bodyFormData.append('file', file);
                    bodyFormData.append('isInvoice', type == "Invoice");
                    axios.post(apiConfig.url.BASE_URL + apiConfig.url.JOB_SEND_ESTIMATE_EMAIL.replace('{jobId}', props.jobId), bodyFormData)
                        .then(res => {
                            if(res.data.isSent == true) {
                                setIsStatus({ failed: false, msg: 'The email was sent successfully' });
                                setOpenSnackBar(true);
                                onCloseModal();
                            } else if(res.data.isSent == false && res.data.message) {
                                setIsStatus({ failed: true, msg: res.data.message });
                                setOpenSnackBar(true);
                            } else {
                                setIsStatus({ failed: true, msg: 'There was a problem sending the email.  Please try again later.' });
                                setOpenSnackBar(true);
                            }
                        })
                        .catch(err => {
                            setIsStatus({ failed: true, msg: 'There was a problem sending the email.  Please try again later.' });
                            setOpenSnackBar(true);
                        })
                        .finally(() => {
                            setIsProgress(false);
                        })
            })
            .catch(err => {
                
            });
    }

    const SortablePromoItem = SortableElement(({ position, promo, moveToTop, remove, indexRecord }) => (
        <TableRow className={classes.bodyRow}>
            <TableCell>
                <Typography
                    style={{ cursor: 'pointer', display: position === 0 ? 'none' : 'block' }}
                    onClick={moveToTop}>
                    Top
                </Typography>
                <DragIcon />
            </TableCell>
            <TableCell align='left'>
                <FormControlLabel
                    disabled={promo.isPrimary}
                    className={classes.checkbox}
                    name='checked'
                    checked={promo.checked}
                    control={
                        <GreenCheckbox />
                    }
                    label={promo.fileName}
                    onChange={e => onChangeItem(e, indexRecord, (promo.checked ? false : true))}
                />
            </TableCell>
            <TableCell align='right'>
                <Link
                    style={{ color: '#263238' }}
                    component="a"
                    href={promo.path}
                    target="_blank">
                    <OpenInNew />
                </Link>
            </TableCell>
        </TableRow>
    ))

    const SortablePromoList = SortableContainer(({ promos, moveToTop }) => (
        <TableBody>
            {promos.map((promo, index) => (
                <SortablePromoItem
                    key={`promo-${index}`}
                    index={index}
                    position={index}
                    promo={promo}
                    moveToTop={() => moveToTop(index)}
                    indexRecord={index}
                />
            ))}
        </TableBody>
    ))
    
    const moveToTop = index => {
        const _promos = [...attachments];
        const promo = _promos[index];
        _promos.splice(index, 1);
        _promos.unshift(promo);
        setAttachments(_promos)
    }

    const sortPromos = ({ oldIndex, newIndex }) => {
        const promosSorted = arrayMove([...attachments], oldIndex, newIndex);
        setAttachments(promosSorted)
    };

    const handleTabsChange = (_, value) => {
        setType(value);
    }

    useEffect(() => {
        const user = localStorage.getUserInfo();
        if (user?.userName) setccEmail(user.userName);
    }, []);

    return (
        <>
            <Modal open={open}>
                <Card className={classes.styleModal}>
                    <CardHeader
                        title={
                            <Tabs
                                onChange={handleTabsChange}
                                value={type}
                            >
                                <Tab label='Quote' value='Quote' />
                                <Tab label='Invoice' value='Invoice' />
                            </Tabs>
                        }
                        style={{ padding: 0 }}
                    />
                    <Divider/>
                    {!loadingData
                        ? <CardContent>

                            <Autocomplete
                                multiple
                                id="tags-filled"
                                options={[]}
                                defaultValue={customerEmail.length > 0 ? customerEmail.split(";") : []}
                                value={customerEmail.length > 0 ? customerEmail.split(";") : []}
                                freeSolo
                                onChange={(e, newValue) => {
                                    onChangeEmail(newValue)
                                }}
                                onBlur={(e,v) => {
                                    let newValue = customerEmail.length > 0 ? customerEmail.split(";") : []
                                    if(e.target.value.trim()){
                                        newValue.push(e.target.value)
                                        onChangeEmail(newValue)
                                    }
                                }}
                                selectOnFocus={true}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip deleteIcon={<CancelRoundedIcon style={{ color: '#546e7a' }} />} variant="outlined" label={option} {...getTagProps({ index })} />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Customer Email" placeholder={!customerEmail.length > 0 ? customerEmail.split(";") : [] ? "Customer Email" : ''} />
                                )}
                            />

                            <Autocomplete
                                style={{ marginTop: 20 }}
                                multiple
                                id="tags-filled"
                                options={[]}
                                defaultValue={ccEmail.length > 0 ? ccEmail.split(";") : []}
                                value={ccEmail.length > 0 ? ccEmail.split(";") : []}
                                freeSolo
                                onChange={(e, newValue) => {
                                    onChangeBcc(newValue)
                                }}
                                onBlur={(e,v) => {
                                    let newValue = ccEmail.length > 0 ? ccEmail.split(";") : []
                                    if(e.target.value.trim()){
                                        newValue.push(e.target.value)
                                        onChangeBcc(newValue)
                                    }
                                }}
                                selectOnFocus={true}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip deleteIcon={<CancelRoundedIcon style={{ color: '#546e7a' }} />} variant="outlined" label={option} {...getTagProps({ index })} />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="BCC Email" placeholder={!ccEmail.length > 0 ? ccEmail.split(";") : [] ? "BCC Email" : ''} />
                                )}
                            />
                            <TextField
                                style={{ marginTop: 20 }}
                                fullWidth
                                multiline
                                rows={1}
                                label='Subject'
                                name='subject'
                                variant='outlined'
                                value={subject}
                                onChange={event => setSubject(event.target.value)}
                            />
                            <TextField
                                style={{ marginTop: 20 }}
                                fullWidth
                                multiline
                                rows={7}
                                label='Body'
                                name='body'
                                variant='outlined'
                                value={body}
                                onChange={event => setBody(event.target.value)}
                            />
                            <div className={classes.rootAttachments}>
                                <div className={classes.rootChildAttachments}>
                                    <Typography className={classes.labelAttachments}>Attachments</Typography>
                                    <TableContainer style={{ marginTop: '0px', maxHeight: 'calc(100vh - 600px)' }}>
                                        <Table>
                                            <SortablePromoList
                                                useDragHandle
                                                promos={attachments}
                                                onSortEnd={sortPromos}
                                                moveToTop={moveToTop}
                                            />
                                        </Table>
                                    </TableContainer>
                                </div>
                                
                            </div>
                        </CardContent>
                        : <CardContent><CircularProgress size={24}/></CardContent>
                    }
                    <Divider/>
                    <CardActions className={classes.conButton}>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            className={classes.buttonSave}
                            onClick={() => {
                                localStorage.set('attachments', JSON.stringify(attachments.filter(attachment => attachment.checked)));
                                window.open(window.location.origin + `/${type === 'Quote' ? 'multiple-quote' : 'multiple-invoice'}/pdf/${jobIdArray.map(j => j.id).toString()}`, '_blank')
                            }}
                        >
                                <Link
                                    style={{ color: 'white', textDecoration: 'none' }}
                                    component="a"
                                    target="_blank"
                                >
                                    Generate PDF
                                </Link>
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            className={classes.buttonCancel}
                            onClick={() => {
                                onCloseModal()
                            }}>
                            CANCEL
                        </Button>
                        <Button
                            style={{marginLeft: '6px'}}
                            disabled={isProgress || (customerEmail == "" || subject == "" || body == "")}
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={() => { submit() }}
                            className={classes.buttonSave}>
                            SEND
                        </Button>
                        {isProgress && <CircularProgress size={24}/>}
                    </CardActions>
                </Card>
            </Modal>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openSnackbar}
                autoHideDuration={3000}
                onClose={() => setOpenSnackBar(false)}>
                <Alert
                    elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    )
}
EmailModalMultipleQuote.propTypes = {
    onCloseModal: PropTypes.func.isRequired
}
EmailModalMultipleQuote.defaultProps = {
    onCloseModal: () => {
    }
}
export default EmailModalMultipleQuote;
