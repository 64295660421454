import React, { useEffect, useState, useRef } from 'react'
import {
    Card, CardHeader, CardContent, CardActions, Divider,
    TableContainer, Table, TableHead, TableBody, TableRow, TableCell,
    TextField, FormControlLabel, IconButton, Button, colors,
    CircularProgress, Grid, Typography, Collapse, TableSortLabel
} from '@material-ui/core'
import { ConfirmModal, Pagination } from "components";
import { KeyboardArrowDown } from '@material-ui/icons'
import { AddBox } from '@material-ui/icons'
import { GreenCheckbox } from 'components'
import { makeStyles } from '@material-ui/styles'
import * as Yup from "yup";
import axios from "utils/axios";
import apiConfig from "apiConfig";
import uuid from "uuid/v1";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import PricingLine from "./PricingLine";

const useStyles = makeStyles(theme => ({
    cardHeader: {
        cursor: 'pointer'
    },
    btn: {
        whiteSpace: 'nowrap',
        padding: '4px 14px',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    btnDis: {
        whiteSpace: 'nowrap',
        color: 'rgba(0, 0, 0, 0.26)',
        backgroundColor: 'rgb(217 217 217)',
        pointerEvents: 'none'
    },
    addIcon: {
        padding: '0',
        '& span svg': {
            fontSize: '40px',
            color: colors.green[600],
            '&:hover': {
                color: colors.green[900]
            }
        }
    },
    headRow: {
        '& th': {
            borderBottom: '1px solid #888888',
            fontSize: '16px',
            padding: '8px 16px'
        }
    },
    bodyRow: {
        '& td': {
            padding: '8px 16px'
        }
    },
    checkbox: {
        margin: 0
    },
    taxCheckbox: {
        '& span': {
            fontSize: '16px',
            fontWeight: 500
        }
    },
    note: {
        padding: '16px',
        fontSize: '16px',
        fontWeight: 500
    },
    paginate: {
        marginTop: theme.spacing(3),
        display: 'flex',
        justifyContent: 'center'
    }
}));

export class Price {
    constructor(companyId) {
        this.id = "";
        this.companyId = companyId;
        this.description = "";
        this.price = null;
        this.taxable = true;
        this.active = true;
    }
}

let pricesPageNumber = 1;
let pricesForcePage = 0;

const validateRowChema = Yup.object().shape({
    description: Yup.string().required("Description is required"),
    price: Yup.number().typeError('Please enter valid number').min(0, 'Min value 0').required("Please enter price"),
    taxable: Yup.boolean().required("Please enter taxable"),
    active: Yup.boolean().required("Please enter active"),
    // qbItemId: Yup.string().typeError('Please enter quickbooks items').required("Please enter quickbooks items"),
})

const validateSchema = Yup.object().shape({
    prices: Yup.array()
        .of(
            validateRowChema
        )
});

const Pricing = ({ adminId }) => {

    const classes = useStyles();
    const isMounted = useRef(false);

    const [saveTaxes, setSaveTaxes] = useState(false)
    const [tax, setTax] = useState(false)
    const [otherQuickbooksItems, setOtherQuickbooksItems] = useState({})
    const [prices, setPrices] = useState([])
    const [pricesPageCount, setPricesPageCount] = useState(0)
    const [pricesSort, setPricesSort] = useState({name: '', type: 'asc'})
    const [loadPrices, setLoadPrices] = useState(true)
    const [saveQuickbooks, setSaveQuickbooks] = useState(false);
    const [saveItems, setSaveItems] = useState(false);
    const [load, setLoad] = useState(false);
    const [loadTax, setLoadTax] = useState(false);
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [errorItem, setErrorItem] = useState([]);
    const [touchItem, setTouchItem] = useState([]);
    const [loading, setLoading] = useState(false);
    const [locateItem, setLocateItem] = useState();
    const [listQuickBook, setListQuickBook] = useState([]);
    const [enableSaveAll, setEnableSaveAll] = useState(false);
    const [expandedTaxes, setExpandedTaxes] = useState(true);
    const [expandedQuickbooks, setExpandedQuickbooks] = useState(true);
    const [expandedPriceItems, setExpandedPriceItems] = useState(true);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const getOtherQuickbooksItems = () => {
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_PRICES_OTHER_QUICKBOOKS_ITEMS.replace('{companyId}', adminId))
            .then(res => {
                if (isMounted.current) {
                    setOtherQuickbooksItems(res.data);
                }
            })
    }

    const saveOtherQuickbooksItems = () => {
        setSaveQuickbooks(true);
        axios.post(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_PRICES_OTHER_QUICKBOOKS_ITEMS.replace('{companyId}', adminId), otherQuickbooksItems)
            .then(res => {
                setIsStatus({ failed: false, msg: 'Save successfully!' });
                setOpenSnackBar(true);
            })
            .catch(err => {
                setIsStatus({ failed: true, msg: 'An error occurred, please try again later.' });
                setOpenSnackBar(true);
            })
            .finally(e => setSaveQuickbooks(false));
    }

    const addItem = () => {
        const item = {
            description: '',
            price: '',
            taxable: tax || false,
            companyId: adminId,
            qbItemId: '',
            active: true,
            quickbookName: ''
        };
        setPrices([item, ...prices])
    }

    const onChangeItem = (event, index) => {
        const { name, value, type } = event.target;
        setPrices((_items) => {
            if (type === 'checkbox') {
                _items[index] = { ..._items[index], [name]: event.target.checked, change: true }
            } else {
                _items[index] = { ..._items[index], [name]: value, change: true }
            }
            return [..._items]
        })
    }

    const setRemoveItem = (id, index) => {
        setOpenConfirm(true);
    }

    const removeItem = () => {
        if (!locateItem.id) {
            const src = [...prices];
            src.splice(locateItem.index, 1);
            setPrices(src);
            setOpenConfirm(false);
            return;
        }

        setIsDelete(true);
        axios.delete(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_PRICES_ITEM.replace('{companyId}', adminId) + `?id=${locateItem.id}`)
            .then(() => {
                const src = [...prices];
                src.splice(locateItem.index, 1);
                setPrices(src);
                setIsStatus({ failed: false, msg: 'Delete successfully!' });
                setOpenSnackBar(true);
            })
            .catch(e => {
                setIsStatus({ failed: true, msg: 'An error occurred, please try again later.' });
                setOpenSnackBar(true);
            })
            .finally(() => {
                setIsDelete(false);
                setOpenConfirm(false)
            })
    }

    const onBlueItem = (key, index) => {
        // const touched = {...touchItem};
        setTouchItem((prevTouched) => {
            const prev = { ...prevTouched }
            prev[`prices[${index}].${key}`] = true;
            return { ...prev }
        });
    }

    const saveItemPrice = (item, index, submit = false) => {
        validateRowChema.validate(item, { abortEarly: false }).then(() => {
            setErrorItem((pre) => {
                pre = [];
                return [...pre];
            });
            if (submit) {
                setSaveItems(true);
                setPrices((_price) => {
                    _price[index] = { ..._price[index], saving: true };
                    return [..._price]
                })
                if (item.id) {
                    axios.put(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_PRICES_ITEM.replace('{companyId}', adminId), item)
                        .then(res => {
                            setIsStatus({ failed: false, msg: 'Save successfully!' });
                            setOpenSnackBar(true);
                            item.change = false
                            // setReloadSource(!reloadSource)
                        })
                        .catch(err => {
                            setIsStatus({ failed: true, msg: 'An error occurred, please try again later.' });
                            setOpenSnackBar(true);
                        })
                        .finally(e => {
                            setSaveItems(false);
                            setPrices((_price) => {
                                _price[index] = { ..._price[index], change: false, saving: false };
                                return [..._price]
                            })
                        });
                } else {
                    axios.put(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_PRICES_ITEM.replace('{companyId}', adminId), item)
                        .then(res => {
                            setIsStatus({ failed: false, msg: 'Save successfully!' });
                            setOpenSnackBar(true);
                            item.change = false
                            // setReloadSource(!reloadSource)
                        })
                        .catch(err => {
                            setIsStatus({ failed: true, msg: 'An error occurred, please try again later.' });
                            setOpenSnackBar(true);
                        })
                        .finally(e => {
                            setSaveItems(false);
                            setPrices((_price) => {
                                _price[index] = { ..._price[index], change: false, saving: false };
                                return [..._price]
                            })
                        });
                }
            }
        })
            .catch(function (err) {
                let listError = [];
                let listTouched = [];
                err.inner.forEach((e, i) => {
                    listTouched[`prices[${index}].${e.path}`] = true;
                    listError[`prices[${index}].${e.path}`] = e.message;
                });
                setTouchItem(listTouched);
                setErrorItem(listError);
            });
    }

    const saveAllItemPrice = () => {
        const pricesChanged = prices.filter(p => p.change);
        validateSchema.validate({ pricesChanged: pricesChanged }, { abortEarly: false }).then(() => {
            setErrorItem([])
            setSaveItems(true)
            axios.put(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_PRICES_ITEM_ALL.replace('{companyId}', adminId), pricesChanged)
                .then(res => {
                    setIsStatus({ failed: false, msg: 'Save successfully!' });
                    setOpenSnackBar(true);
                    const _prices = [...prices.map(item => {
                        if (item.change) {
                            item.change = false;
                        }
                        return item;
                    })];
                    setPrices(_prices);
                })
                // setLoad(!load)
                .catch(err => {
                    setIsStatus({ failed: true, msg: 'An error occurred, please try again later.' });
                    setOpenSnackBar(true);
                })
                .finally(e => setSaveItems(false));
        })
            .catch(function (err) {
                let listError = {};
                let listTouched = {};
                err.inner.forEach((e, index) => {
                    listTouched[e.path] = true;
                    listError[e.path] = e.message;
                });
                setTouchItem(listTouched);
                setErrorItem(listError);
            });
    }

    const getPrices = () => {
        return Promise.all([
            axios.get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_PRICES_QUICKBOOK_LIST.replace('{companyId}', adminId)),
            axios.get(
                apiConfig.url.BASE_URL + apiConfig.url.COMPANY_PRICES_ITEM.replace('{companyId}', adminId),
                {
                    params: {
                        pageSize: 24
                    }
                }
            )
        ]).then(value => {
            if (isMounted.current) {
                value[0].data.sort((a, b) => a.description.localeCompare(b.description));
                setListQuickBook(value[0].data)
                let data = value[1].data.data.map(item => {
                    const quickbook = value[0].data.find(x => x.id === item.qbItemId);
                    item.quickbookName = quickbook ? quickbook.description : '';
                    return item
                })
                setPrices(data);
                setPricesPageCount(value[1].data.pageCount ?? 0);
            }
        }).finally(() => setLoadPrices(false));
    }

    const fetchPrices = () => {
        setLoadPrices(true);
        axios.get(
            apiConfig.url.BASE_URL + apiConfig.url.COMPANY_PRICES_ITEM.replace('{companyId}', adminId),
            {
                params: {
                    pageSize: 24,
                    pageNumber: pricesPageNumber,
                    sortBy: pricesSort.name,
                    sortType: pricesSort.type
                }
            }
        ).then(res => {
            let data = res.data.data.map(item => {
                const quickbook = listQuickBook.find(x => x.id === item.qbItemId);
                item.quickbookName = quickbook ? quickbook.description : '';
                return item
            })
            setPrices(data);
            setPricesPageCount(res.data.pageCount ?? 0);
        }).finally(() => setLoadPrices(false));
    }

    const onPageChange = (page) => {
        pricesPageNumber = page.selected + 1;
        pricesForcePage = page.selected;
        fetchPrices();
    };

    const handlePricesSort = (col) => {
        const isAsc = pricesSort.name === col && pricesSort.type === 'asc';
        setPricesSort({
            name: col,
            type: isAsc ? 'desc' : 'asc'
        });
    }

    const validateItems = (api, index, submit = false) => {
        const pricesChanged = prices.filter(p => p.change);
        validateSchema.validate({ pricesChanged: pricesChanged }, { abortEarly: false }).then(() => {
            setErrorItem({});
            if (submit) {
                setSaveItems(true);
                if (api.siteAccessId) {
                    axios.put(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_INTEGRATIONS_API.replace('{companyId}', adminId), api)
                        .then(res => {
                            setIsStatus({ failed: false, msg: 'Save successfully!' });
                            setOpenSnackBar(true);
                            api.change = false
                            // setLoad(!load)
                        })
                        .catch(err => {
                            setIsStatus({ failed: true, msg: 'An error occurred, please try again later.' });
                            setOpenSnackBar(true);
                        })
                        .finally(e => setSaveItems(false));
                } else {
                    api.siteAccessId = uuid();
                    axios.post(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_INTEGRATIONS_API.replace('{companyId}', adminId), api)
                        .then(res => {
                            setIsStatus({ failed: false, msg: 'Save successfully!' });
                            setOpenSnackBar(true);
                            setLoad(!load)
                            api.change = false
                        })
                        .catch(err => {
                            setIsStatus({ failed: true, msg: 'An error occurred, please try again later.' });
                            setOpenSnackBar(true);
                        })
                        .finally(e => setSaveItems(false));
                }

            }
        })
            .catch(function (err) {
                let listError = {};
                let listTouched = {};
                err.inner.forEach((e, index) => {
                    listTouched[e.path] = true;
                    listError[e.path] = e.message;
                });
                setTouchItem(listTouched);
                setErrorItem(listError)
            });
    }

    const updateTax = () => {
        setSaveTaxes(true);
        axios.put(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_PRICE_TAX.replace('{companyId}', adminId) + `?chargeSalesTax=${tax}`)
            .then(res => {
                setIsStatus({ failed: false, msg: 'Save successfully!' });
                setOpenSnackBar(true);
                setLoadTax(!load)
            })
            .catch(err => {
                setIsStatus({ failed: true, msg: 'An error occurred, please try again later.' });
                setOpenSnackBar(true);
            })
            .finally(e => setSaveTaxes(false));
    }

    useEffect(() => {
        if (isMounted.current) {
            setEnableSaveAll(false);
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].change) {
                    setEnableSaveAll(true);
                    break;
                }
            }
        }
        return () => isMounted.current = true;
    }, [prices])

    useEffect(() => {
        if (pricesSort.name) fetchPrices();
    }, [pricesSort]);

    useEffect(() => {
        isMounted.current = true;
        setLoading(true);
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_PRICE_TAX.replace('{companyId}', adminId))
            .then(res => {
                if (isMounted.current) {
                    setTax(res.data);
                    setLoading(false);
                }
            })
        return () => isMounted.current = false;
    }, [loadTax])

    useEffect(() => {
        isMounted.current = true;
        getPrices();
        getOtherQuickbooksItems();
        return () => isMounted.current = false;
    }, [])

    if (!prices || !listQuickBook) {
        return (
            <Grid
                container
                spacing={0}
                align="center"
                justifyContent="center"
                direction="column">
                <Grid item>
                    <CircularProgress className={classes.circularProgress} size={50} />
                </Grid>
            </Grid>
        )
    }

    return (
        <>
            <Card>
                <CardHeader title='Taxes' className={classes.cardHeader}
                    action={<KeyboardArrowDown />}
                    onClick={() => setExpandedTaxes(!expandedTaxes)}
                />
                <Divider />
                <Collapse in={expandedTaxes} timeout='auto' unmountOnExit>
                    <CardContent>
                        <FormControlLabel
                            className={classes.taxCheckbox}
                            label='Charge Sales Tax'
                            checked={tax}
                            control={
                                <GreenCheckbox />
                            }
                            onChange={(event) => setTax(event.target.checked)}
                        />
                    </CardContent>
                    <Divider />
                    <CardActions>
                        <Button
                            style={{ marginLeft: '8px' }}
                            className={saveTaxes ? classes.btnDis : classes.btn}
                            onClick={() => {
                                updateTax()
                            }}
                        >
                            Save
                        </Button>
                        {saveTaxes && <CircularProgress size={24} />}
                    </CardActions>
                </Collapse>
            </Card>

            <Card style={{ marginTop: '24px' }}>
                <CardHeader title='Other Quickbooks Items' className={classes.cardHeader}
                    action={<KeyboardArrowDown />}
                    onClick={() => setExpandedQuickbooks(!expandedQuickbooks)}
                />
                <Divider />
                <Collapse in={expandedQuickbooks} timeout='auto' unmountOnExit>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label='Discount Items'
                                    name='discountItems'
                                    variant='outlined'
                                    select
                                    SelectProps={{ native: true }}
                                    value={otherQuickbooksItems.discQBID ?? ''}
                                    onChange={event => setOtherQuickbooksItems({ ...otherQuickbooksItems, discQBID: event.target.value || null })}>
                                    <option value=''></option>
                                    {listQuickBook && listQuickBook.map(quickBook => (
                                        <option
                                            value={quickBook.qbId}
                                            key={quickBook.qbId}
                                        >{quickBook.description}</option>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label='Uncategorized Items'
                                    name='uncategorizedItems'
                                    variant='outlined'
                                    select
                                    SelectProps={{ native: true }}
                                    value={otherQuickbooksItems.miscQBID ?? ''}
                                    onChange={event => setOtherQuickbooksItems({ ...otherQuickbooksItems, miscQBID: event.target.value || null })}>
                                    <option value=''></option>
                                    {listQuickBook && listQuickBook.map(quickBook => (
                                        <option
                                            value={quickBook.qbId}
                                            key={quickBook.qbId}
                                        >{quickBook.description}</option>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label='Tip Items'
                                    name='tipItems'
                                    variant='outlined'
                                    select
                                    SelectProps={{ native: true }}
                                    value={otherQuickbooksItems.tipQBID ?? ''}
                                    onChange={event => setOtherQuickbooksItems({ ...otherQuickbooksItems, tipQBID: event.target.value || null })}>
                                    <option value=''></option>
                                    {listQuickBook && listQuickBook.map(quickBook => (
                                        <option
                                            value={quickBook.qbId}
                                            key={quickBook.qbId}
                                        >{quickBook.description}</option>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label='Convenience Fee'
                                    name='convenienceFee'
                                    variant='outlined'
                                    select
                                    SelectProps={{ native: true }}
                                    value={otherQuickbooksItems.convenienceQBID ?? ''}
                                    onChange={event => setOtherQuickbooksItems({ ...otherQuickbooksItems, convenienceQBID: event.target.value || null })}>
                                    <option value=''></option>
                                    {listQuickBook && listQuickBook.map(quickBook => (
                                        <option
                                            value={quickBook.qbId}
                                            key={quickBook.qbId}
                                        >{quickBook.description}</option>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider />
                    <CardActions>
                        <Button
                            style={{ marginLeft: '8px' }}
                            className={saveQuickbooks ? classes.btnDis : classes.btn}
                            onClick={saveOtherQuickbooksItems}>
                            Save
                        </Button>
                        {saveQuickbooks && <CircularProgress size={24} />}
                    </CardActions>
                </Collapse>
            </Card>

            <Card style={{ marginTop: '24px' }}>
                <CardHeader title='Price Line Items' className={classes.cardHeader}
                    action={<KeyboardArrowDown />}
                    onClick={() => setExpandedPriceItems(!expandedPriceItems)}
                />
                <Divider />
                <Collapse in={expandedPriceItems} timeout='auto' unmountOnExit>
                    <CardContent>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow className={classes.headRow}>
                                        <TableCell align='center' style={{minWidth: '150px'}}>
                                            <TableSortLabel
                                                active={pricesSort.name === 'description'}
                                                direction={pricesSort.name === 'description' ? pricesSort.type : 'asc'}
                                                onClick={() => handlePricesSort('description')}>
                                                Description
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell align='center' style={{ width: '11%', minWidth: '120px' }}>
                                            <TableSortLabel
                                                active={pricesSort.name === 'price'}
                                                direction={pricesSort.name === 'price' ? pricesSort.type : 'asc'}
                                                onClick={() => handlePricesSort('price')}>
                                                Price
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell align='center' style={{ width: '5%' }}>
                                            <TableSortLabel
                                                active={pricesSort.name === 'taxable'}
                                                direction={pricesSort.name === 'taxable' ? pricesSort.type : 'asc'}
                                                onClick={() => handlePricesSort('taxable')}>
                                                Taxable
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell align='center' style={{minWidth: '150px'}}>
                                            <TableSortLabel
                                                active={pricesSort.name === 'qbItemId'}
                                                direction={pricesSort.name === 'qbItemId' ? pricesSort.type : 'asc'}
                                                onClick={() => handlePricesSort('qbItemId')}>
                                                Quickbooks Item
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell align='center' style={{ width: '5%' }}>
                                            <TableSortLabel
                                                active={pricesSort.name === 'active'}
                                                direction={pricesSort.name === 'active' ? pricesSort.type : 'asc'}
                                                onClick={() => handlePricesSort('active')}>
                                                Active
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell align='center' style={{ width: '1px'}}>
                                            <CardActions>
                                            <Button
                                                className={(!enableSaveAll || (saveItems && !locateItem)) ? classes.btnDis : classes.btn}
                                                onClick={() => {
                                                    setLocateItem(null)
                                                    saveAllItemPrice()
                                                }}
                                                variant="contained">
                                                Save All
                                            </Button>
                                            {saveItems && !locateItem && <CircularProgress size={24} />}
                                            </CardActions>
                                        </TableCell>
                                        <TableCell align='center' style={{ width: '1px' }}>
                                            <IconButton className={classes.addIcon}
                                                onClick={addItem}>
                                                <AddBox />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        loadPrices ? (
                                            <TableRow>
                                                <TableCell colSpan={7} align="center">
                                                    <CircularProgress size={50}/>
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            prices && prices.length > 0 && prices.map((item, index) => (
                                                <PricingLine
                                                    index={index}
                                                    key={item.id || `admin-pricing-${index}`}
                                                    item={item}
                                                    onBlueItem={onBlueItem}
                                                    validateItems={validateItems}
                                                    onChangeItem={onChangeItem}
                                                    saveItemPrice={saveItemPrice}
                                                    removeItem={setRemoveItem}
                                                    errorItem={errorItem}
                                                    touchItem={touchItem}
                                                    listQuickBook={listQuickBook}
                                                    saveItems={saveItems}
                                                    locateItem={locateItem}
                                                    setLocateItem={setLocateItem}
                                                    tax={tax}
                                                >
                                                </PricingLine>
        
                                            ))
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div style={pricesPageCount === 0 ? {display: 'none'} : null} className={classes.paginate}>
                            <Pagination pageCount={pricesPageCount} forcePage={pricesForcePage} onPageChange={onPageChange}/>
                        </div>
                    </CardContent>
                </Collapse>
            </Card>

            <ConfirmModal
                openConfirm={openConfirm}
                closeConfirm={() => setOpenConfirm(false)}
                onConfirm={removeItem}
                isProgress={isDelete}
            />

            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={() => setOpenSnackBar(false)}>
                <Alert elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>

            <Typography className={classes.note}>
                * Note to user - You will need to run the Quickbooks Webconnector before pricing Items and Sales Tax
                Items appear on this screen.
            </Typography>
        </>
    )

}

export default Pricing;
