const formatTwoPDecimal = (num) => {
    return Math.round(num * 100) / 100;
}

const formatNumber = ( num, fixed ) => {
    if(!num) return 0;
    const options = { 
        minimumFractionDigits: fixed,
        maximumFractionDigits: fixed
    };
    return Number(num).toLocaleString('en', options).replace(/\.00$/, '');
};

const formatPhoneNumber = (phoneNumberString) => {
    let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})(\d*)$/);
    if (match) {
        let intlCode = (match[1] ? '+1 ' : '');
        let ext = match[5] ? 'x' + match[5] : '';
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4], ext].join('');
    }
    return null;
}

export {formatTwoPDecimal, formatNumber, formatPhoneNumber}
