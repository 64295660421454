import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, styled } from '@material-ui/styles';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom'
import NumberFormat from 'react-number-format';
import { AvatarEditor, ConfirmModal, FormErrorCallback, AddressInput } from 'components'
import {
    Card, CardHeader, CardContent, CardActions, Divider,
    Grid, TextField, Button, colors, Typography, Avatar, IconButton, Collapse,
} from '@material-ui/core';
import axios from 'utils/axios';
import apiConfig from 'apiConfig';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Phone } from './components';
import { Formik } from "formik";
import * as Yup from 'yup';
import { KeyboardDatePicker } from 'components';
import { errorMessages } from 'common/constants'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
    root: {},
    cardProfile: {
        width: '100%',
        position: 'relative',
        '& *': {
            transition: theme.transitions.create('all', {
                duration: theme.transitions.duration.shortest,
            })
        }
    },
    cardCollapse: {
        '& $cardContent': {
            paddingTop: theme.spacing(0.75),
            paddingBottom: theme.spacing(0.75),
            height: theme.spacing(6.5),
            maxWidth: `calc(100% - ${theme.spacing(6)}px)`,
            overflow: 'hidden'
        },
        '& $profileName': {
            marginBottom: -41,
            transform: 'translateY(-51px)',
            minWidth: 0,
            whiteSpace: 'nowrap',
            marginLeft: theme.spacing(7)
        },
        '& $hoverAvatar': {
            marginRight: theme.spacing(4)
        }
    },
    cardContent: {
        height: 'calc(100% - 53.5px)'
    },
    expandIcon: {
        position: 'absolute',
        right: 0,
        boxShadow: '-10px 0 10px 0 white',
        zIndex: 10
    },
    profileName: {
        display: 'inline-block',
        fontSize: '20px',
        fontWeight: 500,
        textAlign: 'center',
        marginTop: '20px',
        width: 'auto',
        minWidth: '100%'
    },
    nameDetailProfile: {
        color: theme.palette.text.secondary
    },
    hoverAvatar: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    bigAvatar: {
        width: theme.spacing(20),
        height: theme.spacing(20),
        marginLeft: `calc((100% - ${theme.spacing(20)}px) / 2)`
    },
    alignRightAvatar: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    marginTopOfProgress: {
        marginTop: theme.spacing(2)
    },
    colorUploadBtn: {
        color: theme.palette.purple
    },
    dateField: {
        '& + &': {
            marginLeft: theme.spacing(2)
        }
    },
    btnBox: {
        display: 'flex',
        alignItems: "center",
        justifyContent: 'flex-end'
    },
    saveBtn: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    snackBar: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    circularProgress: {
        marginTop: '30px',
    },
    stickyBtn: {
        position: '-webkit-sticky',
        position: 'sticky',
        backgroundColor: '#f4f6f8',
        top: '-13px',
        zIndex: 2,
    },
}));
let mounted;
const validationSchema = Yup.object().shape({
    firstName: Yup.string()
        .nullable()
        .required('Required.'),
    lastName: Yup.string()
        .nullable()
        .required('Required.'),
    address: Yup.object({
        address1: Yup.string()
            .nullable()
            .required('Required.'),
        city: Yup.string()
            .nullable()
            .required('Required.'),
        zipCode: Yup.string()
            .nullable()
            .required('Required.')
            .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, 'Zip code is invalid.')
    }),
    email: Yup.string()
        .nullable()
        .required('Required.')
        .email('Email is invalid.'),
    phones: Yup.array()
        .of(Yup.object({
            phoneNumber: Yup.string()
                .required('Required.')
                .matches(/\W\d{3}\W\s\w{3}\W\w{4}$|\W\d{3}\W\s\w{3}\W\w{4}x\d{1,6}|\W\d{3}\W\s\w{3}\W\w{4}x/, 'Invalid phone number.')
        })),
})

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
}));

const Information = props => {

    const xs = useMediaQuery(theme => theme.breakpoints.down('xs'));

    const classes = useStyles();
    const history = useHistory();
    const { pathname } = useLocation();

    const { userId, className, ...rest } = props;

    const addressInputRef = useRef();

    const [user, setUser] = useState();
    const [openAvatarModal, setOpenAvatarModal] = useState(false);
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });
    const [openConfirm, setOpenConfirm] = useState(false);
    const [stateBlur, setStateBlur] = useState(false);
    const [reload, setReload] = useState(false);
    const [expanded, setExpanded] = useState(false);

    /// upload profile
    const onGetAvatar = () => {
        setReload(!reload);
    }
    const onUploadAvatar = () => {
        setOpenAvatarModal(true);
    }
    const onRemoveAvatar = () => {
        setIsSaving(true);
        axios.delete(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_DELETE_PROFILE_PICTURE + user.id)
            .then(res => {
                setIsStatus({ failed: false, msg: 'Remove profile picture successfully.' })
                setOpenSnackBar(true);
            })
            .catch(err => {
                setIsStatus({ failed: true, msg: 'Remove profile picture failed, please try again later.' })
                setOpenSnackBar(true);
            })
            .finally(() => {
                setIsSaving(false);
                setOpenConfirm(false)
                setReload(!reload);
            })
    }

    const addUserPhone = (values, setFieldValue) => {
        const phones = [...values.phones];
        phones.push({
            id: "00000000-0000-0000-0000-000000000000",
            formattedPhoneNumber: "",
            phoneNumber: "",
            type: null,
            typeId: values.phoneTypes[0].id
        });
        setFieldValue('phones', phones)
    };
    const onPhoneUpdate = (event, values, setFieldValue, index) => {
        const { name, value } = event.target;
        const phones = [...values.phones];
        phones[index] = { ...values.phones[index], [name]: value?.trim() };
        setFieldValue('phones', phones);
    }
    const onPhoneRemove = (values, setFieldValue, index) => {
        const phones = [...values.phones];
        phones.splice(index, 1);
        setFieldValue('phones', phones);
    }

    const updateUser = data => {
        const { firstName, lastName } = data;
        axios.post(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_UPDATE, data)
            .then(res => {
                setIsStatus({ failed: false, msg: 'Change successfully.' });
                setOpenSnackBar(true);
                // change URL after update success
                const pathSplit = pathname.split('/');
                pathSplit[2] = encodeURIComponent(firstName) + ' ' + encodeURIComponent(lastName);
                history.replace(pathSplit.join('/'))
            })
            .catch(err => {
                setIsStatus({ failed: true, msg: 'An error occurred, please try again later.' });
                setOpenSnackBar(true);
            })
            .finally(() => {
                setIsSaving(false);
                setReload(!reload);
            });
    }

    const closeSnackbar = () => {
        setOpenSnackBar(false);
    };

    const fetchUser = () => {
        axios.get(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_INFO + userId).then(res => {
            if (mounted) {
                res.data.phones && res.data.phones.forEach(phone => phone.phoneNumber = phone.formattedPhoneNumber);
                setUser(res.data)
            }
        });
    };

    useEffect(() => {
        mounted = true;
        fetchUser();
        return () => mounted = false;;
    }, []);

    useEffect(() => {
        mounted = true;
        fetchUser();
        return () => mounted = false
    }, [reload])

    if (!user) {
        return (
            <Grid
                container
                spacing={0}
                align="center"
                justifyContent="center"
                direction="column">
                <Grid item>
                    <CircularProgress className={classes.circularProgress} size={50} />
                </Grid>
            </Grid>

        )
    }

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={user}
                validationSchema={validationSchema}
                onSubmit={values => {
                    if (stateBlur) {
                        return;
                    }
                    setIsSaving(true);
                    const { coordinate, isValid, forceSave, verifyFn } = addressInputRef.current;
                    if (isValid || forceSave) {
                        values = {
                            ...values,
                            address: {
                                ...values.address,
                                latitude: coordinate?.latitude,
                                longitude: coordinate?.longitude
                            }
                        };
                        updateUser(values)
                    } else {
                        verifyFn(true).then(data => data ? updateUser(data) : setIsSaving(false))
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    submitCount,
                    isValid,
                    setFieldTouched,
                    setFieldValue,
                    submitForm,
                }) => (
                    <form>
                        <FormErrorCallback
                            submitCount={submitCount}
                            isValid={isValid}
                            onSubmissionError={() => {
                                setIsStatus({ failed: true, msg: errorMessages.FIELD_CHECK });
                                setOpenSnackBar(true);
                            }}
                        />
                        <Grid className={classes.stickyBtn} container spacing={4}>
                            <Grid item xs={12}>
                                <CardActions>
                                <Button
                                    // type="submit"
                                    onClick={() => {
                                        if (!values.address.state || !values.address.state.name) {
                                            setStateBlur(true)
                                        }
                                        submitForm()
                                    }}
                                    className={classes.saveBtn}
                                    disabled={isSaving}
                                    variant="contained">
                                    Save
                                </Button>
                                {isSaving && <CircularProgress size={24} />}
                                </CardActions>
                            </Grid>
                        </Grid>
                        <Grid container className={clsx(classes.root, className)} spacing={4}>
                            <Grid style={{ alignItems: 'flex-start' }} container item md={12} lg={3}>
                                <Card className={clsx(classes.cardProfile, !xs || expanded ? null : classes.cardCollapse)}>
                                    { xs && 
                                        <ExpandMore
                                            className={classes.expandIcon}
                                            expand={expanded}
                                            onClick={() => setExpanded(!expanded)}
                                            aria-expanded={expanded}
                                            aria-label="show more"
                                        >
                                            <ExpandMoreIcon />
                                        </ExpandMore>
                                    }
                                    <CardContent className={classes.cardContent}>
                                        <div className={classes.hoverAvatar} onClick={onUploadAvatar}>
                                            <Avatar
                                                variant='circular'
                                                src={user.avatar ? user.avatar : ''}
                                                alt="avatar"
                                                className={!xs || expanded ? classes.bigAvatar : null}
                                            />
                                        </div>
                                        <Typography className={classes.profileName}>
                                            {user.fullName}
                                        </Typography>
                                    </CardContent>
                                    <Collapse in={!xs || expanded} timeout="auto" unmountOnExit>
                                        <Divider />
                                        <CardActions>
                                            <Button style={{ width: '100%' }} onClick={onUploadAvatar}>
                                                upload avatar
                                            </Button>
                                        </CardActions>
                                    </Collapse>
                                </Card>
                            </Grid>
                            <Grid container item md={12} lg={9}>
                                <Card {...rest}>
                                    <CardHeader title="Information" />
                                    <Divider />
                                    <CardContent>
                                        <Grid
                                            className={classes.container}
                                            container
                                            spacing={3}>
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    fullWidth
                                                    error={errors.firstName && touched.firstName}
                                                    helperText={(errors.firstName && touched.firstName) && errors.firstName}
                                                    label="First Name"
                                                    name="firstName"
                                                    onBlur={() => setFieldTouched('firstName')}
                                                    onChange={event => {
                                                        setFieldValue('firstName', event.target.value)
                                                        const address = { ...values.address };
                                                        address.name = `${event.target.value} ${values.middleName} ${values.lastName}`;
                                                        setFieldValue('address', address)
                                                    }}
                                                    value={values.firstName}
                                                    variant="outlined"
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    fullWidth
                                                    label="Middle"
                                                    name="middleName"
                                                    onChange={event => {
                                                        setFieldValue('middleName', event.target.value)
                                                        const address = { ...values.address };
                                                        address.name = `${values.firstName} ${event.target.value} ${values.lastName}`;
                                                        setFieldValue('address', address)
                                                    }}
                                                    value={values.middleName || ''}
                                                    variant="outlined"
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    fullWidth
                                                    error={errors.lastName && touched.lastName}
                                                    helperText={(errors.lastName && touched.lastName) && errors.lastName}
                                                    label="Last Name"
                                                    name="lastName"
                                                    onBlur={(event) => {
                                                        setFieldTouched('lastName');
                                                        const address = { ...values.address };
                                                        address.name = `${values.firstName} ${values.middleName} ${event.target.value}`;
                                                        setFieldValue('address', address)
                                                    }}
                                                    onChange={event => {
                                                        setFieldValue('lastName', event.target.value)
                                                    }}
                                                    value={values.lastName || ''}
                                                    variant="outlined"
                                                />
                                            </Grid>

                                            <Grid item md={12} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    error={errors.address && errors.address.name && touched.address && touched.address.name}
                                                    helperText={(errors.address && errors.address.name && touched.address && touched.address.name) && errors.address.name}
                                                    label="Address Name"
                                                    name="addressName"
                                                    onBlur={() => {
                                                        const address = touched.address ? { ...touched.address } : {};
                                                        address.name = true;
                                                        setFieldTouched('address', address)
                                                    }}
                                                    onChange={event => {
                                                        const address = { ...values.address };
                                                        address.name = event.target.value;
                                                        setFieldValue('address', address);
                                                    }}
                                                    value={values.address.name || ''}
                                                    variant="outlined" />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <AddressInput
                                                    ref={addressInputRef}
                                                    errors={errors}
                                                    values={values}
                                                    touched={touched}
                                                    setFieldValue={setFieldValue}
                                                    setFieldTouched={setFieldTouched}
                                                />
                                            </Grid>

                                            <Grid item md={12} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    error={errors.email && touched.email}
                                                    helperText={(errors.email && touched.email) && errors.email}
                                                    label="Email"
                                                    name="email"
                                                    onBlur={() => setFieldTouched('email')}
                                                    onChange={event => setFieldValue('email', event.target.value)}
                                                    value={values.email || ''}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            {/* Add phone */}
                                            {values.phones.map((phone, index) => (
                                                <Phone
                                                    key={`phones-${index}`}
                                                    index={index}
                                                    length={values.phones.length}
                                                    phone={phone}
                                                    errors={errors}
                                                    touched={touched}
                                                    setFieldTouched={setFieldTouched}
                                                    phoneTypes={values.phoneTypes}
                                                    onPhoneRemove={() => onPhoneRemove(values, setFieldValue, index)}
                                                    onPhoneUpdate={event => onPhoneUpdate(event, values, setFieldValue, index)}
                                                />
                                            ))}

                                            <Grid item md={12} xs={12}>
                                                <Button
                                                    onClick={() => addUserPhone(values, setFieldValue)}
                                                    color="primary"
                                                    variant="outlined">
                                                    Add Phone
                                                </Button>
                                            </Grid>

                                            <Grid item md={6} xs={12} className={classes.groupDatePicker}>
                                                <KeyboardDatePicker
                                                    label='Birth Date'
                                                    value={values.birthDate || null}
                                                    onChange={date => setFieldValue('birthDate', date)}
                                                />
                                            </Grid>

                                            <Grid item md={6} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    label="Select Language"
                                                    name="language"
                                                    onChange={event => {
                                                        setFieldValue('language', event.target.value)
                                                        setFieldValue('languageId', event.target.value)
                                                    }}
                                                    select
                                                    value={values.languageId || ''}
                                                    SelectProps={{ native: true }}
                                                    variant="outlined">
                                                    <option value=''></option>
                                                    {values.languageOptions.map(option => (
                                                        <option
                                                            key={option.id}
                                                            value={option.id}
                                                        >
                                                            {option.description}
                                                        </option>
                                                    ))}
                                                </TextField>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <NumberFormat
                                                    customInput={TextField}
                                                    allowNegative={false}
                                                    isAllowed={({ value }) => value <= 32767}
                                                    fullWidth
                                                    label="AskTheSeal Employee Id"
                                                    name="askTheSealId"
                                                    onChange={event => setFieldValue('askTheSealId', event.target.value)}
                                                    value={values.askTheSealId || ''}
                                                    variant="outlined"
                                                />
                                            </Grid>

                                            <Grid item md={12} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    label="Comments"
                                                    name="comments"
                                                    onChange={event => setFieldValue('comments', event.target.value)}
                                                    multiline
                                                    rows={10}
                                                    value={values.comments || ''}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                        </Grid>

                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>

            <AvatarEditor
                id={userId}
                open={openAvatarModal}
                onClose={() => setOpenAvatarModal(false)}
                onGetAvatar={onGetAvatar}
                type='employee-update-avatar'
                avatarImage={user.avatar?.includes(userId) ? user.avatar : null}
            />
            <ConfirmModal
                openConfirm={openConfirm}
                closeConfirm={() => setOpenConfirm(false)}
                onConfirm={onRemoveAvatar}
                isProgress={isSaving}
            />



            <TextField
                type="hidden"
                name="id"
                value={user.id}
            />
            <div className={classes.snackBar}>
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={openSnackbar}
                    autoHideDuration={3000}
                    onClose={closeSnackbar}>
                    <Alert elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                        <Typography
                            color="inherit"
                            variant="h6">
                            {isStatus.msg}
                        </Typography>
                    </Alert>
                </Snackbar>
            </div>
        </>
    );
};


export default Information;
