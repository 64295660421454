import React, { useEffect, useState } from 'react'
import {
    Card, CardHeader, CardContent, Divider, Typography, Tooltip,
    TableContainer, Table, TableHead, TableBody, colors,
    TableRow, TableCell, Button, IconButton, Grid, TextField, Collapse, CircularProgress, CardActions
} from '@material-ui/core'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import AddBoxIcon from '@material-ui/icons/AddBox'
import DeleteIcon from '@material-ui/icons/Delete'
import AddSaleCommission from './AddSaleCommission'
import { ConfirmModal } from 'components'
import { makeStyles } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import NumberFormat from "react-number-format";
import {ArrowDownward, ArrowUpward, KeyboardArrowDown, KeyboardArrowUp} from "@material-ui/icons";
import axios from "utils/axios";
import apiConfig from "apiConfig";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import * as Yup from "yup";
import moment from 'moment';
import { getSubTotal } from 'common/function';
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
    cardHeader: {
        cursor: 'pointer'
    },
    tbContainer: {
        marginTop: 16
    },
    headCell: {
        borderBottom: '1px solid #888888',
        fontSize: '16px',
        padding: '8px 16px',
        '& p': {
            fontSize: '16px',
            fontWeight: 500
        },
        '& span': {
            display: 'flex',
            alignItems: 'center'
        }
    },
    headerActionCell: {
        [theme.breakpoints.down('sm')]: {
            width: '0 !important',
            minWidth: '0 !important',
            '& > *': {
                display: 'none !important'
            }
        }
    },
    saleHead: {
        padding: '8px 16px',
        justifyContent: 'space-between',
        cursor: 'pointer'
    },
    add: {
        padding: '0',
        '& span svg': {
            fontSize: '40px',
            color: colors.green[600],
            '&:hover': {
                color: colors.green[900]
            }
        }
    },
    removeBtn: {
        '& span svg': {
            fontSize: '32px'
        }
    },
    cardTitle: {
        fontSize: '16px',
        fontWeight: 500,
        width: '255px',
        marginRight: theme.spacing(3)
    },
    typo: {
        fontSize: '16px',
        fontWeight: 500,
        marginRight: '24px'
    },
    btn: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    button: {
        cursor: 'pointer',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    btnDis: {
        whiteSpace: 'nowrap',
        color: 'rgba(0, 0, 0, 0.26)',
        backgroundColor: 'rgb(217 217 217)',
        pointerEvents: 'none'
    },
    iconSort: {
        marginBottom: '-5px',
        marginLeft: '10px'
    },
    actionsSm: {
        display: 'none',
        marginBottom: -16,
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            flexWrap: 'wrap'
        }
    }
}));

const customThemeTooltip = createTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: '14px',
                maxWidth: 'none'
            }
        }
    }
});
const validateRowChema = Yup.object().shape({
    employeeId: Yup.string().required("Please select employee"),
})

const validateSchema = Yup.object().shape({
    prices: Yup.array()
        .of(
            validateRowChema
        )
});
let pageNumber = 1;
let forcePage = 0;

const SalesCommission = props => {
    const { information ,jobId, employees, splitType, jobItems, disabled, reload, employeesAll} = props;
    const classes = useStyles();
    const ipad = useMediaQuery('(max-width: 769px)');
    const ipPlus = useMediaQuery('(max-width: 415px)');

    const [openModal, setOpenModal] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [saleCommissions, setSaleCommissions] = useState([]);
    const [index, setIndex] = useState();

    const [even, setEven] = useState(true);
    const [expanded, setExpanded] = useState(true);
    const [paySplitType, setPaySplitType] = useState("3");
    const [lstSplitType, setLstSplitType] = useState([]);
    const [typeSaving, setTypeSaving] = useState(false);
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [change, setChange] = useState(false);
    const [locateItem, setLocateItem] = useState({});
    const [saveItems, setSaveItems] = useState(false);
    const [enableSaveAll, setEnableSaveAll] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [touchItem, setTouchItem] = useState([]);
    const [errorItem, setErrorItem] = useState([]);
    const [sortBy, setSortBy] = useState('Salesperson');
    const [sortType, setSortType] = useState('ASC');

    const toggleExpanded = () => {
        setExpanded(!expanded);
    }
    const addSaleCommission = () => {
        setSaleCommissions([...saleCommissions, {
            id: '00000000-0000-0000-0000-000000000000',
            employeeId: "",
            jobSplitId: jobId,
            isReturningCustomer: false,
            amountAllocated: 0,
            rate: 0,
            amountDueToPerson: 0,
            tip: 0,
            isScheduled: false,
            change: true,
            saleType: 'new_sale',
            new_sale: 0,
            returning_sale: 0,
        }])
        setEnableSaveAll(true)
    }

    const onChangesaleCommission = (event, index) => {
        const { name, value } = event.target;
        const sale = [...saleCommissions];
        sale[index] = { ...saleCommissions[index], [name]: value, change: true }

        if (name === 'employeeId' && (paySplitType == '1' || paySplitType == '2')) {
            setLoading(true)
            axios.get(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_COMPENSATION_HISTORY + value)
                .then(res => {
                    let active = null;
                    if (res.data.salaries) {
                        active = res.data.salaries.find(s => (s.startDate && moment(s.startDate) <= moment()) &&
                            (!s.endDate || moment(s.endDate) >= moment()));
                    }
                    sale[index] = {
                        ...saleCommissions[index],
                        new_sale: active ? active.salePercentNewSale : '',
                        returning_sale: active ? active.salePercentRetSale : '',
                        //rate: acctive.formattedHourly,
                        [name]: value, change: true
                        // hoursOnSite: acctive.formattedHourly,
                        // commissionPercent: acctive.formattedLaborPercent,
                    }
                    setSaleCommissions([...sale]);
                })
                .finally(e => setLoading(false))
        } else if (name === 'amountAllocated') {
            const amountAllocated = sale[index].amountAllocated ? sale[index].amountAllocated.toString().replace(/[\$,]/g, '') : 0;
            const salePercent = sale[index][sale[index].saleType] ? sale[index][sale[index].saleType].toString().replace(/[%,]/g, '') : 0;
            sale[index].amountDueToPerson = (amountAllocated * salePercent / 100).toFixed(2);
            setSaleCommissions([...sale]);
        } else {
            setSaleCommissions([...sale]);
        }
    }
    const changeSaleType = (index, value) => {
        let sl = [...saleCommissions];
        // if (paySplitType == '1' || paySplitType == '2') { ---- DON'T KNOW WHY WE NEED THIS CONDITIONS ----
        setLoading(true)
        axios.get(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_COMPENSATION_HISTORY + sl[index].employeeId)
            .then(res => {
                let active = null;
                if (res.data.salaries) {
                    active = res.data.salaries.find(s => (s.startDate && moment(s.startDate) <= moment()) &&
                        (!s.endDate || moment(s.endDate) >= moment()));
                }
                sl[index] = {
                    ...saleCommissions[index],
                    saleType: value,
                    new_sale: active ? active.salePercentNewSale : '',
                    returning_sale: active ? active.salePercentRetSale : '',
                    change: true,

                }
                const amountAllocated = sl[index].amountAllocated ? sl[index].amountAllocated.toString().replace(/[\$,]/g, '') : 0;
                const salePercent = sl[index][value] ? sl[index][value].toString().replace(/[%,]/g, '') : 0;
                sl[index].amountDueToPerson = (amountAllocated * salePercent / 100).toFixed(2);
                setSaleCommissions(sl);
            })
            .finally(e => setLoading(false))
        // }
    }

    const removeSaleCommission = () => {
        if (locateItem.id) {
            setDeleting(true)
            axios.delete(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_COMMISSION_SALE + '?id=' + locateItem.id)
                .then(value => {
                    const coms = [...saleCommissions];
                    coms.splice(locateItem.index, 1);
                    setSaleCommissions(coms);
                    setOpenConfirm(false);
                    setIsStatus({ failed: false, msg: 'Remove successfully.' });
                    setOpenSnackBar(true);
                }).finally(() => setDeleting(false))
        } else {
            const coms = [...saleCommissions];
            coms.splice(locateItem.index, 1);
            setSaleCommissions(coms);
            setOpenConfirm(false);
            setIsStatus({ failed: false, msg: 'Remove successfully.' });
            setOpenSnackBar(true);
        }
    }
    const loadDocument = (type) => {
        setSortBy(type)
        setSortType(type == sortBy ? (sortType === 'ASC' ? 'DESC' : 'ASC') : 'ASC');
    }
    const getSortIcon = (type) => {
        return (
            sortBy == type &&
            (
                sortType == 'ASC' ?
                    <ArrowUpward className={classes.iconSort}/>
                    :
                    <ArrowDownward className={classes.iconSort}/>
            )
        )
    }
    const getCommissionSale = (job) => {
        setLoading(true);
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_COMMISSION_SALE_LIST,
            {
                params: {
                        jobSplitId: job,
                        sortBy: sortBy,
                        sortType: sortType
                    }
            })
            .then(value => {
                let sale = value.data.map(x => {
                    return {
                        ...x,
                        saleType: x.isReturningCustomer ? 'returning_sale' : 'new_sale',
                        new_sale: x.rate,
                        returning_sale: x.rate,
                    }
                })
                setSaleCommissions(sale);
            }).finally(() => {
                setLoading(false);
            })
    }

    const onSaveCustomerPayment = (item, index, submit = false) => {
        validateRowChema.validate(item, { abortEarly: false }).then(() => {
            setErrorItem((pre) => {
                pre = [];
                return [...pre];
            });
            if (submit) {
                setSaveItems(true);
                setSaleCommissions((_price) => {
                    _price[index] = { ..._price[index], saving: true };
                    return [..._price]
                })

                const data = {
                    id: item.id ? item.id : "00000000-0000-0000-0000-000000000000",
                    employeeId: item.employeeId ? item.employeeId : employees[0].id,
                    jobSplitId: jobId,
                    isReturningCustomer: item.saleType == 'returning_sale',
                    amountAllocated: item.amountAllocated ? item.amountAllocated.toString().replace(/[\$,]/g, '') : 0,
                    rate: item.saleType == 'returning_sale' ? item.returning_sale.toString().replace(/[%,]/g, '') : item.new_sale.toString().replace(/[%,]/g, ''),
                    amountDueToPerson: item.amountDueToPerson ? item.amountDueToPerson.toString().replace(/[\$,]/g, '') : 0,
                    tip: item.tip ? item.tip.toString().replace(/[\$,]/g, '') : 0,
                    isScheduled: item.isScheduled,
                };
                if (item.id != '00000000-0000-0000-0000-000000000000') {
                    axios.put(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_COMMISSION_SALE.replace('{jobId}', jobId), data)
                        .then(res => {
                            setIsStatus({ failed: false, msg: 'Save successfully!' });
                            setOpenSnackBar(true);
                            item.change = false;
                            setSaleCommissions((_price) => {
                                _price[index] = {
                                    ..._price[index], change: false, saving: false,
                                    saleType: res.data.isReturningCustomer ? 'returning_sale' : 'new_sale',
                                    new_sale: res.data.rate,
                                    returning_sale: res.data.rate,
                                };
                                return [..._price]
                            })
                            // setReloadSource(!reloadSource)
                        })
                        .catch(err => {
                            setIsStatus({ failed: true, msg: 'An error occurred, please try again later.' });
                            setOpenSnackBar(true);
                        })
                        .finally(e => {
                            setSaveItems(false);
                            // setSaleCommissions((_price) => {
                            //     _price[index] = {..._price[index], change: false, saving: false};
                            //     return [..._price]
                            // })
                        });
                } else {
                    axios.post(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_COMMISSION_SALE, data)
                        .then(res => {
                            setIsStatus({ failed: false, msg: 'Save successfully!' });
                            setOpenSnackBar(true);
                            item.change = false
                            setSaleCommissions((_price) => {
                                _price[index] = {
                                    ..._price[index], change: false, saving: false,
                                    saleType: res.data.isReturningCustomer ? 'returning_sale' : 'new_sale',
                                    new_sale: res.data.rate,
                                    returning_sale: res.data.rate,
                                };
                                return [..._price]
                            })
                            // setReloadSource(!reloadSource)
                        })
                        .catch(err => {
                            setIsStatus({ failed: true, msg: 'An error occurred, please try again later.' });
                            setOpenSnackBar(true);
                        })
                        .finally(e => {
                            setSaveItems(false);
                            // setCommissionsFilter((_price) => {
                            //     _price[index] = { ..._price[index], change: false, saving: false };
                            //     return [..._price]
                            // })
                        });
                }
            }
        })
            .catch(function (err) {
                let listError = [];
                let listTouched = [];
                err.inner.forEach((e, i) => {
                    listTouched[`prices[${index}].${e.path}`] = true;
                    listError[`prices[${index}].${e.path}`] = e.message;
                });
                setTouchItem(listTouched);
                setErrorItem(listError);
            });
    }

    const saveAllItemPrice = () => {
        validateSchema.validate({ prices: saleCommissions }, { abortEarly: false }).then(() => {
            setErrorItem((pre) => {
                pre = [];
                return [...pre];
            });
            //save
            setSaveItems(true);
            const data = saleCommissions.map(item => {
                return {
                    id: item.id ? item.id : "00000000-0000-0000-0000-000000000000",
                    employeeId: item.employeeId ? item.employeeId : employees[0].id,
                    jobSplitId: jobId,
                    isReturningCustomer: item.isReturningCustomer,
                    amountAllocated: item.amountAllocated ? item.amountAllocated.toString().replace(/[\$,]/g, '') : 0,
                    rate: item.rate ? item.rate.toString().replace(/[%,]/g, '') : 0,
                    amountDueToPerson: item.amountDueToPerson ? item.amountDueToPerson.toString().replace(/[\$,]/g, '') : 0,
                    tip: item.tip ? item.tip.toString().replace(/[\$,]/g, '') : 0,
                    isScheduled: item.isScheduled,
                }
            })
            axios.put(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_COMMISSION_SALE_ALL, data, { params: { jobSplitId: jobId } })
                .then(res => {
                    setIsStatus({ failed: false, msg: 'Save all successfully!' });
                    setOpenSnackBar(true);
                    setEnableSaveAll(false);
                    setSaleCommissions((_price) => {
                        const price = res.data.map(item => {
                            return {
                                ...item,
                                saleType: item.isReturningCustomer ? 'returning_sale' : 'new_sale',
                                new_sale: item.rate,
                                returning_sale: item.rate,
                                change: false,
                                saving: false
                            }
                        })
                        return price
                    })
                })
                .catch(err => {
                    setIsStatus({ failed: true, msg: 'An error occurred, please try again later.' });
                    setOpenSnackBar(true);
                })
                .finally(e => {
                    setSaveItems(false);
                });
            //end save
        })
            .catch(function (err) {
                let listError = [];
                let listTouched = [];
                err.inner.forEach((e, i) => {
                    listTouched[`${e.path}`] = true;
                    listError[`${e.path}`] = e.message;
                });
                setTouchItem(listTouched);
                setErrorItem(listError);
            });
    }

    const updateSplitType = (type) => {
        setTypeSaving(true);
        const data = {
            jobSplitId: jobId,
            commissionLaborSplitType: type
        }
        axios.put(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_SALES_SPLIT_TYPE, data)
            .then(value => {
                setPaySplitType(type);
                setIsStatus({ failed: false, msg: 'Update split type successfully!' });
                setOpenSnackBar(true);

                if (type == 1) {
                    let subtotal = getSubTotal(jobItems,information);
                    const data = saleCommissions.map(item => {
                        const mapped = {
                            id: item.id ? item.id : "00000000-0000-0000-0000-000000000000",
                            employeeId: item.employeeId ? item.employeeId : employees[0].id,
                            jobSplitId: jobId,
                            isReturningCustomer: item.isReturningCustomer,
                            amountAllocated: (subtotal / saleCommissions.length).toFixed(2),
                            rate: item.rate ? item.rate.toString().replace(/[%,]/g, '') : 0,
                            tip: item.tip ? item.tip.toString().replace(/[\$,]/g, '') : 0,
                            isScheduled: item.isScheduled,
                        }
                        mapped.amountDueToPerson = (mapped.amountAllocated * mapped.rate / 100).toFixed(2);
                        return mapped;
                    })
                    return axios.put(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_COMMISSION_SALE_ALL, data, { params: { jobSplitId: jobId } })
                        .then(res => {
                            setIsStatus({ failed: false, msg: 'Save all successfully!' });
                            setOpenSnackBar(true);
                            setEnableSaveAll(false);
                            setSaleCommissions((_price) => {
                                const price = res.data.map(item => {
                                    return {
                                        ...item,
                                        saleType: item.isReturningCustomer ? 'returning_sale' : 'new_sale',
                                        new_sale: item.rate,
                                        returning_sale: item.rate,
                                        change: false,
                                        saving: false
                                    }
                                })
                                return price
                            })
                        })
                        .catch(err => {
                            setIsStatus({ failed: true, msg: 'An error occurred, please try again later.' });
                            setOpenSnackBar(true);
                        })
                        .finally(e => {
                            setSaveItems(false);
                        });
                }
            })
            .catch(ex => {
                setIsStatus({ failed: true, msg: 'Update split type fail!' });
                setOpenSnackBar(true);
            })
            .finally(() => {
                setTypeSaving(false);
            })
    }
    const getCommissionSpaySplitType = () => {
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_PAY_SPLIT_TYPE)
            .then(value => {
                setLstSplitType(value.data);
            }).finally(() => {
            })
    }

    useEffect(() => {
        getCommissionSale(jobId);
    }, [sortBy, sortType])

    useEffect(() => {
        getCommissionSpaySplitType()
    }, [])

    useEffect(() => {
        setPaySplitType(splitType ?? 3);
    }, [splitType]);

    useEffect(() => {
        if (saleCommissions && saleCommissions.length > 0) {
            setEnableSaveAll(saleCommissions.find(x => x.change === true))
        } else {
            setEnableSaveAll(false);
        }
    }, [saleCommissions])

    useEffect(() => {
        getCommissionSale(jobId);
    }, [reload])

    return (
        <Card style={{ marginBottom: '16px' }}>
            <CardHeader className={classes.cardHeader}
                title='Sales Commission Pay Assignment'
                action={expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                onClick={toggleExpanded}
            />
            <Divider />
            <Collapse in={expanded} timeout='auto' unmountOnExit>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{ textAlign: 'right' }}>
                            {
                                lstSplitType && lstSplitType.length > 0 &&
                                <TextField
                                    onClick={e => e.stopPropagation()}
                                    style={{ width: '250px' }}
                                    disabled={disabled}
                                    name='paysaplittype'
                                    variant='outlined'
                                    select
                                    label='Distribute amounts by'
                                    onChange={x => {
                                        updateSplitType(x.target.value)
                                    }}
                                    value={paySplitType || ''}
                                    SelectProps={{ native: true }}>
                                    {lstSplitType.filter(em => em.id !== 2).map((em, index) => (
                                        <option key={index} value={em.id}>{em.description}</option>
                                    ))}
                                </TextField>
                            }
                            {typeSaving && <CircularProgress size={24} />}
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.actionsSm}>
                        <Grid item>
                            <Button
                                size={'small'} style={{ margin: ipPlus ? '8px 0' : '0 16px 0 0' }}
                                className={(disabled || !enableSaveAll || (saveItems && !locateItem)) ? classes.btnDis : classes.button}
                                onClick={() => {
                                    setLocateItem(null)
                                    saveAllItemPrice()
                                }}
                                variant="contained">
                                Save All
                            </Button>
                            {saveItems && !locateItem && <CircularProgress size={24} />}
                        </Grid>
                        <Grid item>
                            <MuiThemeProvider theme={customThemeTooltip}>
                                <Tooltip title='Add Commission Payment' placement='bottom' arrow>
                                    <IconButton className={(disabled || !(employees && employees.length > 0)) ? classes.btnDis : classes.add} onClick={() => addSaleCommission()}
                                        style={disabled ? { backgroundColor: 'transparent' } : null}>
                                        <AddBoxIcon />
                                    </IconButton>
                                </Tooltip>
                            </MuiThemeProvider>
                        </Grid>
                    </Grid>
                    <TableContainer className={classes.tbContainer}>
                        <Table style={{ minWidth: '1000px' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell onClick={() => loadDocument('Salesperson')} align='center'
                                               style={{ minWidth: '200px', width: '20%' }}
                                        className={classes.headCell}>
                                        <span>Salesperson{ getSortIcon('Salesperson')}</span>
                                        </TableCell>
                                    <TableCell style={{ width: '1%', padding: 0 }} align='center'
                                        className={classes.headCell}></TableCell>
                                    <TableCell onClick={() => loadDocument('SaleType')} align='center'
                                               style={{ width: '15%' }}
                                        className={classes.headCell}>
                                        <span>Sale Type{ getSortIcon('SaleType')}</span>
                                        </TableCell>
                                    <TableCell onClick={() => loadDocument('Sale')} align='center'
                                               style={{ width: '10%' }} className={classes.headCell}>
                                        <Typography>
                                            <span>Sale{ getSortIcon('Sale')}</span>
                                            </Typography>
                                    </TableCell>
                                    <TableCell onClick={() => loadDocument('AmountAllcation')} align='center'
                                               tyle={{ width: '15%' }} className={classes.headCell}>
                                        <span>Amount Allcation{ getSortIcon('AmountAllcation')}</span>
                                        </TableCell>
                                    <TableCell onClick={() => loadDocument('SalesCommissionAmountDuetoSalesperson')} align='center' style={{ width: '25%', minWidth: '300px' }}
                                        className={classes.headCell}>
                                        <span>
                                        <Typography>
                                            Sales Commission Amount
                                         <br />
                                            Due to Salesperson
                                        </Typography>
                                        { getSortIcon('SalesCommissionAmountDuetoSalesperson')}</span>
                                    </TableCell>
                                    <TableCell align='center' className={clsx(classes.headCell, classes.headerActionCell)}
                                        style={{ width: '113px', minWidth: '113px', padding: '16px 8px' }}>
                                        <div style={ipPlus ? {
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            flexDirection: 'column'
                                        } : { display: 'flex', alignItems: 'center' }}>
                                            <Button
                                                size={'small'} style={{ margin: ipPlus ? '8px 0' : '0 16px 0 0' }}
                                                className={(disabled || !enableSaveAll || (saveItems && !locateItem)) ? classes.btnDis : classes.button}
                                                onClick={() => {
                                                    setLocateItem(null)
                                                    saveAllItemPrice()
                                                }}
                                                variant="contained">
                                                Save All
                                            </Button>
                                            {saveItems && !locateItem && <CircularProgress size={24} />}
                                            {/*<Button  className={classes.button}>Save All</Button>*/}
                                        </div>
                                    </TableCell>
                                    <TableCell align='center' style={{ width: '1px' }}
                                        className={clsx(classes.headCell, classes.headerActionCell)}>
                                        <MuiThemeProvider theme={customThemeTooltip}>
                                            <Tooltip title='Add Commission Payment' placement='bottom' arrow>
                                                <IconButton className={(disabled || !(employees && employees.length > 0)) ? classes.btnDis : classes.add} onClick={() => addSaleCommission()}
                                                    style={disabled ? { backgroundColor: 'transparent' } : null}>
                                                    <AddBoxIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </MuiThemeProvider>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {employees.length > 0 && saleCommissions.length > 0 && saleCommissions.map((sale, index) => (
                                    <TableRow key={index}>
                                        <TableCell
                                            style={{ display: 'table-cell', verticalAlign: 'middle', paddingRight: 0 }}>
                                            {
                                                employees &&
                                                (() => {
                                                    let emp = employeesAll ? employeesAll.find(x => x.id === sale.employeeId) : '';
                                                    let linkTemplate = <Tooltip arrow
                                                        title={'View employee compensation'}>
                                                        <a
                                                            style={{
                                                                color: !!emp ? '#47a3ff' : '#969696',
                                                                cursor: !!emp ? 'pointer' : 'not-allowed'
                                                            }}
                                                            onClick={(e) => {
                                                                if (!emp) {
                                                                    e.preventDefault()
                                                                }
                                                            }}
                                                            href={emp ? `employees/${encodeURIComponent(emp.firstName)}_${encodeURIComponent(emp.lastName)}/${emp.id}/salary` : ''}
                                                            target='_blank'>
                                                            <i style={{
                                                                marginLeft: '10px',
                                                                fontSize: '16px',
                                                            }} className="fas fa-external-link" />
                                                        </a>
                                                    </Tooltip>
                                                    sale['tooltipTemp'] = linkTemplate
                                                    if (!sale.isScheduled) {
                                                        return <><TextField
                                                            error={!sale.employeeId && errorItem && errorItem[`prices[${index}].employeeId`]}
                                                            helperText={!sale.employeeId && errorItem && errorItem[`prices[${index}].employeeId`]}
                                                            fullWidth
                                                            style={{ paddingRight: 0 }}
                                                            disabled={disabled}
                                                            name='employeeId'
                                                            variant='outlined'
                                                            select
                                                            value={sale.employeeId || ''}
                                                            onChange={e => onChangesaleCommission(e, index)}
                                                            SelectProps={{ native: true }}>
                                                            {<option key='' value={''}></option>}
                                                            {employees && employees.map((em, index) => (
                                                                <option key={index}
                                                                    value={em.id}>{(em.firstName || '') + ' ' + (em.lastName || '')}</option>
                                                            ))}
                                                        </TextField> </>
                                                    } else {
                                                        return emp && <> <p
                                                            style={{ display: 'inline-block' }}>{(emp.firstName || '') + ' ' + (emp.lastName || '')}</p></>
                                                    }
                                                })()
                                            }
                                        </TableCell>
                                        <TableCell align='center' style={{ padding: 0 }}>
                                            {sale.tooltipTemp}
                                        </TableCell>
                                        <TableCell align='center'>
                                            <TextField
                                                fullWidth
                                                disabled={disabled}
                                                name="saleType"
                                                select
                                                SelectProps={{ native: true }}
                                                variant="outlined"
                                                value={sale.saleType || 'new_sale'}
                                                onChange={event => changeSaleType(index, event.target.value)}>
                                                <option key={'new_sale'} value={'new_sale'}>
                                                    New Sale
                                                </option>
                                                <option key={'returning_sale'} value={'returning_sale'}>
                                                    Returning Sale
                                                </option>
                                            </TextField>
                                        </TableCell>
                                        <TableCell align='center'>
                                            {sale.saleType === 'new_sale' ? sale.new_sale : sale.returning_sale}%
                                        </TableCell>
                                        <TableCell align='center'>
                                            <NumberFormat
                                                disabled={disabled || paySplitType != 3}
                                                customInput={TextField}
                                                allowNegative={false}
                                                prefix='$'
                                                thousandSeparator
                                                decimalScale={2}
                                                fullWidth
                                                variant='outlined'
                                                name='amountAllocated'
                                                value={sale.amountAllocated ? parseFloat(sale.amountAllocated.toString().replace(/[\$,]/g, '')).toFixed(2) : ''}
                                                onChange={e => onChangesaleCommission(e, index)}
                                            />
                                        </TableCell>
                                        <TableCell align='center'>
                                            <NumberFormat
                                                disabled
                                                inputProps={{ maxLength: 8 }}
                                                customInput={TextField}
                                                allowNegative={false}
                                                prefix='$'
                                                thousandSeparator
                                                decimalScale={2}
                                                fullWidth
                                                variant='outlined'
                                                name='amountDueToPerson'
                                                value={sale.amountDueToPerson ? parseFloat(sale.amountDueToPerson).toFixed(2) : ''}
                                            />
                                        </TableCell>
                                        <TableCell style={{ padding: '16px 8px' }} align='center'>
                                            <CardActions>
                                                <Button
                                                    size={'small'}
                                                    style={{ margin: ipPlus ? '8px 0' : '0 16px 0 0' }}
                                                    disabled={disabled}
                                                    className={!sale.change ? classes.btnDis : classes.button}
                                                    onClick={() => setTimeout(() => {
                                                        {
                                                            setLocateItem({
                                                                index: index,
                                                                id: sale.id,
                                                                add: sale.add
                                                            });
                                                            setChange(true);
                                                            onSaveCustomerPayment(sale, index, true);
                                                        }
                                                    }, 0)}
                                                >Save</Button>
                                                {sale.saving && change && <CircularProgress size={24} />}
                                            </CardActions>
                                        </TableCell>
                                        <TableCell align='center'>
                                            <IconButton disabled={disabled} className={classes.removeBtn} onClick={() => {
                                                setLocateItem({
                                                    index: index,
                                                    id: sale.id,
                                                    add: sale.add,
                                                });
                                                setIndex(index);
                                                setOpenConfirm(true)
                                            }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Collapse>
            <AddSaleCommission
                open={openModal}
                close={() => setOpenModal(false)}
                save={addSaleCommission}
            />
            <ConfirmModal
                isProgress={deleting}
                openConfirm={openConfirm}
                closeConfirm={() => setOpenConfirm(false)}
                onConfirm={removeSaleCommission}
            />
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openSnackbar}
                autoHideDuration={3000}
                onClose={() => setOpenSnackBar(false)}>
                <Alert
                    elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </Card>
    )
}

export default SalesCommission;
