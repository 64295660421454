import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
    Modal, Card, CardHeader, CardContent, Divider,
    Grid, Typography, FormControlLabel, Button,
    colors, CardActions, TextField, Chip, CircularProgress
} from '@material-ui/core'
import { useParams } from 'react-router-dom'
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { GreenCheckbox } from 'components'
import apiConfig from 'apiConfig'
import axios from 'utils/axios'

const useStyles = makeStyles(theme => ({
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 700,
        maxHeight: '90%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: 500
        },
    },
    exclude: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    days: {
        display: 'block',
        flexWrap: 'wrap'
    },
    day: {
        marginLeft: 0,
        margin: '0 20px',
        '& span': {
            padding: 0
        }
    },
    note: {
        textAlign: 'left',
        paddingRight: '20px',
        fontSize: '12px'
    },
    select: {
        padding: theme.spacing(1),
        boxShadow: '0 0px 12px 0px #cacaca',
        position: 'absolute',
        top: '50%',
        left: '5%',
        zIndex: 2,
        transform: 'translateY(-50%)',
        width: '90%',
        borderRadius: '4px',
        backgroundColor: '#ffffff',
        visibility: 'hidden',
        opacity: '0',
        transition: 'all .3s'
    },
    selectInput: {
        marginBottom: theme.spacing(1),
        position: 'sticky',
        top: 0,
        backgroundColor: '#ffffff'
    },
    clearIcon: {
        position: 'absolute',
        top: '3px',
        right: 0,
    },
    options: {
        maxHeight: '200px',
        overflowY: 'auto',
    },
    option: {
        padding: '8px',
        fontSize: theme.spacing(2),
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#e0e0e0'
        }
    },
    button: {
        minWidth: '170px',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
    chipItem: {
        borderRadius: '6px',
        margin: '3px',
        padding: '0px',
        height: '28px'
    },
    actionsBox: {
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
}));

const getInforJobs = (jobsData) => {
    if (jobsData && jobsData.length > 0) {
        let city = '';
        let price = 0;
        // let start = ''
        // let end = ''
        jobsData.map(js => {
            const pr = js.job ? js.job.price : 0
            price += parseInt(pr.toString().replace('$', ''));

            // start +=  ' - ' + moment(js.jobStart).format('hh A')
            // end += ' - ' + moment(js.jobEnd).format('hh A')
            city += js.job && js.job.city ? js.job.city + ', ' : '';
        })
        if (price === 0) {
            price = '0.00'
        }
        return city + '$' + price;
    }
    return '';
}

const CloneModal = ({ router, open, onClose, addCloneRoute }) => {

    const { date } = useParams();
    const classes = useStyles();
    const cleanersRef = useRef();
    const equipmentsRef = useRef();

    const [excludeDays, setExcludeDays] = useState([]);
    const [openCleaners, setOpenCleaners] = useState(false);
    const [openEquipments, setOpenEquipments] = useState(false);
    const [errorItem, setErrorItem] = useState({});
    const [endDate, setEndDate] = useState();
    const [isCloning, setIsCloning] = useState(false);
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });
    // change split
    const handleThruDateChange = (date, value) => {
        setEndDate(date);
        setErrorItem({});
    }
    const onChangeExcludeDays = val => {
        const dayIndex = excludeDays.findIndex(day => day === val);
        const days = [...excludeDays];
        dayIndex > -1 ? days.splice(dayIndex, 1) : days.push(val);
        setExcludeDays(days)
    }

    const cloneModal = () => {
        if (!endDate) {
            setErrorItem({ endDate: 'Required.' });
            return;
        }
        const data = {
            routeId: router.id,
            endDate: endDate.toISOString(),
            days: [...excludeDays]
        }
        setIsCloning(true);
        axios.post(apiConfig.url.BASE_URL + apiConfig.url.ROUTE_CLONE.replace('{routeId}', router.id), data)
            .then((res) => {
                const { clonedRoutes, unavailableEmployees, unavailableEquipment } = res.data;

                if (clonedRoutes) {
                    const cloneRoute = clonedRoutes.find(r => r.routeDate === new Date(date).toISOString().replace('.000Z', ''));
                    cloneRoute && addCloneRoute(cloneRoute);
                    setIsStatus({ failed: false, msg: 'Clone route successfully.' });
                    setOpenSnackBar(true)
                } else if (
                    unavailableEmployees &&
                    unavailableEquipment &&
                    unavailableEmployees.length > 0 &&
                    unavailableEquipment.length > 0
                ) {
                    setIsStatus({ failed: true, msg: `Sorry, this route can not be cloned because ${unavailableEmployees[0].firstName} ${unavailableEmployees[0].lastName} and ${unavailableEquipment[0].assetTag} are not available on one or more of the selected days.` });
                    setOpenSnackBar(true)
                } else if (unavailableEmployees && unavailableEmployees.length > 0) {
                    setIsStatus({ failed: true, msg: `Sorry, this route can not be cloned because ${unavailableEmployees[0].firstName} ${unavailableEmployees[0].lastName} are not available on one or more of the selected days.` });
                    setOpenSnackBar(true)
                } else if (unavailableEquipment && unavailableEquipment.length > 0) {
                    setIsStatus({ failed: true, msg: `Sorry, this route can not be cloned because ${unavailableEquipment[0].assetTag} are not available on one or more of the selected days.` });
                    setOpenSnackBar(true)
                }

            }).catch(() => {
                setIsStatus({ failed: true, msg: 'Clone route failed, please try again later.' });
                setOpenSnackBar(true)
            }).finally(() => {
                setIsCloning(false);
                onCloseModal()
            })
    }

    const onCloseModal = () => {
        setExcludeDays([]);
        setEndDate(null);
        onClose();
    }

    useEffect(() => {
        const clickOutsideSelect = (event) => {
            if (openCleaners && cleanersRef.current && !cleanersRef.current.contains(event.target)) {
                setOpenCleaners(false);
            }
            if (openEquipments && equipmentsRef.current && !equipmentsRef.current.contains(event.target)) {
                setOpenEquipments(false);
            }
        }
        document.addEventListener('click', clickOutsideSelect);
        return () => document.removeEventListener('click', clickOutsideSelect)
    }, [openCleaners, openEquipments])

    return (
        <div>
            <Modal open={open} onClose={onCloseModal}>
                <Card className={classes.styleModal}>
                    <CardHeader title='Clone Route' />
                    <Divider />
                    <CardContent>
                        <Grid container spacing={2} style={{ alignItems: 'flex-start' }}>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    fullWidth
                                    aria-readonly='true'
                                    label='Route Jobs'
                                    variant='outlined'
                                    disabled
                                    value={router && router.routeJobs ? getInforJobs(router.routeJobs) : ''}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    aria-readonly='true'
                                    label='Original Route Date'
                                    variant='outlined'
                                    disabled
                                    value={router ? moment(router.routeDate).format("MM/DD/YYYY") : ''}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <KeyboardDatePicker
                                    error={errorItem.endDate ? true : false}
                                    helperText={errorItem.endDate && errorItem.endDate}
                                    fullWidth
                                    inputVariant="outlined"
                                    label="Route End Date"
                                    format="MM/DD/YYYY"
                                    minDate={router ? new Date(router.routeDate).setDate(new Date(router.routeDate).getDate() + 1) : null}
                                    value={endDate || null}
                                    InputAdornmentProps={{ position: "end" }}
                                    onChange={(date, value) => handleThruDateChange(date, value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography style={{ marginRight: '5px', whiteSpace: 'nowrap' }}>Employees</Typography>
                                {
                                    router && router.routeEmployees && router.routeEmployees.map((item, i) => (
                                        <Chip key={`model-employee-${i}`}
                                            className={classes.chipItem}
                                            label={`${item.firstName || ''} ${item.lastName || ''}`}
                                            variant="outlined"
                                        />
                                    ))
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Typography style={{ marginRight: '5px', whiteSpace: 'nowrap' }}>Equipment</Typography>
                                {
                                    router && router.routeEquipment && router.routeEquipment.map((item, i) => (
                                        <Chip
                                            key={`model-equipment-${i}`}
                                            className={classes.chipItem}
                                            label={item.assetTag}
                                            variant="outlined"
                                        />
                                    ))
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Typography style={{ marginRight: '5px', whiteSpace: 'nowrap' }}>Create Routes For :</Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.exclude}>
                                <div className={classes.days}>
                                    <FormControlLabel
                                        className={classes.day}
                                        checked={excludeDays.findIndex(day => day === 0) > -1}
                                        onChange={() => onChangeExcludeDays(0)}
                                        control={
                                            <GreenCheckbox />
                                        }
                                        label="Sunday"
                                    />
                                    <FormControlLabel
                                        className={classes.day}
                                        checked={excludeDays.findIndex(day => day === 1) > -1}
                                        onChange={() => onChangeExcludeDays(1)}
                                        control={
                                            <GreenCheckbox />
                                        }
                                        label="Monday"
                                    />
                                    <FormControlLabel
                                        className={classes.day}
                                        checked={excludeDays.findIndex(day => day === 2) > -1}
                                        onChange={() => onChangeExcludeDays(2)}
                                        control={
                                            <GreenCheckbox />
                                        }
                                        label="Tuesday"
                                    />
                                    <FormControlLabel
                                        className={classes.day}
                                        checked={excludeDays.findIndex(day => day === 3) > -1}
                                        onChange={() => onChangeExcludeDays(3)}
                                        control={
                                            <GreenCheckbox />
                                        }
                                        label="Wednesday"
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} className={classes.exclude}>
                                <div className={classes.days}>
                                    <FormControlLabel
                                        className={classes.day}
                                        checked={excludeDays.findIndex(day => day === 4) > -1}
                                        onChange={() => onChangeExcludeDays(4)}
                                        control={
                                            <GreenCheckbox />
                                        }
                                        label="Thursday"
                                    />
                                    <FormControlLabel
                                        className={classes.day}
                                        checked={excludeDays.findIndex(day => day === 5) > -1}
                                        onChange={() => onChangeExcludeDays(5)}
                                        control={
                                            <GreenCheckbox />
                                        }
                                        label="Friday"
                                    />
                                    <FormControlLabel
                                        className={classes.day}
                                        checked={excludeDays.findIndex(day => day === 6) > -1}
                                        onChange={() => onChangeExcludeDays(6)}
                                        control={
                                            <GreenCheckbox />
                                        }
                                        label="Saturday"
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider />

                    <CardActions>
                        <Grid container spacing={2}>
                            <Grid item xs={7}>
                                <Typography className={classes.note}>
                                    Cloning this route will create future empty routes for automatic route creation. The Employees and Equipment assigned will be cloned with the route. Any job(s) scheduled will not be cloned along with this route. If you wish to schedule a job over multiple days with employees and equipment assigned, use the "split job" tab on the job screen and choose "Automatic Splits".
                                </Typography>
                            </Grid>
                            <Grid item xs={5} className={classes.actionsBox}>
                                <CardActions>
                                    <div className={classes.actions}>
                                        <Button
                                            onClick={onCloseModal}
                                            variant="contained">
                                            Close
                                        </Button>
                                        <Button disabled={isCloning} style={{marginLeft: 10}}
                                                className={classes.button}
                                                variant="contained"
                                                color="primary"
                                                onClick={cloneModal}>
                                            Generate Routes
                                        </Button>
                                    </div>
                                    {isCloning && <CircularProgress size={24}/>}
                                </CardActions>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
            </Modal>

            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnackbar}
                autoHideDuration={5000}
                onClose={() => setOpenSnackBar(false)}>
                <Alert elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </div>
    )
}

CloneModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    addCloneRoute: PropTypes.func
}

export default CloneModal;
