import React, {useEffect, useRef, useState} from 'react'
import {makeStyles} from '@material-ui/styles'
import {
    Card, CardContent, Divider,
    TableContainer, Table, TableHead,
    TableBody, TableRow, TableCell, colors,
    Typography, Button, IconButton, Tooltip, Collapse, Grid, TextField, CircularProgress, CardActions, CardHeader
} from '@material-ui/core'
import {AddBox, ArrowDownward, ArrowUpward, Delete, KeyboardArrowDown, KeyboardArrowUp} from '@material-ui/icons'
import AddPayment from './AddPayment'
import {ConfirmModal} from 'components'
import {createTheme, MuiThemeProvider} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {KeyboardDatePicker} from "@material-ui/pickers";
import moment from "moment";
import NumberFormat from "react-number-format";
import axios from "../../../../../../utils/axios";
import apiConfig from "../../../../../../apiConfig";
import CustomerPaymentLine from "./customerPaymentLine";
import * as Yup from "yup";
import uuid from "uuid/v1";
import PricingLine from "../../../../../Admin/components/Pricing/PricingLine";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import {useDispatch} from "react-redux";
import localStorage from 'utils/localStorage'
import { PERMISSIONS } from 'common/permissions'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
    title: {
        cursor: 'pointer',
        '& svg': {
            marginRight: theme.spacing(1)
        }
    },
    cardTitle: {
        fontSize: '16px',
        fontWeight: 500,
        width: '255px',
        marginRight: theme.spacing(3)
    },
    headCell: {
        borderBottom: '1px solid #888888',
        fontSize: '16px',
        padding: '8px 16px',
        cursor: 'pointer',
        '& span': {
            display: 'flex',
            alignItems: 'center'
        }
    },
    headerActionCell: {
        [theme.breakpoints.down('sm')]: {
            width: '0 !important',
            minWidth: '0 !important',
            '& > *': {
                display: 'none !important'
            }
        }
    },
    button: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    add: {
        padding: '0',
        '& span svg': {
            fontSize: '40px',
            color: colors.green[600],
            '&:hover': {
                color: colors.green[900]
            }
        },

        '&:disabled span svg': {
            color: 'rgba(0, 0, 0, 0.26)',
        }
    },
    removeBtn: {
        '& span svg': {
            fontSize: '32px'
        }
    },
    btnDis: {
        whiteSpace: 'nowrap',
        color: 'rgba(0, 0, 0, 0.26)',
        backgroundColor: 'rgb(217 217 217)',
        pointerEvents: 'none'
    },
    iconSort: {
        marginBottom: '-5px',
        marginLeft: '10px'
    },
    customTableCell: {
        borderBottom: '1px solid #969696',
        cursor: 'pointer'
    },
    actionsSm: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'end',
            flexWrap: 'wrap'
        }
    }
}))

const customThemeTooltip = createTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: '14px',
                maxWidth: 'none'
            }
        }
    }
})

const validateRowChema = Yup.object().shape({
    paymentDate: Yup.date().typeError("Invalid date"),
    paymentTypeId: Yup.string().required("Required"),
    amount: Yup.string().required("Please enter tip"),
    checkNumber: Yup.string().nullable()
        .when('paymentTypeId', {
            is: val => val === '6372bd5e-0c96-412d-8148-d2d9927c1a36',
            then: Yup.string().required('Required')
        }),
})

const validateSchema = Yup.object().shape({
    customerPayment: Yup.array()
        .of(
            validateRowChema
        )
});
let pageNumber = 1;
let forcePage = 0;

const CustomerPayment = props => {
    const dispatch = useDispatch();
    const {id, jobId, data, reload} = props;
    const classes = useStyles();
    const isMounted = useRef(false);
    const ipPlus = useMediaQuery('(max-width: 415px)');

    const [openModal, setOpenModal] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [payments, setPayments] = useState([]);
    const [index, setIndex] = useState();
    const [expanded, setExpanded] = useState(true);
    const [paymentType, setPaymentType] = useState();
    const [errorItem, setErrorItem] = useState([]);
    const [isStatus, setIsStatus] = useState({failed: false, msg: ''});
    const [saveItems, setSaveItems] = useState(false);
    const [prices, setPrices] = useState([])
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [touchItem, setTouchItem] = useState([]);
    const [locateItem, setLocateItem] = useState({});
    const [enableSaveAll, setEnableSaveAll] = useState(false);
    const [poting, setPoting] = useState(false);
    const [postingAll, setPostingAll] = useState(false);
    const [postAll, setPostAll] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [sortBy, setSortBy] = useState('Date');
    const [sortType, setSortType] = useState('ASC');

    const userRoles = localStorage.getUserRoles();
    const isFieldTechnician = !userRoles || (userRoles.length === 1 && userRoles[0] === PERMISSIONS.FIELD_TECHNICIAN);

    const addPayment = payment => {
        setPayments([...payments, payment])
    }
    const removePayment = () => {
        if (locateItem.id) {
            const data = {
                id: locateItem.id
            }
            setDeleting(true)
            axios.delete(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_COMPLETED_PAYMENT, {params: {id: locateItem.id}})
                .then(value => {
                    const pays = [...payments];
                    pays.splice(index, 1);
                    setPayments(pays);
                    setOpenConfirm(false);
                    setIsStatus({failed: false, msg: 'Remove successfully.'});
                    setOpenSnackBar(true);
                }).finally(() => {
                    reload && reload();
                    setDeleting(false)
            })
        } else {
            const pays = [...payments];
            pays.splice(index, 1);
            setPayments(pays);
            setOpenConfirm(false);
            setIsStatus({failed: false, msg: 'Remove successfully.'});
            setOpenSnackBar(true);
            reload && reload()
        }
    }
    const toggleExpanded = () => {
        setExpanded(!expanded);
    }
    const onHandleChangeDate = (date, index) => {
        const pays = [...payments];
        pays[index] = {...pays[index], paymentDate: moment(date).format('MM/DD/YYYY'), change: true};
        setPayments([...pays])
    }
    const onChangePayment = (event, index) => {
        const {name, value} = event.target;
        const pays = [...payments];
        pays[index] = {...pays[index],
            [name]: value,
            checkNumber: name === 'paymentTypeId' && value === 'e3a112a6-bfe0-4267-b252-6fcca85ec398' ?
                '' : name === 'checkNumber' ?  value: pays[index].checkNumber,  change: true}
        setPayments(pays)
    }

    const openConfirmDelete = (data) => {
        setOpenConfirm(data);
    }
    const onBlueItem = (key, index) => {
        // const touched = {...touchItem};
        setTouchItem((prevTouched) => {
            const prev = {...prevTouched}
            prev[`prices[${index}].${key}`] = true;
            return {...prev}
        });
    }
    const addSaleCommission = () => {
        setPayments([...payments, {
            paymentDate: '',
            paymentTypeId: '',
            amount: '',
            checkNumber: '',
            postDate: '',
            notes: '',
        }])
    }

    const getCustomerPayment = () => {
        setLoading(true);
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_COMPLETED_PAYMENT,
            {
                params: {
                        jobId: jobId,
                        sortBy: sortBy,
                        sortType: sortType
                    }
            }
            )
            .then(value => {
                setPayments(value.data);
                dispatch({ type: 'INIT_JOB_PAYMENT', payments: value.data })
                const notPosted = value.data.find(x => !x.queuedToQuickbooks)
                setPostAll(!notPosted);
            }).finally(() => {
            setLoading(false);
        })
    }

    const postAllQuickBook = (jobId) => {
        setPostingAll(true);
        axios.post(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMERS_COMPLETED_POST_ALL_TO_QUICKBOOK, {}, {params: {jobId: jobId}})
            .then(value => {
                getCustomerPayment();
                setIsStatus({failed: false, msg: 'Posted quickbooks successfully.'});
                setOpenSnackBar(true);
            })
            .catch(ex => {
                setIsStatus({failed: true, msg: 'Posted quickbooks failed.'});
                setOpenSnackBar(true);
            })
            .finally(() => {
                setPostingAll(false);
            })
    }

    const postQuickBook = async (id, index) => {
        setPoting(false);
        let jobPaymentId = id;
        const payment = payments[index];
        if (payment.change) {
            const paymentSaved = await onSaveCustomerPayment(payment, index, true);
            if (!paymentSaved) return;
            jobPaymentId = paymentSaved.id;
        }
        setPayments((_price) => {
            _price[index] = {..._price[index], posting: true};
            return [..._price]
        })
        axios.post(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMERS_COMPLETED_POST_TO_QUICKBOOK, {}, {params: {jobPaymentId}})
            .then(value => {
                getCustomerPayment();
                setIsStatus({failed: false, msg: 'Posted quickbooks successfully.'});
                setOpenSnackBar(true);
            }).catch(ex => {
            setIsStatus({failed: true, msg: 'Posted quickbooks failed.'});
            setOpenSnackBar(true);
        }).finally(() => {
            setPayments((_price) => {
                _price[index] = {..._price[index], posting: false};
                return [..._price]
            })
            setPoting(!poting);
        })
    }

    const onSaveCustomerPayment = (item, index, submit = false) => {
        return new Promise((resolve, reject) => {
            validateRowChema.validate(item, {abortEarly: false}).then(() => {
                setErrorItem((pre) => {
                    pre = [];
                    return [...pre];
                });
                if (submit) {
                    setSaveItems(true);
                    setPayments((_price) => {
                        _price[index] = {..._price[index], saving: true};
                        return [..._price]
                    })

                    const data = {
                        "id": item.id ? item.id : "00000000-0000-0000-0000-000000000000",
                        "companyId": id,
                        "paymentDate": moment(item.paymentDate).tz(moment.tz.guess()).format(),
                        "jobId": jobId,
                        "paymentTypeId": item.paymentTypeId,
                        "amount": item.amount.toString().replace('$', '').replace(',', ''),
                        "checkNumber": item.checkNumber.toString().replace('$', '').replace(',', ''),
                        "notes": item.notes,
                        "datePosted": "",
                        "qbId": "",
                        "dateCreated": moment(item.date).tz(moment.tz.guess()).format(),
                        "createdById": "",
                        "dateModified": moment(item.date).tz(moment.tz.guess()).format(),
                        "modifiedById": ""
                    }

                    if (item.id) {
                        return axios.put(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_COMPLETED_PAYMENT, data)
                            .then(res => {
                                setIsStatus({failed: false, msg: 'Save successfully!'});
                                setOpenSnackBar(true);
                                item.change = false;
                                reload && reload();
                                // setReloadSource(!reloadSource)
                                resolve(res.data);
                            })
                            .catch(err => {
                                setIsStatus({failed: true, msg: 'An error occurred, please try again later.'});
                                setOpenSnackBar(true);
                            })
                            .finally(e => {
                                setSaveItems(false);
                                setPayments((_price) => {
                                    _price[index] = {..._price[index], change: false, saving: false};
                                    return [..._price]
                                })
                            });
                    } else {
                        return axios.post(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_COMPLETED_PAYMENT, data)
                            .then(res => {
                                setIsStatus({failed: false, msg: 'Save successfully!'});
                                setOpenSnackBar(true);
                                item.change = false
                                // setReloadSource(!reloadSource)
                                reload && reload();
                                getCustomerPayment();
                                resolve(res.data);
                            })
                            .catch(err => {
                                setIsStatus({failed: true, msg: 'An error occurred, please try again later.'});
                                setOpenSnackBar(true);
                            })
                            .finally(e => {
                                setSaveItems(false);
                                setPayments((_price) => {
                                    _price[index] = {..._price[index], change: false, saving: false};
                                    return [..._price]
                                })
                            });
                    }
                }
            })
                .catch(function (err) {
                    let listError = [];
                    let listTouched = [];
                    err.inner.forEach((e, i) => {
                        listTouched[`prices[${index}].${e.path}`] = true;
                        listError[`prices[${index}].${e.path}`] = e.message;
                    });
                    setTouchItem(listTouched);
                    setErrorItem(listError);
                });
        });
    }

    const saveAllItemPrice = () => {
        setSaveItems(true);
        const data = payments.map(item => {
            return {
                ...item,
                id: item.id ? item.id : "00000000-0000-0000-0000-000000000000",
                companyId: id,
                paymentDate: moment(item.paymentDate).tz(moment.tz.guess()).format(),
                jobId: jobId,
                paymentTypeId: item.paymentTypeId,
                amount: item.amount.toString().replace('$', ''),
                checkNumber: item.checkNumber.toString().replace('$', ''),
            }
        })
        axios.put(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_COMPLETED_ALL_PAYMENT, data)
            .then(res => {
                setIsStatus({failed: false, msg: 'Save all successfully!'});
                setOpenSnackBar(true);
                reload && reload();
                // setReloadSource(!reloadSource)
            })
            .catch(err => {
                setIsStatus({failed: true, msg: 'An error occurred, please try again later.'});
                setOpenSnackBar(true);
            })
            .finally(e => {
                setSaveItems(false);
                setPayments((_price) => {
                    const price =  _price.map(item => {
                        return {
                            ...item, change: false, saving: false
                        }
                    })
                    return price
                })
            });
    }

    const validateItems = (api, index, submit = false) => {
        validateSchema.validate({customerPayment: prices}, {abortEarly: false}).then(() => {
            setErrorItem({});
        })
            .catch(function (err) {
                let listError = {};
                let listTouched = {};
                err.inner.forEach((e, index) => {
                    listTouched[e.path] = true;
                    listError[e.path] = e.message;
                });
                setTouchItem(listTouched);
                setErrorItem(listError)
            });
    }

    const loadDocument = (type) => {
        setSortBy(type)
        setSortType(type == sortBy ? (sortType === 'ASC' ? 'DESC' : 'ASC') : 'ASC');
    }

    const getSortIcon = (type) => {
        return (
            sortBy == type &&
            (
                sortType == 'ASC' ?
                    <ArrowUpward className={classes.iconSort}/>
                    :
                    <ArrowDownward className={classes.iconSort}/>
            )
        )
    }

    useEffect(() => {
        getCustomerPayment();
    }, [sortBy, sortType])

    useEffect(() => {
        if (isMounted.current) {
            setEnableSaveAll(false);
            for (let i = 0; i < payments.length; i++) {
                if (payments[i].change) {
                    setEnableSaveAll(true);
                    break;
                }
            }
        }
        return () => isMounted.current = true;
    }, [payments])

    useEffect(() => {
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_COMPLETED_PAYMENT_TYPES)
            .then(res => {
                setPaymentType(res.data);
            })
        getCustomerPayment();
    }, [])

    return (
        <Card style={{marginBottom: '16px'}}>
            <CardHeader
                         className={classes.title}
                         onClick={toggleExpanded}
                         title="Customer Payment"
                         action={expanded ? <KeyboardArrowUp /> :
                            <KeyboardArrowDown />}
            >
            </CardHeader>
            <Divider/>
            <Collapse in={expanded} timeout='auto' unmountOnExit>
                <CardContent>
                    <div className={classes.actionsSm}>
                        <div style={ipPlus ? {
                                display: 'flex',
                                alignItems: 'flex-start',
                                flexDirection: 'column'
                            } : {display: 'flex', alignItems: 'center'}}>
                            <div>
                                <Button
                                    size={'small'}
                                    style={{
                                        margin: ipPlus ? '8px 0' : '0 0 0 0',
                                        minWidth: '200px',
                                        backgroundColor: !payments || payments.length === 0|| !data.queuedToQuickbooks ? "" :  postAll ? '#1b5e20' : '#43a047'
                                    }}
                                    className={!payments || payments.length === 0 || !data.queuedToQuickbooks ? classes.btnDis : classes.button}
                                    onClick={() => postAllQuickBook(jobId)}
                                    variant="contained"
                                >{
                                    postAll ? 'Posted all to Quickbooks' :
                                        'Post all to quickbooks'
                                }
                                </Button>
                                {postingAll && <CircularProgress size={24}/>}
                            </div>
                            
                        </div>
                        <div style={{
                            display: 'flex',
                            alignItems: ipPlus ? 'flex-start' : 'center',
                            flexDirection: ipPlus ? 'column' : 'row',
                            marginLeft: 8,
                            marginRight: 4
                        }}>
                            <Button
                                size={'small'} style={{margin: ipPlus ? '8px 0' : '0 0 0 0'}}
                                className={(!enableSaveAll || (saveItems && !locateItem)) ? classes.btnDis : classes.button}
                                onClick={() => {
                                    setLocateItem(null)
                                    saveAllItemPrice()
                                }}
                                variant="contained">
                                Save All
                            </Button>
                            {saveItems && !locateItem && <CircularProgress size={24}/>}
                        </div>
                        <MuiThemeProvider theme={customThemeTooltip}>
                            <Tooltip title='Add payment' placement='bottom' arrow>
                                <IconButton className={classes.add} onClick={() => addSaleCommission()}>
                                    <AddBox/>
                                </IconButton>
                            </Tooltip>
                        </MuiThemeProvider>
                    </div>
                    <TableContainer>
                        <Table style={{minWidth: '1000px'}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell onClick={() => loadDocument('Date')} align='center' style={{width: '180px', minWidth: '180px', padding: '4px'}}
                                               className={classes.headCell}>
                                        <span>Date{ getSortIcon('Date')}</span>
                                        </TableCell>
                                    <TableCell onClick={() => loadDocument('Type')} align='center'
                                               style={{width: '120px', minWidth: '120px', padding: '4px'}}
                                               className={classes.headCell}>
                                        <span>Type{ getSortIcon('Type')}</span>
                                        </TableCell>
                                    <TableCell onClick={() => loadDocument('Checknumber')} align='center' style={{width: '120px', minWidth: '120px', padding: '4px'}}
                                               className={classes.headCell}>
                                        <span>Check number{ getSortIcon('Checknumber')}</span>
                                        </TableCell>
                                    <TableCell onClick={() => loadDocument('Payment')} align='center' style={{width: '120px', minWidth: '120px', padding: '4px'}}
                                               className={classes.headCell}>
                                        <span>Payment{ getSortIcon('Payment')}</span>
                                        </TableCell>
                                    <TableCell onClick={() => loadDocument('Notes')} align='center' style={{minWidth: '300px', width: '99%', padding: '4px'}}
                                               className={classes.headCell}>
                                        <span>Notes{ getSortIcon('Notes')}</span>
                                        </TableCell>
                                    <TableCell onClick={() => loadDocument('PostedDate')} align='center' style={{width: '104px', minWidth: '104px', padding: '4px'}}
                                               className={classes.headCell}>
                                        <span>Posted Date{ getSortIcon('PostedDate')}</span>
                                        </TableCell>
                                    <TableCell align='center' style={{width: '225px', minWidth: '225px', padding: '4px'}}
                                               className={clsx(classes.headCell, classes.headerActionCell)}>
                                        <div style={ipPlus ? {
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            flexDirection: 'column'
                                        } : {display: 'flex', alignItems: 'center'}}>
                                            <div>
                                                <Button
                                                    disabled={isFieldTechnician}
                                                    size={'small'}
                                                    style={{
                                                        margin: ipPlus ? '8px 0' : '0 0 0 0',
                                                        minWidth: '200px',
                                                        backgroundColor: !payments || payments.length === 0|| !data.queuedToQuickbooks ? "" :  postAll ? '#1b5e20' : '#43a047'
                                                    }}
                                                    className={!payments || payments.length === 0 || !data.queuedToQuickbooks ? classes.btnDis : classes.button}
                                                    onClick={() => postAllQuickBook(jobId)}
                                                    variant="contained"
                                                >{
                                                    postAll ? 'Posted all to Quickbooks' :
                                                        'Post all to quickbooks'
                                                }
                                                </Button>
                                                {postingAll && <CircularProgress size={24}/>}
                                            </div>
                                        </div>

                                    </TableCell>
                                    <TableCell className={clsx(classes.headCell, classes.headerActionCell)} align='center'
                                               style={{width: '90px', minWidth: '90px', padding: '4px'}}>
                                        <div style={ipPlus ? {
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            flexDirection: 'column'
                                        } : {display: 'flex', alignItems: 'center'}}>
                                            <Button
                                                disabled={isFieldTechnician}
                                                size={'small'} style={{margin: ipPlus ? '8px 0' : '0 0 0 0'}}
                                                className={(!enableSaveAll || (saveItems && !locateItem)) ? classes.btnDis : classes.button}
                                                onClick={() => {
                                                    setLocateItem(null)
                                                    saveAllItemPrice()
                                                }}
                                                variant="contained">
                                                Save All
                                            </Button>
                                            {saveItems && !locateItem && <CircularProgress size={24}/>}
                                        </div>
                                    </TableCell>
                                    <TableCell align='center' style={{width: '1px', padding: '4px'}}
                                               className={clsx(classes.headCell, classes.headerActionCell)}>
                                        <MuiThemeProvider theme={customThemeTooltip}>
                                            <Tooltip title='Add payment' placement='bottom' arrow>
                                                <IconButton disabled={isFieldTechnician} className={classes.add} onClick={() => addSaleCommission()}>
                                                    <AddBox/>
                                                </IconButton>
                                            </Tooltip>
                                        </MuiThemeProvider>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {payments.map((pay, index) => (
                                    <CustomerPaymentLine
                                        key={pay.id}
                                        item={pay}
                                        index={index}
                                        pay={pay}
                                        disabledPostToQuickbooks={!data.queuedToQuickbooks}
                                        onHandleChangeDate={onHandleChangeDate}
                                        onChangePayment={onChangePayment}
                                        paymentType={paymentType}
                                        setIndex={setIndex}
                                        openConfirmDelete={openConfirmDelete}
                                        onSaveCustomerPayment={onSaveCustomerPayment}
                                        errorItem={errorItem}
                                        touchItem={touchItem}
                                        onBlueItem={onBlueItem}
                                        validateItems={validateItems}
                                        setLocateItem={({index, id, add}) => setLocateItem({index, id, add})}
                                        postQuickBook={postQuickBook}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Collapse>
            <AddPayment
                open={openModal}
                close={() => setOpenModal(false)}
                save={addPayment}
            />
            <ConfirmModal
                openConfirm={openConfirm}
                closeConfirm={() => setOpenConfirm(false)}
                onConfirm={removePayment}
                isProgress={deleting}
            />
            <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={openSnackbar}
                      autoHideDuration={3000}
                      onClose={() => setOpenSnackBar(false)}>
                <Alert
                    elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </Card>
    )
}

export default CustomerPayment;
