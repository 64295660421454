import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
    Card, CardHeader, CardContent, Divider,
    FormControlLabel, Modal, TextField, colors,
    IconButton, Button, CardActions, Grid, CircularProgress,
    Typography, Snackbar, Tooltip
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import NumberFormat from 'react-number-format'
import { AddBox, Delete } from '@material-ui/icons'
import { GreenCheckbox } from 'components'
import { makeStyles } from '@material-ui/styles'
import axios from 'utils/axios'
import apiConfig from 'apiConfig'
import localStorage from "../../../../../utils/localStorage";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles(theme => ({
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 700,
        maxHeight: '90%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: 500
        },
    },
    add: {
        position: 'relative',
        padding: 0,
        '& svg': {
            fontSize: '32px',
            color: colors.green[600]
        }
    },
    contact: {
        display: 'flex',
        alignItems: 'center'
    },
    checkbox: {
        margin: 0,
        '& span': {
            padding: 0
        }
    },
    actionCard: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    button: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
    divider: {
        height: '1px',
        backgroundColor: '#eeeeee',
        margin: '8px 0'
    },
    snackBar: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    deleIcon: {
        padding: 0
    },
}))

const ContactsModal = ({ address, update, open, onClose, phoneTypes }) => {

    const classes = useStyles();

    const contacts = address.contacts || [];
    contacts.sort((contact1, contact2) => {
        return contact1.name > contact2.name ? 1 : (contact1.name < contact2.name ? -1 : 0)
    });
    const primaryIndex = contacts.findIndex(con => con.isPrimary);
    if (primaryIndex > -1) {
        const primaryContact = contacts[primaryIndex];
        contacts.splice(primaryIndex, 1);
        contacts.unshift(primaryContact)
    }

    const [_contacts, setContacts] = useState(contacts);
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [delIndex, setDelIndex] = useState();
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });
    const mobile = useMediaQuery('(max-width: 600px)');
    const onAddContact = () => {
        const contact = { isPrimary: false, name: '', phone: '', email: '' }
        setContacts([..._contacts, contact])
    }
    const onRemoveContact = (index) => {
        const contacts = [..._contacts];
        const delContact = contacts.splice(index, 1);
        if (!delContact[0].id) {
            setContacts([...contacts])
            setIsStatus({ failed: false, msg: 'Delete contact successfully.' });
            setOpenSnackBar(true);
            return
        }
        setDelIndex(index);
        setIsDelete(true);
        axios.delete(apiConfig.url.BASE_URL + `address/${delContact[0].addressId}/contact/${delContact[0].id}`)
            .then(() => {
                setContacts(contacts);
                setIsStatus({ failed: false, msg: 'Delete contact successfully.' });
                setOpenSnackBar(true);
                update({ ...address, contacts: contacts })
            })
            .catch(() => {
                setIsStatus({ failed: true, msg: 'Delete contact failed, please try again later.' });
                setOpenSnackBar(true)
            })
            .finally(() => setIsDelete(false))
    }
    const onChangeContact = (event, index) => {
        const { name, value } = event.target;
        const conts = [..._contacts];
        if (name === 'isPrimary') {
            const contacts = conts.map((con, inx) => {
                return {
                    ...con,
                    isPrimary: inx === index
                }
            })
            setContacts(contacts);
        } else if (name === 'isSendText') {
            conts[index] = { ...conts[index], [name]: event.target.checked };
            setContacts(conts);
        } else {
            conts[index] = { ...conts[index], [name]: value };
            setContacts(conts);
        }
    }
    const onUpdateJobContacts = () => {
        const postdata = { ...address, contacts: _contacts };
        setIsSaving(true);
        axios.put(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_ADDRESSES_ADD, postdata)
            .then(() => {
                setIsStatus({ failed: false, msg: 'Update successfully.' });
                setOpenSnackBar(true);
                update();
            })
            .catch(() => {
                setIsStatus({ failed: true, msg: 'An error occurred, please try again later' });
                setOpenSnackBar(true);
            })
            .finally(() => setIsSaving(false))
    }

    const resetModal = () => {
        setContacts(contacts);
        onClose()
    }

    return (
        <>
            <Modal open={open} onClose={resetModal}>
                <Card className={classes.styleModal}>
                    <CardHeader title='Job Address Contacts' />
                    <Divider />
                    <CardContent>
                        {_contacts.map((con, index) => (
                            <React.Fragment key={con.id}>
                                <Grid key={con.id} container spacing={2}>
                                    <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <FormControlLabel
                                            className={classes.checkbox}
                                            label='Primary'
                                            labelPlacement='bottom'
                                            name='isPrimary'
                                            checked={con.isPrimary}
                                            onChange={e => onChangeContact(e, index)}
                                            control={
                                                <GreenCheckbox />
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={9}>
                                        <TextField
                                            fullWidth
                                            label='Full Name'
                                            name='name'
                                            variant='outlined'
                                            value={con.name || ''}
                                            onChange={e => onChangeContact(e, index)}
                                        />
                                    </Grid>
                                    <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={1}>
                                        {
                                            index === 0 &&
                                            <Tooltip title='Add Contact' arrow>
                                                <IconButton className={classes.add} onClick={onAddContact}>
                                                    <AddBox />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                        {
                                            ((contacts[index] && !contacts[index].isPrimary) || !contacts[index]) &&
                                            <IconButton className={classes.deleIcon}
                                                        disabled={isDelete}
                                                        onClick={() => onRemoveContact(index)}>
                                                <Delete />
                                            </IconButton>
                                        }
                                        {isDelete && index === delIndex && <CircularProgress size={16} />}
                                    </Grid>
                                    <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <FormControlLabel
                                            className={classes.checkbox}
                                            label='Send Text'
                                            labelPlacement='bottom'
                                            name='isSendText'
                                            checked={con.isSendText}
                                            onChange={e => onChangeContact(e, index)}
                                            control={
                                                <GreenCheckbox />
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={4} sm={2}>
                                        <TextField
                                            fullWidth
                                            label='Phone Type'
                                            name='phoneTypeId'
                                            variant='outlined'
                                            select
                                            SelectProps={{ native: true }}
                                            value={con.phoneTypeId || ''}
                                            onChange={event => onChangeContact(event, index)}
                                        >
                                            {phoneTypes.map(type => (
                                                <option key={type.id} value={type.id}>
                                                    {type.type}
                                                </option>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={5} sm={3}>
                                        <NumberFormat
                                            customInput={TextField}
                                            format='(###) ###-####x######'
                                            fullWidth
                                            label='Phone Number'
                                            name='phone'
                                            variant='outlined'
                                            value={con.phone || ''}
                                            onChange={e => onChangeContact(e, index)}
                                        />
                                    </Grid>
                                    {
                                        mobile &&
                                        <Grid item xs={2}>
                                        </Grid>
                                    }

                                    <Grid item xs={9} sm={4}>
                                        <TextField
                                            fullWidth
                                            label='Email'
                                            name='email'
                                            variant='outlined'
                                            value={con.email}
                                            onChange={e => onChangeContact(e, index)}
                                        />
                                    </Grid>
                                </Grid>
                                <div style={{ display: index + 1 === _contacts.length ? 'none' : 'block' }} className={classes.divider}></div>
                            </React.Fragment>
                        ))}
                    </CardContent>
                    <CardActions className={classes.actionCard}>
                        <Button
                            onClick={resetModal}
                            variant="contained">
                            Close
                        </Button>
                        <Button
                            className={classes.button}
                            disabled={isSaving || isDelete}
                            variant="contained"
                            color="primary"
                            onClick={onUpdateJobContacts}>
                            Save
                        </Button>
                        {isSaving && <CircularProgress size={24} />}
                    </CardActions>
                </Card>
            </Modal>

            <Snackbar
                open={openSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                onClose={() => setOpenSnackBar(false)}>
                <Alert
                    elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    )
}

ContactsModal.propTypes = {
    address: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    update: PropTypes.func
}

export default ContactsModal;
