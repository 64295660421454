import React, { useEffect, useState, useRef } from 'react';
import {
    Card, CardHeader, CardContent, CardActions, Divider,
    Grid, TextField, Button, colors, Typography, Avatar,
    FormControlLabel, Collapse
} from '@material-ui/core'
import { ExpandLess,ExpandMore } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import Phone from './Phone';
import { makeStyles } from '@material-ui/styles'
import { Formik } from 'formik'
import * as yup from 'yup'
import { AddressInput, AvatarEditor, ConfirmModal, FormErrorCallback } from 'components';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { GreenRadio } from 'components';
import NumberFormat from 'react-number-format';
import * as Yup from "yup";
import { KeyboardDatePicker } from '@material-ui/pickers'
import { errorMessages } from 'common/constants'

import axios from "utils/axios";
import apiConfig from "apiConfig";

import useMediaQuery from '@material-ui/core/useMediaQuery'
import {formatPhoneNumber} from "../../../../utils/formatNumber";
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    cardHeader: {
        cursor: 'pointer'
    },
    root: {
        alignItems: 'flex-start'
    },
    btnSticky: {
        padding: '8px 12px',
        position: 'sticky',
        top: 0,
        zIndex: 2,
        backgroundColor: '#f4f6f8'
    },
    card: {
        marginTop: '24px',
        width: '100%'
    },
    cardW: {
        width: '100%'
    },
    cardActions: {
        justifyContent: 'flex-end',
        paddingRight: 16
    },
    saveBtn: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    snackBar: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    cardProfile: {
        width: '100%'
    },
    profileName: {
        fontSize: '20px',
        fontWeight: 500,
        textAlign: 'center',
        marginTop: '20px'
    },
    hoverAvatar: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    bigAvatar: {
        width: theme.spacing(20),
        height: theme.spacing(20),
        margin: 'auto'
    },
    circularProgress: {
        marginTop: '30px',
    },
    radioGroup: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: '8px'
    },
    groupField: {
        display: 'flex',
        alignItems: 'center',
        '& div': {
            width: '150px',
            marginRight: '15px'
        }
    },
    groupFieldNoWidth: {
        display: 'flex',
        alignItems: 'center',
        '& div': {
            marginRight: '15px'
        }
    },
    typo: {
        width: '117px',
        fontSize: '16px',
        marginRight: '24px'
    },
    dayInWeek: {
        fontSize: 16,
        position: 'absolute',
        top: 16,
        left: 110,
        textTransform: 'uppercase'
    },
    errorPeriodDate: {
        color: '#e53935',
        fontSize: 11,
        margin: '0 14px'
    },
    groupFieldLocateSettings: {
        display: 'flex',
        alignItems: 'center',
        '& div': {
            width: '225px',
            marginRight: '15px'
        }
    },
    txtMiles: {
        width: '35px',
        fontSize: '16px',
        marginRight: '24px'
    },
}));

const adminSchema = yup.object().shape({
    name: Yup.string()
        .nullable().required('This field is required.'),
    address: Yup.object({
        address1: Yup.string()
            .nullable().required('This field is required.'),
        city: Yup.string()
            .nullable().required('This field is required.'),
        zipCode: Yup.string()
            .nullable()
            .required('This field is required.')
            .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, 'Zip code is invalid.')
    }),
    email: Yup.string().nullable().required('This field is required.'),
    phones: Yup.array()
        .of(Yup.object({
            phoneNumber: Yup.string()
                .required('Required.')
                .matches(/\W\d{3}\W\s\w{3}\W\w{4}$|\W\d{3}\W\s\w{3}\W\w{4}x\d{1,6}|\W\d{3}\W\s\w{3}\W\w{4}x/, 'Invalid phone number.')
        })),
    yellowMiles: Yup.string()
        .nullable().matches(/^\d{0,4}(\.\d{1,2})?$/gm, 'Wrong format')
        .test('yellowMiles-match', 'This value needs to be greater than 0', function(value){
            return !value || value > 0
        })
        .test('redMiles-match', 'Must not coincide with Red Miles', function(value){
            return !value || this.parent.redMiles !== value
        }),
    redMiles: Yup.string()
        .nullable().matches(/^\d{0,4}(\.\d{1,2})?$/gm, 'Wrong format')
        .test('redMiles-match', 'This value needs to be greater than 0', function(value){
            return !value || value > 0
        })
        .test('yellowMiles-match', 'Must not coincide with Yellow Miles', function(value){
            return !value || this.parent.yellowMiles !== value
        }),
});

const Information = ({ adminId }) => {

    const classes = useStyles();
    const isMounted = useRef(false);

    const ipPlus = useMediaQuery('(max-width: 769px)');
    const mobile = useMediaQuery('(max-width: 960px)');

    const addressInputRef = useRef();

    const [openAvatarModal, setOpenAvatarModal] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });
    const [reload, setReload] = useState(false);
    const [admin, setAdmin] = useState({});
    const [contacts, setContacts] = useState();
    const [contactPhone, setContactPhone] = useState();
    const [contactEmail, setContactEmail] = useState();
    const [stateBlur, setStateBlur] = useState(false);
    const [contactBlur, setContactBlur] = useState(false);
    const [errorPeriodDate, setErrorPeriodDate] = useState('');
    const [expandedInfor, setExpandedInfor] = useState(true);
    const [expandedLocate, setExpandedLocate] = useState(true);
    const [expandedPay, setExpandedPay] = useState(true);
    const [expandedInvoice, setExpandedInvoice] = useState(true);
    // avatar
    const onGetAvatar = () => {
        setReload(!reload);
    }

    const addUserPhone = (values, setFieldValue) => {
        const phones = [...values.phones];
        phones.push({
            id: "00000000-0000-0000-0000-000000000000",
            phoneNumber: "",
            phoneTypeId: admin.phoneTypes[0].id
        });
        setFieldValue('phones', phones)
    }
    const onPhoneUpdate = (event, values, setFieldValue, index) => {
        const { name, value } = event.target;
        const phones = [...values.phones];
        phones[index] = { ...values.phones[index], [name]: value?.trim() };
        setFieldValue('phones', phones);
    }
    const onPhoneRemove = (values, setFieldValue, index) => {
        const phones = [...values.phones];
        phones.splice(index, 1);
        setFieldValue('phones', phones);
    }

    useEffect(() => {
        isMounted.current = true;
        let url = apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_LIST_ACTIVE_IN_COMPANY;
        axios.get(url)
            .then((res) => {
                isMounted.current && setContacts(res.data.users || []);
            })
            .finally()
        return () => isMounted.current = false;
    }, [reload])

    useEffect(() => {
        isMounted.current = true;
        if (contacts) {
            axios.get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_INFO + adminId)
                .then(res => {
                    if (res.data.logoUrl) res.data.logoUrl = res.data.logoUrl + '?t=' + new Date().getTime();
                    res.data.phones && res.data.phones.forEach(phone => phone.phoneNumber = formatPhoneNumber(phone.phoneNumber));
                    res.data.address = res.data.addresses ? res.data.addresses[0] : {};
                    if (res.data.address) res.data.address.state = { id: res.data.address.stateId };
                    const contact = contacts.find(contact => contact.id === res.data.contactId);
                    if (isMounted.current) {
                        setContactPhone(contact && contact.phones[0] && contact.phones[0].formattedPhoneNumber);
                        setContactEmail(contact && contact.email);
                        setAdmin(adm => ({ ...adm, ...res.data }))
                    }
                })
        }
        return () => isMounted.current = false;
    }, [reload, contacts])

    useEffect(() => {
        isMounted.current = true;
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.EMPLOYEE_STATES_LIST)
            .then(res => {
                res.data = res.data.sort((a, b) => a.name.localeCompare(b.name))
                
                isMounted.current && setAdmin(adm => ({ ...adm, states: res.data }));
            })

        return () => isMounted.current = false;
    }, [])

    if (!admin.id || !contacts) {
        return (
            <Grid
                container
                spacing={0}
                align="center"
                justifyContent="center"
                direction="column">
                <Grid item>
                    <CircularProgress className={classes.circularProgress} size={50} />
                </Grid>
            </Grid>
        )
    }

    return (
        <>
            <Formik
                initialValues={admin}
                validationSchema={adminSchema}
                onSubmit={values => {
                    if (stateBlur) {
                        return;
                    }
                    if (contactBlur) {
                        return;
                    }
                    if (!values.periodStartDate) {
                        setErrorPeriodDate('Required.')
                        return;
                    } else {
                        setErrorPeriodDate('')
                    }
                    //quangpv
                    values.addresses = values.addresses ?? [];
                    values.addresses[0] = values.address;
                    // delete (values.address);

                    setIsSaving(true);

                    const updateCompany = () => {
                        axios.put(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_UPDATE.replace('{companyId}', values.id), values)
                        .then(res => {
                            setReload(!reload);
                            setIsStatus({ failed: false, msg: 'Save successfully!' });
                            setOpenSnackBar(true);
                        })
                        .catch(err => {
                            setIsStatus({ failed: true, msg: 'An error occurred, please try again later.' });
                            setOpenSnackBar(true);
                        })
                        .finally(e => setIsSaving(false));
                    };

                    const { coordinate, isValid, forceSave, verifyFn } = addressInputRef.current;
                    if (isValid || forceSave) {
                        values.addresses[0].latitude = coordinate?.latitude;
                        values.addresses[0].longitude = coordinate?.longitude;
                        updateCompany();
                    } else {
                        verifyFn(true).then(data => data ? updateCompany() : setIsSaving(false));
                    }

                    
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    submitCount,
                    isValid,
                    setFieldValue,
                    setFieldTouched,
                    handleSubmit,
                    submitForm
                }) => (
                    <form onSubmit={handleSubmit}>
                        <FormErrorCallback
                            submitCount={submitCount}
                            isValid={isValid}
                            onSubmissionError={() => {
                                setIsStatus({ failed: true, msg: errorMessages.FIELD_CHECK });
                                setOpenSnackBar(true);
                            }}
                        />

                        <Grid container className={classes.root} spacing={3}>
                            <Grid item xs={12} className={classes.btnSticky}>
                                <CardActions style={{ padding: 0 }}>
                                    <Button
                                        onClick={() => {
                                            values.address.stateId = values.address.state?.id;
                                            if (!values.address.stateId) {
                                                setStateBlur(true)
                                            }
                                            if (!values.contactId) {
                                                setContactBlur(true)
                                            }
                                            submitForm()
                                        }}
                                        style={{ marginBottom: '0px' }}
                                        disabled={isSaving}
                                        className={classes.saveBtn}
                                        variant="contained">
                                        Save
                                    </Button>
                                    {isSaving && <CircularProgress size={24} />}
                                </CardActions>
                            </Grid>
                            <Grid container item md={12} lg={3}>
                                <Card className={classes.cardProfile}>
                                    <CardContent>
                                        <div
                                            className={classes.hoverAvatar}
                                            onClick={() => setOpenAvatarModal(true)}>
                                            <Avatar
                                                variant='circular'
                                                src={(admin && admin.logoUrl  ? admin && admin.logoUrl : '') || null}
                                                alt="avatar"
                                                className={classes.bigAvatar}
                                            />
                                        </div>
                                        <Typography className={classes.profileName}>
                                            {(admin && admin.name) || 'N/A'}
                                        </Typography>
                                    </CardContent>
                                    <Divider />
                                    <CardActions>
                                        <Button style={{ width: '100%' }} onClick={() => setOpenAvatarModal(true)}>
                                            upload logo
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                            <Grid container item md={12} lg={9}>
                                <Card className={classes.cardW}>
                                    <CardHeader title="Information" className={classes.cardHeader}
                                        onClick={() => { setExpandedInfor(!expandedInfor) }} 
                                        action={ 
                                                <IconButton aria-label="Information">
                                                { expandedInfor ? <ExpandMore/> : <ExpandLess /> }
                                                </IconButton>
                                        }/>
                                    <Divider />
                                    <Collapse in={expandedInfor} timeout="auto" unmountOnExit>
                                        <CardContent style={{ paddingBottom: 0 }}>
                                            <Grid container spacing={3} style={{ marginBottom: '16px' }}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        error={errors.name && touched.name}
                                                        helperText={(errors.name && touched.name) && errors.name}
                                                        label="Company Name"
                                                        name="name"
                                                        onBlur={() => setFieldTouched('name')}
                                                        onChange={event => setFieldValue('name', event.target.value)}
                                                        value={values.name || ''}
                                                        variant="outlined" />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        error={errors.legalName && touched.legalName}
                                                        helperText={(errors.legalName && touched.legalName) && errors.legalName}
                                                        label="Legal Name"
                                                        name="legalName"
                                                        onBlur={() => setFieldTouched('legalName')}
                                                        onChange={event => setFieldValue('legalName', event.target.value)}
                                                        value={values.legalName || ''}
                                                        variant="outlined" />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <AddressInput
                                                        ref={addressInputRef}
                                                        errors={errors}
                                                        values={values}
                                                        touched={touched}
                                                        setFieldValue={setFieldValue}
                                                        setFieldTouched={setFieldTouched}
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Divider />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        error={errors.email && touched.email}
                                                        helperText={(errors.email && touched.email) && errors.email}
                                                        label="Email"
                                                        name="email"
                                                        onBlur={() => setFieldTouched('email')}
                                                        onChange={event => setFieldValue('email', event.target.value)}
                                                        value={values.email || ''}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                {values.phones.map((phone, index) => (
                                                    <Phone
                                                        key={phone.id}
                                                        index={index}
                                                        length={values.phones.length}
                                                        phone={phone}
                                                        errors={errors}
                                                        touched={touched}
                                                        setFieldTouched={setFieldTouched}
                                                        phoneTypes={admin.phoneTypes}
                                                        onPhoneRemove={() => onPhoneRemove(values, setFieldValue, index)}
                                                        onPhoneUpdate={event => onPhoneUpdate(event, values, setFieldValue, index)}
                                                    />
                                                ))}
                                                <Grid item md={12} xs={12}>
                                                    <Button
                                                        onClick={() => addUserPhone(values, setFieldValue)}
                                                        color="primary"
                                                        variant="outlined">
                                                        Add Phone
                                                    </Button>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Divider />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        error={!values.contactId && contactBlur}
                                                        helperText={(!values.contactId && contactBlur) ? 'This field is required.' : ''}
                                                        label='Contact'
                                                        name='contactName'
                                                        variant='outlined'
                                                        select
                                                        onBlur={() => {
                                                            (!values.contactId) ? setContactBlur(true) : setContactBlur(false)
                                                        }}
                                                        value={values.contactId || ''}
                                                        onChange={event => {
                                                            const contact = contacts.find(contact => contact.id === event.target.value);
                                                            values.contactId = contact && contact.id || null
                                                            setContactPhone(contact && contact.phones[0] ? contact.phones[0].formattedPhoneNumber : null);
                                                            setContactEmail(contact ? contact.email : null);
                                                        }}
                                                        SelectProps={{ native: true }}
                                                    >
                                                        <option value=''></option>
                                                        {contacts.map(contact => (
                                                            <option
                                                                key={contact.id}
                                                                value={contact.id}>
                                                                {contact.fullName}
                                                            </option>
                                                        ))}
                                                    </TextField>
                                                </Grid>
                                                <Grid item md={4} xs={12}>
                                                    <Typography style={{ paddingLeft: '14px' }}>Phone Number</Typography>
                                                    <Typography style={{ paddingLeft: '14px' }}>{contactPhone}</Typography>
                                                </Grid>
                                                <Grid item md={8} xs={12}>
                                                    <Typography style={{ paddingLeft: '14px' }}>Email</Typography>
                                                    <Typography style={{ paddingLeft: '14px' }}>{contactEmail}</Typography>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Collapse>
                                </Card>

                                <Card className={classes.card}>
                                    <CardHeader title='Locate Range Settings' className={classes.cardHeader}
                                        onClick={() => { setExpandedLocate(!expandedLocate) }} 
                                        action={ 
                                                <IconButton aria-label="Locate Range Settings">
                                                { expandedLocate ? <ExpandMore/> : <ExpandLess /> }
                                                </IconButton>
                                        }/>
                                    <Divider />
                                    <Collapse in={expandedLocate} timeout="auto" unmountOnExit>
                                        <CardContent>
                                            <div className={classes.groupFieldLocateSettings} style={{ paddingTop:  12, paddingBottom:  12 }}>
                                                <div className={classes.groupFieldLocateSettings} style={{ backgroundColor: '#F5D71D', width: '90px', height: '3.1876em' }} />
                                                <NumberFormat
                                                    label={"Mid range"}
                                                    customInput={TextField}
                                                    name="yellowMiles"
                                                    placeholder={"1234 or 1234.56"}
                                                    onBlur={() => setFieldTouched('yellowMiles')}
                                                    onChange={event => setFieldValue('yellowMiles', event.target.value)}
                                                    value={values.yellowMiles || ''}
                                                    variant="outlined"
                                                    decimalScale={2}
                                                    decimalSeparator={"."}
                                                    error={errors.yellowMiles && touched.yellowMiles}
                                                    helperText={(errors.yellowMiles && touched.yellowMiles) && errors.yellowMiles}
                                                />
                                            </div>
                                            <div className={classes.groupFieldLocateSettings} style={{ paddingTop:  12, paddingBottom:  12 }}>
                                                <div className={classes.groupFieldLocateSettings} style={{ backgroundColor: '#F72A0F', width: '90px', height: '3.1876em' }} />
                                                <NumberFormat
                                                    label={"Long range"}
                                                    customInput={TextField}
                                                    name="redMiles"
                                                    placeholder={"1234 or 1234.56"}
                                                    onBlur={() => setFieldTouched('redMiles')}
                                                    onChange={event => setFieldValue('redMiles', event.target.value)}
                                                    value={values.redMiles || ''}
                                                    variant="outlined"
                                                    decimalScale={2}
                                                    decimalSeparator={"."}
                                                    error={errors.redMiles && touched.redMiles}
                                                    helperText={(errors.redMiles && touched.redMiles) && errors.redMiles}
                                                />
                                            </div>
                                        </CardContent>
                                    </Collapse>
                                </Card>

                                <Card className={classes.card}>
                                    <CardHeader title='Pay Period Settings' className={classes.cardHeader}
                                        onClick={() => { setExpandedPay(!expandedPay) }} 
                                        action={ 
                                                <IconButton aria-label="Pay Period Settings">
                                                { expandedPay ? <ExpandMore/> : <ExpandLess /> }
                                                </IconButton>
                                        }/>
                                    <Divider />
                                    <Collapse in={expandedPay} timeout="auto" unmountOnExit>
                                        <CardContent>
                                            <div style={{ position: 'relative', paddingTop: (ipPlus ? 12 : 0), paddingBottom: (ipPlus ? 12 : 0) }}>
                                                <KeyboardDatePicker
                                                    // disabled={!!admin.periodStartDate}
                                                    error={errorPeriodDate ? true : false}
                                                    label='Start Date'
                                                    inputVariant='outlined'
                                                    format="MM/DD/YYYY"
                                                    value={values.periodStartDate ? new Date(values.periodStartDate) : null}
                                                    onChange={date => setFieldValue('periodStartDate', date)}
                                                    shouldDisableDate={date => date.day() > 1}
                                                />
                                                <Typography className={classes.dayInWeek} style={{top: ipPlus ? '28px' : '16px'}}>
                                                    {(values.periodStartDate && new Date(values.periodStartDate).getDay() === 0) ?
                                                        'Sunday' : 'Monday'
                                                    }
                                                </Typography>
                                                {errorPeriodDate && <Typography className={classes.errorPeriodDate}>
                                                    {errorPeriodDate}
                                                </Typography>}
                                            </div>
                                            <div className={classes.radioGroup} style={{ paddingTop: (ipPlus ? 12 : 0), paddingBottom: (ipPlus ? 12 : 0) }}>
                                                <Typography className={classes.typo} style={{ width: '130px' }}>
                                                    Period Duration
                                                </Typography>
                                                <FormControlLabel
                                                    style={{ width: '105px' }}
                                                    checked={values.periodDuration === 1 ? true : false}
                                                    label='One Week'
                                                    onChange={() => setFieldValue('periodDuration', 1)}
                                                    control={
                                                        <GreenRadio />
                                                    }
                                                    // disabled={admin.periodDuration === 1 || admin.periodDuration === 2}
                                                />
                                                <FormControlLabel
                                                    label='Two Weeks'
                                                    checked={values.periodDuration === 2 ? true : false}
                                                    onChange={() => setFieldValue('periodDuration', 2)}
                                                    // disabled={admin.periodDuration === 1 || admin.periodDuration === 2}
                                                    control={
                                                        <GreenRadio />
                                                    }
                                                />
                                            </div>
                                            <div className={ipPlus ? classes.groupFieldNoWidth : classes.groupField} style={{ paddingTop: (ipPlus ? 12 : 0), paddingBottom: (ipPlus ? 12 : 0) }}>
                                                <NumberFormat
                                                    // disabled={!!admin.periodOvertimeAfter}
                                                    customInput={TextField}
                                                    label="Overtime Pay After"
                                                    name="periodOvertimeAfter"
                                                    variant="outlined"
                                                    value={values.periodOvertimeAfter || ''}
                                                    onChange={event => setFieldValue('periodOvertimeAfter', event.target.value)}
                                                />
                                                <Typography className={classes.typo}>Hours</Typography>
                                            </div>
                                        </CardContent>
                                    </Collapse>
                                </Card>

                                <Card className={classes.card}>
                                    <CardHeader title="Invoice Settings" className={classes.cardHeader}
                                        onClick={() => { setExpandedInvoice(!expandedInvoice) }} 
                                        action={ 
                                                <IconButton aria-label="Invoice Settings">
                                                { expandedInvoice ? <ExpandMore/> : <ExpandLess /> }
                                                </IconButton>
                                        }/>
                                    <Divider />
                                    <Collapse in={expandedInvoice} timeout="auto" unmountOnExit>
                                        <CardContent>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        label="Start invoice number at"
                                                        name="invoiceSeed"
                                                        onChange={event => setFieldValue('invoiceSeed', event.target.value)}
                                                        value={values.invoiceSeed || ''}
                                                        variant="outlined"
                                                        disabled={!!admin.invoiceSeed}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Collapse>
                                </Card>
                            </Grid>
                        </Grid>

                    </form>
                )}
            </Formik>

            <AvatarEditor
                open={openAvatarModal}
                onClose={() => setOpenAvatarModal(false)}
                onGetAvatar={onGetAvatar}
                id={adminId}
                type="admin-update-avatar"
                avatarImage={admin.logoUrl?.includes(adminId) ? admin.logoUrl : null}
            />
            <ConfirmModal
                openConfirm={openConfirm}
                closeConfirm={() => setOpenConfirm(false)}
            // onConfirm={onRemoveAvatar}
            />

            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={() => setOpenSnackBar(false)}>
                <Alert elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    )
}

export default Information;
