import React, {useEffect, useRef, useState} from "react";
import {
    Button, CardActions,
    CircularProgress,
    colors,
    IconButton,
    TableCell,
    TableRow,
    TextField,
    Typography
} from "@material-ui/core";
import {KeyboardDatePicker} from "@material-ui/pickers";
import NumberFormat from "react-number-format";
import {Delete} from "@material-ui/icons";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {makeStyles} from "@material-ui/styles";
import moment from "moment";
import localStorage from "utils/localStorage";
import { PERMISSIONS } from "common/permissions";
const useStyles = makeStyles(theme => ({
    btnDis: {
        whiteSpace: 'nowrap',
        color: 'rgba(0, 0, 0, 0.26)',
        backgroundColor: 'rgb(217 217 217)',
        pointerEvents: 'none'
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '8px 16px'
    },
    cardTitle: {
        fontSize: '16px',
        fontWeight: 500,
        width: '255px',
        marginRight: theme.spacing(3)
    },
    headCell: {
        borderBottom: '1px solid #888888',
        fontSize: '16px',
        padding: '8px 16px'
    },
    button: {
        cursor: 'pointer',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    add: {
        padding: '0',
        '& span svg': {
            fontSize: '40px',
            color: colors.green[600],
            '&:hover': {
                color: colors.green[900]
            }
        }
    },
    removeBtn: {
        padding: '0',
        '& span svg': {
            fontSize: '32px'
        }
    },
    bodyCell: {
        padding: '4px',
        verticalAlign: 'top'
    },
}))

const CustomerPaymentLine = (props) => {
    const classes = useStyles();
    const mounted = useRef(false);
    const ipPlus = useMediaQuery('(max-width: 415px)');
    const {
        index,
        pay,
        disabledPostToQuickbooks,
        onHandleChangeDate,
        onChangePayment,
        paymentType,
        setIndex,
        openConfirmDelete,
        errorItem,
        touchItem,
        onBlueItem,
        validateItems,
        setLocateItem,
        onSaveCustomerPayment,
        postQuickBook
    } = props
    const [save, setSave] = useState(false);
    const [change, setChange] = useState(false);

    const userRoles = localStorage.getUserRoles();
    const isFieldTechnician = !userRoles || (userRoles.length === 1 && userRoles[0] === PERMISSIONS.FIELD_TECHNICIAN);

    useEffect(() => {
        mounted.current = true;
        if (mounted.current) {
            setChange(pay.change);
        }
        return () => mounted.current = true;
    }, [pay.change])

    return (
        <>
            <TableRow key={index}>
                <TableCell className={classes.bodyCell} align='center'>
                    <KeyboardDatePicker
                        disabled={isFieldTechnician}
                        fullWidth
                        inputVariant="outlined"
                        size="small"
                        minDate={false}
                        maxDate={false}
                        name={'paymentDate'}
                        //label="Date"
                        format="MM/DD/YYYY"
                        value={pay.paymentDate || null}
                        InputAdornmentProps={{ position: "end" }}
                        onChange={(date, value) => { onHandleChangeDate(date, index) }}
                        error={errorItem && errorItem[`prices[${index}].paymentDate`]}
                        helperText={
                            (
                                errorItem &&
                                errorItem[`prices[${index}].paymentDate`] &&
                                touchItem[`prices[${index}].paymentDate`]
                            ) && errorItem[`prices[${index}].paymentDate`]}
                        onBlur={() => {
                            setTimeout(() => {
                                onBlueItem('paymentDate', index, touchItem)
                                validateItems(pay, index)
                            }, 0)
                        }}
                    />
                </TableCell>
                <TableCell className={classes.bodyCell}  align='center'>
                    <TextField
                        disabled={isFieldTechnician}
                        fullWidth
                        //label='Type'
                        name='paymentTypeId'
                        variant='outlined'
                        size="small"
                        select
                        SelectProps={{ native: true }}
                        value={pay.paymentTypeId || ''}
                        onChange={(e) => onChangePayment(e, index)}
                        error={errorItem && errorItem[`prices[${index}].paymentTypeId`]}
                        helperText={
                            (
                                errorItem &&
                                errorItem[`prices[${index}].paymentTypeId`] &&
                                touchItem[`prices[${index}].paymentTypeId`]
                            ) && errorItem[`prices[${index}].paymentTypeId`]}
                        onBlur={() => {
                            setTimeout(() => {
                                onBlueItem('type', index, touchItem)
                                validateItems(pay, index)
                            }, 0)
                        }}
                    >
                        <option value=''></option>
                        {
                            paymentType && paymentType.map(item => (
                                <option value={item.id} key={item.id}>
                                    {item.description}
                                </option>
                            ))
                        }
                    </TextField>
                </TableCell>

                <TableCell  className={classes.bodyCell} align='center'>
                    <NumberFormat
                        disabled={isFieldTechnician || (paymentType && paymentType.find(x => (x.id == pay.paymentTypeId && x.paymentMethodType != 'Check') || !pay.paymentTypeId))}
                        inputProps={{ maxLength: 8 }}
                        customInput={TextField}
                        allowNegative={false}

                        fullWidth
                        //label='Payment'
                        name='checkNumber'
                        variant='outlined'
                        size="small"
                        value={pay.checkNumber || ''}
                        error={errorItem && errorItem[`prices[${index}].checkNumber`]}
                        helperText={
                            (
                                errorItem &&
                                errorItem[`prices[${index}].checkNumber`] &&
                                touchItem[`prices[${index}].checkNumber`]
                            ) && errorItem[`prices[${index}].checkNumber`]}
                        onBlur={() => {
                            setTimeout(() => {
                                onBlueItem('checkNumber', index, touchItem)
                                validateItems(pay, index)
                            }, 0)
                        }}
                        onChange={(e) => onChangePayment(e, index)}
                    />
                </TableCell>
                <TableCell  className={classes.bodyCell} align='center'>
                    <NumberFormat
                        disabled={isFieldTechnician}
                        inputProps={{ maxLength: 11 }}
                        customInput={TextField}
                        allowNegative={false}
                        prefix='$'
                        thousandSeparator
                        decimalScale={2}
                        fullWidth
                        //label='Tip'
                        name='amount'
                        variant='outlined'
                        size="small"
                        value={pay.amount ? parseFloat(pay.amount.toString().replace(/[\$,]/g, '')).toFixed(2) : ''}
                        error={errorItem && errorItem[`prices[${index}].amount`]}
                        helperText={
                            (
                                errorItem &&
                                errorItem[`prices[${index}].amount`] &&
                                touchItem[`prices[${index}].amount`]
                            ) && errorItem[`prices[${index}].amount`]}
                        onBlur={() => {
                            setTimeout(() => {
                                onBlueItem('amount', index, touchItem)
                                validateItems(pay, index)
                            }, 0)
                        }}
                        onChange={(e) => {
                            onChangePayment(e, index)
                        }}
                    />
                </TableCell>

                <TableCell  className={classes.bodyCell} align='center'>
                    <TextField
                        fullWidth
                        //label='Notes'
                        name='notes'
                        variant='outlined'
                        size="small"
                        multiline
                        rows={1}
                        value={pay.notes || ''}
                        onChange={(e) => onChangePayment(e, index)}
                    />
                </TableCell>
                <TableCell className={classes.bodyCell}  align='center'>
                    {
                        pay && pay.queuedToQuickbooks &&
                        <Typography style={{marginLeft: '10px'}}><strong>{pay && pay.postedToQuickbooks ? 'Posted Date' : 'Pending Date'}: </strong>
                            {pay.postedToQuickbooks ? moment(pay.postedToQuickbooks).format('MM/DD/YYYY') : moment(pay.queuedToQuickbooks).format('MM/DD/YYYY')}</Typography>
                    }
                </TableCell>
                <TableCell  className={classes.bodyCell} align='center'>
                <div>
                    <Button
                        disabled={isFieldTechnician || pay.posting}
                        size={'small'} style={{ margin: ipPlus ? '8px 0' : '0 0 0 0', minWidth: '180px', backgroundColor: disabledPostToQuickbooks ? '' : pay && !!pay.queuedToQuickbooks ? '#1b5e20': '#43a047' }}
                        className={disabledPostToQuickbooks ? classes.btnDis : classes.button}
                    onClick={()=> postQuickBook(pay.id, index)}
                    >{
                        pay && !!pay.queuedToQuickbooks ? (!!pay.PostedToQuickbooks ? 'Posted To Quickbooks' : 'Queued to Quickbooks') :
                            'Posted To Quickbooks'
                    }</Button>
                    {pay.posting && <CircularProgress size={24} />}
                </div>
                </TableCell>
                <TableCell  className={classes.bodyCell} align='center'>
                    <div>
                    <Button
                        disabled={isFieldTechnician}
                        size={'small'}
                        style={{ margin: ipPlus ? '8px 0' : '0 0 0 0' }}
                        className={!pay.change ? classes.btnDis : classes.button}
                        onClick={() => setTimeout(() => {
                            setLocateItem({
                                index: index,
                                id: pay.id,
                                add: pay.add
                            });
                            setChange(true);
                            onSaveCustomerPayment(pay, index, true);
                        }, 0)}
                    >Save</Button>
                    {pay.saving && change && <CircularProgress size={24} />}
                    </div>
                </TableCell>
                <TableCell  className={classes.bodyCell} align='center'>
                    <IconButton disabled={isFieldTechnician || pay.postedToQuickbooks} className={classes.removeBtn} onClick={() => {
                        setLocateItem({
                            index: index,
                            id: pay.id,
                            add: pay.add,
                        });
                        openConfirmDelete(true);
                    }}>
                        <Delete />
                    </IconButton>
                </TableCell>
            </TableRow>
        </>
    );
}
export default CustomerPaymentLine;
