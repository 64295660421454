import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    Collapse,
    colors,
    Divider,
    Grid,
    makeStyles,
    Snackbar,
    TextField,
    Typography,
    useMediaQuery
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { Alert, Autocomplete } from "@material-ui/lab";
import apiConfig from "apiConfig";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "utils/axios";
import localStorage from "utils/localStorage";
import ATSSnackBar from "../../ATSSnackBar";
import { useAskTheSeal } from "../../useAskTheSeal";

const useStyles = makeStyles(theme => ({
    button: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    btnDis: {
        whiteSpace: 'nowrap',
        color: 'rgba(0, 0, 0, 0.26)',
        backgroundColor: 'rgb(217 217 217)',
        pointerEvents: 'none'
    },
    cardActions: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-end'
        }
    }
}));

let mounted = false;
const Salesperson = props => {
    const { information, disabled, onSaved } = props;

    const classes = useStyles();
    const dispatch = useDispatch();
    const { jobId } = useParams();
    const { postAskTheSealReview, isButtonsEnabled, getAtsId } = useAskTheSeal();

    const [isProgress, setIsProgress] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [expanded, setExpanded] = useState(true);
    const [salePerson, setSalePerson] = useState([]);
    const [commission, setCommission] = useState([]);
    const [estimator, setEstimator] = useState([]);
    const [selectedEstimator, setSelectedEstimator] = useState(null);
    const [atsId, setAtsId] = useState(null);
    const [ATSModalState, setATSModalState] = useState({ isOpen: false, type: '' });
    const [ATSButtonsIsDisabled, setATSButtonsIsDisabled] = useState(true);
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });
    const [openSnackbar, setOpenSnackBar] = useState(false);

    const cId = localStorage.getCompanyId();

    const mobile = useMediaQuery('(max-width: 769px)');

    const toggleExpanded = () => {
        setExpanded(!expanded);
    }

    const onChangeCommission = (com) => {
        setSalePerson([...com])
    }
    const clearCommission = () => {
        setSalePerson([])
    }

    const onSave = () => {
        setIsSaving(true)
        let selectedCommissions = salePerson.map(x => x.id)
        information.estimatorUserId = selectedEstimator && selectedEstimator.id

        Promise.all([
            axios.post(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_SALESPERSON_COMMISSION_SAVE + information.id, selectedCommissions),
            axios.put(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_UPDATE, information)
        ]).then(() => {
            setIsStatus({ failed: false, msg: 'Save successfully!' })
            setOpenSnackBar(true)
            sessionStorage.setItem('sw-information', JSON.stringify(information))
            onSaved();
        })
            .catch(() => {
                setIsStatus({ failed: true, msg: 'An error occurred, please try again later.' })
                setOpenSnackBar(true)
            })
            .finally(() => setIsSaving(false))
    }

    useEffect(() => {
        mounted = true;
        if (information && information.hasOwnProperty("id")) {
            Promise.all([
                axios.get(apiConfig.url.USER_URL + apiConfig.url.CUSTOMER_JOB_SALESPERSON_COMMISSION),
                axios.get(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_SALESPERSON_COMMISSION_GET + information.id),
                axios.get(apiConfig.url.USER_URL + apiConfig.url.COMPANY_EMPLOYEES)
            ]).then(([res1, res2, res3]) => {
                let listCommissions = res1.data && res1.data.users
                if (listCommissions) {
                    listCommissions = listCommissions.map(item => {
                        item['title'] = item.firstName + ' ' + item.lastName;
                        return item;
                    })
                    listCommissions = listCommissions.sort((a, b) => a.lastName.localeCompare(b.lastName))

                    if (mounted) {
                        setCommission(listCommissions)
                        setSalePerson(listCommissions.filter(u => res2.data.indexOf(u.id) >= 0))
                    }
                }

                let activeEmployees = res3.data && res3.data.users;
                 activeEmployees = activeEmployees.filter(x => !x.termDate || x.id == information.estimatorUserId);
                activeEmployees = activeEmployees.sort((a, b) => (b.termDate ? -1 : 1) - ((a.termDate ? -1 : 1)))
                if (activeEmployees) {
                    if (mounted) {
                        setEstimator(activeEmployees);
                        setSelectedEstimator(activeEmployees.find(e => e.id == information.estimatorUserId))
                    }
                }
            })
                .catch(() => {
                    setIsStatus({ failed: true, msg: 'An error occurred, please try again later.' })
                    setOpenSnackBar(true)
                })
                .finally(() => mounted && setIsProgress(false))
        }

        isButtonsEnabled(jobId).then((isTrue) => { if (isTrue) return setATSButtonsIsDisabled(false) })

        return () => mounted = false;
    }, [information]);

    useEffect(() => {
        mounted = true;
        mounted && dispatch({ type: 'CHANGE_SALE', salePerson });

        return () => mounted = false;
    }, [salePerson])

    useEffect(() => {
        getAtsId(cId).then((res) => {
            setAtsId(res.data)
        });
    }, [])

    return (
        <>
            <ATSSnackBar setATSModalState={setATSModalState} isOpen={ATSModalState.isOpen} type={ATSModalState.type} />
            {isProgress ?
                <div style={{ width: '100%', textAlign: 'center', padding: '8px' }}>
                    <CircularProgress size={24} />
                </div> :
                <Card style={{ marginBottom: '16px' }}>
                    <CardHeader
                        title='Salesperson'
                        action={expanded ? <KeyboardArrowUp /> :
                            <KeyboardArrowDown />}
                        onClick={toggleExpanded} />
                    <Divider />
                    <Collapse in={expanded} timeout='auto' unmountOnExit>
                        <CardContent>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        disabled={disabled}
                                        disablePortal={true}
                                        multiple
                                        id="tags-outlined"
                                        options={commission}
                                        getOptionLabel={(option) => option.title}
                                        filterSelectedOptions
                                        value={salePerson}
                                        onChange={(e, value) => {
                                            onChangeCommission(value)
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Salesperson"
                                                placeholder="Search for salesperson"
                                            />
                                        )}
                                    />
                                </Grid>
                                {/* <Grid item xs={12}>
                                    <Autocomplete
                                        disabled={disabled}
                                        disablePortal={true}
                                        options={estimator}
                                        getOptionLabel={(option) => option.fullName}
                                        getOptionDisabled={(option) => option.termDate != '' && option.termDate != null}
                                        disableClearable
                                        value={selectedEstimator}
                                        onChange={(e, value, q) => {
                                            setSelectedEstimator(value)
                                        }}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                label="Estimator (non-commissioned)"
                                                margin="normal"
                                                variant='outlined'
                                            />
                                        }
                                    />
                                </Grid> */}
                            </Grid>
                        </CardContent>
                        <Divider />
                        <CardActions className={classes.cardActions}>
                            {atsId && (
                                <>
                                    <Button disabled={ATSButtonsIsDisabled} onClick={async () => {
                                        const res = await postAskTheSealReview(jobId)
                                        if (res) {
                                            setATSModalState({ type: 'Review', isOpen: true })
                                        } else {
                                            setATSModalState({ type: 'Error', isOpen: true })
                                        }
                                    }
                                    } className={classes.button}>
                                        Ask The Seal Review
                                    </Button>
                                </>
                            )}
                            <Button className={(disabled || isSaving) ? classes.btnDis : classes.button}
                                style={{ marginLeft: mobile ? "8px" : 'auto' }}
                                onClick={onSave}>
                                Save
                            </Button>
                            {isSaving && <CircularProgress size={24} />}
                        </CardActions>
                    </Collapse>
                </Card>
            }
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openSnackbar}
                autoHideDuration={3000}
                onClose={() => setOpenSnackBar(false)}>
                <Alert
                    elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    )
}

export default Salesperson;