import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Alert } from '@material-ui/lab'
import {
    IconButton, Table, TableContainer, TableBody, TableCell, TableHead, TableRow,
    colors, Card, CardHeader, CardContent, Divider, FormControlLabel,
    Grid, Typography, Snackbar, CircularProgress, Tooltip
} from '@material-ui/core';
import {GreenCheckbox, ConfirmModal, Pagination} from 'components'
import { AddAttachment } from './components'
import moment from 'moment'
import axios from 'utils/axios';
import axiosNoAuth from 'utils/axiosNoAuth'
import apiConfig from 'apiConfig';
import { useDispatch } from 'react-redux';
import AddBoxIcon from "@material-ui/icons/AddBox";
import {ArrowDownward, ArrowUpward} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    root: {},
    customTableCell: {
        borderBottom: '1px solid #969696',
        cursor: 'pointer',
        '& span': {
            display: 'flex'
        },
        iconSort: {
            marginBottom: '-5px',
            marginLeft: '10px'
        }
    },
    conUpFile: {
        marginTop: theme.spacing(2),
    },
    inputFile: {
        display: 'none'
    },
    btnUpFile: {
        flexShrink: '0',
        height: '50px',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3)
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
        zIndex: 1
    },
    table: {
        minWidth: '1000px'
    },
    responsiveTable: {
        width: '100%',
        overflowX: 'auto'
    },
    cusFontsize: {
        '& th': {
            fontSize: '15px'
        }
    },
    titleNote: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start'
        }
    },
    message: {
        fontSize: theme.spacing(2),
        fontWeight: 500
    },
    thumbnail: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    circularProgress: {
        marginTop: theme.spacing(3)
    },
    openImage: {
        color: '#263238',
        '&:hover': {
            opacity: 0.7
        }
    },
    addBox: {
		color: colors.green[600],
		"&:hover": {
			color: colors.green[900]
		},
		"& span svg": {
			fontSize: "48px"
		}
	},
    paginate:{
        paddingTop: '10px'
    }
}));
let pageNumber = 1;
let forcePage = 0;
const Attachments = props => {

    const classes = useStyles();
    const mounted = useRef(false);
    const dispatch = useDispatch();
    const matches = useMediaQuery('(min-width:992px)');

    const { customerId } = props;

    const [isSuccess, setIsSuccess] = useState({ failed: false, msg: '' });
    const [openSnack, setOpenSnack] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [listFile, setListFile] = useState();
    const [locateFile, setLocateFile] = useState();
    const [openConfirm, setOpenConfirm] = useState(false);
    const [isProgress, setIsProgress] = useState(false);
    const [isDisable, setDisable] = useState(false);
    const [sortBy, setSortBy] = useState('FileName');
    const [sortType, setSortType] = useState('ASC');
    const [pageCount, setPageCount] = useState(0);

    const onDeleteFile = () => {
        setIsProgress(true);
        axios.delete(apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMERS_DELETE_DOCUMENT + locateFile.id)
            .then(() => {
                setIsSuccess({ failed: false, msg: 'Remove files success.' });
                setOpenSnack(true);
                dispatch({ type: 'RELOAD_CUSTOMERS_COUNTER', reloadCustomer: true });
                const del_files = [...listFile];
                del_files.splice(locateFile.index, 1);
                setListFile(del_files);
            })
            .catch(() => {
                setIsSuccess({ failed: true, msg: 'Remove file failed, please try again later.' });
                setOpenSnack(true);
            })
            .finally(() => {
                setIsProgress(false);
                setOpenConfirm(false);
            })
    }
    const onUpdateFiles = file => {
        setListFile([...listFile, file])
    }

    const isDisplayToFieldTech = id => {
        const file = listFile.find(f => f.id === id);
        setDisable(true);
        axios.patch(apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMERS_DOCUMENT_UPDATE + id, { 'isDisplayToFieldTech': !file.isDisplayToFieldTech })
            .then(res => {
                file.isDisplayToFieldTech = !file.isDisplayToFieldTech;
                setIsSuccess({ failed: false, msg: 'Update file success.' });
                setOpenSnack(true);
            })
            .catch(() => {
                setIsSuccess({ failed: true, msg: 'Update file failed, please try again later.' });
                setOpenSnack(true);
            })
            .finally(() => setDisable(false))
    }

    const onDownloadFile = (url, fileName) => {
        axiosNoAuth.get(url, {
            headers: {},
            responseType: 'blob',
        })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            })
            .catch(() => {
                setIsSuccess({ failed: true, msg: 'Download file failed.' });
                setOpenSnack(true);
            })

    }

    const handleEditOpen = () => {
        setOpenEdit(true);
    }
    const handleEditClose = () => {
        setOpenEdit(false);
    }
    const loadDocument = (type) => {
        setSortBy(type)
        setSortType(type == sortBy ? (sortType === 'ASC' ? 'DESC' : 'ASC') : 'ASC');
        pageNumber = 1;
        forcePage = 0;
    }
    const getSortIcon = (type) => {
        return (
            sortBy == type &&
            (
                sortType == 'ASC' ?
                    <ArrowUpward className={classes.iconSort}/>
                    :
                    <ArrowDownward className={classes.iconSort}/>
            )
        )
    }
    const onPageChange = (page) => {
        pageNumber = page.selected + 1;
        forcePage = page.selected;
        fetchAttachments();
    };
    const fetchAttachments = () => {
        axios.get(apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMERS_DOCUMENT_LIST + customerId,
            {
                params:
                    {
                        sortBy: sortBy,
                        sortType: sortType,
                        limit: 0,
                        pageSize: 24,
                        pageNumber: pageNumber,
                    }
            })
            .then(res => {
                mounted.current && setListFile(res.data.data);
                setPageCount(res.data.pageCount);
            })
    }
    useEffect(() => {
        fetchAttachments();
    }, [sortBy, sortType])
    useEffect(() => {
        mounted.current = true;
        fetchAttachments();
        return () => mounted.current = false;
    }, [])

    if (!listFile) {
        return (
            <Grid
                container
                spacing={0}
                align="center"
                justifyContent="center"
                direction="column">
                <Grid item>
                    <CircularProgress className={classes.circularProgress} size={50} />
                </Grid>
            </Grid>
        )
    }

    return (
        <>
            {/* <Fab
                className={classes.fab}
                color="primary"
                onClick={handleEditOpen}>
                <AddIcon />
            </Fab> */}
            <AddAttachment
                onUpdateAttachments={onUpdateFiles}
                onClose={handleEditClose}
                customerId={customerId}
                open={openEdit} />

            <Card>
                <CardHeader title="Attachments" />
                <Divider />
                <CardContent>
                    <TableContainer className={classes.responsiveTable}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead className={classes.customTableHead}>
                                <TableRow className={classes.cusFontsize}>
                                    <TableCell onClick={() => loadDocument('FileName')} style={{ width: '20%' }} className={classes.customTableCell}><span>File Name{ getSortIcon('FileName')}</span></TableCell>
                                    <TableCell onClick={() => loadDocument('Note')} style={{ width: '25%' }} className={classes.customTableCell}><span>Note{ getSortIcon('Note')}</span></TableCell>
                                    <TableCell onClick={() => loadDocument('UploadedBy')} className={classes.customTableCell}><span>Uploaded By{ getSortIcon('UploadedBy')}</span></TableCell>
                                    <TableCell onClick={() => loadDocument('DateUploaded')} className={classes.customTableCell}><span>Date Uploaded{ getSortIcon('DateUploaded')}</span></TableCell>
                                    <TableCell onClick={() => loadDocument('ExpirationDate')} className={classes.customTableCell}><span>Expiration Date{ getSortIcon('ExpirationDate')}</span></TableCell>
                                    {matches ? <TableCell onClick={() => loadDocument('Size')} className={classes.customTableCell}><span>Size{ getSortIcon('Size')}</span></TableCell> : null}
                                    <TableCell onClick={() => loadDocument('DisplaytoFieldTechs')} align='center' className={classes.customTableCell}><span>Display to Field Tech{ getSortIcon('DisplaytoFieldTechs')}</span></TableCell>
                                    <TableCell align='center' className={classes.customTableCell} style={{ width: '1%' }}>
                                        <Tooltip arrow title={"Add Files"}>
                                            <IconButton
                                                className={classes.addBox}
                                                onClick={handleEditOpen}
                                            >
                                                <AddBoxIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listFile && listFile.length !== 0 ? listFile.map((file, index) => (
                                    <TableRow key={file.id}>
                                        <TableCell>
                                            <div className={classes.thumbnail}>
                                                <a className={classes.openImage} href={file.documentUrl} target='_blank'>
                                                    {file.filename}<span style={{color: 'red', display: moment(file.expirationDate) < moment().endOf('day') ? 'inline-block' : 'none', paddingLeft: '5px'}}>- Expired</span>
                                                </a>
                                                <img width='30px' height='auto' src='/images/thumbnail/file.png' alt='thumb' />
                                            </div>
                                        </TableCell>
                                        <TableCell>{file.note}</TableCell>
                                        <TableCell>{file.uploadedBy}</TableCell>
                                        <TableCell>{moment(file.dateUploaded).format('MM/DD/YYYY')}</TableCell>
                                        <TableCell>{file.expirationDate && moment(file.expirationDate).format('MM/DD/YYYY')}</TableCell>
                                        {matches ? <TableCell>{file.size}</TableCell> : null}
                                        <TableCell align='center'>
                                            <FormControlLabel
                                                onChange={() => isDisplayToFieldTech(file.id)}
                                                disabled={isDisable}
                                                control={
                                                    <GreenCheckbox
                                                        checked={file.isDisplayToFieldTech}
                                                        value="tech"
                                                    />
                                                }
                                            />
                                        </TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap' }}>
                                            <IconButton onClick={() => onDownloadFile(file.documentUrl, file.filename)}>
                                                <GetAppIcon />
                                            </IconButton>


                                            <IconButton aria-label="delete" onClick={() => {
                                                setLocateFile({ index: index, id: file.id });
                                                setOpenConfirm(true)
                                            }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )) : (
                                        <TableRow>
                                            <TableCell colSpan={10}>
                                                <Typography className={classes.message}>No data found...</Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}
                            </TableBody>
                        </Table>
                        <div style={pageCount === 0 ? { display: 'none' } : null} className={classes.paginate}>
                            <Pagination pageCount={pageCount} forcePage={forcePage} onPageChange={onPageChange} />
                        </div>
                    </TableContainer>
                </CardContent>
            </Card>

            <ConfirmModal
                openConfirm={openConfirm}
                closeConfirm={() => setOpenConfirm(false)}
                onConfirm={onDeleteFile}
                isProgress={isProgress}
            />

            <Snackbar
                open={openSnack}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                onClose={() => setOpenSnack(false)}>
                <Alert
                    elevation={6} variant="filled" severity={isSuccess.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isSuccess.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    );
};

Attachments.propTypes = {
    className: PropTypes.string
};

export default Attachments;
