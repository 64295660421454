import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import { Alert } from '@material-ui/lab'
import {
    colors, Grid, CircularProgress, Typography, Snackbar, Button, Link
} from '@material-ui/core'
import { SearchInput, ConfirmModal } from 'components'
import { JobAddressCard, AddJobAddress } from './components'
import apiConfig from 'apiConfig'
import axios from 'utils/axios'
import moment from "moment";
import EmailModal from "../../../CustomerDetails/components/JobAddresses/components/EmailModal";
import EmailModalMultipleQuote from "../../../CustomerDetails/components/JobAddresses/components/EmailModalMultipleQuote";
import ModalCustomerService from "../../../CustomerDetails/components/JobAddresses/components/ModalCustomerService";
import CopyJobModal from "../../../CustomerDetails/components/JobAddresses/components/CopyJobModal";
import CombineJobModal from "../../../CustomerDetails/components/JobAddresses/components/CombineJobModal";
import { useHistory } from 'react-router-dom'
import { formatPhoneNumber } from 'utils/formatNumber'

const useStyles = makeStyles(theme => ({
    root: {},
    actionButton: {
        justifyContent: 'flex-end'
    },
    saveBtn: {
        marginLeft: theme.spacing(1),
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    fab: {
        position: 'fixed',
        bottom: 40,
        right: 40,
        zIndex: 999,
        backgroundColor: '#43a047',
        '&:hover': {
            backgroundColor: '#1b5e20'
        },
    },
    addr: {
        fontSize: theme.spacing(2),
        fontWeight: 500,
        padding: theme.spacing(2),
        cursor: 'pointer',
        backgroundColor: '#ececec',
        borderRadius: '4px',
        marginBottom: theme.spacing(1),
        '&:last-child': {
            marginBottom: '0'
        },
        '&:hover': {
            color: '#ffffff',
            backgroundColor: '#43a047'
        }
    },
    noData: {
        padding: '16px',
        fontSize: theme.spacing(2),
        fontWeight: 500,
        textAlign: 'center'
    },
    menuItem: {
        '&:hover': {
            color: '#ffffff',
            backgroundColor: colors.green[600]
        }
    },
    cardActions: {
        justifyContent: "flex-end",
        flexWrap: "wrap",
        padding: 8,
        '& > :not(:first-child)': {
          margin: 0
        },
        [theme.breakpoints.down("xs")]: {
          justifyContent: "center",
        },
        paddingRight: 0, paddingLeft: 0,
        position: 'sticky',
        zIndex: 3,
        top: 0,
        backgroundColor: '#f4f6f8',
        marginTop: '10px'
    },
    cardActionsNoZIndex: {
        justifyContent: "flex-end",
        flexWrap: "wrap",
        padding: 8,
        '& > :not(:first-child)': {
          margin: 0
        },
        [theme.breakpoints.down("xs")]: {
          justifyContent: "center",
        },
        paddingRight: 0, paddingLeft: 0,
        position: 'sticky',
        top: 0,
        backgroundColor: '#f4f6f8',
        marginTop: '10px'
    },
    btnAction: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        "&:hover": {
          backgroundColor: colors.green[900]
        }
    },
    popupActionButtons: {
        position: "absolute",
        top: 42,
        backgroundColor: "#fff",
        padding: theme.spacing(1),
        marginLeft: -theme.spacing(1),
        borderRadius: theme.spacing(0.5),
        boxShadow: "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
        zIndex: 9999999,
        '& button': {
          width: 80,
          '&:first-child': {
            marginBottom: theme.spacing(1)
          }
        }
    }
}));

let mounted;
const useSearchDelay = (val, delay) => {
    delay = delay || 500;
    const [debounced, setDebounced] = useState(val)

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebounced(val)
        }, delay)

        return () => clearTimeout(handler)
    }, [val, delay])

    return debounced;
};

const JobAddresses = props => {
    const titleCombineJobs = `One or more of the selected jobs contain discounts. The discounts will not be applied to the new combined job. If you wish to apply a discount, you will need to edit the job and add the desired discount.<br><br><br>Please go to the Job Information page of the newly combined job and fill out the Discount value.`;
    const history = useHistory();
    const classes = useStyles();

    const { customerId, customerName } = props;

    const [customer, setCustomer] = useState();
    const [taxGroups, setTaxGroups] = useState();
    const [jobAddresses, setJobAddresses] = useState([]);
    const [jobAddressesFilter, setJobAddressesFilter] = useState();
    const [expandJob, setExpandJob] = useState([]);
    const [val, setVal] = useState('');
    let searchQuery = useSearchDelay(val);
    const [confirm, setConfirm] = useState({ open: false, title: '', isOk: true })
    const [openModal, setOpenModal] = useState(false);
    const [address, setAddress] = useState();
    const [addressRemoved, setAddressRemoved] = useState();
    const [isProgress, setIsProgress] = useState(false);
    const [openSnack, setOpenSnack] = useState(false);
    const [isSuccess, setIsSuccess] = useState({ failed: false, msg: '' });
    const [reload, setReload] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const onChangeAccordion = (event, expanded, index) => {
        // if(expanded)
        //     setCombineJobs([]);
        expandJob[index] = expanded ? true : false;
        setExpandJob( [...expandJob]);
    }
    const setAddJobAddress = () => {
        setAddress(null);
        setOpenModal(true)
    }
    const setUpdateJobAddress = (address) => {
        if (!address.contacts || !address.contacts[0]) {
            address.contacts = [];
            const contact = {
                id: "00000000-0000-0000-0000-000000000000",
                isPrimary: address.contacts.length === 0,
                firstname: "",
                lastname: "",
                email: "",
                phone: ""
            }
            address.contacts.push(contact);
        }else if (!address.contacts[0].phone) {
            address.contacts[0].phone = "";
        }
        setAddress(address);
        setOpenModal(true)
    }
    const setRemoveJobAddress = (jobAddress, index) => {
        const { address, jobCount } = jobAddress;
        if (jobCount > 0) {
            setConfirm({
                open: true,
                title: 'Please remove all jobs before delete this address.',
                isOk: false
            })
        } else {
            setAddressRemoved({ id: address.id, index });
            setConfirm({
                open: true,
                title: 'Are you sure you want to delete this item?',
                isOk: true
            })
        }
    }
    const onRemoveJobAddress = () => {
        setIsProgress(true);
        axios.delete(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_ADDRESSES_DELETE + addressRemoved.id)
            .then(() => {
                setIsSuccess({ failed: false, msg: 'Delete successfully.' });
                setOpenSnack(true);
                setConfirm({ ...confirm, open: false });
                const _jobAddresses = [...jobAddressesFilter];
                _jobAddresses.splice(addressRemoved.index, 1);
                // debugger
                setJobAddresses(_jobAddresses);
            })
            .catch(() => {
                setIsSuccess({ failed: true, msg: 'An error was happend, please try again later.' });
                setOpenSnack(true);
            })
            .finally(() => setIsProgress(false))
    }
    const onAddJobAddress = address => {
        const _jobAddresses = [...jobAddressesFilter];
        const jobAddress = { address, jobCount: 0 };
        _jobAddresses.unshift(jobAddress);
        // debugger
        setJobAddresses(_jobAddresses);
    }
    const onUpdateJobAddress = address => {
        if (address) {
            const indexJobAddress = jobAddressesFilter.findIndex(jobAddress => jobAddress.address.id === address.id);
            const _jobAddresses = [...jobAddressesFilter];
            const jobAddress = { address, jobCount: _jobAddresses[indexJobAddress].jobCount };
            _jobAddresses.splice(indexJobAddress, 1, jobAddress);
            // debugger
            setJobAddresses(_jobAddresses);
        } else {
            // debugger
            setJobAddresses(null);
            setReload(!reload)
        }
    }
    const combineJobsAddress = status => {
        setIsSuccess(status);
        setOpenSnack(true)
    }
    const onRemoveJob = status => {
        setIsSuccess(status);
        setOpenSnack(true)
    }

    const filterAdress = () => {
        let address = [...jobAddresses];
        let search = searchQuery.toLocaleLowerCase();
        address = address.filter(x => {
            let hasContact = x.address.contacts.find(c => {
                return (
                    c.email && c.email.toLocaleLowerCase().includes(search) ||
                    c.name && c.name.toLocaleLowerCase().includes(search) ||
                    c.phone && c.phone.toLocaleLowerCase().includes(search)
                )
            })

            return (
                x.address.name && x.address.name.toLocaleLowerCase().includes(search) ||
                x.address.address1 && x.address.address1.toLocaleLowerCase().includes(search) ||
                x.address.address2 && x.address.address2.toLocaleLowerCase().includes(search) ||
                x.address.address3 && x.address.address3.toLocaleLowerCase().includes(search) ||
                x.address.city && x.address.city.toLocaleLowerCase().includes(search) ||
                x.address.country && x.address.country.toLocaleLowerCase().includes(search) ||
                x.address.zipCode && x.address.zipCode.toLocaleLowerCase().includes(search) ||
                !!hasContact
            )

        })

        mounted && setJobAddressesFilter(address)
    }

    useEffect(() => {
        mounted = true;
        axios.get(apiConfig.url.CUSTOMER_URL + apiConfig.url.CUSTOMERS_GET + customerId)
            .then(res => {
                if (mounted) {
                    setCustomer(res.data)
                    setTaxGroups(res.data.taxgroups)
                }
            })

        return () => mounted = false;
    }, [])
    useEffect(() => {
        mounted = true;
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_ADDRESSES + customerId)
            .then(res => {
                if (mounted) {
                    res.data.addresses.forEach(address => {
                        address?.address?.contacts && address.address.contacts.forEach(contact => {
                            contact && (contact.phone = formatPhoneNumber(contact.phone));
                        });
                    });
                    setJobAddresses(res.data.addresses)
                    setLoaded(true);
                }
            })
        return () => mounted = false;
    }, [reload])

    useEffect(() => {
        mounted = true;
        filterAdress();
        return () => mounted = false;
    }, [searchQuery]);

    useEffect(() => {
        mounted = true;

        setJobAddressesFilter(jobAddresses);
        return () => mounted = false;
    }, [jobAddresses])

    const [combineJobs, setCombineJobs] = useState([]);
    const [openEmailModal, setOpenEmailModal] = useState(false);
    const [isCustomerSerice, setIsCustomerSerice] = useState(false);
    const [title, setTitle] = useState();
    const [newJob, setNewJob] = useState(false);
    const [showModalCopy, setShowModalCopy] = useState(false);
    const [reloadJobs, setReloadJobs] = useState(false);
    const [isStatus, setIsStatus] = useState({ failed: false, msg: "" });
    const [openSnackbar, setOpenSnackBar] = useState(false);

    const [isCanCombine, setIsCanCombine] = useState(true);

    const [jobIdArray, setJobIdArray] = useState([]);

    const onCheckCombine = (event, job, jobAddress) => {
        if (event.target.checked) {
            var result = [...combineJobs, job].reduce(function (r, a) {
                r[a.address_id] = r[a.address_id] || [];
                r[a.address_id].push(a);
                return r;
            }, Object.create(null));
            if(Object.keys(result).length <= 1) {
                setIsCanCombine(false);
            } else {
                setIsCanCombine(true);
            }
            setCombineJobs([...combineJobs, job]);

            if ( job.bidCompleted && moment(job.bidCompleted) <= moment() ) {
                setJobIdArray([...jobIdArray, job]);
            }
        } else {
            const _jobIdArray = [...jobIdArray];
            const indexJobIdArray = _jobIdArray.findIndex(j => j.id === job.id);
            _jobIdArray.splice(indexJobIdArray, 1);
            setJobIdArray(_jobIdArray);

            const _combineJobs = [...combineJobs];
            const indexJob = _combineJobs.findIndex(j => j.id === job.id);
            _combineJobs.splice(indexJob, 1);
            var result = _combineJobs.reduce(function (r, a) {
                r[a.address_id] = r[a.address_id] || [];
                r[a.address_id].push(a);
                return r;
            }, Object.create(null));
            if(Object.keys(result).length <= 1) {
                setIsCanCombine(false);
            } else {
                setIsCanCombine(true);
            }
            setCombineJobs(_combineJobs);
        }
    }

    const addCustomerServices = job => {
        setIsCustomerSerice(true);
        axios
            .post(
                apiConfig.url.BASE_URL +
                apiConfig.url.CUSTOMER_JOB_SERVICE.replace("{jobId}", job.id)
            )
            .then(res => {
                // setIsStatus({ failed: false, msg: 'Copy successfully.' });
                // setOpenSnackBar(true);
                setTitle("The customer service job been added.");
                setNewJob(res.data);
                setShowModalCopy(true);
                setReloadJobs(!reloadJobs);
            })
            .catch(() => {
                setIsStatus({
                    failed: true,
                    msg: "Add Job failed, please try again later"
                });
                setOpenSnackBar(true);
            })
            .finally(() => setIsCustomerSerice(false));
    };

    const redirectToNewJob = () => {
        if (newJob) {
          const path = `/customers/${encodeURIComponent(customerName)}/${newJob.customerId}/jobs/${newJob.id}/information`;
          const win = window.open(path, "_blank");
          win.focus();
          setShowModalCopy(false);
        }
    };

    const onScheduleJob = () => {
        if (newJob) {
          setShowModalCopy(false);
          history.push('/schedules', {jobId: newJob.id});
        }
    };

    const [openCopyModal, setOpenCopyModal] = useState(false);
    const [isCopyJob, setIsCopyJob] = useState(false);
    const [reloadJobsTest, setReloadJobsTest] = useState(null);
    const [isCombine, setIsCombine] = useState(false);
    const [openCombineModal, setOpenCombineModal] = useState(false);
    const [includesCombined, setIncludesCombined] = useState({
        hasJobSiteNote: true,
        hasDiscount: true,
        hasSalesperson: true,
        hasAttachments: true,
        prices: {}
    });

    const onCopyIncludesForCombine = ({
        hasJobSiteNote,
        hasDiscount,
        hasSalesperson,
        hasAttachments,
        prices
      }) => {
        setIncludesCombined({ hasJobSiteNote, hasDiscount, hasSalesperson, hasAttachments, prices });
      };

    useEffect(() => {
        if (combineJobs.length > 0) {
          const hasDiscount = combineJobs.findIndex(job => job.hasDiscount);
          if (includesCombined.hasDiscount && hasDiscount !== -1) {
            setConfirm({
              open: true,
              title: titleCombineJobs,
              onConfirm: onCombineJobs
            });
          } else {
            onCombineJobs();
          }
        }
    }, [includesCombined]);

    const onCombineJobs = () => {
        const jobIds = combineJobs.map(j => j.id);
        const data = { ...includesCombined, jobIds };
        setIsCombine(true);
        axios
          .post(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_COMBINES, data)
          .then(res => {
            combineJobsAddress({ failed: false, msg: "Combine successfully." });
            setTitle("The selected jobs have been combined.");
            setNewJob(res.data);
            setShowModalCopy(true);
            setReloadJobsTest(combineJobs[0])
          })
          .catch(() => {
            combineJobsAddress({
              failed: true,
              msg: "Combine failed, please try again later."
            });
          })
          .finally(() => {
            setIsCombine(false);
            setOpenCombineModal(false);
            setConfirm({ open: false, title: null, onConfirm: null });
          });
    };

    if (!customer || !jobAddressesFilter || !taxGroups) {
        return (
            <Grid
                container
                spacing={0}
                align="center"
                justifyContent="center"
                direction="column">
                <Grid item>
                    <CircularProgress className={classes.circularProgress} size={50} />
                </Grid>
            </Grid>
        )
    }

    return (
        <div>
            <Fab color="primary" onClick={setAddJobAddress} className={classes.fab}>
                <AddIcon />
            </Fab>
            <AddJobAddress
                open={openModal}
                onClose={() => setOpenModal(false)}
                customer={customer}
                updatedAddress={address}
                taxGroups={taxGroups}
                add={onAddJobAddress}
                update={onUpdateJobAddress}
                jobAddresses={jobAddresses}
            />
            <ConfirmModal
                title={confirm.title}
                isOk={confirm.isOk}
                openConfirm={confirm.open}
                isProgress={isProgress}
                closeConfirm={() => setConfirm({ ...confirm, open: false })}
                onConfirm={onRemoveJobAddress}
            />
            <SearchInput
                placeholder='Search Jobs...'
                value={val}
                onChange={e => {
                    setVal(e.target.value)
                }}
                onClear={() => setVal('')}
                onSearch={filterAdress}
            />
            <Grid container className={ (openEmailModal || openCopyModal || openCombineModal || showModalCopy) ? classes.cardActionsNoZIndex : classes.cardActions} spacing={1}>
                <Grid item>
                    <Button
                        className={classes.btnAction}
                        disabled={combineJobs.length < 1}
                        variant="contained"
                        onClick={() => setOpenEmailModal(true)}
                    >
                        Quote/Invoice
                    </Button>
                </Grid>

                <Grid item>
                    <Button
                        variant={"contained"}
                        className={classes.btnAction}
                        disabled={combineJobs.length !== 1}
                        onClick={() => {
                            setOpenCopyModal(true);
                        }}
                    >
                    Copy & Schedule
                  </Button>
                  {isCopyJob && <CircularProgress size={24} />}
                </Grid>

                <Grid item>
                    <Button
                        variant={"contained"}
                        className={classes.btnAction}
                        disabled={combineJobs.length < 2 || isCombine || isCanCombine}
                        onClick={() => setOpenCombineModal(true)}
                    >
                        Combine
                    </Button>
                </Grid>

                <Grid item>
                    <Button
                        variant={"contained"}
                        className={classes.btnAction}
                        disabled={combineJobs.length !== 1}
                        onClick={() => {
                            addCustomerServices(combineJobs[0]);
                        }}
                    >
                        Customer Service
                    </Button>
                    { isCustomerSerice && <CircularProgress size={24} />}
                </Grid>
            </Grid>
            {jobAddressesFilter.length > 0 ? jobAddressesFilter.map((jobAddress, index) => (
                <JobAddressCard
                    index={index}
                    key={jobAddress.address.id}
                    jobAddress={jobAddress}
                    expandJob={expandJob[index]}
                    onChangeAccordion={(event, expanded) => onChangeAccordion(event, expanded, index)}
                    states={customer.states}
                    update={() => setUpdateJobAddress(jobAddress.address)}
                    remove={() => setRemoveJobAddress(jobAddress, index)}
                    removeJob={onRemoveJob}
                    updateJobAddress={onUpdateJobAddress}
                    phoneTypes={customer.phoneTypes}
                    onCheckCombineOutside={(event, job) => onCheckCombine(event, job, jobAddress)}
                    reloadJobsTest={reloadJobsTest}
                    redirectToNewJob={redirectToNewJob}
                    onScheduleJob={onScheduleJob}
                />
            )) : (
                loaded &&
                <Typography className={classes.noData}>No data found</Typography>
            )}

            <Snackbar
                open={openSnack}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                onClose={() => setOpenSnack(false)}>
                <Alert
                    elevation={6} variant="filled" severity={isSuccess.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isSuccess.msg}
                    </Typography>
                </Alert>
            </Snackbar>

            <EmailModalMultipleQuote
                setTitle={() => {}}
                onCloseModal={() => setOpenEmailModal(false)}
                open={openEmailModal}
                jobId={combineJobs && combineJobs.length > 0 ? combineJobs[0].id : ""}
                jobIdArray={combineJobs && combineJobs.length > 0 ? combineJobs : []}
            />

            <ModalCustomerService
                title={title}
                openConfirm={showModalCopy}
                closeConfirm={() => setShowModalCopy(false)}
                onConfirm={redirectToNewJob}
                onScheduleJob={onScheduleJob}
            />

            <CopyJobModal
                setTitle={setTitle}
                job={combineJobs[0]}
                setShowModalCopy={setShowModalCopy}
                setReloadJobs={() => {
                    setReloadJobsTest(null)
                    setReloadJobsTest(combineJobs[0])
                }}
                setNewJob={setNewJob}
                onCloseModal={() => setOpenCopyModal(false)}
                open={openCopyModal}
            />

            <CombineJobModal
                isProgress={isCombine}
                jobs={combineJobs}
                open={openCombineModal}
                onClose={() => setOpenCombineModal(false)}
                onCopyIncludes={onCopyIncludesForCombine}
            />

        </div>
    )
}

export default JobAddresses;
