import React, { useState } from 'react'
import {
    Card, CardHeader, CardContent, CardActions, Divider,
    Typography, TextField, Button, colors, Snackbar, Grid,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { DatePicker } from '@material-ui/pickers'
import { makeStyles } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import moment from 'moment'
import { Page } from "components";
import { AccessControl } from "components/AccessControl/AccessControl";
import { PERMISSIONS } from "common/permissions";

const useStyles = makeStyles(theme => ({
    btn: {
        display: 'block',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    label: {
        fontSize: '15px',
        fontWeight: 500
    },
    dates: {
        display: 'flex',
        justifyContent: 'center',
        padding: '0 32px',
        marginTop: '16px'
    },
    iconDatePicker: {
        position: 'absolute',
        right: '3px',
        top: '50%',
        transform: 'translateY(-50%)'
    },
    styleTo: {
        fontWeight: 'bold',
        textAlign: 'center',
        lineHeight: '50px',
    },
    checkboxGroup: {
        border: '1px solid #b3b3b3',
        borderRadius: '4px',
        marginLeft: '15px',
        paddingLeft: '16px',
    },
    checkbox: {
        display: 'block'
    },
    transferCity: {
        display: 'flex',
        marginTop: '16px',
        [theme.breakpoints.down('xs')]: {
            display: 'block'
        }
    },
    cities: {
        width: '45%',
        border: '1px solid #b3b3b3',
        borderRadius: '4px',
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    overBox: {
        width: '100%',
        height: '200px',
        overflowY: 'auto',
        padding: '16px 8px',
        '&::-webkit-scrollbar': {
            width: '5px'
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#e4e4e4'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#9c9c9c',
            borderRadius: '60px'
        }
    },
    labelCity: {
        position: 'absolute',
        top: '-10px',
        left: '10px',
        backgroundColor: '#ffffff',
        padding: '0 3px',
        fontWeight: 500
    },
    city: {
        paddingLeft: '3px',
        cursor: 'pointer'
    },
    filterCity: {
        marginBottom: '5px',
        '& .MuiInput-input': {
            paddingLeft: '4px'
        }
    },
    transferActions: {
        width: '10%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            flexDirection: 'row',
            margin: '16px 0'
        },
        '& p': {
            width: '50%',
            textAlign: 'center',
            borderRadius: '4px',
            padding: '6px 0',
            backgroundColor: colors.green[600],
            color: '#ffffff',
            fontSize: '20px',
            [theme.breakpoints.down('xs')]: {
                width: '20%'
            },
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: colors.green[900]
            }
        }
    }
}));

const citiesData = [
    'Brookly, NY', 'Miami, FL', 'Las Vegas, NV', 'Dallas, TX',
    'Chicago, IL', 'Seattle, WA'
]

const TransferCompanyData = props => {

    const classes = useStyles();
    const ipPlus = useMediaQuery('(max-width: 415px)');
    const ipad = useMediaQuery('(max-width: 769px)');

    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });
    const [openPicker, setOpenPicker] = useState(false);
    const [typeDate, setTypeDate] = useState();
    const [date, setDate] = useState({});
    const [marketingList, setMarketingList] = useState([0]);
    const [include, setInclude] = useState(['billing']);
    const [cities, setCities] = useState({ excluded: [...citiesData], included: [] });
    const [filterValue, setFilterValue] = useState({ excluded: '', included: '' });
    const [filterCities, setFilterCities] = useState({ excluded: cities.excluded, included: cities.included })
    const [selectedCity, setSelectedCity] = useState({ excluded: null, included: null });

    const onShowDatePicker = type => {
        if (type === 'toDate') {
            if (date.fromDate) {
                setTypeDate(type);
                setOpenPicker(true);
            }
        } else {
            setTypeDate(type);
            setOpenPicker(true);
        }
    }
    const onDatePickerChange = day => {
        setDate({ ...date, [typeDate]: moment(day).format('MM/DD/YYYY') })
    }
    const onChangeMarketing = val => {
        marketingList.length === 2 ?
            (val === 2 ?
                setMarketingList([3]) : setMarketingList([2])
            )
            :
            ((marketingList[0] === 0 || marketingList[0] === 1) ?
                setMarketingList([val]) : (marketingList[0] !== val && setMarketingList([...marketingList, val]))
            )
    }
    const onChangeInclude = val => {
        include.length === 2 ?
            val === 'billing' ? setInclude(['jobsite']) : setInclude(['billing'])
            :
            include[0] !== val && setInclude([...include, val])
    }

    // transfer actions
    const transferOneToIncluded = () => {
        const filterCts = [...filterCities.excluded];
        const cts = [...cities.excluded];
        const city = filterCts.splice(selectedCity.excluded, 1);
        cts.splice(cts.findIndex(c => c === city[0]), 1);
        setSelectedCity({ ...selectedCity, excluded: null });
        setFilterCities({ excluded: filterCts, included: [...filterCities.included, city[0]] });
        setCities({ excluded: cts, included: [...cities.included, city[0]] })
    }
    const transferAllToIncluded = () => {
        const filterCts = [...filterCities.excluded];
        const cts = [...cities.excluded];
        setFilterCities({ excluded: [], included: filterCities.included.concat(filterCts) });
        setCities({ excluded: [], included: cities.included.concat(cts) })
    }
    const transferOneToExcluded = () => {
        const filterCts = [...filterCities.included];
        const cts = [...cities.included];
        const city = filterCts.splice(selectedCity.included, 1);
        cts.splice(cts.findIndex(c => c === city[0]), 1);
        setSelectedCity({ ...selectedCity, included: null });
        setFilterCities({ excluded: [...filterCities.excluded, city[0]], included: filterCts });
        setCities({ excluded: [...cities.excluded, city[0]], included: cts })
    }
    const transferAllToExcluded = () => {
        const filterCts = [...filterCities.included];
        const cts = [...cities.included];
        setFilterCities({ excluded: filterCities.excluded.concat(filterCts), included: [] });
        setCities({ excluded: cities.excluded.concat(cts), included: [] })
    }
    // filter cities
    const onChangeFilter = event => {
        const { name, value } = event.target;
        const filterCts = cities[name].filter(city => city.toLowerCase().indexOf(value.toLowerCase()) > -1);
        setFilterValue({ ...filterValue, [name]: value });
        setFilterCities({ ...filterCities, [name]: filterCts })
    }

    return (
        <AccessControl requiredPermission={PERMISSIONS.page.superAdmin} redirect>
            <Page title={'Super admin - Transfer Company Data'}>
                <div style={{ padding: 32 }}>
                    <Card>
                        <CardHeader title='Transfer' />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label='From Company'
                                        name='fromCompany'
                                        variant='outlined'
                                        select
                                        SelectProps={{ native: true }}>
                                        <option value=''></option>
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label='To Company'
                                        name='toCompany'
                                        variant='outlined'
                                        select
                                        SelectProps={{ native: true }}>
                                        <option value=''></option>
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label='QB Suffix for Transferred Customers'
                                        name='QBSuffix'
                                        variant='outlined'
                                    />
                                </Grid>
                            </Grid>

                            <Typography style={{ color: '#ff0000', fontWeight: 500, marginTop: '40px' }}>
                                Note that the following tax groups do not exist in the destination company.
                            </Typography>

                            <Typography className={classes.label} style={{ marginTop: '32px' }}>Choose Geography:</Typography>
                            <div className={classes.transferCity}>
                                <div className={classes.cities}>
                                    <Typography className={classes.labelCity}>Excluded Cities</Typography>
                                    <div className={classes.overBox}>
                                        <TextField
                                            fullWidth
                                            className={classes.filterCity}
                                            name='excluded'
                                            placeholder='Search cities...'
                                            variant='standard'
                                            value={filterValue.excluded}
                                            onChange={onChangeFilter}
                                        />
                                        {filterCities.excluded.map((city, index) => (
                                            <Typography className={classes.city} key={index}
                                                style={index === selectedCity.excluded ? { backgroundColor: '#43a047', color: '#ffffff' } : null}
                                                onClick={() => setSelectedCity({ ...selectedCity, excluded: selectedCity.excluded !== index ? index : null })}>
                                                {city}
                                            </Typography>
                                        ))}
                                    </div>
                                </div>
                                <div className={classes.transferActions}>
                                    <Typography style={selectedCity.excluded === null ? {
                                        pointerEvents: 'none',
                                        opacity: 0.7,
                                        color: 'rgba(0, 0, 0, 0.26)',
                                        backgroundColor: 'rgb(217 217 217)',
                                    } : null}
                                        onClick={transferOneToIncluded}>
                                        {'>'}
                                    </Typography>
                                    <Typography style={selectedCity.included === null ? {
                                        pointerEvents: 'none',
                                        opacity: 0.7,
                                        color: 'rgba(0, 0, 0, 0.26)',
                                        backgroundColor: 'rgb(217 217 217)',
                                    } : null}
                                        onClick={transferOneToExcluded}>
                                        {'<'}
                                    </Typography>
                                    <Typography style={filterCities.excluded.length === 0 ? {
                                        pointerEvents: 'none',
                                        opacity: 0.7,
                                        color: 'rgba(0, 0, 0, 0.26)',
                                        backgroundColor: 'rgb(217 217 217)',
                                    } : null}
                                        onClick={transferAllToIncluded}>
                                        {'>>'}
                                    </Typography>
                                    <Typography style={filterCities.included.length === 0 ? {
                                        pointerEvents: 'none',
                                        opacity: 0.7,
                                        color: 'rgba(0, 0, 0, 0.26)',
                                        backgroundColor: 'rgb(217 217 217)',
                                    } : null}
                                        onClick={transferAllToExcluded}>
                                        {'<<'}
                                    </Typography>
                                </div>
                                <div className={classes.cities}>
                                    <Typography className={classes.labelCity}>Included Cities</Typography>
                                    <div className={classes.overBox}>
                                        <TextField
                                            fullWidth
                                            className={classes.filterCity}
                                            name='included'
                                            placeholder='Search cities...'
                                            variant='standard'
                                            value={filterValue.included}
                                            onChange={onChangeFilter}
                                        />
                                        {filterCities.included.map((city, index) => (
                                            <Typography className={classes.city} key={index}
                                                style={index === selectedCity.included ? { backgroundColor: '#43a047', color: '#ffffff' } : null}
                                                onClick={() => setSelectedCity({ ...selectedCity, included: selectedCity.included !== index ? index : null })}>
                                                {city}
                                            </Typography>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                        <Divider />
                        <CardActions style={{ paddingLeft: '16px' }}>
                            <Button className={classes.btn}>
                                Transfer Customers
                            </Button>
                        </CardActions>
                    </Card>

                    <DatePicker
                        minDate={typeDate === 'fromDate' ? new Date('1900-01-01') : new Date(date.fromDate)}
                        maxDate={typeDate === 'toDate' ? new Date() : (date.toDate ? new Date(date.toDate) : new Date())}
                        onChange={onDatePickerChange}
                        onClose={() => setOpenPicker(false)}
                        open={openPicker}
                        style={{ display: 'none' }}
                        variant="dialog"
                    />

                    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={openSnackbar}
                        autoHideDuration={3000}
                        onClose={() => setOpenSnackBar(false)}>
                        <Alert elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                            <Typography
                                color="inherit"
                                variant="h6">
                                {isStatus.msg}
                            </Typography>
                        </Alert>
                    </Snackbar>
                </div>
            </Page>
        </AccessControl>
    )
}

export default TransferCompanyData;
