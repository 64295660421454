import React, { useEffect, useState, useRef } from 'react'
import {
    Card, CardHeader, CardContent, CardActions, Divider,
    Button, TextField, CircularProgress, Grid,
    colors, Typography, Collapse
} from '@material-ui/core'
import { KeyboardArrowDown } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import axios from "utils/axios";
import apiConfig from "apiConfig";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { errorMessages } from 'common/constants'
import { Link } from "react-router-dom";
import { checkFeature, FeatureFlags } from 'FeatureService';

const useStyles = makeStyles(theme => ({
    cardHeader: {
        cursor: 'pointer'
    },
    stickyBtn: {
        marginBottom: 0,
        position: '-webkit-sticky',
        position: 'sticky',
        backgroundColor: '#f4f6f8',
        top: '-35px',
        zIndex: 2,
    },
    btn: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    textarea: {
        resize: "vertical",
        minHeight: '150px',
        width: '100%',
        padding: '0px'
    }
}));

const ContractsAndForms = ({ adminId }) => {

    const classes = useStyles();
    const isMounted = useRef(false);

    const [quote, setQuote] = useState();
    const [quoteBlur, setQuoteBlur] = useState(false);
    const [invoice, setInvoice] = useState();
    const [invoiceBlur, setInvoiceBlur] = useState(false);
    const [workOrder, setWorkOrder] = useState();
    const [workOrderBlur, setWorkOrderBlur] = useState(false);
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [reload, setReload] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });
    const [expandedQuoteForm, setExpandedQuoteForm] = useState(true);
    const [expandedInvoiceForm, setExpandedInvoiceForm] = useState(true);
    const [expandedWorkOrderForm, setExpandedWorkOrderForm] = useState(true);
    const [enableWorkOrder, setEnableWorkOrder] = useState(true)

    const closeSnackbar = () => {
        setOpenSnackBar(false);
    };

    const newLine = '- ';
    const onFocusQuote = () => {
        if (!quote) {
            setQuote(quote + '- ');
        } else {
            let value = quote.split('\n').slice(-1)[0]
            if (value.trim() !== '-') {
                setQuote(quote + '\n- ');
            }
        }
    }
    const onKeyPressQuoteTextField = (e) => {
        let value = quote.split('\n').slice(-1)[0]
        if (value.trim() === '') {
            setQuote(e.target.value + '- ');
        }
        if (value === '-') {
            setQuote(e.target.value + ' ');
        }
    }
    const onBlurQuoteTextField = (e) => {
        if (e.target.value.trim() === '-') {
            setQuote('');
        }
    }

    const onFocusInvoice = () => {
        if (!invoice) {
            setInvoice(invoice + '- ');
        } else {
            let value = invoice.split('\n').slice(-1)[0]
            if (value.trim() !== '-') {
                setInvoice(invoice + '\n- ');
            }
        }
    }
    const onKeyPressInvoiceTextField = (e) => {
        let value = invoice.split('\n').slice(-1)[0]
        if (value.trim() === '') {
            setInvoice(e.target.value + '- ');
        }
        if (value === '-') {
            setInvoice(e.target.value + ' ');
        }
    }
    const onBlurInvoiceTextField = (e) => {
        if (e.target.value.trim() === '-') {
            setInvoice('');
        }
    }

    const onFocusWorkOrder = () => {
        if (!workOrder) {
            setWorkOrder(workOrder + '- ');
        } else {
            let value = workOrder.split('\n').slice(-1)[0]
            if (value.trim() !== '-') {
                setWorkOrder(workOrder + '\n- ');
            }
        }
    }
    const onKeyPressWorkOrderTextField = (e) => {
        let value = workOrder.split('\n').slice(-1)[0]
        if (value.trim() === '') {
            setWorkOrder(e.target.value + '- ');
        }
        if (value === '-') {
            setWorkOrder(e.target.value + ' ');
        }
    }
    const onBlurWorkOrderTextField = (e) => {
        if (e.target.value.trim() === '-') {
            setWorkOrder('');
        }
    }

    const submitForm = () => {
        if (!quote) {
            setQuoteBlur(true)
            setIsStatus({ failed: true, msg: errorMessages.FIELD_CHECK });
            setOpenSnackBar(true);
            return;
        }
        if (!invoice) {
            setInvoiceBlur(true)
            setIsStatus({ failed: true, msg: errorMessages.FIELD_CHECK });
            setOpenSnackBar(true);
            return;
        }
        if (enableWorkOrder && !workOrder) {
            setWorkOrderBlur(true)
            setIsStatus({ failed: true, msg: errorMessages.FIELD_CHECK });
            setOpenSnackBar(true);
            return;
        }
        setIsSaving(true);
        const data = {
            quoteForm: quote.replaceAll(newLine, '').replace(/\n+$/, ''),
            invoiceForm: invoice.replaceAll(newLine, '').replace(/\n+$/, ''),
            workOrderForm: workOrder.replaceAll(newLine, '').replace(/\n+$/, '')
        }
        axios.put(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_CONTRACT_FORM.replace('{id}', adminId), data)
            .then(res => {
                setIsStatus({ failed: false, msg: '' });
                setOpenSnackBar(true);
                setReload(!reload)
            })
            .catch(err => {
                setIsStatus({ failed: true, msg: 'An error occurred, please try again later.' });
                setOpenSnackBar(true);
            })
            .finally(e => setIsSaving(false));
    }

    useEffect(() => {
        isMounted.current = true;
        setLoading(true);
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_CONTRACT_FORM.replace('{id}', adminId))
            .then(res => {
                if (isMounted.current) {
                    setQuote(res.data.quoteForm ? '- ' + res.data.quoteForm.replaceAll('\n', '\n- ') : '');
                    setInvoice(res.data.invoiceForm ? '- ' + res.data.invoiceForm.replaceAll('\n', '\n- ') : '');
                    setWorkOrder(res.data.workOrderForm ? '- ' + res.data.workOrderForm.replaceAll('\n', '\n- ') : '');
                    setLoading(false);
                    const inputElement = document.getElementsByClassName('MuiOutlinedInput-multiline');
                    if (inputElement && inputElement.length) {
                        inputElement[0].style.padding = "4px"
                        inputElement[1].style.padding = "4px"
                        // inputElement[2].style.padding = "4px"
                    }
                }
            })
        return () => isMounted.current = false;
    }, [reload])
    useEffect(() => {
        isMounted.current = true;
        (async () => {
            const isEnableWorkOrder = await checkFeature(FeatureFlags.WORK_ORDER);
            setEnableWorkOrder(isEnableWorkOrder)
            }
        )();
      return () => isMounted.current = false;
    }, [])
    if (quote === undefined && invoice === undefined && workOrder === undefined && loading) {
        return (
            <Grid
                container
                spacing={0}
                align="center"
                justifyContent="center"
                direction="column">
                <Grid item>
                    <CircularProgress className={classes.circularProgress} size={50} />
                </Grid>
            </Grid>
        )
    }
    return (
        <>
            <Grid className={classes.stickyBtn} container spacing={4}>
                <Grid item xs={12}>
                    <CardActions>
                        <Button
                            onClick={() => {
                                submitForm()
                            }}
                            className={classes.btn}
                            disabled={isSaving}
                            variant="contained">
                            Save
                        </Button>
                        {isSaving && <CircularProgress size={24} />}
                    </CardActions>
                </Grid>
            </Grid>

            <Card>
                <CardHeader title='Quote Form' className={classes.cardHeader}
                    action={<KeyboardArrowDown />}
                    onClick={() => setExpandedQuoteForm(!expandedQuoteForm)}
                />
                <Divider />
                <Collapse in={expandedQuoteForm} timeout='auto' unmountOnExit>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    aria-label="Additional Verbiage"
                                    placeholder="Additional Verbiage"
                                    error={!quote && quoteBlur}
                                    helperText={!quote && quoteBlur ? 'This field is required.' : ''}
                                    onFocus={onFocusQuote}
                                    onBlur={(event) => {
                                        onBlurQuoteTextField(event)
                                        setQuoteBlur(true)
                                    }}
                                    onKeyPress={(ev) => {
                                        if (ev.key === 'Enter') {
                                            setQuote(quote + '\n- ')
                                            ev.preventDefault();
                                        } else {
                                            onKeyPressQuoteTextField(ev);
                                        }
                                    }}
                                    fullWidth
                                    label='Additional Verbiage'
                                    name='quoteForm'
                                    multiline
                                    variant='outlined'
                                    inputProps={{ className: classes.textarea }}
                                    onChange={event => setQuote(event.target.value)}
                                    value={quote}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider />
                    <CardActions style={{ justifyContent: 'flex-end', paddingRight: '16px' }}>
                        <Button
                            className={classes.btn}
                        >
                            <Link
                                style={{ color: 'white', textDecoration: 'none' }}
                                component="a"
                                to={"/quote/pdf/draft"}
                                target="_blank"
                            >
                                View Form
                            </Link>
                        </Button>
                    </CardActions>
                </Collapse>
            </Card>

            <Card style={{ marginTop: '24px' }}>
                <CardHeader title='Invoice Form' className={classes.cardHeader}
                    action={<KeyboardArrowDown />}
                    onClick={() => setExpandedInvoiceForm(!expandedInvoiceForm)}
                />
                <Divider />
                <Collapse in={expandedInvoiceForm} timeout='auto' unmountOnExit>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    aria-label="Additional Verbiage"
                                    placeholder="Additional Verbiage"
                                    error={!invoice && invoiceBlur}
                                    helperText={!invoice && invoiceBlur ? 'This field is required.' : ''}
                                    onFocus={onFocusInvoice}
                                    onBlur={(event) => {
                                        onBlurInvoiceTextField(event)
                                        setInvoiceBlur(true);
                                    }}
                                    onKeyPress={(ev) => {
                                        if (ev.key === 'Enter') {
                                            setInvoice(invoice + '\n- ')
                                            ev.preventDefault();
                                        } else {
                                            onKeyPressInvoiceTextField(ev);
                                        }
                                    }}
                                    fullWidth
                                    label='Additional Verbiage'
                                    name='invoiceForm'
                                    multiline
                                    variant='outlined'
                                    onChange={event => setInvoice(event.target.value)}
                                    inputProps={{ className: classes.textarea }}
                                    value={invoice}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider />
                    <CardActions style={{ justifyContent: 'flex-end', paddingRight: '16px' }}>
                        <Button
                            className={classes.btn}
                        >
                            <Link
                                style={{ color: 'white', textDecoration: 'none' }}
                                component="a"
                                to={"/invoice/pdf/draft"}
                                target="_blank"
                            >
                                View Form
                            </Link>
                        </Button>
                    </CardActions>
                </Collapse>
            </Card>
            {enableWorkOrder &&
            <Card style={{ marginTop: '24px' }}>
                <CardHeader title='Work Order Form' className={classes.cardHeader}
                    action={<KeyboardArrowDown />}
                    onClick={() => setExpandedWorkOrderForm(!expandedWorkOrderForm)}
                />
                <Divider />
                <Collapse in={expandedWorkOrderForm} timeout='auto' unmountOnExit>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    aria-label="Additional Verbiage"
                                    placeholder="Additional Verbiage"
                                    error={!workOrder && workOrderBlur}
                                    helperText={!workOrder && workOrderBlur ? 'This field is required.' : ''}
                                    onFocus={onFocusWorkOrder}
                                    onBlur={(event) => {
                                        onBlurWorkOrderTextField(event)
                                        setWorkOrderBlur(true);
                                    }}
                                    onKeyPress={(ev) => {
                                        if (ev.key === 'Enter') {
                                            setWorkOrder(workOrder + '\n- ')
                                            ev.preventDefault();
                                        } else {
                                            onKeyPressWorkOrderTextField(ev);
                                        }
                                    }}
                                    fullWidth
                                    label='Additional Verbiage'
                                    name='workOrderForm'
                                    multiline
                                    variant='outlined'
                                    onChange={event => setWorkOrder(event.target.value)}
                                    inputProps={{ className: classes.textarea }}
                                    value={workOrder}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider />
                    <CardActions style={{ justifyContent: 'flex-end', paddingRight: '16px' }}>
                        <Button
                            className={classes.btn}
                            disabled = {true}
                        >
                            <Link
                                style={{ color: 'white', textDecoration: 'none' }}
                                component="a"
                                to={"/invoice/pdf/draft"}
                                target="_blank"
                            >
                                View Form
                            </Link>
                        </Button>
                    </CardActions>
                </Collapse>
            </Card>
            }
            <Snackbar anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }} open={openSnackbar}
                autoHideDuration={3000}
                onClose={closeSnackbar}
            >
                {isStatus.failed
                    ?
                    <Alert
                        elevation={6} variant="filled" severity="error">
                        <Typography
                            color="inherit"
                            variant="h6">
                            {isStatus.msg}
                        </Typography>
                    </Alert>
                    :
                    <Alert
                        elevation={6} variant="filled" severity="success">
                        <Typography
                            color="inherit"
                            variant="h6">
                            Save successfully!
                        </Typography>
                    </Alert>
                }
            </Snackbar>

            <div style={{ height: 1, marginTop: 20 }}></div>
        </>
    )
}

export default ContractsAndForms;
