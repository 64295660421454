import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Alert } from '@material-ui/lab';
import {
    Modal, CardHeader, Card, CardContent, CardActions,
    Typography, TextField, Button, colors, FormControlLabel,
    Snackbar, Divider, CircularProgress
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { GreenRadio } from 'components';
import NumberFormat from 'react-number-format';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'utils/axios';
import apiConfig from 'apiConfig';

const useStyles = makeStyles(theme => ({
    modelStyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 700,
        maxHeight: '90%',
        overflowY: 'auto',
        maxWidth: '100%'
    },
    actions: {
        justifyContent: 'flex-end'
    },
    saveButton: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    dayInWeek: {
        fontSize: 16,
        position: 'absolute',
        top: 16,
        left: 110,
        textTransform: 'uppercase'
    },
    errorPeriodDate: {
        color: '#e53935',
        fontSize: 11,
        margin: '0 14px'
    },
    radioGroup: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: '8px'
    },
    groupField: {
        display: 'flex',
        alignItems: 'center',
        '& div': {
            width: '150px',
            marginRight: '15px'
        }
    },
}));

const companySchema = Yup.object().shape({
    periodStartDate: Yup.string().nullable().required('Required.'),
    periodOvertimeAfter: Yup.string().nullable().required('Required.'),
});

const CompanySettingModel = (props) => {
    const classes = useStyles();
    const history = useHistory();

    const { open, onClose, company } = props;

    const [isSaving, setIsSaving] = useState(false);
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });

    return (
        <>
            <Modal open={open} onClose={onClose}>
                <Card className={classes.modelStyle}>
                    <Formik
                        initialValues={company}
                        validationSchema={companySchema}
                        enableReinitialize
                        onSubmit={values => {
                            setIsSaving(true);
                            axios.put(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_UPDATE.replace('{companyId}', values.id), values)
                                .then(res => history.push("/employees/add-user"))
                                .catch(err => {
                                    setIsStatus({ failed: true, msg: 'An error occurred, please try again later.' });
                                    setOpenSnackBar(true);
                                })
                                .finally(e => setIsSaving(false));
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            setFieldValue,
                            setFieldTouched,
                            handleSubmit
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <CardHeader title="Admin/PayPeriodSettings" />
                                <Divider />
                                <CardContent>
                                    <div style={{ position: 'relative' }}>
                                        <KeyboardDatePicker
                                            disabled={!!values.periodStartDate}
                                            error={errors.periodStartDate && touched.periodStartDate ? true : false}
                                            helperText={errors.periodStartDate && touched.periodStartDate && errors.periodStartDate}
                                            label='Start Date'
                                            inputVariant='outlined'
                                            format="MM/DD/YYYY"
                                            onBlur={() => setFieldTouched('periodStartDate')}
                                            value={values.periodStartDate ? new Date(values.periodStartDate) : null}
                                            onChange={date => setFieldValue('periodStartDate', date.toISOString())}
                                            shouldDisableDate={date => date.day() > 1}
                                        />
                                        <Typography className={classes.dayInWeek}>
                                            {(values.periodStartDate && new Date(values.periodStartDate).getDay() === 0) ?
                                                'Sunday' : 'Monday'
                                            }
                                        </Typography>
                                        {/* {errorPeriodDate && <Typography className={classes.errorPeriodDate}>
                                            {errorPeriodDate}
                                        </Typography>} */}
                                    </div>
                                    <div className={classes.radioGroup}>
                                        <Typography className={classes.typo} style={{ width: '130px' }}>
                                            Period Duration
                                        </Typography>
                                        <FormControlLabel
                                            style={{ width: '105px' }}
                                            checked={values.periodDuration === 1 ? true : false}
                                            label='One Week'
                                            onChange={() => setFieldValue('periodDuration', 1)}
                                            control={
                                                <GreenRadio />
                                            }
                                            disabled={values.periodDuration === 1 || values.periodDuration === 2}
                                        />
                                        <FormControlLabel
                                            label='Two Weeks'
                                            checked={values.periodDuration === 2 ? true : false}
                                            onChange={() => setFieldValue('periodDuration', 2)}
                                            disabled={values.periodDuration === 1 || values.periodDuration === 2}
                                            control={
                                                <GreenRadio />
                                            }
                                        />
                                    </div>
                                    <div className={classes.groupField}>
                                        <NumberFormat
                                            //disabled={!!values.periodOvertimeAfter}
                                            customInput={TextField}
                                            errors={errors.periodOvertimeAfter && touched.periodOvertimeAfter}
                                            helperText={errors.periodOvertimeAfter && touched.periodOvertimeAfter && errors.periodOvertimeAfter}
                                            label="Overtime Pay After"
                                            name="periodOvertimeAfter"
                                            variant="outlined"
                                            onBlur={() => setFieldTouched('periodOvertimeAfter')}
                                            value={values.periodOvertimeAfter || ''}
                                            onChange={event => setFieldValue('periodOvertimeAfter', event.target.value)}
                                        />
                                        <Typography className={classes.typo}>Hours</Typography>
                                    </div>
                                </CardContent>
                                <Divider />
                                <CardActions className={classes.actions}>
                                    <Button
                                        onClick={onClose}
                                        variant="contained">
                                        Cancel
                                    </Button>
                                    <Button disabled={isSaving || !values.periodDuration}
                                        type='submit'
                                        className={classes.saveButton}
                                        variant="contained">
                                        Continue
                                    </Button>
                                    {isSaving && <CircularProgress size={24} />}
                                </CardActions>
                            </form>
                        )}
                    </Formik>
                </Card>
            </Modal>

            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={() => setOpenSnackBar(false)}>
                <Alert elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    )
}

CompanySettingModel.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    company: PropTypes.object
}

export default CompanySettingModel;