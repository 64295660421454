import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Alert } from '@material-ui/lab'
import {
    Modal, Card, CardHeader, CardContent, CardActions,
    Grid, Typography, TextField, Button, Divider,
    colors, Snackbar,
} from '@material-ui/core'
import NumberFormat from 'react-number-format'

const useStyles = makeStyles(theme => ({
    styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 500,
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: 500
        },
    },
    conButton: {
        padding: '16px',
        justifyContent: 'flex-end'
    },
    buttonSave: {
        flexShrink: '0',
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
    hidePicker: {
        display: 'none'
    },
}))

const AddsaleCommission = props => {

    const classes = useStyles();
    const { open, save, close } = props;

    const [saleCommission, setSaleCommission] = useState({});
    const [openSnack, setOpenSnack] = useState(false);
    const [status, setStatus] = useState({ failed: false, msg: '' })

    const onChangesaleCommission = event => {
        const { name, value } = event.target;
        setSaleCommission({ ...saleCommission, [name]: value })
    }
    const resetModal = () => {
        setSaleCommission({});
        close();
    }
    const onSave = () => {
        const { person, rate, type, amountAllocation, amountSale } = saleCommission;
        if (!person || !rate || !type || !amountAllocation || !amountSale) {
            setStatus({ failed: true, msg: 'Please fill up the fields.' });
            setOpenSnack(true);
            return;
        }
        save(saleCommission);
        resetModal();
    }

    return (
        <>
            <Modal open={open} onClose={resetModal}>
                <Card className={classes.styleModal}>
                    <CardHeader title='Sales Commission' />
                    <Divider />
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={8}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label='Person'
                                    name='person'
                                    value={saleCommission.person || ''}
                                    onChange={onChangesaleCommission}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <NumberFormat
                                    customInput={TextField}
                                    allowNegative={false}
                                    suffix='%'
                                    isAllowed={values => {
                                        return !values.floatValue || values.floatValue <= 100
                                    }}
                                    fullWidth
                                    label="Rate"
                                    name="rate"
                                    variant="outlined"
                                    value={saleCommission.rate || ''}
                                    onChange={onChangesaleCommission}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label='Type'
                                    name='type'
                                    variant='outlined'
                                    select
                                    SelectProps={{ native: true }}
                                    value={saleCommission.type || ''}
                                    onChange={onChangesaleCommission}>
                                    <option value=''></option>
                                    <option value='New Sale'>New Sale</option>
                                    <option value='Returning'>Returning</option>
                                    <option value='Sale'>Sale</option>
                                    <option value='Update'>Update</option>
                                </TextField>
                            </Grid>

                            <Grid item xs={12}>
                                <NumberFormat
                                    customInput={TextField}
                                    allowNegative={false}
                                    prefix='$'
                                    thousandSeparator
                                    fullWidth
                                    label='Amount Allocation'
                                    name='amountAllocation'
                                    variant='outlined'
                                    value={saleCommission.amountAllocation || ''}
                                    onChange={onChangesaleCommission}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <NumberFormat
                                    customInput={TextField}
                                    allowNegative={false}
                                    prefix='$'
                                    thousandSeparator
                                    fullWidth
                                    label='Sale Commission Amount Due to Sale Person'
                                    name='amountSale'
                                    variant='outlined'
                                    value={saleCommission.amountSale || ''}
                                    onChange={onChangesaleCommission}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider />
                    <CardActions className={classes.conButton}>
                        <Button
                            variant="contained"
                            size="large"
                            onClick={resetModal}>
                            Close
                        </Button>
                        <div>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={onSave}
                                className={classes.buttonSave}>
                                Add
                            </Button>
                        </div>
                    </CardActions>
                </Card>
            </Modal>

            <Snackbar
                open={openSnack}
                onClose={() => setOpenSnack(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={3000}>
                <Alert elevation={6} variant='filled' severity={status.failed ? 'error' : 'success'}>
                    <Typography variant='h6' color='inherit'>
                        {status.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    )
}

AddsaleCommission.propTypes = {
    open: PropTypes.bool,
    save: PropTypes.func,
    close: PropTypes.func
}

export default AddsaleCommission;
