import React, { useEffect, useState, useRef } from 'react'
import {
    Card, CardHeader, CardContent, Divider,
    TableContainer, Table, TableHead, TableBody, TableRow, TableCell,
    IconButton, colors, Button, CircularProgress, Snackbar,
    Typography, Grid, CardActions,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { AddBox } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import axios from "utils/axios";
import apiConfig from "apiConfig";
import * as Yup from "yup";
import { FieldArray, Form } from "formik";
import { Formik } from "formik";
import { ConfirmModal } from "components";
import uuid from "uuid/v1";
import EquipmentList from "./EquipmentList";

const useStyles = makeStyles(theme => ({
    headRow: {
        '& th': {
            borderBottom: '1px solid #888888',
            fontSize: '16px',
        }
    },
    bodyRow: {
        '& td': {
            padding: '8px 16px'
        }
    },
    cardHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px 16px'
    },
    addIcon: {
        padding: '0',
        '& span svg': {
            fontSize: '40px',
            color: colors.green[600],
            '&:hover': {
                color: colors.green[900]
            }
        }
    },
    btn: {
        whiteSpace: 'nowrap',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
    },
    btnDis: {
        whiteSpace: 'nowrap',
        color: 'rgba(0, 0, 0, 0.26)',
        backgroundColor: 'rgb(217 217 217)',
        pointerEvents: 'none'
    },
}));

export class Em {
    constructor(companyId) {
        this.id = '';
        this.companyId = companyId;
        this.assetTag = "";
        this.description = "";
        this.active = false;
        this.modified = false;
        this.add = true;
    }
}

const validateSchema = Yup.object().shape({
    equipments: Yup.array()
        .of(
            Yup.object().shape({
                assetTag: Yup.string().required("Nickname is required"),
                description: Yup.string().required("Please enter description"),
            })
        )
});

const Equipment = ({ adminId }) => {
    const isMounted = useRef(false);
    const formikRef = useRef();
    const initialValues = {
        equipments: [],
        load: false
    };
    const classes = useStyles();
    const [isSaving, setIsSaving] = useState(false);
    const [saveAll, setSaveAll] = useState(false);
    const [load, setLoad] = useState(false);
    const [equipments, setEquipments] = useState(initialValues);
    const [isStatus, setIsStatus] = useState({ failed: false, msg: '' });
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [canSave, setCanSave] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [isProgress, setIsProgress] = useState(false);
    const [locateFile, setLocateFile] = useState();
    const [equipmentChanging, setEquipmentChanging] = useState([]);

    const closeSnackbar = () => {
        setOpenSnackBar(false);
    };

    const onChangeEquipment = (event, setFieldValue, index) => {
        setFieldValue(event.target.name, event.target.value);
        if (!equipmentChanging.includes(index)) {
            setEquipmentChanging([...equipmentChanging, index])
        }
    }
    const addEquipment = (unshift) => {
        setCanSave(true);
        const eq = [...equipments.equipments]
        const newEq = new Em(adminId);
        unshift(newEq);
        eq.unshift(newEq);
        setEquipments({
            equipments: eq,
            load: true
        })
        setEquipmentChanging(equipmentChanging.map(ec => ec + 1))
    }

    const getAllEquipments = () => {
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_EQUIPMENT_LIST.replace('{id}', adminId))
            .then(res => {
                if (isMounted.current) {
                    res.data.sort((a, b) => a.assetTag.localeCompare(b.assetTag));
                    setEquipments({
                        equipments: res.data,
                        load: true
                    })
                    formikRef.current.setFieldValue('equipments', res.data);
                }
            })
    }
    const onDeleteFile = () => {
        if (locateFile.add) {
            locateFile.delete(locateFile.index);
            setOpenConfirm(false);
        } else {
            setIsProgress(true);
            axios.delete(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_EQUIPMENT.replace('{id}', adminId) + `/${locateFile.id}`)
                .then(() => {
                    setIsStatus({ failed: false, msg: 'Remove files success.' });
                    setOpenSnackBar(true);
                    locateFile.delete(locateFile.index);
                    setLoad(!load)
                })
                .catch(() => {

                    setIsStatus({ failed: true, msg: 'Remove file failed, please try again later.' });
                    setOpenSnackBar(true);
                })
                .finally(() => {

                    setIsProgress(false);
                    setOpenConfirm(false);
                })
        }
    }

    const updateEquipment = (dataInsert, index) => {
        setIsSaving(true);
        const item = dataInsert;
        setEquipmentChanging(equipmentChanging.filter(i => i !== index));
        delete item.add;
        axios.put(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_EQUIPMENT.replace('{id}', adminId), item)
            .then(res => {
                setIsStatus({ failed: false, msg: '' });
                setOpenSnackBar(true);
                setIsSaving(false)
                setSaveAll(false);
            })
            .catch(err => {
                setIsStatus({ failed: true, msg: 'An error occurred, please try again later.' });
                setOpenSnackBar(true);
                setIsSaving(false)
                setSaveAll(false)
            })
            .finally();
    }
    const insertEquipment = (dataInsert, index, setFieldValue) => {
        setIsSaving(true);
        const item = dataInsert;
        setEquipmentChanging(equipmentChanging.filter(i => i !== index));
        delete item.add;
        item.id = uuid();
        axios.post(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_EQUIPMENT.replace('{id}', adminId), item)
            .then(res => {
                setIsStatus({ failed: false, msg: '' });
                setOpenSnackBar(true);
                setIsSaving(false)
                setSaveAll(false);
                setFieldValue(`equipments.${index}.id`, res.data.id);
            })
            .catch(err => {
                setIsStatus({ failed: true, msg: 'An error occurred, please try again later.' });
                setOpenSnackBar(true);
                setIsSaving(false);
                setSaveAll(false);
            })
            .finally();
    }

    const saveRow = (errors, touched, index, equipment, setFieldValue) => {
        if (!equipment.assetTag || !equipment.description) {
            return
        }
        setIsSaving(true);
        if (equipment.id) {
            updateEquipment(equipment, index)
        } else {
            insertEquipment(equipment, index, setFieldValue)
        }
    }

    useEffect(() => {
        isMounted.current = true;
        getAllEquipments()
        return () => isMounted.current = false
    }, [load])

    if (!equipments.load) {
        return (
            <Grid
                container
                spacing={0}
                align="center"
                justifyContent="center"
                direction="column">
                <Grid item>
                    <CircularProgress className={classes.circularProgress} size={50} />
                </Grid>
            </Grid>
        )
    }

    return (
        <>
            <Formik
                innerRef={formikRef}
                initialValues={equipments}
                validationSchema={validateSchema}
                // enableReinitialize
                onSubmit={values => {
                    setSaveAll(true)
                    setEquipmentChanging([]);
                    values.equipments.map(item => {
                        delete (item.add);
                        if (!item.id) delete (item.id);
                        return item
                    })

                    const equipmentChanged = equipmentChanging.map(index => values.equipments[index]);
                    axios.put(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_EQUIPMENT_SAVE_ALL.replace('{id}', adminId), equipmentChanged)
                        .then(res => {
                            setIsStatus({ failed: false, msg: 'Save successfully!' });
                            setOpenSnackBar(true);
                            setLoad(!load)
                            setSaveAll(false)
                        })
                        .catch(err => {
                            setIsStatus({ failed: true, msg: 'An error occurred, please try again later.' });
                            setOpenSnackBar(true);
                            setIsSaving(false)
                            setSaveAll(false)
                        })
                        .finally();
                }}
                render={(
                    {
                        values,
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                        submitForm
                    }) => {
                    return (
                        <Form>
                            <FieldArray
                                subscription={{}}
                                name="equipments"
                                render={({ insert, remove, unshift }) => (
                                    <Card>
                                        <CardHeader title='Equipment' />
                                        <Divider />
                                        <CardContent>
                                            <TableContainer>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow className={classes.headRow}>
                                                            <TableCell align='center' style={{ width: '25%' }}>Nickname
                                                                (for
                                                                schedule)</TableCell>
                                                            <TableCell align='center'>Description</TableCell>
                                                            <TableCell align='center' style={{ width: '1px' }}>
                                                                <CardActions>
                                                                <Button className={equipmentChanging.length === 0 ? classes.btnDis : classes.btn}
                                                                    onClick={() => submitForm()}
                                                                >
                                                                    Save All
                                                                </Button>
                                                                {saveAll && <CircularProgress size={24} />}
                                                                </CardActions>
                                                            </TableCell>
                                                            <TableCell align='center' style={{ width: '1px' }}>
                                                                <IconButton
                                                                    className={classes.addIcon}
                                                                    onClick={() => addEquipment(unshift)}
                                                                >
                                                                    <AddBox />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            values.equipments.length > 0 &&
                                                            values.equipments.map((equipment, index) => (
                                                                <EquipmentList
                                                                    key={index}
                                                                    index={index}
                                                                    equipment={equipment}
                                                                    equipmentChanging={equipmentChanging.includes(index)}
                                                                    onChangeEquipment={event => onChangeEquipment(event, setFieldValue, index)}
                                                                    errors={errors}
                                                                    touched={touched}
                                                                    setFieldTouched={setFieldTouched}
                                                                    saveRow={(errors, touched, index, equipment) => saveRow(errors, touched, index, equipment, setFieldValue)}
                                                                    isSaving={isSaving}
                                                                    setOpenConfirm={setOpenConfirm}
                                                                    setCanSave={setCanSave}
                                                                    remove={remove}
                                                                    setLocateFile={setLocateFile}
                                                                    locateFile={locateFile}
                                                                />
                                                            ))
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </CardContent>
                                    </Card>
                                )}
                            />
                        </Form>
                    );
                }}
            />

            <ConfirmModal
                openConfirm={openConfirm}
                closeConfirm={() => setOpenConfirm(false)}
                onConfirm={onDeleteFile}
                isProgress={isProgress}
            />

            <Snackbar anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }} open={openSnackbar}
                autoHideDuration={3000}
                onClose={closeSnackbar}
            >
                {isStatus.failed
                    ?
                    <Alert
                        elevation={6} variant="filled" severity="error">
                        <Typography
                            color="inherit"
                            variant="h6">
                            {isStatus.msg}
                        </Typography>
                    </Alert>
                    :
                    <Alert
                        elevation={6} variant="filled" severity="success">
                        <Typography
                            color="inherit"
                            variant="h6">
                            {isStatus.msg || 'Save successfully!'}
                        </Typography>
                    </Alert>
                }
            </Snackbar>

            <div style={{ height: 1, marginTop: 20 }}></div>
        </>
    )
}

export default Equipment;
