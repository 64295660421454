import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Alert } from '@material-ui/lab';
import {
    Modal,
    Card,
    CardContent,
    CardActions,
    Grid,
    Typography,
    TextField,
    Switch,
    Button,
    colors,
    Snackbar, FormControlLabel, FormControl, FormGroup, FormLabel, CardHeader, Divider, CircularProgress
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { Formik } from 'formik';
import * as Yup from 'yup';

import axios from 'utils/axios';
import apiConfig from 'apiConfig';
import { GreenCheckbox } from "components";
import ConfirmModalExistsDate from "./ConfirmModalExistsDate";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { isDate } from "moment";
import moment from "moment";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 700,
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%'
    },
    container: {
        // marginTop: theme.spacing(3)
    },
    actions: {
        justifyContent: 'flex-end'
    },
    saveButton: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    checkBokLabel: {
        textAlign: 'end'
    },
    taxCheckbox: {
        width: '100%',
        justifyContent: 'space-between'
    },
    datepicker: {
        '& > div': {
            paddingRight: 0
        }
    }
}));

const validationSchema = Yup.object().shape({
    salary: Yup.string().nullable()
        .when('salaryCheckBox', {
            is: true,
            then: Yup.string().required('This field is required.'),
        }),
    hourly: Yup.string().nullable()
        .when('hourlyCheckBox', {
            is: true,
            then: Yup.string().required('This field is required.'),
        }),
    laborPercent: Yup.string().nullable()
        .when('laborCommission', {
            is: true,
            then: Yup.string().required('This field is required.'),
        }),
    salePercentNewSale: Yup.string().nullable()
        .when('saleCommission', {
            is: true,
            then: Yup.string().required('This field is required.'),
        }),
    salePercentRetSale: Yup.string().nullable()
        .when('saleCommission', {
            is: true,
            then: Yup.string().required('This field is required.'),
        }),
    upsalePercent: Yup.string().nullable()
        .when('upsaleCommission', {
            is: true,
            then: Yup.string().required('This field is required.'),
        }),
    startDate: Yup.date()
        .typeError('Invalid date.')
        .when(
            'endDate',
            (endDate, schema) => {
                let d = new Date(endDate)
                if (endDate != null && !isNaN(d.getTime()) && isDate(d)) {
                    // d = d.setDate(d.getDate())
                    return schema.max(new Date(d), 'Start date cannot be after the end date.')
                }
                return schema;
            },
        )
        .nullable().required('This field is required.'),
    endDate: Yup.date()
        .typeError('Invalid date.')
        .when(
            'startDate',
            (startDate, schema) => {
                let d = new Date(startDate)
                if (!isNaN(d.getTime()) && isDate(d)) {
                    // d = d.setDate(d.getDate())
                    return schema.min(new Date(d), 'End date cannot be less than start date.')
                }
                return schema;
            },
        )
        .nullable()
}, ['startDate', 'endDate'])

const initialValues = {
    salaryCheckBox: false,
    hourlyCheckBox: false,
    salary: '',
    hourly: '',
    hourlyAllHours: true,
    hourlySpecificHours: false,
    laborCommission: false,
    laborPercent: '',
    laborCommissionAllJobs: true,
    laborCommissionSpecificJobs: false,
    saleCommission: false,
    salePercentNewSale: "",
    salePercentRetSale: "",
    upsaleCommission: false,
    upsalePercent: "",
    startDate: null,
    endDate: null,
    hasValue: false,
    userId: ''
};

let mounted = false;
const AddCompensation = props => {
    const { userId, open, onClose, salary, onUpdateCompensation, salaryHistory } = props;

    const classes = useStyles();

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [saveHistory, setSaveHistory] = useState(false);
    const [modalConfirm, setModalConfirm] = useState(false);
    const [errorHourlyRate, setErrorHourlyRate] = useState(false);
    const [errorLaborCommission, setErrorLaborCommission] = useState(false);
    const [formValue, setFormValue] = useState(initialValues);
    const [currentSalary, setCurrentSalary] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const checkExitsCompensation = (startDate, endDate, id) => {
        let existed = false;
        if (salaryHistory[0]) {
            if (salaryHistory[0].id && id === salaryHistory[0].id) return existed;

            startDate = new Date(startDate);
            endDate = new Date(endDate);
            const start = salaryHistory[0].startDate ? new Date(salaryHistory[0].startDate) : null;
            const end = salaryHistory[0].endDate ? new Date(salaryHistory[0].endDate) : null;
            // check exist date
            if (salaryHistory[0].endDate) {
                if (startDate.setHours(0, 0, 0, 0) <= end.setHours(0, 0, 0, 0)) {
                    existed = true
                }
            } else if (salaryHistory[0].startDate) {
                if (startDate.setHours(0, 0, 0, 0) <= start.setHours(0, 0, 0, 0)) {
                    existed = true
                }
            }
        }

        return existed;
    }

    const onChange = (event, setFieldValue, values) => {
        const { name, value, type } = event.target;
        if (type !== 'checkbox') {
            setFieldValue(name, value)
        }
        if (type === 'checkbox' && !event.target.checked) {
            switch (name) {
                case 'salaryCheckBox':
                    setFieldValue(name, event.target.checked)
                    setFieldValue('salary', '');
                    break;
                case 'hourlyCheckBox':
                    setFieldValue(name, event.target.checked)
                    setFieldValue('hourly', '');
                    break;
                case 'laborCommission':
                    setFieldValue(name, event.target.checked)
                    setFieldValue('laborPercent', '');
                    break;
                case 'saleCommission':
                    setFieldValue(name, event.target.checked)
                    setFieldValue('salePercentNewSale', '');
                    setFieldValue('salePercentRetSale', '');
                    break;
                case 'upsaleCommission':
                    setFieldValue(name, event.target.checked)
                    setFieldValue('upsalePercent', '');
                    break;
                case 'hourlyAllHours':
                    if (values.hourlySpecificHours) {
                        setFieldValue(name, event.target.checked)
                    }
                    break;
                case 'hourlySpecificHours':
                    if (values.hourlyAllHours) {
                        setFieldValue(name, event.target.checked)
                    }
                    break;
                case 'laborCommissionAllJobs':
                    if (values.laborCommissionSpecificJobs) {
                        setFieldValue(name, event.target.checked)
                    }
                    break;
                case 'laborCommissionSpecificJobs':
                    if (values.laborCommissionAllJobs) {
                        setFieldValue(name, event.target.checked)
                    }
                    break;
            }
        }
        if (type === 'checkbox' && event.target.checked) {
            switch (name) {
                case 'hourlyCheckBox':
                    setFieldValue(name, event.target.checked)
                    if (!value.hourlyAllHours && !values.hourlySpecificHours) {
                        setFieldValue('hourlyAllHours', true)
                    }
                    break;
                case 'laborCommission':
                    setFieldValue(name, event.target.checked)
                    if (!value.laborCommissionAllJobs && !values.laborCommissionSpecificJobs) {
                        setFieldValue('laborCommissionAllJobs', true)
                    }
                    break;
                case 'salaryCheckBox':
                case 'saleCommission':
                    setFieldValue(name, event.target.checked)
                    break;
                case 'upsaleCommission':
                    setFieldValue(name, event.target.checked)
                    break;
                case 'hourlyAllHours':
                    setErrorHourlyRate(false);
                    setFieldValue(name, event.target.checked)
                    setFieldValue('hourlySpecificHours', false)
                    break;
                case 'hourlySpecificHours':
                    setErrorHourlyRate(false);
                    setFieldValue(name, event.target.checked)
                    setFieldValue('hourlyAllHours', false)
                    break;
                case 'laborCommissionAllJobs':
                    setErrorLaborCommission(false);
                    setFieldValue(name, event.target.checked)
                    setFieldValue('laborCommissionSpecificJobs', false)
                    break;
                case 'laborCommissionSpecificJobs':
                    setErrorLaborCommission(false);
                    setFieldValue(name, event.target.checked)
                    setFieldValue('laborCommissionAllJobs', false)
                    break;
            }
        }
    }

    useEffect(() => {
        mounted = true;
        if (mounted && salary) {
            const data = {
                ...salary,
                startDate: salary.startDate ? salary.startDate : '',
                endDate: salary.endDate ? salary.endDate : '',
                salaryCheckBox: !!salary.salary,
                hourlyCheckBox: !!salary.hourly,
                laborCommission: !!salary.laborPercent,
                saleCommission: !!salary.salePercentNewSale,
                upsaleCommission: !!salary.upsalePercent,
            }

            setFormValue(data);
            setDisabled((salaryHistory && salary && salaryHistory[0] && salary.id && salary.id !== salaryHistory[0].id) || false);
            setCurrentSalary(true)
        } else {
            setDisabled(false);
            setCurrentSalary(false);
            setFormValue({ ...initialValues })
        }

        return () => mounted = false;
    }, [salary])

    useEffect(() => {
        mounted = true;
        if (mounted) {
            setErrorHourlyRate(false);
            setErrorLaborCommission(false);
        }

        return () => mounted = false;
    }, [open])

    return (
        <>
            <Modal
                onClose={onClose}
                open={open}>
                <Card className={classes.root}>
                    <Formik
                        initialValues={formValue}
                        validationSchema={validationSchema}
                        enableReinitialize
                        onSubmit={values => {
                            values = { ...values, startDate: values.startDate ? moment(values.startDate).format('MM/DD/YYYY') : null, endDate: values.endDate ? moment(values.endDate).format('MM/DD/YYYY') : null }
                            if (values.hourlyCheckBox && (!values.hourlyAllHours && !values.hourlySpecificHours)) {
                                setErrorHourlyRate(true);
                                return;
                            }
                            if (values.laborCommission && (!values.laborCommissionAllJobs && !values.laborCommissionSpecificJobs)) {
                                setErrorLaborCommission(true);
                                return;
                            }
                            if (checkExitsCompensation(values.startDate, values.endDate, values.id)) {
                                setModalConfirm(true);
                                return;
                            }
                            const data = { ...values, userId: userId }
                            setSaveHistory(true);
                            if (values.id) {
                                axios.post(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_COMPENSATION_HISTORY_UPDATE, data)
                                    .then(() => {
                                        setIsSuccess(true);
                                        setOpenSnackbar(true);
                                        onClose();
                                        onUpdateCompensation();
                                    })
                                    .catch(() => {
                                        setIsSuccess(false);
                                        setOpenSnackbar(true);
                                    }).finally(res => setSaveHistory(false))
                            } else {
                                axios.put(apiConfig.url.USER_URL + apiConfig.url.EMPLOYEE_COMPENSATION_PUT, data)
                                    .then(() => {
                                        setIsSuccess(true);
                                        setOpenSnackbar(true);
                                        onClose();
                                        onUpdateCompensation();
                                    })
                                    .catch(() => {
                                        setIsSuccess(false);
                                        setOpenSnackbar(true);
                                    }).finally(() => res => setSaveHistory(false))
                            }

                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            setFieldValue,
                            setFieldTouched,
                            handleSubmit
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <CardHeader title={formValue.id ? 'Edit Compensation' : 'Add Compensation'} />
                                <Divider />
                                <CardContent>
                                    <Grid
                                        className={classes.container}
                                        container
                                        spacing={2}
                                    >
                                        <Grid item md={4} xs={4} className={classes.checkBokLabel}>
                                            <FormControlLabel
                                                disabled={disabled || currentSalary}
                                                className={classes.taxCheckbox}
                                                name={'salaryCheckBox'}
                                                label='Salary'
                                                checked={values.salaryCheckBox || false}
                                                labelPlacement="start"
                                                control={
                                                    <GreenCheckbox />
                                                }
                                                onChange={(event) => onChange(event, setFieldValue, values)}
                                            />
                                        </Grid>
                                        <Grid item md={8} xs={8}>
                                            <NumberFormat
                                                customInput={TextField}
                                                prefix='$'
                                                thousandSeparator
                                                allowNegative={false}
                                                fullWidth
                                                disabled={disabled || currentSalary || !values.salaryCheckBox}
                                                error={
                                                    values.salaryCheckBox && errors.salary && touched.salary ?
                                                        true : false
                                                }
                                                helperText={(values.salaryCheckBox && errors.salary && touched.salary) && errors.salary}
                                                label="Weekly Salary"
                                                name="salary"
                                                onBlur={() => setFieldTouched('salary', true)}
                                                onValueChange={val => setFieldValue('salary', val.value)}
                                                value={
                                                    values.salary ?
                                                        (Number.isInteger(parseFloat(values.salary)) ?
                                                            parseInt(values.salary) :
                                                            values.salary
                                                        ) : ''
                                                }
                                                variant="outlined" />
                                        </Grid>

                                        <Grid item md={4} xs={4} className={classes.checkBokLabel}>
                                            <FormControlLabel
                                                disabled={disabled || currentSalary}
                                                className={classes.taxCheckbox}
                                                name='hourlyCheckBox'
                                                label='Hourly'
                                                checked={values.hourlyCheckBox || false}
                                                labelPlacement="start"
                                                control={
                                                    <GreenCheckbox />
                                                }
                                                onChange={(event) => onChange(event, setFieldValue, values)}
                                            />
                                        </Grid>
                                        <Grid item md={8} xs={8}>
                                            <NumberFormat
                                                customInput={TextField}
                                                prefix='$'
                                                thousandSeparator
                                                allowNegative={false}
                                                fullWidth
                                                disabled={disabled || currentSalary || !values.hourlyCheckBox}
                                                error={
                                                    values.hourlyCheckBox && errors.hourly && touched.hourly ?
                                                        true : false
                                                }
                                                helperText={(values.hourlyCheckBox && errors.hourly && touched.hourly) && errors.hourly}
                                                label="Hourly Rate"
                                                name="hourly"
                                                onBlur={() => setFieldTouched('hourly', true)}
                                                onValueChange={val => setFieldValue('hourly', val.value)}
                                                value={
                                                    values.hourly ?
                                                        (Number.isInteger(parseFloat(values.hourly)) ?
                                                            parseInt(values.hourly) :
                                                            values.hourly
                                                        ) : ''
                                                }
                                                variant="outlined" />
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            <FormControl component="fieldset" style={{
                                                border: errorHourlyRate ? '1px solid rgba(255, 0, 0, 0.38)' : '1px solid rgba(0, 0, 0, 0.38)',
                                                padding: '10px'
                                            }}>
                                                <FormLabel style={{ color: errorHourlyRate ? '#e53935' : 'initial' }}
                                                    component="legend">Hourly Rate Default Actions
                                                </FormLabel>
                                                <FormGroup aria-label="position" row>
                                                    <FormControlLabel
                                                        disabled={disabled || currentSalary || !values.hourlyCheckBox}
                                                        value="top"
                                                        control={
                                                            <GreenCheckbox />
                                                        }
                                                        label="Apply Hourly Rate to all hours logged"
                                                        labelPlacement="end"
                                                        checked={values.hourlyAllHours || false}
                                                        name='hourlyAllHours'
                                                        onChange={(event) => {
                                                            onChange(event, setFieldValue, values)
                                                        }}
                                                    />
                                                    <FormControlLabel
                                                        disabled={disabled || currentSalary || !values.hourlyCheckBox}
                                                        value="top"
                                                        control={
                                                            <GreenCheckbox />
                                                        }
                                                        label="Only apply Hourly Rate when specific to the period logged by the user"
                                                        labelPlacement="end"
                                                        checked={values.hourlySpecificHours || false}
                                                        name='hourlySpecificHours'
                                                        onChange={(event) => onChange(event, setFieldValue, values)}
                                                    />
                                                </FormGroup>
                                            </FormControl>
                                            {
                                                errorHourlyRate &&
                                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" style={{ color: '#e53935' }}>This field is required.</p>
                                            }
                                        </Grid>
                                        <Grid item md={4} xs={4} className={classes.checkBokLabel}>
                                            <FormControlLabel
                                                disabled={disabled || currentSalary}
                                                className={classes.taxCheckbox}
                                                name='laborCommission'
                                                label='Labor Commission'
                                                checked={values.laborCommission || false}
                                                labelPlacement="start"
                                                control={
                                                    <GreenCheckbox />
                                                }
                                                onChange={(event) => {
                                                    // setFieldTouched('laborPercent', true);
                                                    onChange(event, setFieldValue, values)
                                                }}
                                            />
                                        </Grid>
                                        <Grid item md={8} xs={8}>
                                            <NumberFormat
                                                customInput={TextField}
                                                suffix='%'
                                                thousandSeparator
                                                allowNegative={false}
                                                disabled={disabled || currentSalary || !values.laborCommission}
                                                fullWidth
                                                error={values.laborCommission && errors.laborPercent && touched.laborPercent ? true : false}
                                                helperText={(values.laborCommission && errors.laborPercent && touched.laborPercent) && errors.laborPercent}
                                                label="Commission Rate"
                                                name="laborPercent"
                                                onBlur={() => setFieldTouched('laborPercent', true)}
                                                onValueChange={val => setFieldValue('laborPercent', val.value)}
                                                value={
                                                    values.laborPercent ?
                                                        (Number.isInteger(parseFloat(values.laborPercent)) ?
                                                            parseInt(values.laborPercent) :
                                                            values.laborPercent
                                                        ) : ''
                                                }
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            <FormControl component="fieldset" style={{
                                                border: errorLaborCommission ? '1px solid rgba(255, 0, 0, 0.38)' : '1px solid rgba(0, 0, 0, 0.38)',
                                                padding: '10px'
                                            }}>
                                                <FormLabel style={{ color: errorLaborCommission ? '#e53935' : 'initial' }} component="legend">
                                                    Labor Commission Default Actions
                                                </FormLabel>
                                                <FormGroup aria-label="position" row>
                                                    <FormControlLabel
                                                        disabled={disabled || currentSalary || !values.laborCommission}
                                                        value="top"
                                                        control={
                                                            <GreenCheckbox />
                                                        }
                                                        label="Apply Commission Rate to all assigned jobs"
                                                        labelPlacement="end"
                                                        checked={values.laborCommissionAllJobs || false}
                                                        name='laborCommissionAllJobs'
                                                        onChange={(event) => onChange(event, setFieldValue, values)}
                                                    />
                                                    <FormControlLabel
                                                        disabled={disabled || currentSalary || !values.laborCommission}
                                                        value="top"
                                                        control={
                                                            <GreenCheckbox />
                                                        }
                                                        label="Only apply Commission Rate when selected on Job Completion screen"
                                                        labelPlacement="end"
                                                        checked={values.laborCommissionSpecificJobs || false}
                                                        name='laborCommissionSpecificJobs'
                                                        onChange={(event) => onChange(event, setFieldValue, values)}
                                                    />
                                                </FormGroup>
                                            </FormControl>
                                            {
                                                errorLaborCommission &&
                                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" style={{ color: '#e53935' }}>This
                                                    field is required.</p>
                                            }
                                        </Grid>
                                        <Grid item md={4} xs={4} className={classes.checkBokLabel}>
                                            <FormControlLabel
                                                disabled={disabled || currentSalary}
                                                className={classes.taxCheckbox}
                                                name={'saleCommission'}
                                                label='Sales Commission'
                                                checked={values.saleCommission || false}
                                                labelPlacement="start"
                                                control={
                                                    <GreenCheckbox />
                                                }
                                                onChange={(event) => onChange(event, setFieldValue, values)}
                                            />
                                        </Grid>
                                        <Grid item sm={4} xs={8}>
                                            <NumberFormat
                                                customInput={TextField}
                                                suffix='%'
                                                thousandSeparator
                                                allowNegative={false}
                                                disabled={disabled || currentSalary || !values.saleCommission}
                                                fullWidth
                                                error={values.saleCommission && errors.salePercentNewSale && touched.salePercentNewSale ? true : false}
                                                helperText={(values.saleCommission && errors.salePercentNewSale && touched.salePercentNewSale) && errors.salePercentNewSale}
                                                label="New Customer"
                                                name="salePercentNewSale"
                                                onBlur={() => {
                                                    setFieldTouched('salePercentNewSale', true);
                                                }}
                                                onChange={event => {
                                                    setFieldValue('salePercentNewSale', event.target.value)
                                                }}
                                                value={
                                                    values.salePercentNewSale ?
                                                        (Number.isInteger(parseFloat(values.salePercentNewSale)) ?
                                                            parseInt(values.salePercentNewSale) :
                                                            values.salePercentNewSale
                                                        ) : ''
                                                }
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item sm={'auto'} xs={4} style={{ padding: 0 }}></Grid>
                                        <Grid item sm={4} xs={8}>
                                            <NumberFormat
                                                customInput={TextField}
                                                suffix='%'
                                                thousandSeparator
                                                allowNegative={false}
                                                disabled={disabled || currentSalary || !values.saleCommission}
                                                fullWidth
                                                error={values.saleCommission && errors.salePercentRetSale && touched.salePercentRetSale ? true : false}
                                                helperText={(values.saleCommission && errors.salePercentRetSale && touched.salePercentRetSale) && errors.salePercentRetSale}
                                                label="Returning Customer"
                                                name="salePercentRetSale"
                                                onBlur={() => {
                                                    setFieldTouched('salePercentRetSale', true);
                                                }}
                                                onChange={event => {
                                                    setFieldValue('salePercentRetSale', event.target.value)
                                                }}
                                                value={
                                                    values.salePercentRetSale ?
                                                        (Number.isInteger(parseFloat(values.salePercentRetSale)) ?
                                                            parseInt(values.salePercentRetSale) :
                                                            values.salePercentRetSale
                                                        ) : ''
                                                }
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item md={4} xs={4} className={classes.checkBokLabel}>
                                            <FormControlLabel
                                                disabled={disabled || currentSalary}
                                                className={classes.taxCheckbox}
                                                name={'upsaleCommission'}
                                                label='Upsales Commission'
                                                checked={values.upsaleCommission || false}
                                                labelPlacement="start"
                                                control={
                                                    <GreenCheckbox />
                                                }
                                                onChange={(event) => onChange(event, setFieldValue, values)}
                                            />
                                        </Grid>
                                        <Grid item xs={8}>
                                            <NumberFormat
                                                customInput={TextField}
                                                suffix='%'
                                                thousandSeparator
                                                allowNegative={false}
                                                disabled={disabled || currentSalary || !values.upsaleCommission}
                                                fullWidth
                                                error={values.upsaleCommission && errors.upsalePercent && touched.upsalePercent ? true : false}
                                                helperText={(values.upsaleCommission && errors.upsalePercent && touched.upsalePercent) && errors.upsalePercent}
                                                label="Commission Rate"
                                                name="upsalePercent"
                                                onBlur={() => {
                                                    setFieldTouched('upsalePercent', true);
                                                }}
                                                onChange={event => {
                                                    setFieldValue('upsalePercent', event.target.value)
                                                }}
                                                value={
                                                    values.upsalePercent ?
                                                        (Number.isInteger(parseFloat(values.upsalePercent)) ?
                                                            parseInt(values.upsalePercent) :
                                                            values.upsalePercent
                                                        ) : ''
                                                }
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item sm={2} style={{ padding: 0 }}>
                                        </Grid>
                                        <Grid item sm={4} xs={6}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    error={(
                                                        (values.salaryCheckBox && values.salary) ||
                                                        (values.hourlyCheckBox && values.hourly) ||
                                                        (values.laborCommission && values.laborPercent) ||
                                                        (values.saleCommission && values.salePercentNewSale && values.salePercentRetSale) ||
                                                        (values.upsaleCommission && values.upsalePercent)
                                                    ) && errors.startDate && touched.startDate ? true : false}
                                                    helperText={((
                                                        (values.salaryCheckBox && values.salary) ||
                                                        (values.hourlyCheckBox && values.hourly) ||
                                                        (values.laborCommission && values.laborPercent) ||
                                                        (values.saleCommission && values.salePercentNewSale && values.salePercentRetSale) ||
                                                        (values.upsaleCommission && values.upsalePercent)
                                                    ) && errors.startDate && touched.startDate) && errors.startDate}
                                                    disabled={
                                                        disabled ||
                                                        currentSalary ||
                                                        !(
                                                            (values.salaryCheckBox && values.salary) ||
                                                            (values.hourlyCheckBox && values.hourly) ||
                                                            (values.laborCommission && values.laborPercent) ||
                                                            (values.saleCommission && values.salePercentNewSale && values.salePercentRetSale) ||
                                                            (values.upsaleCommission && values.upsalePercent)
                                                        )}
                                                    minDate={false}
                                                    maxDate={values.endDate ? (isDate(values.endDate) ? values.endDate : new Date(values.endDate)) : undefined}
                                                    fullWidth
                                                    className={classes.datepicker}
                                                    inputVariant="outlined"
                                                    label='Start Date'
                                                    format="MM/dd/yyyy"
                                                    value={values.startDate || null}
                                                    onChange={date => {
                                                        setFieldValue('startDate', date)
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item sm={4} xs={6}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    error={(
                                                        (values.salaryCheckBox && values.salary) ||
                                                        (values.hourlyCheckBox && values.hourly) ||
                                                        (values.laborCommission && values.laborPercent) ||
                                                        (values.saleCommission && values.salePercentNewSale && values.salePercentRetSale) ||
                                                        (values.upsaleCommission && values.upsalePercent)
                                                    ) && errors.endDate && touched.endDate ? true : false}
                                                    helperText={((
                                                        (values.salaryCheckBox && values.salary) ||
                                                        (values.hourlyCheckBox && values.hourly) ||
                                                        (values.laborCommission && values.laborPercent) ||
                                                        (values.saleCommission && values.salePercentNewSale && values.salePercentRetSale) ||
                                                        (values.upsaleCommission && values.upsalePercent)
                                                    ) && errors.endDate && touched.endDate) && errors.endDate}
                                                    disabled={disabled}
                                                    fullWidth
                                                    className={classes.datepicker}
                                                    inputVariant="outlined"
                                                    label='End Date'
                                                    format="MM/dd/yyyy"
                                                    value={values.endDate || null}
                                                    InputAdornmentProps={{ position: "end" }}
                                                    minDate={values.startDate ? (isDate(values.startDate) ? values.startDate : new Date(values.startDate)) : new Date().setDate((new Date()).getDate() + 1)}
                                                    onChange={date => {
                                                        setFieldValue('endDate', date)
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item sm={2} style={{ padding: 0 }}>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <Divider />
                                <CardActions className={classes.actions}>
                                    <Button
                                        onClick={onClose}
                                        variant="contained">
                                        Close
                                    </Button>
                                    <Button
                                        disabled={
                                            disabled ||
                                            (!values.salaryCheckBox && !values.hourlyCheckBox && !values.laborCommission && !values.saleCommission && !values.upsaleCommission)
                                            ||
                                            !(
                                                (!values.salaryCheckBox || (values.salaryCheckBox && values.salary)) &&
                                                (!values.hourlyCheckBox || (values.hourlyCheckBox && values.hourly)) &&
                                                (!values.laborCommission || (values.laborCommission && values.laborPercent)) &&
                                                (!values.saleCommission || (values.saleCommission && values.salePercentNewSale && values.salePercentRetSale)) &&
                                                (!values.upsaleCommission || (values.upsaleCommission && values.upsalePercent))
                                            )
                                            || saveHistory
                                        }
                                        type='submit'
                                        className={classes.saveButton}
                                        variant="contained">
                                        Save
                                    </Button>
                                    {saveHistory && <CircularProgress size={24} />}
                                </CardActions>
                            </form>
                        )}
                    </Formik>
                </Card>
            </Modal>
            <ConfirmModalExistsDate
                title={'The selected date is not available.  Please choose a different date.'}
                openConfirm={modalConfirm}
                closeConfirm={() => setModalConfirm(false)}
                onConfirm={() => setModalConfirm(false)}
            />

            <Snackbar anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }} open={openSnackbar}
                autoHideDuration={3000}
                onClose={() => setOpenSnackbar(false)}
            >
                <Alert
                    elevation={6} variant="filled" severity={isSuccess ? "success" : "error"}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isSuccess ? 'Update successfuly.' : 'An error occurred, please try again later.'}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    );
};

AddCompensation.defaultProps = {
    salaryHistory: [],
    open: false,
    onClose: () => {
    }
};

export default AddCompensation;
