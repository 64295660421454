import React, { useEffect, useState } from 'react'
import {
    Card, CardHeader, CardContent, Divider,
    TableContainer, Table, TableHead, TableBody, Grid,
    TableRow, TableCell, Typography, CircularProgress, FormControlLabel,TextField
} from '@material-ui/core'
import moment from 'moment'
import { GreenCheckbox, GreenRadio, Pagination } from 'components'
import { makeStyles } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Page, KeyboardDatePicker } from 'components'
import { AccessControl } from "components/AccessControl/AccessControl";
import { PERMISSIONS } from "common/permissions";
import axios from 'utils/axios'
import apiConfig from 'apiConfig'
import {Link} from "react-router-dom";
import {ArrowDownward, ArrowUpward, KeyboardArrowDown, KeyboardArrowUp} from '@material-ui/icons'
import NumberFormat from 'react-number-format'
import Axios from 'axios';
import { useSearchDelay } from 'utils/useSearchDelay'

const useStyles = makeStyles(theme => ({
    page: {
        padding: theme.spacing(4)
    },
    circularProgress: {
        marginTop: '30px',
    },
    jobOptions: {
        display: 'flex',
        justifyContent: 'center'
    },
    jobOptionsDis: {
        display: 'flex',
        justifyContent: 'center',
        pointerEvents: 'none',
        opacity: 0.5
    },
    options: {
        alignItems: 'baseline'
    },
    dateRange: {
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1)
    },
    tableContainer: {
        marginTop: theme.spacing(3),
        overflowX: 'auto'
    },
    headCell: {
        borderBottom: '1px solid #888888',
        fontSize: '16px',
        padding: '8px 16px',
        '& span': {
            display: 'flex',
            alignItems: 'center'
        }
    },
    jobCompletion: {
        cursor: 'pointer',
        '& td': {
            padding: '0'
        },
        '&:hover': {
            backgroundColor: '#43a047',
            '& td': {
                color: '#ffffff',
                '& p': {
                    color: '#ffffff'
                },
                '& a': {
                    color: '#ffffff'
                }
            }
        }
    },
    paginate: {
        marginTop: theme.spacing(3),
        display: 'flex',
        justifyContent: 'center'
    },
    linkTo: {
        width: '100%',
        height: '100%',
        color: 'black',
        padding: '16px',
        display: 'block'
    },
    customTableCell: {
        borderBottom: '1px solid #969696',
        cursor: 'pointer'
    },
    iconSort: {
        marginBottom: '-5px',
        marginLeft: '10px'
    }
}));

let pageNumber = 1;
let forcePage = 0;
const JobCompletion = ({ history, location }) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const fromDate = params.get('fromDate');

    const toDate = params.get('toDate');
    const scheduledJob = params.get('scheduledJob');
    const completedJobs = params.get('completedJobs');
    const toSendInvoice = params.get('toSendInvoice');
    const postToQuickBooks = params.get('postToQuickBooks');
    const paramUnpaidBalance = params.get('unpaidBalance');
    const paramLaborCommissions = params.get('laborCommissions');
    const paramInvoiceNumber = params.get('invoiceNumber');
    const paramTotalAmount = params.get('totalAmount');
    const paramForcePage = params.get('pageNumber') * 1;

    const classes = useStyles();
    const ipad = useMediaQuery('(max-width: 769px)');
    const txtStatusJobCompleted = 'Job Completed';
    const txtStatusScheduled = 'Scheduled';
    const stages = ['None', 'To Bid', 'Bid Completed', 'To Be Scheduled', txtStatusScheduled, txtStatusJobCompleted, 'Cancelled', 'Customer Service'];

    const [forcePage, setForcePage] = useState(paramForcePage - 1 ?? 0);
    const [pageCount, setPageCount] = useState(0);
    const [jobCompletion, setJobCompletion] = useState([]);
    const [statusJob, setStatusJob] = useState(0);
    const [dateRange, setDateRange] = useState({
        startdate: location.state ? location.state.date : fromDate ? moment(new Date(fromDate)).toISOString() : moment().subtract(1, 'months').startOf('month').toISOString(),
        enddate: location.state ? location.state.date : moment(toDate ? new Date(toDate) : '').toISOString()
    });
    
    const [statusScheduledJob, setStatusScheduledJob] = useState(scheduledJob === 'true');
    const [statusCompletedJob, setStatusCompletedJob] = useState(completedJobs == null ? true : completedJobs === 'true');
    const [needSendInvoice, setNeedSendInvoice] = useState(toSendInvoice === 'true');
    const [needPostToQuickBooks, setNeedPostToQuickBooks] = useState(postToQuickBooks === 'true');
    const [unpaidBalance, setUnpaidBalance] = useState(paramUnpaidBalance === 'true');
    const [laborCommisions, setLaborCommisions] = useState(paramLaborCommissions === 'true');
    const [invoiceNumber, setInvoiceNumber] = useState(paramInvoiceNumber);
    const [totalAmount, setTotalAmount] = useState(paramTotalAmount);
    const [isLoadingJobs, setIsLoadingJobs] = useState(false);
    const [sortBy, setSortBy] = useState(null);
    const [sortType, setSortType] = useState('ASC');
    const [fetchCancelToken, setCancelToken] = useState();

    const searchInvoiceNumber = useSearchDelay(invoiceNumber);
    const searchTotalAmount = useSearchDelay(totalAmount);

    const loadDocument = (type) => {
        setSortBy(type)
        setSortType(type == sortBy ? (sortType === 'ASC' ? 'DESC' : 'ASC') : 'ASC');
    }
    const getSortIcon = (type) => {
        return (
            sortBy == type &&
            (
                sortType == 'ASC' ?
                    <ArrowUpward className={classes.iconSort}/>
                    :
                    <ArrowDownward className={classes.iconSort}/>
            )
        )
    }

    const minDate = enddate => {
        const date = new Date(enddate);
        date.setDate(date.getDate() + 1);
        return date;
    }
    const maxDate = startdate => {
        const date = new Date(startdate);
        date.setDate(date.getDate() - 1);
        return date;
    }

    const getJobs = params => {
        if (typeof fetchCancelToken != typeof undefined) {
            fetchCancelToken.cancel("Operation canceled due to new request.");
        }
        setIsLoadingJobs(true);
        const cancelToken = Axios.CancelToken.source();
        setCancelToken(cancelToken);
        params.sortBy = sortBy;
        params.sortType = sortType;
        axios.get(apiConfig.url.BASE_URL + apiConfig.url.CUSTOMER_JOB_COMPLETION, 
            { params ,
              cancelToken: cancelToken.token}
        ).then(res => {
            setJobCompletion(res.data.data);
            setPageCount(res.data.pageCount);
            setIsLoadingJobs(false);
        })
        //.finally(() => setIsLoadingJobs(false))
    }

    const onPageChange = (page) => {
        setForcePage(page.selected);

        setIsLoadingJobs(true);
        const params = {
            startDate: dateRange.startdate || '',
            endDate: dateRange.enddate || '',
            includeScheduled: statusScheduledJob,
            includeCompleted: statusCompletedJob,
            includeUnpaid: unpaidBalance,
            includeLaborComissions: laborCommisions,
            needSendInvoice,
            needPostToQuickBooks,
            invoiceNumber: invoiceNumber || '',
            totalAmount: totalAmount || '',
            pageNumber: page.selected + 1
        };
        getJobs(params);
    };

    const openCompletedJob = (job) => {
        const { jobId, customerName, customerId } = job;
        window.open(`/customers/${encodeURIComponent(customerName)}/${customerId}/jobs/${jobId}/completed`)
    }

    const getOpenCompletedJob = (job) => {
        const { jobId, customerName, customerId } = job;
        const params = {
            fromDate: moment(dateRange.startdate).format('MM/DD/YYYY')  || '',
            toDate: moment(dateRange.enddate).format('MM/DD/YYYY') || '',
            scheduledJob: statusScheduledJob,
            completedJobs: statusCompletedJob,
            unpaidBalance: unpaidBalance,
            laborCommissions: laborCommisions,
            toSendInvoice: needSendInvoice,
            postToQuickBooks: needPostToQuickBooks,
            invoiceNumber: invoiceNumber || '',
            totalAmount: totalAmount || '',
            pageNumber: forcePage + 1
        };

        return `/customers/${encodeURIComponent(customerName)}/${customerId}/jobs/${jobId}/completed?completion=${JSON.stringify(params)}`;
    }
    
    useEffect(() => {
        if ((statusScheduledJob || statusCompletedJob)) {
            setIsLoadingJobs(true);

            const params = {
                endDate: dateRange.enddate || '',
                includeScheduled: statusScheduledJob,
                includeCompleted: statusCompletedJob,
                includeUnpaid: unpaidBalance,
                includeLaborComissions: laborCommisions,
                needSendInvoice,
                needPostToQuickBooks,
                invoiceNumber : invoiceNumber || '',
                totalAmount : totalAmount || '',
                pageNumber: forcePage + 1
            };
            if (location.state) {
                const { routeId } = location.state;
                params.routeId = routeId;
            } else {
                params.startDate = dateRange.startdate || '';
            }
            getJobs(params);
        }
    }, [dateRange, statusScheduledJob, statusCompletedJob, needSendInvoice, needPostToQuickBooks, unpaidBalance, laborCommisions, searchInvoiceNumber, searchTotalAmount, sortBy, sortType]);

    return (
        <AccessControl requiredPermission={PERMISSIONS.page.jobCompletion} redirect>
            <Page title='Job Completion' className={classes.page}>
                <Card>
                    <CardHeader title='Job Completion and Receivables' />
                    <Divider />
                    <CardContent>
                        <div style={ipad ? { flexDirection: 'column', alignItems: 'center' } : null}
                            className={isLoadingJobs ? classes.jobOptionsDis : classes.jobOptions}>
                            <div style={ipad ? { marginRight: '0' } : null} className={classes.dateRange}>
                                <KeyboardDatePicker
                                    style={{ marginRight: 10 }}
                                    label='From Date'
                                    value={dateRange.startdate || null}
                                    maxDate={dateRange.enddate && maxDate(dateRange.enddate)}
                                    onChange={date => {
                                        setDateRange({ ...dateRange, startdate: date && moment(date).toISOString() });
                                    }}
                                    onClose={() => setForcePage(0)}
                                />
                                <KeyboardDatePicker
                                    label='To Date'
                                    value={dateRange.enddate || null}
                                    minDate={dateRange.startdate && minDate(dateRange.startdate)}
                                    onChange={date => {
                                        setDateRange({ ...dateRange, enddate: date && moment(date).toISOString() });
                                    }}
                                    onClose={() => setForcePage(0)}
                                />
                            </div>
                            <div className={classes.options}>
                                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                    <Typography style={{ marginRight: '8px' }}>Stage: </Typography>
                                    <div className={classes.optionGroup}>
                                        <div className={classes.radioOptions}>
                                            <FormControlLabel
                                                label='Scheduled Jobs'
                                                checked={statusScheduledJob}
                                                control={<GreenCheckbox />}
                                                onChange={e => {
                                                    setStatusScheduledJob(e.target.checked);
                                                    setForcePage(0);
                                                }}
                                                style={{ marginRight: 10 }}
                                            />
                                            <FormControlLabel
                                                label='Completed Jobs'
                                                checked={statusCompletedJob}
                                                control={<GreenCheckbox />}
                                                onChange={e => {
                                                    setStatusCompletedJob(e.target.checked);
                                                    setForcePage(0);
                                                }}
                                                style={{ marginRight: 10 }}
                                            />
                                            <NumberFormat
                                            customInput={TextField}
                                            allowNegative={false}
                                            style={{ marginRight: 10 }}
                                            label='Invoice Number'
                                            variant='outlined'
                                            value={invoiceNumber || ''}
                                            onChange={event => {
                                                setInvoiceNumber(event.target.value);
                                                setForcePage(0);
                                            }}
                                            />
                                            <NumberFormat
                                            customInput={TextField}
                                            allowNegative={false}
                                            style={{ marginRight: 10 }}
                                            label='Job Total Amount'
                                            variant='outlined'
                                            value={totalAmount || ''}
                                            onChange={event => {
                                                setTotalAmount(event.target.value);
                                                setForcePage(0);
                                            }}
                                        />
                                        </div>
                                    </div>
                                </div>
                                <Divider />
                                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                    <Typography style={{ marginRight: '13px' }}>Filter: </Typography>
                                    <div className={classes.optionGroup}>
                                        <div className={classes.radioOptions}>
                                            <FormControlLabel
                                                disabled={false}
                                                label='Need to Send Invoice'
                                                checked={needSendInvoice}
                                                onChange={e => {
                                                    setNeedSendInvoice(e.target.checked);
                                                    setForcePage(0);
                                                }}
                                                control={<GreenCheckbox />}
                                                style={{ marginRight: 9 }}
                                            />
                                            <FormControlLabel
                                                label='Need to Post to Quickbooks'
                                                checked={needPostToQuickBooks}
                                                onChange={e => {
                                                    setNeedPostToQuickBooks(e.target.checked);
                                                    setForcePage(0);
                                                }}
                                                control={<GreenCheckbox />}
                                                style={{ marginRight: 9 }}
                                            />
                                            <FormControlLabel
                                                label='Unpaid Balance'
                                                checked={unpaidBalance}
                                                onChange={e => {
                                                    setUnpaidBalance(e.target.checked);
                                                    setForcePage(0);
                                                }}
                                                control={<GreenCheckbox />}
                                                style={{ marginRight: 9 }}
                                            />
                                            <FormControlLabel
                                                label='Labor Commisions'
                                                checked={laborCommisions}
                                                onChange={e => {
                                                    setLaborCommisions(e.target.checked);
                                                    setForcePage(0);
                                                }}
                                                control={<GreenCheckbox />}
                                                style={{ marginRight: 9 }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Divider />
                        {isLoadingJobs &&
                            <Grid
                                container
                                spacing={0}
                                align="center"
                                justifyContent="center"
                                direction="column">
                                <Grid item>
                                    <CircularProgress className={classes.circularProgress} size={32} />
                                </Grid>
                            </Grid>
                        }
                        {!isLoadingJobs &&
                            <TableContainer className={classes.tableContainer}>
                                <Table style={{ minWidth: '1400px', height: '100%' }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell onClick={() => loadDocument('Status')} align='center' className={classes.headCell}>
                                                <span>Status { getSortIcon('Status')}</span>
                                            </TableCell>
                                            <TableCell onClick={() => loadDocument('Date')} align='center' className={classes.headCell}>
                                                <span>Date { getSortIcon('Date')}</span>
                                            </TableCell>
                                            <TableCell onClick={() => loadDocument('RouteOrder')} align='center' className={classes.headCell}>
                                                <span>Route # { getSortIcon('RouteOrder')}</span>
                                            </TableCell>
                                            <TableCell onClick={() => loadDocument('Customer')} align='center' className={classes.headCell}>
                                                <span>Customer { getSortIcon('Customer')}</span>
                                            </TableCell>
                                            <TableCell onClick={() => loadDocument('FieldTechnicians')} align='center' className={classes.headCell}>
                                                <span>Field Technicians { getSortIcon('FieldTechnicians')}</span>
                                            </TableCell>
                                            <TableCell onClick={() => loadDocument('InvoiceSent')} align='center' className={classes.headCell}>
                                                <span>Invoice Sent { getSortIcon('InvoiceSent')}</span>
                                            </TableCell>
                                            <TableCell onClick={() => loadDocument('InvoiceNumber')} align='center' className={classes.headCell}>
                                                <span>Invoice Number { getSortIcon('InvoiceNumber')}</span>
                                            </TableCell>
                                            <TableCell onClick={() => loadDocument('PaymentReceived')} align='center' className={classes.headCell}>
                                                <span>Payment Received { getSortIcon('PaymentReceived')}</span>
                                            </TableCell>
                                            <TableCell onClick={() => loadDocument('PostToQuickbooks')} align='center' className={classes.headCell}>
                                                <span>Post to Quickbooks { getSortIcon('PostToQuickbooks')}</span>
                                            </TableCell>
                                            <TableCell onClick={() => loadDocument('CommissionsAssigned')} align='center' className={classes.headCell}>
                                                <span>Commissions Assigned { getSortIcon('CommissionsAssigned')}</span>
                                            </TableCell>
                                            <TableCell onClick={() => loadDocument('UnpaidBalance')} align='center' className={classes.headCell}>
                                                <span>Unpaid Balance { getSortIcon('UnpaidBalance')}</span>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {jobCompletion && jobCompletion.length > 0 ? jobCompletion.map((job) => (
                                            <TableRow key={job.jobId} className={classes.jobCompletion}>
                                                <TableCell>
                                                    <Link className={classes.linkTo} to={() => getOpenCompletedJob(job)}>
                                                    {stages[job.status] || ''}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>
                                                    <Link className={classes.linkTo} to={() => getOpenCompletedJob(job)}>
                                                        {stages[job.status] == txtStatusJobCompleted ? (job.completedDate ? moment.utc(job.completedDate).local().format('MM/DD/YYYY') : '') : ''}
                                                        {stages[job.status] == txtStatusScheduled ? (job.scheduledDate ? moment.utc(job.scheduledDate).local().format('MM/DD/YYYY') : '') : ''}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>
                                                    <Link className={classes.linkTo} to={() => getOpenCompletedJob(job)}>
                                                    {job.routeOrder || ''}
                                                    </Link>
                                                    </TableCell>
                                                <TableCell>
                                                    <Link className={classes.linkTo} to={() => getOpenCompletedJob(job)}>
                                                    <Typography>
                                                        {job.customerName || ''}</Typography>
                                                    <Typography>{(job.customerAddress && job.customerAddress.address1) || ''}</Typography>
                                                    <Typography>
                                                        {(job.customerAddress && `${job.customerAddress.city || ''}, ${job.customerAddress.state && job.customerAddress.state.name || ''} ${job.customerAddress.zipCode || ''}`) || ''}
                                                    </Typography>
                                                    </Link>
                                                </TableCell>
                                                <TableCell>
                                                    <Link className={classes.linkTo} to={() => getOpenCompletedJob(job)}>
                                                    {job.routeEmployees.map((e, i) => (
                                                        <p key={i}>{e.firstName || ''} {e.lastName || ''}</p>
                                                    ))}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>
                                                    <Link className={classes.linkTo} to={() => getOpenCompletedJob(job)}>
                                                    {job.invoiceSent ? moment(job.invoiceSent).format('MM/DD/YYYY') : ''}
                                                    </Link>
                                                    </TableCell>
                                                <TableCell>
                                                    <Link className={classes.linkTo} to={() => getOpenCompletedJob(job)}>
                                                    {job.invoiceNumber || ''}
                                                    </Link>
                                                    </TableCell>    
                                                <TableCell>
                                                    <Link className={classes.linkTo} to={() => getOpenCompletedJob(job)}>
                                                    {job.paymentReceived ? 'Yes' : 'No'}
                                                    </Link>
                                                    </TableCell>
                                                <TableCell>
                                                    <Link className={classes.linkTo} to={() => getOpenCompletedJob(job)}>
                                                    {job.postToQuickbooks}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>
                                                    <Link className={classes.linkTo} to={() => getOpenCompletedJob(job)}>
                                                    {job.commissionsAssigned}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>
                                                    <Link className={classes.linkTo} to={() => getOpenCompletedJob(job)}>
                                                    ${job.unpaidBalance ? parseFloat(job.unpaidBalance).toFixed(2).replace(/(\d)(?=(\d{3})+\.\d{2})/g, '$1,') : '0.00'}
                                                    </Link>
                                                    </TableCell>
                                            </TableRow>
                                        )) : (
                                            <TableRow>
                                                <TableCell colSpan={10}>
                                                    <Typography style={{ fontWeight: 500 }}>No found Job Completion</Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        }
                    </CardContent>
                </Card>

                <div style={pageCount === 0 ? { display: 'none' } : null} className={classes.paginate}>
                    <Pagination pageCount={pageCount} forcePage={forcePage} onPageChange={onPageChange} />
                </div>
            </Page>
        </AccessControl>
    )
}

export default JobCompletion;
