import React from 'react'
import moment from 'moment'
import { Document, Page, Text, View, StyleSheet, Font , Image } from '@react-pdf/renderer';
import {formatNumber} from "../../../../../utils/formatNumber";

const styles = StyleSheet.create({
    invoiceSection: {
        fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 20
    },
    sectionOne: {
        flexDirection: "row",
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center'
    },
    textBold: {
        fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px'
    },
    sectionDateAndInvoice: {
        width: '50%', height: 'auto', flexDirection: 'row'
    },
    sectionChildDateAndInvoice: {
        fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '50%', border: 1, textAlign: 'center', padding: 6
    },
    sectionTwo: {
        marginTop: 60, 
        flexDirection: "row",
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center'
    },
    sectionThree: {
        marginTop: 10, 
        flexDirection: "row",
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center'
    },
    dueDate: {
        fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '100%', border: 1, textAlign: 'center', padding: 6
    },
    sectionTable: {
        width: '100%', height: 'auto', flexDirection: 'row'
    },
    jobDescription: {
        marginTop: 10, 
        flexDirection: "row",
        width: '100%',
    }
});

const JobMultipleInvoicePdf = (props) => {

    Font.register({
        family: 'Roboto',
        fonts: [
            {
                src: `https://cdn.jsdelivr.net/npm/roboto-fontface@0.10.0/fonts/roboto/Roboto-Regular.woff`,
                fontWeight: 'medium'
            },
            {
                src: `https://cdn.jsdelivr.net/npm/roboto-fontface@0.10.0/fonts/roboto/Roboto-Bold.woff`,
                fontWeight: 'bold'
            }
        ]
    })

    return (
        <>
            <Document>
                {props.jobInfoPdf.map((jobInfo, index) => (
                    <Page size="A4" key={index} style={{ padding: 30, paddingTop: 20 }}>
                        <View style={{flexDirection: "row",justifyContent: 'space-between', width: '100%',}}>
                            <View style={{ width: '50%', height: 'auto', alignItems: 'flex-start' }}>
                                <Image resizeMode={'contain'} src="/images/quote_invoice/Logos/Logo1.jpg" style={{ width: 80, height: 35 }}/>
                            </View>
                            <View style={{ width: '50%', height: 'auto', alignItems: 'flex-end', verticalAlign: 'super' }}>
                                <Text style={styles.invoiceSection}>Invoice</Text>
                            </View>
                        </View>

                        <View
                            style={styles.sectionOne}>
                            <View style={{ width: '50%', height: 'auto', alignItems: 'flex-start' }}>
                                <Text style={styles.textBold}>
                                    {jobInfo && jobInfo.company ? jobInfo.company.name : ""}
                                </Text>
                                <Text style={styles.textBold}>
                                    {jobInfo && jobInfo.company && jobInfo.company.addresses && jobInfo.company.addresses.length > 0 ? jobInfo.company.addresses[0].address1 : ""}
                                </Text>
                                <Text style={styles.textBold}>
                                    {jobInfo && jobInfo.company && jobInfo.company.addresses && jobInfo.company.addresses.length > 0 ? jobInfo.company.addresses[0].city+", " : ""}
                                    {jobInfo && jobInfo.company && jobInfo.company.addresses && jobInfo.company.addresses.length > 0 ? jobInfo.company.addresses[0].state.name+" " : ""}
                                    {jobInfo && jobInfo.company && jobInfo.company.addresses && jobInfo.company.addresses.length > 0 ? jobInfo.company.addresses[0].zipCode : ""}
                                </Text>
                            </View>
                            <View style={{ width: '50%', height: 'auto', alignItems: 'flex-end' }}>
                                <View style={styles.sectionDateAndInvoice}>
                                    <Text style={styles.sectionChildDateAndInvoice}>DATE</Text>
                                    <Text style={[styles.sectionChildDateAndInvoice, { borderLeft: 0 }]}>INVOICE #</Text>
                                </View>
                                <View style={styles.sectionDateAndInvoice}>
                                    <Text style={[styles.sectionChildDateAndInvoice, { borderTop: 0 }]}>
                                        {jobInfo && jobInfo.scheduledDate ? moment(jobInfo.scheduledDate).format('MM/DD/YYYY') : ""}
                                    </Text>
                                    <Text style={[styles.sectionChildDateAndInvoice, { borderLeft: 0, borderTop: 0 }]}>{jobInfo && jobInfo.invoiceNo ? jobInfo.invoiceNo : ""}</Text>
                                </View>
                            </View>
                        </View>

                        <View
                            style={styles.sectionTwo}
                            >
                            <View style={{ width: '55%', height: 'auto', alignItems: 'flex-start' }}>
                                <Text style={styles.textBold}>BILL TO</Text>
                                <Text style={{ fontSize: 10, fontFamily: 'Roboto', fontWeight: 'medium' }}>{jobInfo && jobInfo.customer ? jobInfo.customer.companyName : ""}</Text>
                                <Text style={{ fontSize: 10, fontFamily: 'Roboto', fontWeight: 'medium' }}>
                                    {jobInfo && jobInfo.customer && jobInfo.customer.address && jobInfo.customer.address.address1 ? jobInfo.customer.address.address1 : ""}
                                </Text>
                                <Text style={{ fontSize: 10, fontFamily: 'Roboto', fontWeight: 'medium' }}>
                                    {jobInfo && jobInfo.customer && jobInfo.customer.address ? jobInfo.customer.address.city+", " : ""}
                                    {jobInfo && jobInfo.customer && jobInfo.customer.address ? jobInfo.customer.address.state.name+" " : ""}
                                    {jobInfo && jobInfo.customer && jobInfo.customer.address ? jobInfo.customer.address.zipCode : ""}
                                </Text>
                            </View>
                            <View style={{ width: '30%', height: 'auto' }}>
                                <Text style={styles.textBold}>CUSTOMER/JOB</Text>
                                <Text style={{ fontSize: 10, fontFamily: 'Roboto', fontWeight: 'medium' }}>
                                    {jobInfo && jobInfo.job && jobInfo.job.jobAddress ? jobInfo.job.jobAddress.name : ""}
                                </Text>
                                <Text style={{ fontSize: 10, fontFamily: 'Roboto', fontWeight: 'medium' }}>
                                    {jobInfo && jobInfo.job && jobInfo.job.jobAddress && jobInfo.job.jobAddress.address1 ? jobInfo.job.jobAddress.address1 : ""}
                                </Text>
                                <Text style={{ fontSize: 10, fontFamily: 'Roboto', fontWeight: 'medium' }}>
                                    {jobInfo && jobInfo.job && jobInfo.job.jobAddress ? jobInfo.job.jobAddress.city+", " : ""}
                                    {jobInfo && jobInfo.job && jobInfo.job.jobAddress ? jobInfo.job.jobAddress.state.name+" " : ""}
                                    {jobInfo && jobInfo.job && jobInfo.job.jobAddress ? jobInfo.job.jobAddress.zipCode : ""}
                                </Text>
                            </View>
                            <View style={{ width: '15%', height: 'auto' }} />
                        </View>

                        <View style={styles.jobDescription}>
                            <Text style={styles.textBold}>Job Description:</Text>
                            <Text style={{ width: '60%', marginLeft: 10, fontSize: 10, fontFamily: 'Roboto', fontWeight: 'medium' }}>{jobInfo?.job?.description ?? ''}</Text>
                        </View>

                        <View
                            style={styles.sectionThree}
                            >
                            <View style={{ width: '15%', height: 'auto' }}>
                                <Text style={[styles.dueDate, { borderColor: 'white', color: 'white' }]}>PO #</Text>
                                <Text style={[styles.dueDate, { borderBottom: 0 }]}>
                                    PO # {'\u00A0'} {jobInfo && jobInfo.job ? jobInfo.job.purchaseOrder : ""}
                                </Text>
                            </View>
                            <View style={{ width: '65%', height: 'auto' }} />
                            <View style={{ width: '20%', height: 'auto', marginTop: 1 }}>
                                <Text style={[styles.dueDate, { borderBottom: 0 }]}>DUE DATE</Text>
                                <Text style={[styles.dueDate, { borderBottom: 0 }]}>
                                    {jobInfo && jobInfo.dueDate ? jobInfo.dueDate : '\u00A0'}
                                </Text>
                            </View>
                        </View>

                        <View style={styles.sectionTable}>
                            <Text style={{ fontSize: 10, width: '60%', border: 1, textAlign: 'left', padding: 6, borderRight: 0, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>Description</Text>
                            <Text style={{ fontSize: 10, width: '10%', border: 1, textAlign: 'center', padding: 6, borderRight: 0, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>Quantity</Text>
                            <Text style={{ fontSize: 10, width: '10%', border: 1, textAlign: 'center', padding: 6, borderRight: 0, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>Rate</Text>
                            <Text style={{ fontSize: 10, width: '20%', border: 1, textAlign: 'center', padding: 6, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>Amount</Text>
                        </View>
                        {jobInfo && jobInfo.jobPriceItems && jobInfo.job && jobInfo.job.displayOnPrintOutsEmails && jobInfo.jobPriceItems.map(function (value, index, array) {
                            return <View key={index} style={styles.sectionTable}>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '60%', border: 1, textAlign: 'left', padding: 6, borderRight: 0, borderTop: 0 }}>{value.description}</Text>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '10%', border: 1, textAlign: 'right', padding: 6, borderRight: 0, borderTop: 0 }}>{value.quantity}</Text>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '10%', border: 1, textAlign: 'right', padding: 6, borderRight: 0, borderTop: 0 }}>
                                            {(value && value.price && `$${formatNumber(value.price || 0, 2)}`) || '$0.00'}
                                        </Text>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '20%', border: 1, textAlign: 'center', padding: 6, borderTop: 0 }}>
                                            {(value && value.total && `$${formatNumber(value.total || 0,2)}`) || '$0.00'}
                                        </Text>
                                    </View>
                        })}
                        
                        {jobInfo && jobInfo.job && jobInfo.job.discountPercent > 0 && jobInfo.job.discountDollars > 0
                        ? <View style={styles.sectionTable}>
                                <Text style={{ width: '10%' }}></Text>
                                <Text style={{ width: '50%'}}></Text>
                                <Text style={{ width: '20%', border: 1, borderRight: 0, borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, paddingRight: 0, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>
                                    Discount ({jobInfo.job.discountPercent}%)
                                </Text>
                                <Text style={{ width: '20%', border: 1, borderLeft: 0, borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, fontFamily: 'Roboto', fontWeight: 'medium' }}>
                                    {(jobInfo && jobInfo.job && `$${formatNumber(jobInfo.job.discountDollars || 0,2)}`) || '$0.00'}
                                </Text>
                            </View>
                        : null }

                        <View style={styles.sectionTable}>
                            <Text style={{ width: '10%' }}></Text>
                            <Text style={{ width: '50%'}}></Text>
                            <Text style={{ width: '20%', border: 1, borderRight: 0, borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, paddingRight: 0, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>
                                Sales Tax ({jobInfo && jobInfo.job ? jobInfo.job.taxPercent : "0"}%)
                            </Text>
                            <Text style={{ width: '20%', border: 1, borderLeft: 0, borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, fontFamily: 'Roboto', fontWeight: 'medium' }}>
                                {(jobInfo && jobInfo.taxDollar && `$${formatNumber(jobInfo.taxDollar || 0, 2)}`) || '$0.00'}
                            </Text>
                        </View>

                        {jobInfo?.tip > 0
                        ? <View style={styles.sectionTable}>
                                <Text style={{ width: '10%' }}></Text>
                                <Text style={{ width: '50%'}}></Text>
                                <Text style={{ width: '20%', border: 1, borderRight: 0, borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, paddingRight: 0, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>
                                    Tip
                                </Text>
                                <Text style={{ width: '20%', border: 1, borderLeft: 0, borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, fontFamily: 'Roboto', fontWeight: 'medium' }}>
                                    ${formatNumber(jobInfo.tip, 2)}
                                </Text>
                            </View>
                        : null}

                        <View style={styles.sectionTable}>
                            <Text style={{ width: '10%' }}></Text>
                            <Text style={{ width: '50%'}}></Text>
                            <Text style={{ width: '20%', border: 1, borderRight: 0, borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, paddingRight: 0, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>
                                Total
                            </Text>
                            <Text style={{ width: '20%', border: 1, borderLeft: 0, borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, fontFamily: 'Roboto', fontWeight: 'medium' }}>
                                {(jobInfo && jobInfo.totalAmount && `$${formatNumber(jobInfo.totalAmount || 0, 2)}`) || '$0.00'}
                            </Text>
                        </View>

                        <View
                            style={{
                                marginTop: 50, 
                                width: '100%',
                            }}
                        >
                            <Text style={{ fontSize: '10px', fontFamily: 'Roboto', fontWeight: 'medium' }}>
                                {jobInfo && jobInfo.company ? jobInfo.company.invoiceForm : ""}
                                {props.draftPdf ? props.draftPdf : ""}
                            </Text>
                        </View>
                        
                    </Page>
                ))}
            </Document>
        </>
    )
}

JobMultipleInvoicePdf.propTypes = {
    
}

export default JobMultipleInvoicePdf;
